import { API } from '../../helpers/apiHelpers';

const getCustomerProfileDetails = () => {
    const url = '/persons/profile';
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updateBusinessProfileService = (businessProfileObject) => {
    // const { companyId } = businessProfileObject;
    const url = 'persons/profile';
    return API.put(url, businessProfileObject)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updateCredentials = (credentialObject, personId, option) => {
    let url = '';
    if (option === 'username') {
        url = `persons/${personId}/resetusername`;
    }

    if (option === 'password') {
        url = `persons/${personId}/resetpassword`;
    }

    return API.put(url, credentialObject)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export {
    getCustomerProfileDetails,
    updateBusinessProfileService,
    updateCredentials
};
