import { updateObject } from '../../utils/reducer_utility';

import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT,
    AUTH_COMPANY_NAME,
    LOGOUT_START
} from '../actions/actionTypes';

const initialState = {
    token: null,
    userId: null,
    errorMessage: null,
    loading: false,
    showAlert: false,
    isLoggedIn: false,
    companyLegalName: null,
    redirect: null,
    logoutLoading: false,
    isIntakeDetailsPending: false,
    numberOfIntakeCompletedCampaigns: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case AUTH_START: {
        return updateObject(state, {
            errorMessage: null,
            loading: true
        });
    }
    case AUTH_SUCCESS: {
        return updateObject(state, {
            token: action.data.token,
            // userId: action.userId,
            personId: action.data.personId,
            isLoggedIn: true,
            role: action.data.role,
            errorMessage: null,
            loading: false,
            redirect: false,
            companyName: action.data.companies && action.data.companies[0].companyName,
            email: action.data.email,
            firstName: action.data.firstName,
            lastName: action.data.lastName,
            isIntakeDetailsPending: action.data.incompleteIntakeDetails,
            numberOfIntakeCompletedCampaigns: action.data.numberOfIntakeCompletedCampaigns
        });
    }
    case AUTH_FAIL: {
        return updateObject(state, {
            errorMessage: action.errorMessage,
            loading: false,
            showAlert: true,
            logoutLoading: false
        });
    }
    case AUTH_LOGOUT: {
        return updateObject(state, {
            token: null,
            userId: null,
            logoutLoading: false,
            isLoggedIn: false,
            showAlert: false,
            errorMessage: null,
            redirect: true,
            loading: false
        });
    }
    case AUTH_COMPANY_NAME: {
        return updateObject(state, {
            companyLegalName: action.companyLegalName,
            loading: false
        });
    }
    case LOGOUT_START:
        return updateObject(state, {
            logoutLoading: true
        });
    default:
        return state;
    }
};

export default reducer;
