/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import Logo from '../../../assets/images/Empower-new-logo.svg';

class AuthHeader extends Component {
	render() {
		const pathName = window.location.pathname;
        return (
			<header className="auth-header-main header-wrapper header-shadow">
				<div className="container">
					<div className="row">
						<div className="col-sm-3 header-logo-wrapper">
							<div className="header-logo">
								<a href="https://www.yourmediaagent.com/">
									<img src={Logo} alt="Empower-logo" />
								</a>
							</div>
						</div>
						<div className="col-sm-9 navigation-wrapper">
							<ul className="navigation-list">
								<li className="navigation-list-item">
									<a href="https://www.yourmediaagent.com/industries/">
										INDUSTRIES
									</a>
								</li>
								<li className="navigation-list-item">
									<a href="https://www.yourmediaagent.com/platform/">
										PLATFORM
									</a>
								</li>
								<li className="navigation-list-item">
									<a href="https://www.yourmediaagent.com/pricing/">
										PRICING
									</a>
								</li>
								<li className="navigation-list-item">
									<a href="https://www.yourmediaagent.com/about-us/">
										ABOUT US
									</a>
								</li>
								<li className="navigation-list-item">
									<a href="https://www.yourmediaagent.com/get-in-touch/" className="primary-btn-royal-blue getin-touch-btn">
										GET IN TOUCH
									</a>
								</li>
								{
									(!pathName.includes('login') && pathName !== '/')
										&& (
										<li className="navigation-list-item">
											<a href="/login" className="secondary-btn-royal-blue login-btn">
												LOG IN
											</a>
										</li>
									)
								}
							</ul>
						</div>
					</div>
				</div>
			</header>
        );
    }
}

export default AuthHeader;
