/* eslint-disable no-unused-vars */
import {
    format,
    parseISO
} from 'date-fns';

import {
    CLEAR_ALL_PACKAGE_RELATED_STORES,

    REVIEW_ERROR_HANDLER,
    REVIEW_FORM_SUBMITTED,
    REVIEW_INITIATION,
    REVIEW_SUCCESS_DETAILS,
    POST_REVIEW_INITIATIONS,
    SHOW_DISCOUNT_ALERT
} from './actionTypes';

import {
    getReviewDetails,
    postReviewData,
    validateCampaignDiscount
} from '../services/review';

import {
    isObjectEmpty,
    setErrorMessage
} from '../../utils/utility_helpers';

const buildReviewData = (response = {}) => {
    const reviewDetails = {};
    const { data } = response;
    const {
        isLocationSpecificBudget,
        isDetailedFlighting,
        person,
        campaignChannels,
        campaignAudiences,
        locationBudgetComment,
        datesFlightingComment,
        objectiveApproachComment,
        mediaMixComment,
        audienceComment
    } = data;

    const startDate = data.startDate ? format(parseISO(data.startDate), 'M/d/yyyy') : 'M/d/YYYY';
    const endDate = data.endDate ? format(parseISO(data.endDate), 'M/d/yyyy') : 'M/d/YYYY';
    const campaignLocations = data.campaignLocations ? data.campaignLocations : [];
    const locations = [];
    const flightingStatus = isDetailedFlighting ? 'I have specified priority weeks (with increased media investment during those weeks) and/or dark weeks (with no media investment those weeks).' : 'no data';

    if (campaignLocations) {
        campaignLocations.map((item, index) => {
            const location = {};
            location.id = item.id;
            location.name = item.storeLocationName;
            location.address1 = item.address1 ? item.address1 : '';
            location.address2 = item.address2 ? item.address2 : '';
            location.city = item.city ? item.city : '';
            location.state = item.state ? item.state : '';
            location.zip = item.zip ? item.zip : '';
            location.locationBudget = isLocationSpecificBudget ? item.locationBudget.intakeMediaBudget : '';
            locations.push(location);

            return locations;
        });
    }

    const mediaBudgetTotal = data.mediaBudgetTotal ? data.mediaBudgetTotal : 0;
    const campaignObjective = !isObjectEmpty(data.campaignObjective) ? data.campaignObjective.campaignObjectiveDesc : '';
    const campaignMediaApproach = !isObjectEmpty(data.campaignMediaApproach) ? data.campaignMediaApproach.mediaApproachDesc : '';
    const campaignDemographic = {};
    campaignDemographic.gender = !isObjectEmpty(data.campaignDemographic) ? data.campaignDemographic.gender : '';
    campaignDemographic.range = !isObjectEmpty(data.campaignDemographic) ? data.campaignDemographic.range : '';
    const channels = [];

    if (campaignChannels) {
        campaignChannels.map((item, index) => {
            const channelDetails = {};
            const { channelName } = item.channel;
            const { preferenceIndicator, campaignChannelAssets } = item;
            const channelAssetName = [];

            if (campaignChannelAssets) {
                campaignChannelAssets.map((list, i) => {
                    const { typeOfEntry } = list;

                    if (typeOfEntry.toLowerCase() === 'multiselect') {
                        return channelAssetName.push(list.channelAssetName);
                    }

                    if (typeOfEntry.toLowerCase() === 'textbox' || typeOfEntry.toLowerCase() === 'multiselecttextbox') {
                        return channelAssetName.push(list.notes);
                    }

                    return channelAssetName;
                });
            }

            channelDetails.channelId = item.id;
            channelDetails.channelName = channelName;
            channelDetails.preferenceIndicator = preferenceIndicator;
            channelDetails.channelAssetName = channelAssetName;
            channels.push(channelDetails);

            return channels;
        });
    }

    const audience = [];

    if (campaignAudiences) {
        campaignAudiences.map((item, index) => {
            return audience.push(item.audience);
        });
    }

    reviewDetails.startDate = startDate;
    reviewDetails.endDate = endDate;
    reviewDetails.flightingStatus = flightingStatus;
    reviewDetails.locations = locations;
    reviewDetails.mediaBudgetTotal = mediaBudgetTotal;
    reviewDetails.campaignObjective = campaignObjective;
    reviewDetails.campaignMediaApproach = campaignMediaApproach;
    reviewDetails.isLocationSpecificBudget = isLocationSpecificBudget;
    reviewDetails.isDetailedFlighting = isDetailedFlighting;
    reviewDetails.userEmail = !isObjectEmpty(person) ? person.email : '';
    reviewDetails.campaignDemographic = campaignDemographic;
    reviewDetails.channels = channels;
    reviewDetails.audience = audience;
    reviewDetails.locationBudgetComment = locationBudgetComment || '';
    reviewDetails.datesFlightingComment = datesFlightingComment || '';
    reviewDetails.objectiveApproachComment = objectiveApproachComment || '';
    reviewDetails.mediaMixComment = mediaMixComment || '';
    reviewDetails.audienceComment = audienceComment || '';
    return reviewDetails;
};

const getReview = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: REVIEW_INITIATION
            });

            const reviewResponse = await getReviewDetails();

            if (reviewResponse && reviewResponse.data.resultCount > 0) {
                dispatch({
                    type: REVIEW_SUCCESS_DETAILS,
                    data: buildReviewData(reviewResponse.data)
                });
            }
        } catch (error) {
            dispatch({
                type: REVIEW_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
        }
    };
};

const postReview = (data) => {
    return async (dispatch) => {
        try {
            const postData = {};
            const {
                firstName,
                lastName,
                jobTitle,
                dateAndTime,
                showDiscountAlert
            } = data;

            postData.firstName = firstName;
            postData.lastName = lastName;
            postData.jobTitle = jobTitle;
            postData.dateAndTime = dateAndTime;

            if (showDiscountAlert) {
                dispatch({
                    type: POST_REVIEW_INITIATIONS
                });
                const response = await postReviewData(postData);

                if (response && response.data.resultCount > 0) {
                    dispatch({
                        type: REVIEW_FORM_SUBMITTED,
                        data: response.data.data
                    });
                }
            } else {
                dispatch({
                    type: REVIEW_INITIATION
                });
                const getResponse = await validateCampaignDiscount();
                console.log(getResponse);

                if (getResponse && getResponse.data.resultCount) {
                    const { status } = getResponse.data.data;

                    if (!status) {
                        dispatch({
                            type: SHOW_DISCOUNT_ALERT,
                            data: true
                        });
                    } else {
                        dispatch({
                            type: POST_REVIEW_INITIATIONS
                        });
                        const response = await postReviewData(postData);

                        if (response && response.data.resultCount > 0) {
                            dispatch({
                                type: REVIEW_FORM_SUBMITTED,
                                data: response.data.data
                            });
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);

            dispatch({
                type: REVIEW_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
        }
    };
};

const handleDiscountPopup = (data = {}) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_DISCOUNT_ALERT,
            data
        });
    };
};

const clearAllPackageRelatedStore = () => {
    return (dispatch) => {
        localStorage.removeItem('CampaignId');
        localStorage.removeItem('IntakeStepId');
        localStorage.removeItem('PackageId');

        dispatch({
            type: CLEAR_ALL_PACKAGE_RELATED_STORES
        });
    };
};

export {
    getReview,
    postReview,
    clearAllPackageRelatedStore,
    handleDiscountPopup
};
