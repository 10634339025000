import { API } from '../../helpers/apiHelpers';

const getAdminPromotionsService = (campaignId) => {
    if (campaignId) {
        const url = `/campaigns/${campaignId}/promotions`;
        return API.get(url)
            .then((response) => {
                console.log(response);
                return response;
            }).catch((error) => {
                return Promise.reject(error);
            });
    }
    return null;
};

const getAdminCreativesService = (campaignId) => {
    if (campaignId) {
        const url = `/campaigns/${campaignId}/creativeuploads`;
        return API.get(url)
            .then((response) => {
                console.log(response);
                return response;
            }).catch((error) => {
                return Promise.reject(error);
            });
    }
    return null;
};

export { getAdminPromotionsService, getAdminCreativesService };
