/* eslint-disable no-param-reassign */
import React from 'react';
import Confetti from 'react-confetti';

import { useWindowSize } from 'react-use';

const ConfettiAnimation = () => {
    const { width, height } = useWindowSize();

    return (
        <Confetti
            width={width}
            height={height}
            initialVelocityY={10}
            gravity={0.08}
            tweenDuration={4000}
            numberOfPieces={300}
            colors={['#F74205', '#00B140', '#009FDF', '#B236D6', '#F4E800']}

            drawShape={(ctx) => {
                ctx.save();
                ctx.strokeStyle = 'rgba(0,0,0,0)';
                ctx.scale(0.5, 0.5);
                ctx.scale(0.5, 0.5);
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(3.74, 51.93);
                ctx.lineTo(3.74, 35.86);
                ctx.lineTo(36.81, 35.86);
                ctx.lineTo(36.81, 21);
                ctx.lineTo(3.74, 21);
                ctx.lineTo(3.74, 4.93);
                ctx.lineTo(52.74, 4.93);
                ctx.lineTo(52.74, 51.93);
                ctx.closePath();
                ctx.fill();
                ctx.stroke();
                ctx.restore();
                ctx.restore();
            }}
        />
    );
};

export default ConfettiAnimation;
