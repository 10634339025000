/* eslint-disable array-callback-return */
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    customerList: [{
        companyName: null,
        companyLegalName: null,
        companyId: null,
        personId: null,
        userName: null,
        firstName: null,
        lastName: null,
        customerStatus: null,
        campaignDetail: [{
            campaignId: null,
            startDate: null,
            endDate: null,
            campaignCreatedDateTime: null,
            packageId: null,
            packageName: null,
            campaignName: null
        }]
    }],
    actionItemList: [],
    allMessageList: [],
    loading: false,
    actionItemsLoading: false,
    errorMessage: null
};

const adminCustomerListStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        errorMessage: null
    });
};

const adminCustomerListFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: action.errorMessage
    });
};

const getAdminCustomerListSuccess = (state, action) => {
    return updateObject(state, {
        customerList: action.data,
        loading: false,
        errorMessage: null
    });
};

const getAllActionItemsStart = (state, action) => {
    return updateObject(state, {
        actionItemsLoading: true
    });
};

const getAllActionItemsSuccess = (state, action) => {
    const allMessageList = [];
    action.data.map((item) => {
        item.actionItems.messageActionItems.map((message) => {
            allMessageList.push(message);
        });
    });
    return updateObject(state, {
        actionItemList: action.data,
        actionItemsLoading: false,
        errorMessage: null,
        allMessageList
    });
};

const getAllActionItemsFail = (state, action) => {
    return updateObject(state, {
        actionItemsLoading: false,
        actionItemList: []
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.ADMIN_CUSTOMER_LIST_START:
        return adminCustomerListStart(state, action);
    case actionTypes.GET_ADMIN_CUSTOMER_LIST_SUCCESS:
        return getAdminCustomerListSuccess(state, action);
    case actionTypes.ADMIN_CUSTOMER_LIST_FAIL:
        return adminCustomerListFail(state, action);
    case actionTypes.GET_ALL_ACTION_ITEMS_START:
        return getAllActionItemsStart(state, action);
    case actionTypes.GET_ALL_ACTION_ITEMS_SUCCESS:
        return getAllActionItemsSuccess(state, action);
    case actionTypes.GET_ALL_ACTION_ITEMS_FAIL:
        return getAllActionItemsFail(state, action);
    default:
        return state;
    }
};

export default reducer;
