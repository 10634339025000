/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React from 'react';
import { Modal } from 'react-bootstrap';

import close from '../../../assets/images/icon-close.svg';
import retry from '../../../assets/images/icon-retry.svg';

const ResetPasswordModal = (props) => {
    const {
        show,
        headerText,
        bodyText,
        reSubmit,
        modalClose,
        showResubmit
    } = props;

    return (
        <Modal
            show={show}
            // aria-labelledby="contained-modal-title-vcenter"
            centered
            className="reset-password-modal"
        >
            <Modal.Header>
                <h3 className="reset-password-modal-title">
                    {headerText}
                </h3>

                <button className="modal-btn-close" onClick={modalClose} type="button">
                    <img src={close} alt="reset-password-close" />
                </button>
            </Modal.Header>

            <Modal.Body>
                <p>
                    {bodyText}

                    {showResubmit ? null : (
                        <>
                            <br />
                            or
                            {' '}
                            <a href="#" onClick={(event) => { reSubmit(event); }} className="color-green">
                                <img
                                    className="verification-resend-img"
                                    src={retry}
                                    alt="retry"
                                />
                                {' '}
                                <span className="color-green">click here to try sending again.</span>
                            </a>
                        </>
                    )}
                </p>
            </Modal.Body>
        </Modal>
    );
};

export default ResetPasswordModal;
