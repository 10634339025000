/* eslint-disable array-callback-return */
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    channels: [{
        channelId: null,
        channelName: null,
        sortOrder: null,
        preferenceIndicator: null,
        requiredAssetErrorMessage: '',
        assets: [{
            id: null,
            channelAssetName: null,
            channelAssetId: null,
            typeOfEntry: null,
            sortOrder: null,
            fileTypes: null,
            isSelected: null,
            notes: null
        }]
    }],
    isSaved: false,
    errorMessage: null,
    loading: false,
    mediaMixComment: ''
};

const mediaChannelsStart = (state, action) => {
    return updateObject(state, {
        loading: true
    });
};

const mediaChannelsSuccess = (state, action) => {
    return updateObject(state, {
        channels: action.data,
        errorMessage: null,
        loading: false
    });
};

const mediaChannelsFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.errorMessage,
        loading: false
    });
};

const updateIsMediaChannelSaved = (state, action) => {
    return updateObject(state, {
        isSaved: action.data
    });
};

const getChannelAssetNotes = (result, asset) => {
    const tempAsset = result.campaignChannelAssets.filter((x) => {
        return x.channelAssetId === asset.id;
    });
    if (tempAsset.length > 0) {
        return tempAsset[0].notes;
    }
    return null;
};

const getChannelAssets = (assets, result) => {
    const tempAssets = [];
    assets.map((asset, index) => {
        tempAssets.push({
            id: asset.id,
            channelAssetName: asset.channelAssetName,
            typeOfEntry: asset.typeOfEntry,
            sortOrder: asset.sortOrder,
            fileTypes: asset.fileTypes,
            isSelected: result !== undefined
                ? (result.campaignChannelAssets.some((x) => {
                    return x.channelAssetId === asset.id;
                })
                    ? true : null) : null,
            notes: result !== undefined
                ? (getChannelAssetNotes(result, asset)) : null
        });
    });
    return tempAssets;
};

const buildIndividaulMedaiObject = (channel, campaignChannels) => {
    const result = campaignChannels.find((x) => { return x.channel.id === channel.id; });
    console.log(result);
    const tempChannel = {
        channelId: channel.id,
        channelName: channel.channelName,
        sortOrder: channel.sortOrder,
        preferenceIndicator: result !== undefined ? result.preferenceIndicator : null,
        requiredAssetErrorMessage: '',
        assets: getChannelAssets(channel.assets, result)
    };
    return tempChannel;
};

const constructMediaChannelObject = (state, action) => {
    const tempChannels = [];
    if (action.data.campaignChannels.length > 0) {
        action.data.channels.map((channel, index) => {
            tempChannels.push(buildIndividaulMedaiObject(channel, action.data.campaignChannels));
        });
    } else {
        action.data.channels.map((channel, index) => {
            tempChannels.push(buildIndividaulMedaiObject(channel, action.data.campaignChannels));
        });
    }
    console.log(tempChannels);
    return updateObject(state,
        {
            channels: tempChannels,
            loading: false,
            mediaMixComment: action.data.mediaMixComment,
            isSaved: true
        });
};

const updateLocalStoreState = (state, action) => {
    return updateObject(state, {
        channels: action.data.channels,
        mediaMixComment: action.data.mediaMixComment,
        loading: false,
        isSaved: true,
        errorMessage: null
    });
};

const setMediaMixInitialState = (state, action) => {
    return updateObject(state, initialState);
};

const reducers = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.MEDIA_CHANNELS_START:
        return mediaChannelsStart(state, action);
    case actionTypes.MEDIA_CHANNELS_SUCCESS:
        return mediaChannelsSuccess(state, action);
    case actionTypes.MEDIA_CHANNELS_FAIL:
        return mediaChannelsFail(state, action);
    case actionTypes.CONSTRUCT_MEDIA_CHANNEL_OBJECT:
        return constructMediaChannelObject(state, action);
    case actionTypes.IS_MEDIA_CHANNEL_SAVED:
        return updateIsMediaChannelSaved(state, action);
    case actionTypes.UPDATE_LOCAL_STORE_STATE:
        return updateLocalStoreState(state, action);
    case actionTypes.SET_MEDIA_MIX_INITIAL_STATE:
        return setMediaMixInitialState(state, action);
    case actionTypes.MEDIA_UNSAVED_CHANGES:
        return updateObject(state, initialState);
    case actionTypes.CLEAR_ALL_PACKAGE_RELATED_STORES:
        return initialState;
    default:
        return state;
    }
};

export default reducers;
