/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Lottie from 'lottie-react';
import logoAnimation from '../../assets/images/MA_Symbol_Animation.json';

const SignInLeftSidePanel = () => {
    return (
        <div className="col sign-in-flow-left">
            <Lottie
                className="logo-animation"
                loop
                autoplay
                animationData={logoAnimation}
                height={300}
                width={300}
            />
        </div>
    );
};

export default SignInLeftSidePanel;
