/* eslint-disable indent */
/* eslint-disable no-tabs */
import { API } from '../../helpers/apiHelpers';

const startCampaignSercvice = (data) => {
    const url = '/campaigns';
    return API.post(url, data)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const deleteCampaignService = (campaignId) => {
    const url = '/campaigns/' + campaignId;
    return API.delete(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getCampaignSercvice = (campaignId) => {
    const url = '/campaigns/' + campaignId + '?isDetailed=false';
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updateCampaignService = (campaignId, formData) => {
    const url = '/campaigns/' + campaignId;
    return API.patch(url, formData)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const moveToArchiveService = (campaignIds) => {
    const url = '/campaigns/archivecampaigns';
    return API.put(url, campaignIds)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getCampaignRewardsService = (campaignId) => {
    const url = '/campaign/' + campaignId + '/rewards';
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getCampaignTasksService = (campaignId) => {
    const url = '/campaigns/' + campaignId + '/tasks';
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getCampaignDocumentsService = (campaignId) => {
    const url = '/campaigns/' + campaignId + '/documents';
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const deleteCampaignDocumentsService = (campaignId, fileId) => {
    const url = '/campaigns/' + campaignId + '/files/' + fileId;
    return API.delete(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updateRewardPointsService = (object) => {
    const url = '/campaign/' + object.campaignId + '/rewards';
    return API.post(url, object.reward)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const postFileUploadDetails = async (campaignId, data) => {
    if (campaignId) {
        const url = `/campaigns/${campaignId}/files`;
        try {
            return await API.post(url, data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const postNewTask = async (campaignId, data) => {
    if (campaignId) {
        const url = `/campaigns/${campaignId}/task`;
        try {
            return await API.post(url, data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const getTaskDetails = async (campaignId, taskId) => {
    if (campaignId) {
        const url = `/campaigns/${campaignId}/tasks/${taskId}`;
        try {
            return await API.get(url);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};
const updateTaskDetailsService = (campaignId, taskId, object) => {
    const url = '/campaigns/' + campaignId + '/tasks/' + taskId;
    return API.post(url, object)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const markAsCompleteTaskService = (workflowTaskId) => {
    const url = '/markascomplete/' + workflowTaskId;
    return API.post(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const isHoliday = async (date) => {
    const url = '/isholiday';
    try {
        return await API.get(url, {
            params: {
                date
            }
        });
    } catch (error) {
        return Promise.reject(error);
    }
};

const updateTaskInformation = async (campaignId, taskId, data) => {
    if (campaignId) {
        const url = `campaigns/${campaignId}/tasks/${taskId}`;
        try {
            return await API.put(url, data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const updateCampaignDate = async (campaignId, data) => {
    if (campaignId) {
        const url = `campaigns/${campaignId}/requestcampaigndateschange`;
        try {
            return await API.put(url, data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

export {
    startCampaignSercvice,
    deleteCampaignService,
    getCampaignSercvice,
    updateCampaignService,
    moveToArchiveService,
    getCampaignRewardsService,
    getCampaignTasksService,
    getCampaignDocumentsService,
    deleteCampaignDocumentsService,
    updateRewardPointsService,
    postFileUploadDetails,
    postNewTask,
    getTaskDetails,
    isHoliday,
    updateTaskDetailsService,
    updateTaskInformation,
    updateCampaignDate,
	markAsCompleteTaskService
};
