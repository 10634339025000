import React from 'react';
import CustomerDashBoardHeader from '../common/CustomerDashBoardHeader';
import Footer from '../common/Footer';

const AdminLayout = (props) => {
    const { children } = props;
    return (
        <>
            <CustomerDashBoardHeader />
            {children}
            <Footer />
        </>
    );
};

export default AdminLayout;
