/* eslint-disable no-script-url */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import classnames from 'classnames';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';

import Debounce from '../Utils/Debounce';
import Spinner from './Loader';
import AccountDetails from '../customer/accountSettings/AccountDetails';

import { getAllActionItems } from '../../store/actions/index';

import {
    logout as logoutService
} from '../../store/actions/auth';

import { handleViewMessage, getAllCampaignDetails } from '../../store/actions/adminCampaignDetails';
import { isLoggedInIsAdmin, isAuthenticated } from '../../utils/utility_helpers';

import Logo from '../../assets/images/Empower-new-logo.svg';
import Messages from '../../assets/images/icon-messages.svg';
import DownArrow from '../../assets/images/icon-down-arrow.svg';
import { PackageId } from '../Constants';

class HeaderDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prevScrollpos: window.pageYOffset,
            shadow: true,
            show: false
        };
    }

    // Adds an event listener when the component is mount.
    componentDidMount() {
        if (isLoggedInIsAdmin()) {
            document.body.classList.add('admin');
            document.body.classList.remove('customer');
        }

        if (!isLoggedInIsAdmin()) {
            window.location.href = '/customer/campaign-details';
        }
        const { getActionItems } = this.props;
        window.addEventListener('scroll', this.handleScroll);
        getActionItems();
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener('scroll', Debounce(this.handleScroll, 20));
    }

    // Hide or show the menu.
    handleScroll = () => {
        const { prevScrollpos } = this.state;
        const currentScrollPos = window.pageYOffset;
        const shadow = prevScrollpos < 93;

        this.setState({
            prevScrollpos: currentScrollPos,
            shadow
        });
    };

    handleLogout = (event) => {
        const { onLogout } = this.props;
        event.preventDefault();

        onLogout();
    };

    profileSettings = () => {
        this.setState({ show: true });
    }

    profileSettingsClose = () => {
        this.setState({
            show: false
        });
    }

    handleViewMessage = (item, companyId) => {
        const {
            handleViewMessageDetails,
            history,
            campaignId,
            getAllCampaignData
        } = this.props;

        const pathName = window.location.pathname;
        const isMIMPackageSelected = item.packageId === Number(PackageId.MarketingImpactModel);

        const path = '/admin/customer-campaign-details?id=' + btoa(item.campaignId) + '&packageId=' + btoa(item.packageId);

        history.push(path);
        const data = {};

        data.campaignId = item.campaignId;
        data.showMessageDetails = true;
        data.showMessageListPopup = false;
        data.threadId = item.threadId || 0;
        data.messageId = [item.messageId] || [];
        data.isRead = item.isRead;
        data.companyId = companyId;

        if (campaignId !== item.campaignId && pathName === '/admin/customer-campaign-details') {
            data.isMIMPackageSelected = isMIMPackageSelected;
            getAllCampaignData(data);
        } else {
            handleViewMessageDetails(data);
        }
    }

    render() {
        const {
            shadow,
            show
        } = this.state;

        const {
            loading,
            allMessageList,
            actionItemsLoading
        } = this.props;

        return (
            <header
                className={classnames('header-wrapper header-dashboard-wrapper', {
                    'header-shadow': !shadow
                })}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 header-left">
                            <div className="media-agent-logo-wrapper">
                                <a>
                                    <img src={Logo} className="img-fluid ma-admin-logo" alt="Media Agent Admin Portal" />
                                </a>
                            </div>
                        </div>

                        <div className="col-sm-9 header-right">
                            <ul className="navigation-list">
                                <li className="navigation-list-item">
                                    <NavLink to="/admin/customer-task-lists">tasks</NavLink>
                                </li>

                                <li className="navigation-list-item">
                                    <NavLink to="/admin/list-customers" className="">customers</NavLink>
                                </li>
                                <li>
                                    <Dropdown className="navigation-list-item">
                                        <Dropdown.Toggle
                                            className="welcome-user-link dropdown-toggle-btn"
                                            id="dropdown-basic-user"
                                        >
                                            Welcome,
                                            {' ' + localStorage.getItem('FirstName')}
                                            {/* <img
                                                className="down-arrow-icon"
                                                src={DownArrow}
                                                alt="downArrow"
                                            /> */}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                            alignRight
                                        >
                                            <Dropdown.Item
                                                onClick={this.handleLogout}
                                            >
                                                Log out
                                            </Dropdown.Item>

                                            <Dropdown.Item
                                                onClick={this.profileSettings}
                                            >
                                                Profile Settings
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li>
                                    <Dropdown className="navigation-list-item">
                                        <Dropdown.Toggle
                                            className="messages-link dropdown-toggle-btn"
                                            id="dropdown-basic-messages"
                                        >
                                            <img src={Messages} alt="icon-messages" />
                                            <span className="badge">{allMessageList.length}</span>
                                            <img
                                                className="down-arrow-icon"
                                                src={DownArrow}
                                                alt="icon-down-arrow"
                                            />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {allMessageList.map((list) => {
                                                return (
                                                    <Dropdown.Item onClick={() => {
                                                        this.handleViewMessage(list, null);
                                                    }}
                                                    >
                                                        {list.subject}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {(loading || actionItemsLoading) && <Spinner loaderClass="loader-fullscreen" />}

                {!isAuthenticated() && <Redirect to="/login" />}

                <AccountDetails
                    show={show}
                    close={this.profileSettingsClose}
                />
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.logoutLoading,
        errorMessage: state.auth.errorMessage,
        allMessageList: state.adminCustomerList.allMessageList,
        redirect: state.auth.redirect,
        campaignId: state.adminCampaignDetails.campaignId
        // actionItemsLoading: state.adminCustomerList.actionItemsLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => {
            return dispatch(logoutService());
        },
        handleViewMessageDetails: (data) => {
            return dispatch(handleViewMessage(data));
        },
        getActionItems: () => {
            return dispatch(getAllActionItems());
        },
        getAllCampaignData: (data) => {
            return dispatch(getAllCampaignDetails(data));
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderDashboard));
