/* eslint-disable max-len */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { isAuthenticated } from '../../utils/utility_helpers';
import addressWhite from '../../assets/images/icon-address.svg';
import addressBlack from '../../assets/images/icon-address-black.svg';

class Footer extends React.PureComponent {
    checkUserLogged = () => {
        return isAuthenticated();
    };

    render() {
        const loggedIn = this.checkUserLogged();
        const pathName = window.location.pathname;
        let showHeader = false;

        if (localStorage.getItem('PackageId')) {
            if (loggedIn && pathName === '/packages') {
                showHeader = true;
            }
        }
        return (
            <footer className="footer-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 footer-left">
                            <ul className="footer-left-nav-list">
                                <li className="footer-left-nav-item">
                                    &copy;2022 Empower&reg;
                                </li>
                                <li className="footer-left-nav-item">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="Read more about privacy policy"
                                        href="/privacy-policy"
                                    >
                                        Privacy Policy, Terms &amp; Conditions
                                    </a>
                                </li>
                            </ul>

                            {showHeader && (
                                <div className="footer-nav-container">
                                    <ul className="footer-nav-list">
                                        <li className="footer-nav-item">
                                            <NavLink to="/what-to-expect">
                                                WHAT TO EXPECT
                                            </NavLink>
                                        </li>

                                        <li className="footer-nav-item">
                                            <NavLink to="/packages" onClick={this.getPackages}>
                                                PACKAGES AND PRICING
                                            </NavLink>
                                        </li>

                                        <li className="footer-nav-item">
                                            <NavLink to="/how-it-works">
                                                HOW IT WORKS
                                            </NavLink>
                                        </li>

                                        <li className="footer-nav-item">
                                            <a
                                                href="https://www.empowermm.com/category/articles/"
                                                target="_blank"
                                                aria-label="Read more about articles"
                                                rel="noopener noreferrer"
                                            >
                                                ARTICLES
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div className="col-sm-6 footer-right">
                            <p className="address">
                                <img className="location-icon-white" src={addressWhite} alt="location-icon" />
                                <img className="location-icon-black" src={addressBlack} alt="location-icon" />
                                <span>15 East 14th Street Cincinnati, OH 45202</span>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
