/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ProfileInfo from './ProfileInfo';
import MailingAddress from './MailingAddress';
import AccountPayableInfo from './AccountPayableInfo';
import { updateCompanyProfile, updateCompanyProfileReset } from '../../../store/actions/customerProfile';
import {
    LocationFeilds,
    AlertMessages,
    RegEx,
    // contactUs,
    InvitationFields
} from '../../Constants';
import { validatePerson } from '../../../store/services/invitation';
import ConfirmAlert from '../../UIComponents/ConfirmAlert';
import Spinner from '../../common/Loader';

const addressErrorMessages = {
    address1: '',
    city: '',
    state: '',
    zipCode: ''
};

class CompanyProfileIndex extends React.Component {
    constructor() {
        super();
        this.state = {
            companyDetails: {
                companyId: null,
                companyName: null,
                companyLegalName: null,
                personDetail: {
                    personId: null,
                    firstName: null,
                    lastName: null,
                    userName: null,
                    contactNumber: null,
                    email: null
                },
                accountsPayableName: null,
                accountsPayableEmail: null,
                taxId: null,
                isAdmin: false,
                customerSuccessPersonId: null,
                timeZone: null,
                additionalEmail: '',
                companyAddresses: [{
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    isBillingAddress: false,
                    isPrimary: false
                }]
            },
            billingAddressError: { ...addressErrorMessages },
            mailingAddressError: { ...addressErrorMessages },
            accountsPayableNameError: '',
            accountsPayableEmailError: '',
            contactNumberError: '',
            businessLegalNameError: '',
            businessEmailError: '',
            companyNameError: '',
            firstNameError: '',
            lastNameError: '',
            userNameError: '',
            showAlert: false,
            fieldToBeValidate: '',
            confirmText: '',
            isEditable: false,
            isValidEmail: true,
            additionalEmailError: ''
            // unUsedCompanyDetails: null,
            // unUsedComapnyAddresses: null
        };
        this.childRef = React.createRef();
        this.mailingAddressRef = React.createRef();
        this.accountInfoRef = React.createRef();
        this.validateAdditionalEmail = this.validateAdditionalEmail.bind(this);
        this.validateBusinessEmail = this.validateBusinessEmail.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        console.log('props', props);
        console.log('state', state);
        if (props.customerProfile && state.companyDetails.companyLegalName === null) {
            return {
                companyDetails: props.customerProfile
            };
        }
        return {
            unUsedCompanyDetails: props.unUsedCompanyDetails,
            unUsedComapnyAddresses: props.unUsedComapnyAddresses,
            unUsedUserProfile: props.unUsedUserProfile
        };
    }

    // componentDidMount() {
    //     const { fetchCustomerProfile } = this.props;
    //     fetchCustomerProfile();
    // }

    changeEditable = () => {
        const { companyProfileReset } = this.props;
        this.setState({ isEditable: true }, () => {
            companyProfileReset();
        });
    }

    onCancelClick = () => {
        const { unChangedCompanyDetails, companyProfileReset } = this.props;
        const data = {};
        if (unChangedCompanyDetails) {
            data.companyId = _.cloneDeep(unChangedCompanyDetails.unChangedCompanyId);
            data.companyName = _.cloneDeep(unChangedCompanyDetails.unChangedCompanyName);
            data.companyLegalName = _.cloneDeep(unChangedCompanyDetails.unChangedCompanyLegalName);
            data.personDetail = _.cloneDeep(unChangedCompanyDetails.unChangedPersonDetail);
            data.accountsPayableName = _.cloneDeep(
                unChangedCompanyDetails.unChangedAccountsPayableName
            );
            data.accountsPayableEmail = _.cloneDeep(
                unChangedCompanyDetails.unChangedAccountsPayableEmail
            );
            data.taxId = _.cloneDeep(unChangedCompanyDetails.unChangedTaxId);
            data.timeZone = _.cloneDeep(unChangedCompanyDetails.unChangedTimeZone);
            data.isAdmin = _.cloneDeep(unChangedCompanyDetails.unChangedIsAdmin);
            data.customerSuccessPersonId = _.cloneDeep(
                unChangedCompanyDetails.unChangedCustomerSuccessPersonId
            );
            data.companyAddresses = _.cloneDeep(unChangedCompanyDetails.unChangedCompanyAddresses);
            data.additionalEmail = _.cloneDeep(unChangedCompanyDetails.unChangedAdditionalEmail);
        }

        this.setState({
            isEditable: false,
            companyDetails: data,
            billingAddressError: { ...addressErrorMessages },
            mailingAddressError: { ...addressErrorMessages },
            accountsPayableNameError: '',
            accountsPayableEmailError: '',
            contactNumberError: '',
            businessLegalNameError: '',
            businessEmailError: '',
            companyNameError: '',
            firstNameError: '',
            lastNameError: '',
            userNameError: '',
            additionalEmailError: ''
        }, () => {
            companyProfileReset();
        });
    }

    handleChange = (event, type) => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }

        const value = event.target.value.trim();
        console.log(value);

        switch (type) {
        case 'companyLegalName':
            companyDetails.companyLegalName = value;
            this.setState({ companyDetails, businessLegalNameError: value ? '' : AlertMessages.LocationForm.BusinessLegalName });
            break;
        case 'companyName':
            companyDetails.companyName = value;
            this.setState({
                companyDetails,
                companyNameError: value ? '' : AlertMessages.LocationForm.CompanyName
            });
            break;
        case 'firstName':
            companyDetails.personDetail.firstName = value;
            this.setState({ companyDetails, firstNameError: value ? '' : AlertMessages.LocationForm.FirstName });
            break;
        case 'lastName':
            companyDetails.personDetail.lastName = value;
            this.setState({ companyDetails, lastNameError: value ? '' : AlertMessages.LocationForm.LastName });
            break;
        case 'contactNumber':
            companyDetails.personDetail.contactNumber = value;
            this.setState({ companyDetails, contactNumberError: value ? '' : AlertMessages.LocationForm.ContactNumber });
            break;
        case 'email':
            companyDetails.personDetail.email = value;
            this.setState({ companyDetails, businessEmailError: value ? '' : AlertMessages.LocationForm.businessEmailError });
            break;
        case 'additionalEmail':
            companyDetails.additionalEmail = value;
            this.setState({ companyDetails, additionalEmailError: value ? '' : '' });
            break;
        case 'taxId':
            companyDetails.taxId = value;
            this.setState({ companyDetails });
            break;
        default:
            break;
        }
    }

    onBillingAddressChange = (event, field, billingIndex) => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        const { billingAddressError } = this.state;
        const tempCompanyAddresses = companyDetails;
        console.log(tempCompanyAddresses);

        const tempBillingAddressError = billingAddressError;
        switch (field) {
        case LocationFeilds.Address1:
            companyDetails.companyAddresses[billingIndex].address1 = event.target.value;
            if (event.target.value.trim() === '') {
                tempBillingAddressError.address1 = AlertMessages.LocationForm.Address1;
                break;
            } else {
                tempBillingAddressError.address1 = '';
                break;
            }
        case LocationFeilds.Address2:
            companyDetails.companyAddresses[billingIndex].address2 = event.target.value;
            break;
        case LocationFeilds.City:
            companyDetails.companyAddresses[billingIndex].city = event.target.value;
            if (event.target.value.trim() === '') {
                tempBillingAddressError.city = AlertMessages.LocationForm.City;
                break;
            } else {
                tempBillingAddressError.city = '';
                break;
            }
        case LocationFeilds.State:
            companyDetails.companyAddresses[billingIndex].state = event.value;
            if (event.value.trim() === '') {
                tempBillingAddressError.state = AlertMessages.LocationForm.State;
                break;
            } else {
                tempBillingAddressError.state = '';
                break;
            }
        case LocationFeilds.ZipCode:
            if (event.target.value === '' || RegEx.OnlyNumber.test(event.target.value)) {
                companyDetails.companyAddresses[billingIndex].zipCode = event.target.value;
                if (event.target.value.trim() === '') {
                    tempBillingAddressError.zipCode = AlertMessages.LocationForm.Zip;
                    break;
                } else {
                    tempBillingAddressError.zipCode = '';
                    break;
                }
            }
            break;
        case LocationFeilds.TimeZone:
            if (event.value.trim() === '') {
                companyDetails.timeZone = '';
                break;
            } else {
                companyDetails.timeZone = event.value;
                break;
            }
        default:
            break;
        }
        this.setState({
            companyDetails,
            billingAddressError: tempBillingAddressError
        });
    }

    validateZipCode = () => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        const { billingAddressError, mailingAddressError } = this.state;
        console.log('companyDetails validateZipCode', companyDetails);
        let isValid = true;
        const tempBillingAddressError = billingAddressError;
        const tempMailingAddressError = mailingAddressError;
        const billingAddress = companyDetails && companyDetails.companyAddresses
            .filter((x) => { return x.isPrimary === true; });

        console.log('billingAddress[0]', billingAddress[0]);

        const mailingAddress = companyDetails && companyDetails.companyAddresses
            .filter((x) => { return x.isPrimary === false; });

        if (billingAddress[0] && billingAddress[0].zipCode.length > 0
            && billingAddress[0].zipCode.length < 5) {
            tempBillingAddressError.zipCode = AlertMessages.LocationForm.ValidZip;
            isValid = false;
        }

        if (mailingAddress[0] && mailingAddress[0].zipCode.length > 0
            && mailingAddress[0].zipCode.length < 5) {
            tempMailingAddressError.zipCode = AlertMessages.LocationForm.ValidZip;
            isValid = false;
        }

        this.setState({
            billingAddressError: tempBillingAddressError,
            mailingAddressError: tempMailingAddressError
        });
        return isValid;
    };

    validateMultipleEmails = (businessEmail) => {
        const emails = businessEmail.split(',');
        let flag = false;
        emails.forEach((email) => {
            if (!RegEx.EmailAddress.test(email.trim())) {
                flag = true;
            }
        });
        return flag;
    }

    onMailingAddressChange = (event, field, mailingIndex) => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        const { mailingAddressError } = this.state;
        const tempCompanyAddresses = companyDetails;
        console.log('TempCompanyAddresses :', mailingIndex);
        switch (field) {
        case LocationFeilds.Address1:
            tempCompanyAddresses.companyAddresses[mailingIndex].address1 = event.target.value;
            if (event.target.value.trim() !== '') {
                mailingAddressError.address1 = '';
            }
            break;
        case LocationFeilds.Address2:
            tempCompanyAddresses.companyAddresses[mailingIndex].address2 = event.target.value;
            break;
        case LocationFeilds.City:
            tempCompanyAddresses.companyAddresses[mailingIndex].city = event.target.value;
            if (event.target.value.trim() !== '') {
                mailingAddressError.city = '';
            }
            break;
        case LocationFeilds.State:
            tempCompanyAddresses.companyAddresses[mailingIndex].state = event.value;
            mailingAddressError.state = '';
            break;
        case LocationFeilds.ZipCode:
            if (event.target.value === '' || RegEx.OnlyNumber.test(event.target.value)) {
                tempCompanyAddresses.companyAddresses[mailingIndex].zipCode = event.target.value;
                if (event.target.value.trim() !== '') {
                    mailingAddressError.zipCode = '';
                }
            }
            break;
        default:
            break;
        }
        this.setState({
            companyDetails: tempCompanyAddresses
        });
    }

    setMailingAddressRef = (input) => {
        this.mailingAddressRef = input;
    }

    onAccountsPayableNameChange = (event) => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        companyDetails.accountsPayableName = event.target.value;
        this.setState({
            companyDetails,
            accountsPayableNameError: event.target.value.trim() === ''
                ? AlertMessages.Company.RequiredAccountsPaybleName
                : ''
        });
    }

    onAccountsPayableEmailChange = (event) => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        companyDetails.accountsPayableEmail = event.target.value;
        this.setState({
            companyDetails,
            accountsPayableEmailError: event.target.value.trim() === ''
                ? AlertMessages.Company.RequiredAccountsPaybleEmail
                : ''
        });
    }

    validateAccountsPayableEmail = () => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        let isValid = true;
        if (companyDetails.accountsPayableEmail.trim() !== ''
            && !RegEx.EmailAddress.test(companyDetails.accountsPayableEmail)) {
            this.setState({ accountsPayableEmailError: AlertMessages.Common.ValidEmailAddress });
            isValid = false;
        }
        return isValid;
    }

    createBusinessProfileObject = () => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }

        const billingIndex = companyDetails.companyAddresses && companyDetails.companyAddresses
            .findIndex((x) => { return x.isPrimary === true; });
        const mailingAddress = companyDetails.companyAddresses && companyDetails.companyAddresses
            .filter((x) => { return x.isPrimary === false; });
        const mailingIndex = companyDetails.companyAddresses && companyDetails.companyAddresses
            .findIndex((x) => { return x.isPrimary === false; });
        let isMailingAddressDifferent = false;
        if (mailingAddress[0].address1 !== ''
        || mailingAddress[0].address2 !== ''
        || mailingAddress[0].city !== ''
        || mailingAddress[0].state !== ''
        || mailingAddress[0].zipCode !== '') {
            isMailingAddressDifferent = true;
        }
        let tempCompanyAddressPostDetails = [];
        if (isMailingAddressDifferent) {
            tempCompanyAddressPostDetails = companyDetails.companyAddresses;
            tempCompanyAddressPostDetails[billingIndex].isBillingAddress = false;
            tempCompanyAddressPostDetails[mailingIndex].isBillingAddress = true;
        } else {
            let tempAddresss = [];
            tempAddresss = companyDetails.companyAddresses;
            tempAddresss[billingIndex].isBillingAddress = true;
            tempCompanyAddressPostDetails.push(tempAddresss[billingIndex]);
        }
        const tempTaxId = companyDetails.taxId || 0;
        return {
            companyObject: {
                companyId: companyDetails.companyId,
                companyLegalName: companyDetails.companyLegalName,
                companyName: companyDetails.companyName,
                personDetailDTO: {
                    personId: companyDetails.personDetail.personId,
                    firstName: companyDetails.personDetail.firstName,
                    lastName: companyDetails.personDetail.lastName,
                    contactNumber: companyDetails.personDetail.contactNumber,
                    email: companyDetails.personDetail.email,
                    userName: companyDetails.personDetail.userName
                },
                accountsPayableName: companyDetails.accountsPayableName,
                accountsPayableEmail: companyDetails.accountsPayableEmail,
                taxId: tempTaxId,
                timeZone: companyDetails.timeZone,
                companyAddressPostDetailsDTO: tempCompanyAddressPostDetails,
                additionalEmail: companyDetails.additionalEmail
            }
        };
    }

    validatePayableInformation = () => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }

        let isValid = true;
        if (companyDetails.accountsPayableName.trim() === '') {
            this.setState({
                accountsPayableNameError: AlertMessages.Company.RequiredAccountsPaybleName
            });
            isValid = false;
            console.log(isValid, AlertMessages);
        }
        if (companyDetails.accountsPayableEmail.trim() === '') {
            this.setState({
                accountsPayableEmailError: AlertMessages.Company.RequiredAccountsPaybleEmail
            });
            console.log(isValid);
            isValid = false;
        } else if (!this.validateAccountsPayableEmail()) {
            isValid = false;
            console.log(isValid);
        }
        if (!isValid && this.accountInfoRef && this.accountInfoRef.scrollIntoView) {
            this.accountInfoRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        return isValid;
    }

    validateAddress = () => {
        const { billingAddressError, mailingAddressError } = this.state;
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }

        let isValid = true;
        const tempBillingAddressError = billingAddressError;
        const billingAddress = companyDetails && companyDetails.companyAddresses
            .filter((x) => { return x.isPrimary === true; });
        const mailingAddress = companyDetails && companyDetails.companyAddresses
            .filter((x) => { return x.isPrimary === false; });
        if (billingAddress[0].address1.trim() === '') {
            tempBillingAddressError.address1 = AlertMessages.LocationForm.Address1;
            isValid = false;
        }
        if (billingAddress[0].city.trim() === '') {
            tempBillingAddressError.city = AlertMessages.LocationForm.City;
            isValid = false;
        }
        if (billingAddress[0].state.trim() === '') {
            tempBillingAddressError.state = AlertMessages.LocationForm.State;
            isValid = false;
        }
        if (billingAddress[0].zipCode.trim() === '') {
            tempBillingAddressError.zipCode = AlertMessages.LocationForm.Zip;
            isValid = false;
        }
        if (!this.validateZipCode()) {
            isValid = false;
        }
        this.setState({ billingAddressError: tempBillingAddressError });

        let isMailingAddressDifferent = false;
        if (mailingAddress[0].address1 !== ''
        || mailingAddress[0].address2 !== ''
        || mailingAddress[0].city !== ''
        || mailingAddress[0].state !== ''
        || mailingAddress[0].zipCode !== '') {
            isMailingAddressDifferent = true;
        }

        const isAddressValid = isValid;

        if (isMailingAddressDifferent) {
            const tempMailingAddressError = mailingAddressError;
            if (mailingAddress[0].address1.trim() === '') {
                tempMailingAddressError.address1 = AlertMessages.LocationForm.Address1;
                isValid = false;
            }
            if (mailingAddress[0].city.trim() === '') {
                tempMailingAddressError.city = AlertMessages.LocationForm.City;
                isValid = false;
            }
            if (mailingAddress[0].state.trim() === '') {
                tempMailingAddressError.state = AlertMessages.LocationForm.State;
                isValid = false;
            }
            if (mailingAddress[0].zipCode.trim() === '') {
                tempMailingAddressError.zipCode = AlertMessages.LocationForm.Zip;
                isValid = false;
            }
            this.setState({ mailingAddressError: tempMailingAddressError });
            if (isAddressValid && !isValid && this.mailingAddressRef
                && this.mailingAddressRef.scrollIntoView) {
                this.mailingAddressRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        } else {
            let tempMailingAddressError = mailingAddressError;
            tempMailingAddressError = {
                address1: '',
                city: '',
                state: '',
                zipCode: ''
            };
            this.setState({ mailingAddressError: tempMailingAddressError });
        }
        if (!isAddressValid) {
            if (!isValid && this.childRef && this.childRef.scrollIntoView) {
                this.childRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
        return isValid;
    }

    validateCompany = () => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }

        let isValid = true;
        if (!this.validatePhoneNumber()) {
            console.log(isValid);
            isValid = false;
        }
        if (companyDetails.personDetail.userName.trim() === '') {
            this.setState({ userNameError: AlertMessages.Common.RequiredUserName });
            isValid = false;
        }
        if (companyDetails.personDetail.email.trim() === '') {
            this.setState({ businessEmailError: AlertMessages.Invitations.RequiredEmail });
            isValid = false;
        }
        if (companyDetails.personDetail.email.trim() !== ''
            && !RegEx.EmailAddress.test(companyDetails.personDetail.email)) {
            this.setState({ businessEmailError: AlertMessages.Common.ValidEmailAddress });
            isValid = false;
        }
        if (companyDetails.companyName.trim() === '') {
            this.setState({ companyNameError: AlertMessages.LocationForm.CompanyName });
            isValid = false;
            console.log(isValid);
        }
        if (companyDetails.companyLegalName.trim() === '') {
            this.setState({ businessLegalNameError: AlertMessages.LocationForm.BusinessLegalName });
            isValid = false;
            console.log(isValid);
        }
        if (companyDetails.personDetail.firstName.trim() === '') {
            this.setState({ firstNameError: AlertMessages.LocationForm.FirstName });
            isValid = false;
            console.log(isValid);
        }
        if (companyDetails.personDetail.lastName.trim() === '') {
            this.setState({ lastNameError: AlertMessages.LocationForm.FirstName });
            isValid = false;
            console.log(isValid);
        }
        if (companyDetails.personDetail.contactNumber.trim() === '') {
            this.setState({ contactNumberError: AlertMessages.LocationForm.ContactNumber });
            isValid = false;
            console.log(isValid);
        }
        console.log(isValid);
        if (!isValid && this.childRef && this.childRef.scrollIntoView) {
            this.childRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        return isValid;
    }

    validatePhoneNumber = () => {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }

        let isValid = true;
        if (companyDetails.personDetail.contactNumber !== null) {
            if (companyDetails.personDetail.contactNumber.length > 0
                && companyDetails.personDetail.contactNumber.length < 10) {
                this.setState({ contactNumberError: 'Please enter valid phone number' });
                isValid = false;
            }
        }
        return isValid;
    };

    validateMultipleEmails = (str) => {
        const emails = str.split(',');
        let isValid = false;
        emails.forEach((email) => {
            if (!RegEx.EmailAddress.test(email.trim())) {
                isValid = true;
            }
        });
        return isValid;
    }

    saveBusinessCustomerProfile = () => {
        const { updateCustomerCompanyProfile } = this.props;
        const { isValidEmail } = this.state;
        console.log('SaveBusinessCustomerProfile Triggered 1');
        let isValid = true;
        if (!this.validatePayableInformation()) {
            console.log('ValidatePayableInformation Failed');
            isValid = false;
        }
        if (!this.validateAddress()) {
            console.log('ValidateAddress Failed');
            isValid = false;
        }
        if (!this.validateCompany()) {
            console.log('ValidateCompany Failed');
            isValid = false;
        }
        console.log('SaveBusinessCustomerProfile Triggered 2');
        if (isValid && isValidEmail) {
            console.log('updateCustomerCompanyProfile Calling');
            updateCustomerCompanyProfile(this.createBusinessProfileObject());
        }
        console.log('SaveBusinessCustomerProfile Triggered 3');
        if (isValid && isValidEmail) {
            console.log('All Validation Success');
            const { profileLoading, profileErrorMessage } = this.props;
            if (profileLoading === false) {
                if (profileErrorMessage === null) {
                    this.setState({ isEditable: false });
                }
            }
        } else {
            console.log('All Validation Failed');
        }
        console.log('SaveBusinessCustomerProfile Triggered 4');
    }


    async validateUserName() {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        const { userName } = this.props;
        if (userName !== companyDetails.personDetail.userName) {
            if (companyDetails.personDetail.userName.length > 0
            && companyDetails.personDetail.userName.length < 5) {
                this.setState({ userNameError: AlertMessages.Invitations.MinUserName });
            } else {
                try {
                    const response = await validatePerson({
                        email: false,
                        userName: true,
                        searchText: companyDetails.personDetail.userName
                    });
                    if (response.data.resultCount > 0) {
                        if (companyDetails.personDetail.userName === response.data.data.userName) {
                            this.setState({
                                showAlert: true,
                                confirmText: AlertMessages.Invitations.ExistingErrorMessage + 'User Name',
                                fieldToBeValidate: InvitationFields.UserName
                            });
                            console.log(response);
                        }
                    }
                } catch (error) {
                    console.log(console.log(error));
                }
            }
        }
    }

    async validateBusinessEmail() {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        const { unChangedCompanyDetails } = this.props;

        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        let businessEmail = '';

        if (unChangedCompanyDetails) {
            businessEmail = unChangedCompanyDetails.unChangedPersonDetail.email;
        }
        // console.log('New :', companyDetails.personDetail.email);
        if (businessEmail !== companyDetails.personDetail.email) {
            if (companyDetails.personDetail.email.trim() === '') {
                this.setState({ businessEmailError: AlertMessages.Invitations.RequiredEmail });
            } else if (!RegEx.EmailAddress.test(companyDetails.personDetail.email)) {
                this.setState({ businessEmailError: AlertMessages.Common.ValidEmailAddress });
            } else {
                try {
                    const response = await validatePerson({
                        email: true,
                        userName: false,
                        searchText: companyDetails.personDetail.email
                    });
                    if (response.data.resultCount > 0) {
                        if (companyDetails.personDetail.email === response.data.data.email) {
                            this.setState({
                                businessEmailError: AlertMessages.Invitations.ExistingErrorMessage + 'Business Email',
                                isValidEmail: false
                            });
                            console.log(response);
                        }
                    }
                } catch (error) {
                    console.log(console.log(error));
                }
            }
        }
    }

    async validateAdditionalEmail() {
        let { companyDetails } = this.state;
        const { customerProfile } = this.props;
        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        const { unChangedCompanyDetails } = this.props;

        if (companyDetails === null) {
            companyDetails = customerProfile;
        }
        let additionalEmail = '';

        if (unChangedCompanyDetails) {
            additionalEmail = unChangedCompanyDetails.additionalEmail;
        }
        if (additionalEmail !== companyDetails.additionalEmail) {
            if (this.validateMultipleEmails(companyDetails.additionalEmail)) {
                this.setState({
                    additionalEmailError: AlertMessages.Common.ValidEmailAddress
                });
            }
        }
    }

    render() {
        const {
            isEditable,
            accountsPayableNameError, accountsPayableEmailError, contactNumberError,
            businessLegalNameError, companyNameError, firstNameError, lastNameError,
            mailingAddressError, billingAddressError, userNameError, showAlert,
            confirmText, businessEmailError, fieldToBeValidate, additionalEmailError
        } = this.state;

        console.log(isEditable,
            accountsPayableNameError, accountsPayableEmailError, contactNumberError,
            businessLegalNameError, companyNameError, firstNameError, lastNameError,
            mailingAddressError, billingAddressError, userNameError, showAlert,
            confirmText, businessEmailError, fieldToBeValidate);

        let { companyDetails } = this.state;
        const {
            customerProfile,
            profileLoading,
            profileErrorMessage,
            oldUserName
        } = this.props;

        if (companyDetails === null) {
            companyDetails = customerProfile;
        }

        const billingAddress = [];

        if (companyDetails && companyDetails.companyAddresses) {
            companyDetails.companyAddresses
                .map((x) => {
                    console.log(x);
                    if (x.isPrimary === true) {
                        billingAddress.push(x);
                    }
                    return x.isPrimary === true;
                });
        }

        const billingIndex = companyDetails
        && companyDetails.companyAddresses
        && companyDetails.companyAddresses
            .findIndex((x) => { return x.isPrimary === true; });

        const mailingAddress = companyDetails
        && companyDetails.companyAddresses
         && companyDetails.companyAddresses
             .filter((x) => { return x.isPrimary === false; });

        const mailingIndex = companyDetails
        && companyDetails.companyAddresses
        && companyDetails.companyAddresses
            .findIndex((x) => { return x.isPrimary === false; });


        console.log('billingIndex :', billingIndex);
        console.log('mailingIndex :', mailingIndex);

        console.log('CustomerProfile :', companyDetails);
        console.log('billingAddress :', billingAddress, mailingIndex, profileErrorMessage);

        return (
            <>
                <ProfileInfo
                    editable={this.changeEditable}
                    isEditable={isEditable}
                    companyDetails={companyDetails}
                    contactNumberError={contactNumberError}
                    firstNameError={firstNameError}
                    lastNameError={lastNameError}
                    businessLegalNameError={businessLegalNameError}
                    companyNameError={companyNameError}
                    billingAddressError={billingAddressError}
                    billingAddress={billingAddress}
                    billingIndex={billingIndex === -1 ? 0 : billingIndex}
                    onBillingAddressChange={this.onBillingAddressChange}
                    onTaxIdChange={this.onTaxIdChange}
                    validateZipCode={this.validateZipCode}
                    validatePhoneNumber={this.validatePhoneNumber}
                    validateBusinessEmail={this.validateBusinessEmail}
                    onEmailChange={this.onEmailChange}
                    businessEmailError={businessEmailError}
                    setRef={this.setRef}
                    handleChange={this.handleChange}
                    oldUserName={oldUserName}
                    validateAdditionalEmail={this.validateAdditionalEmail}
                    additionalEmailError={additionalEmailError}
                />
                <MailingAddress
                    isEditable={isEditable}
                    mailingAddress={mailingAddress}
                    mailingIndex={mailingIndex === -1 ? 0 : mailingIndex}
                    mailingAddressError={mailingAddressError}
                    companyDetails={companyDetails}
                    onMailingAddressChange={this.onMailingAddressChange}
                    validateZipCode={this.validateZipCode}
                    setMailingAddressRef={this.setMailingAddressRef}
                />
                <AccountPayableInfo
                    companyDetails={companyDetails}
                    isEditable={isEditable}
                    onAccountsPayableEmailChange={this.onAccountsPayableEmailChange}
                    onAccountsPayableNameChange={this.onAccountsPayableNameChange}
                    accountsPayableNameError={accountsPayableNameError}
                    accountsPayableEmailError={accountsPayableEmailError}
                    validateAccountsPayableEmail={this.validateAccountsPayableEmail}
                    setAccountInfoRef={this.setAccountInfoRef}
                />
                {profileErrorMessage && <p className="alert alert-danger">{profileErrorMessage}</p>}
                { isEditable
                    ? (
                        <div className="cd-cancel-save-btn-wrapper">
                            <button
                                className="secondary-btn secondary-btn-blue"
                                type="submit"
                                onClick={() => {
                                    this.onCancelClick();
                                }}
                            >
                                CANCEL
                            </button>
                            <button
                                className="primary-btn primary-btn-blue"
                                type="submit"
                                onClick={this.saveBusinessCustomerProfile}
                            >
                                SAVE
                            </button>
                        </div>
                    )
                    : null}

                <ConfirmAlert
                    show={showAlert}
                    save={this.alertSave}
                    close={this.alertSave}
                    headerText=""
                    confirmText={confirmText}
                    saveButtonText="Continue"
                    cancelButtonText="Cancel"
                />
                {profileLoading ? <Spinner loaderClass="loader-fullscreen" /> : null}
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        customerProfile: state.customerProfile.customerProfileDetails,
        unChangedCompanyDetails: state.customerProfile.unChangedCompanyDetails,
        loading: state.customerProfile.loading,
        profileLoading: state.customerProfile.profileLoading,
        profileErrorMessage: state.customerProfile.profileErrorMessage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCustomerCompanyProfile: (companyObject) => {
            return dispatch(updateCompanyProfile(companyObject));
        },
        companyProfileReset: () => {
            return updateCompanyProfileReset();
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileIndex);
