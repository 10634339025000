/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

class ChangeUsername extends React.Component {
    render() {
        // const { customerProfile } = this.props;
        const {
            currentUsername,
            newUsername,
            confirmUsername,
            currentUsernameError,
            newUsernameError,
            confirmUsernameError,
            handleChange,
            validateUserNameCall
        } = this.props;
        return (
            <div className="change-username">
                <h2 className="cd-sub-heading">
                    Change Username
                </h2>
                <div className="form-group">
                    <label htmlFor="currentUsername">Current Username</label>
                    <input
                        className={
                            currentUsernameError === ''
                                ? 'form-control'
                                : 'form-control is-invalid'
                        }
                        id="currentUsername"
                        type="text"
                        // value={customerProfile && customerProfile.personDetail.userName}
                        value={currentUsername}
                        onChange={(event) => {
                            handleChange(event, 'currentUsername', 'username');
                        }}
                        placeholder="User Name"
                    />
                    <span className="invalid-feedback">
                        {currentUsernameError}
                    </span>
                </div>
                <div className="form-group">
                    <label htmlFor="newUsername">Enter New Username</label>
                    <input
                        className={
                            newUsernameError === ''
                                ? 'form-control'
                                : 'form-control is-invalid'
                        }
                        id="newUsername"
                        type="text"
                        value={newUsername}
                        onChange={(event) => {
                            handleChange(event, 'newUsername', 'username');
                        }}
                        placeholder="Enter New Username"
                        onBlur={validateUserNameCall}
                    />
                    <span className="invalid-feedback">
                        {newUsernameError}
                    </span>
                </div>
                <div className="form-group">
                    <label htmlFor="reenterNewUsername">Re-Enter New Username</label>
                    <input
                        className={
                            confirmUsernameError === ''
                                ? 'form-control'
                                : 'form-control is-invalid'
                        }
                        id="reenterNewUsername"
                        type="text"
                        value={confirmUsername}
                        onChange={(event) => {
                            handleChange(event, 'confirmUsername', 'username');
                        }}
                        placeholder="Re-Enter New Username"
                    />
                    <span className="invalid-feedback">
                        {confirmUsernameError}
                    </span>
                </div>
            </div>
        );
    }
}

export default ChangeUsername;
