import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    packages: [
        {
            packageName: '',
            setupFee: null,
            recurringFee: null,
            searchAndSocialCommission: null,
            otherMediaCommission: null,
            modelingFee: null,
            id: null
        }
    ],
    errorMessage: null,
    loading: null
};

export const getPackagesSuccess = (state, action) => {
    console.log('data====>', action.data);
    return updateObject(state, {
        packages: action.data,
        errorMessage: null,
        loading: false
    });
};

const packageStart = (state, action) => {
    return updateObject(state, { errorMessage: null, loading: true });
};

const getPackageFail = (state, action) => {
    return updateObject(state, { errorMessage: action.errorMessage, loading: false });
};

const packageReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.GET_PACKAGES:
        return getPackagesSuccess(state, action);
    case actionTypes.PACKAGE_START:
        return packageStart(state, action);
    case actionTypes.GET_PACKAGES_FAIL:
        return getPackageFail(state, action);
    default:
        return state;
    }
};

export default packageReducer;
