/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

class AccountPayableInfo extends React.Component {
    render() {
        const {
            companyDetails,
            isEditable,
            onAccountsPayableEmailChange,
            onAccountsPayableNameChange,
            accountsPayableNameError,
            accountsPayableEmailError,
            validateAccountsPayableEmail,
            setAccountInfoRef
        } = this.props;
        return (
            <div className="cd-account-payable-info">
                <h2 className="cd-sub-heading">
                    Accounts Payable Information
                </h2>
                <span ref={setAccountInfoRef} />
                <div className="form-group">
                    <label htmlFor="cdPayableContactName">Accounts Payable Contact Name</label>
                    <input
                        className={`form-control ${
                            accountsPayableNameError === '' ? '' : 'is-invalid'
                        }`}
                        id="cdPayableContactName"
                        type="text"
                        placeholder="Accounts Payable Contact Name"
                        value={companyDetails && companyDetails.accountsPayableName}
                        disabled={!isEditable}
                        onChange={(event) => {
                            onAccountsPayableNameChange(event);
                        }}
                    />
                    <span className="invalid-feedback">
                        {accountsPayableNameError}
                    </span>
                </div>
                <div className="form-group mb-0">
                    <label htmlFor="cdPayableEmailAddress">Accounts Payable Email</label>
                    <input
                        className={`form-control ${
                            accountsPayableEmailError === '' ? '' : 'is-invalid'
                        }`}
                        id="cdPayableEmailAddress"
                        type="text"
                        placeholder="Accounts Payable Email Address"
                        value={companyDetails && companyDetails.accountsPayableEmail}
                        onChange={(event) => {
                            onAccountsPayableEmailChange(event);
                        }}
                        disabled={!isEditable}
                        onBlur={validateAccountsPayableEmail}
                    />
                    <span className="invalid-feedback">
                        {accountsPayableEmailError}
                    </span>
                </div>
            </div>
        );
    }
}

export default AccountPayableInfo;
