import {
    REQUEST_FLIGHTING_CHANGE_POST_START,
    REQUEST_FLIGHTING_CHANGE_POST_FAIL,
    REQUEST_FLIGHTING_CHANGE_POST_SUCCESS,
    REQUEST_FLIGHTING_CHANGE_POST_RESET
} from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    loading: false,
    showSuccess: false,
    errorMessage: null
};

const requestFlightingChangeStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        showSuccess: false,
        errorMessage: null
    });
};

const requestFlightingChangeFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        showSuccess: false,
        errorMessage: action.errorMessage
    });
};

const requestFlightingChangeSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        showSuccess: true,
        errorMessage: null
    });
};

const requestFlightingChangeReset = (state, action) => {
    return updateObject(state, {
        loading: false,
        showSuccess: false,
        errorMessage: null
    });
};

const requestFlightingChangeReducer = (state = initialState, action) => {
    switch (action.type) {
    case REQUEST_FLIGHTING_CHANGE_POST_START:
        return requestFlightingChangeStart(state, action);
    case REQUEST_FLIGHTING_CHANGE_POST_SUCCESS:
        return requestFlightingChangeSuccess(state, action);
    case REQUEST_FLIGHTING_CHANGE_POST_RESET:
        return requestFlightingChangeReset(state, action);
    case REQUEST_FLIGHTING_CHANGE_POST_FAIL:
        return requestFlightingChangeFail(state, action);
    default:
        return state;
    }
};

export default requestFlightingChangeReducer;
