import React from 'react';
import HeaderDashboard from '../common/HeaderDashboard';
import Footer from '../common/Footer';

const AdminLayout = (props) => {
    const { children } = props;
    return (
        <>
            <HeaderDashboard />
            {children}
            <Footer />
        </>
    );
};

export default AdminLayout;
