import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    promotionList: [],
    channelList: [],
    creativeUploads: null,
    loading: false,
    errorMessage: null,
    getPromotionLoading: false,
    getChannelLoading: false,
    getCreativeLoading: false
};

const adminPromotionListStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        errorMessage: null
    });
};

const adminPromotionListFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: action.errorMessage,
        getPromotionLoading: false
    });
};

const getAdminPromotionListSuccess = (state, action) => {
    return updateObject(state, {
        promotionList: action.data.promotionList,
        loading: false,
        errorMessage: null,
        getPromotionLoading: false
    });
};


const adminChannelListStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        errorMessage: null
    });
};

const adminChannelListFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: action.errorMessage,
        getChannelLoading: false
    });
};

const getAdminChannelListSuccess = (state, action) => {
    return updateObject(state, {
        channelList: action.data.channelList,
        loading: false,
        errorMessage: null,
        getChannelLoading: false
    });
};


const adminCreativeListStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        errorMessage: null
    });
};

const adminCreativeListFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: action.errorMessage,
        getCreativeLoading: false
    });
};

const getAdminCreativeListSuccess = (state, action) => {
    return updateObject(state, {
        creativeUploads: action.data.creativeUploads,
        loading: false,
        errorMessage: null,
        getCreativeLoading: false
    });
};


const promotionReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.ADMIN_PROMOTION_LIST_START:
        return adminPromotionListStart(state, { getPromotionLoading: true });
    case actionTypes.ADMIN_PROMOTION_LIST_SUCCESS:
        return getAdminPromotionListSuccess(state, action);
    case actionTypes.ADMIN_PROMOTION_LIST_FAIL:
        return adminPromotionListFail(state, action);

    case actionTypes.ADMIN_CHANNEL_LIST_START:
        return adminChannelListStart(state, { getChannelLoading: true });
    case actionTypes.ADMIN_CHANNEL_LIST_SUCCESS:
        return getAdminChannelListSuccess(state, action);
    case actionTypes.ADMIN_CHANNEL_LIST_FAIL:
        return adminChannelListFail(state, action);

    case actionTypes.ADMIN_CREATIVE_LIST_START:
        return adminCreativeListStart(state, { getCreativeLoading: true });
    case actionTypes.ADMIN_CREATIVE_LIST_SUCCESS:
        return getAdminCreativeListSuccess(state, action);
    case actionTypes.ADMIN_CREATIVE_LIST_FAIL:
        return adminCreativeListFail(state, action);

    default:
        return state;
    }
};

export default promotionReducer;
