import {
    CLEAR_ALL_PACKAGE_RELATED_STORES,
    UPDATE_NAVIGATION
} from './actionTypes';

export const updateNavigation = (key, id) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_NAVIGATION,
            data: { selectedKey: key, stepId: id }
        });
    };
};

export const clearAllPackageStores = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_PACKAGE_RELATED_STORES
        });
    };
};
