/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import Edit from '../../../assets/images/icon-edit.svg';
import {
    LocationFeilds,
    StateCodes,
    TimeZones
} from '../../Constants';

class ProfileInfo extends React.Component {
    constructor() {
        super();
        this.state = {
            // isEditable: false
        };
    }

    getTimezones = () => {
        const options = [];
        options.push({ value: '', label: 'Unselected' });
        // eslint-disable-next-line array-callback-return
        TimeZones.map((item) => {
            options.push({ value: item.value, label: item.value });
        });
        return options;
    }

    getStates = () => {
        const options = [];
        options.push({ value: '', label: 'Select' });
        StateCodes.map((item) => {
            options.push({ value: item.StateCode, label: item.StateCode });
            return options;
        });
        return options;
    };

    changeEditable = () => {
        const { editable } = this.props;
        editable();
    }

    render() {
        const {
            onUserNameChange,
            isEditable,
            companyDetails,
            onClickCompanyProfileEdit,
            onCompanyNameChange,
            onFirstNameChange,
            onLastNameChange,
            onContactNumberChange,
            onCompanyLegalNameChange,
            validatePhoneNumber,
            validateZipCode,
            billingAddress,
            billingIndex,
            billingAddressError,
            onBillingAddressChange,
            taxId,
            onTaxIdChange,
            contactNumberError,
            businessLegalNameError,
            companyNameError,
            firstNameError,
            lastNameError,
            validateUserName,
            userNameError,
            validateBusinessEmail,
            businessEmailError,
            onEmailChange,
            setRef,
            handleChange,
            oldUserName,
            additionalEmailError,
            validateAdditionalEmail
        } = this.props;
        console.log(onUserNameChange,
            isEditable,
            companyDetails,
            onClickCompanyProfileEdit,
            onCompanyNameChange,
            onFirstNameChange,
            onLastNameChange,
            onContactNumberChange,
            onCompanyLegalNameChange,
            validatePhoneNumber,
            validateZipCode,
            billingAddress,
            billingIndex,
            billingAddressError,
            onBillingAddressChange,
            taxId,
            onTaxIdChange,
            contactNumberError,
            businessLegalNameError,
            companyNameError,
            firstNameError,
            lastNameError,
            validateUserName,
            userNameError,
            validateBusinessEmail,
            businessEmailError,
            onEmailChange,
            setRef);

        let address = null;
        if (billingAddress && billingAddress.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            address = billingAddress[0];
        }

        let state = null;
        if (address) {
            state = address.state.trim() === ''
                ? 'Select' : address.state;
        }
        // const state = '';
        const stateSelectedValue = {
            value: state === 'Select' ? '' : state,
            label: state === 'Select' ? 'Select' : state
        };

        let timeZone = null;
        if (companyDetails.timeZone) {
            timeZone = companyDetails.timeZone.trim() === ''
                ? 'Unselected' : companyDetails.timeZone;
        } else {
            timeZone = 'Unselected';
        }
        // const state = '';
        const timeZoneSelectedValue = {
            value: timeZone === 'Unselected' ? 'Unselected' : timeZone,
            label: timeZone === 'Unselected' ? 'Unselected' : timeZone
        };

        return (
            <div className="cd-profile-info">
                { !isEditable
                    ? (
                        <button
                            className="edit-img"
                            type="button"
                            onClick={() => {
                                this.changeEditable();
                            }}
                        >
                            <img src={Edit} alt="edit-icon" />
                        </button>
                    )
                    : null}
                <h2 className="cd-sub-heading">
                    Profile Information
                </h2>
                <div className="form-group">
                    <label htmlFor="accountUserName">User Name</label>
                    <input
                        className="form-control"
                        id="accountUserName"
                        type="text"
                        disabled="disabled"
                        value={oldUserName}
                        placeholder="User Name"
                    />
                </div>
                <div className="form-group-wrapper">
                    <div className="form-group">
                        <label htmlFor="cdBusinessName">Legal Name of Business Entity</label>
                        <input
                            className={
                                businessLegalNameError === ''
                                    ? 'form-control'
                                    : 'form-control is-invalid'
                            }
                            id="cdBusinessName"
                            type="text"
                            placeholder="Enter business legal name"
                            value={companyDetails && companyDetails.companyLegalName}
                            onChange={(event) => {
                                handleChange(event, 'companyLegalName');
                            }}
                            disabled={!isEditable}
                        />
                        <span className="invalid-feedback">
                            {businessLegalNameError}
                        </span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cdDBAName">Doing Business As</label>
                        <input
                            className={
                                companyNameError === ''
                                    ? 'form-control'
                                    : 'form-control is-invalid'
                            }
                            id="cdDBAName"
                            type="text"
                            disabled={!isEditable}
                            value={companyDetails && companyDetails.companyName}
                            onChange={(event) => {
                                handleChange(event, 'companyName');
                            }}
                            placeholder="Doing Business As Name"
                        />
                        <span className="invalid-feedback">
                            {companyNameError}
                        </span>
                    </div>
                </div>
                <div className="form-group-wrapper">
                    <div className="form-group">
                        <label htmlFor="cdFirstName2">First Name</label>
                        <input
                            className={
                                firstNameError === ''
                                    ? 'form-control'
                                    : 'form-control is-invalid'
                            }
                            id="cdFirstName2"
                            type="text"
                            disabled={!isEditable}
                            value={companyDetails && companyDetails.personDetail.firstName}
                            onChange={(event) => {
                                handleChange(event, 'firstName');
                            }}
                            placeholder="Enter First Name"
                        />
                        <span className="invalid-feedback">
                            {firstNameError}
                        </span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cdLastName1">Last Name</label>
                        <input
                            className={
                                lastNameError === ''
                                    ? 'form-control'
                                    : 'form-control is-invalid'
                            }
                            id="cdLastName1"
                            type="text"
                            disabled={!isEditable}
                            value={companyDetails && companyDetails.personDetail.lastName}
                            onChange={(event) => {
                                handleChange(event, 'lastName');
                            }}
                            placeholder="Enter Last Name"
                        />
                        <span className="invalid-feedback">
                            {lastNameError}
                        </span>
                    </div>
                </div>
                <div className="form-group-wrapper">
                    <div className="form-group">
                        <label htmlFor="cdPhoneNumber">Phone Number</label>
                        <NumberFormat
                            className={
                                contactNumberError === ''
                                    ? 'form-control'
                                    : 'form-control is-invalid'
                            }
                            id="cdPhoneNumber"
                            type="text"
                            disabled={!isEditable}
                            format="(###) ###-####"
                            value={companyDetails && companyDetails.personDetail.contactNumber}
                            onChange={(event) => {
                                handleChange(event, 'contactNumber');
                            }}
                            placeholder="Enter Phone Number"
                        />
                        <span className="invalid-feedback">
                            {contactNumberError}
                        </span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cdBusinessEmail">Business Email</label>
                        <input
                            className={
                                businessEmailError === ''
                                    ? 'form-control'
                                    : 'form-control is-invalid'
                            }
                            id="cdBusinessEmail"
                            type="email"
                            disabled={companyDetails && companyDetails.isAdmin && isEditable ? '' : !isEditable ? 'disabled' : 'disabled'}
                            value={companyDetails && companyDetails.personDetail.email}
                            onChange={(event) => {
                                handleChange(event, 'email');
                            }}
                            onBlur={validateBusinessEmail}
                            placeholder="Business Email"
                        />
                        <span className="invalid-feedback">
                            {businessEmailError}
                        </span>
                    </div>
                </div>
                <div className="form-group-wrapper">
                    <div className="form-group">
                        <label htmlFor="cdAdditionalEmail">Copy on Email Updates</label>
                        <textarea
                            className={
                                additionalEmailError === ''
                                    ? 'form-control copy-email-textarea'
                                    : 'form-control copy-email-textarea is-invalid'
                            }
                            id="cdAdditionalEmail"
                            type="email"
                            placeholder="Copy Email"
                            value={companyDetails.additionalEmail}
                            disabled={!isEditable}
                            onChange={(event) => {
                                handleChange(event, 'additionalEmail');
                            }}
                            onBlur={validateAdditionalEmail}
                        />
                        <span className="invalid-feedback">{additionalEmailError}</span>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="cd-address1">Address 1</label>
                    <input
                        className={
                            billingAddressError.address1 === ''
                                ? 'form-control'
                                : 'form-control is-invalid'
                        }
                        id="cd-address1"
                        type="text"
                        disabled={!isEditable}
                        value={address && address.address1}
                        onChange={(event) => {
                            onBillingAddressChange(event, LocationFeilds.Address1, billingIndex);
                        }}
                        placeholder="Address 1"
                    />
                    <span className="invalid-feedback">
                        {billingAddressError.address1}
                    </span>
                </div>
                <div className="form-group">
                    <label htmlFor="cd-address2">Address 2</label>
                    <input
                        className="form-control"
                        id="cd-address2"
                        type="text"
                        disabled={!isEditable}
                        value={address && address.address2}
                        onChange={(event) => {
                            onBillingAddressChange(event, LocationFeilds.Address2, billingIndex);
                        }}
                        placeholder="Address 2"
                    />
                </div>
                <div className="city-state-wrapper">
                    <div className="form-group">
                        <label htmlFor="cdCity2">City</label>
                        <input
                            className={
                                billingAddressError.city === ''
                                    ? 'form-control input-city'
                                    : 'form-control is-invalid'
                            }
                            id="cdCity2"
                            type="text"
                            disabled={!isEditable}
                            value={address && address.city}
                            onChange={(event) => {
                                onBillingAddressChange(event, LocationFeilds.City, billingIndex);
                            }}
                            placeholder="City"
                        />
                        <span className="invalid-feedback">
                            {billingAddressError.city}
                        </span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cdState2">State</label>
                        <Select
                            className="custom-react-select custom-react-state-select"
                            aria-label="cdState2"
                            id="cdState2"
                            options={this.getStates()}
                            value={stateSelectedValue}
                            isDisabled={!isEditable}
                            onChange={(event) => {
                                onBillingAddressChange(event, LocationFeilds.State, billingIndex);
                            }}
                            classNamePrefix="custom-react-dropdown"
                        />
                        <span className="invalid-feedback">
                            {billingAddressError.state}
                        </span>
                    </div>
                    <div className="form-group cd-zip-code">
                        <label htmlFor="cdZipCode2">Zip Code</label>
                        <input
                            className={
                                billingAddressError.zipCode === ''
                                    ? 'form-control input-zipcode'
                                    : 'form-control is-invalid'
                            }
                            id="cdZipCode2"
                            type="text"
                            maxLength="5"
                            disabled={!isEditable}
                            value={address && address.zipCode}
                            onChange={(event) => {
                                onBillingAddressChange(event, LocationFeilds.ZipCode, billingIndex);
                            }}
                            onBlur={validateZipCode}
                            pattern="\d*"
                            placeholder="Zip Code"
                        />
                        <span className="invalid-feedback">
                            {billingAddressError.zipCode}
                        </span>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="taxId">Tax ID (Optional)</label>
                    <input
                        className="form-control"
                        id="taxId"
                        type="text"
                        disabled={!isEditable}
                        value={companyDetails && companyDetails.taxId}
                        onChange={(event) => {
                            handleChange(event, 'taxId');
                        }}
                        placeholder="Enter Tax ID"
                    />
                </div>
                <div className="form-group time-zone-dropdown">
                    <label htmlFor="timeZone">Time Zone</label>
                    <Select
                        className="custom-react-select custom-react-state-select"
                        id="timeZone"
                        aria-label="timeZone"
                        isDisabled={!isEditable}
                        options={this.getTimezones()}
                        value={timeZoneSelectedValue}
                        classNamePrefix="custom-react-dropdown"
                        onChange={(event) => {
                            onBillingAddressChange(event, LocationFeilds.TimeZone, billingIndex);
                        }}
                        placeholder="Unselected"
                    />
                </div>
            </div>
        );
    }
}

export default ProfileInfo;
