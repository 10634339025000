/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

class Security extends React.Component {
    constructor() {
        super();
        this.state = {
            // isEditable: false
        };
    }

    changeEditable = (option) => {
        const { editable } = this.props;
        editable(option);
    }

    render() {
        const { customerProfile } = this.props;
        return (
            <div className="security">
                <div className="form-group">
                    <label htmlFor="cdUserName">User Name</label>
                    <input
                        className="form-control"
                        id="cdUserName"
                        type="text"
                        value={customerProfile && customerProfile.personDetail.userName}
                        placeholder="User Name"
                    />
                    <p
                        onClick={() => {
                            this.changeEditable('username');
                        }}
                        className="change-username-password-link"
                    >
                        Change Username
                    </p>
                </div>
                <div className="form-group">
                    <label htmlFor="cdPassword">Password</label>
                    <input
                        className="form-control"
                        id="cdPassword"
                        type="password"
                        value="**********"
                        placeholder="Password"
                    />
                    <p
                        onClick={() => {
                            this.changeEditable('password');
                        }}
                        className="change-username-password-link"
                    >
                        Change Password
                    </p>
                </div>
            </div>
        );
    }
}

export default Security;
