/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable  no-tabs */
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import { v4 as uuIdv4 } from 'uuid';

import {
    ADMIN_GET_FLIGHTING_START,
    STORE_CAMPAIGN_ID,
    CAMPAIGN_DETAILS_END,
    CAMPAIGN_DETAILS_START,
    GET_FLIGHTING_SUCCESS,
    FLIGHTING_DETAILS_ERROR_HANDLER,
    UPDATE_FLIGHTING_STATUS,
	UPDATE_FLIGHTING_STATUS_ERROR,
	GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_START,
	GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_COMPLETE,
	GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_FAIL,

    // Promotion Section
    POST_PROMOTION_DETAILS,
    PROMOTION_MODAL_HANDLER,
    PROMOTION_ERROR_HANDLER,
    PROMOTION_LOCATIONS_GROUP,
    PROMOTION_LOCATIONS_GROUP_ERROR_HANDLER,

    // Location And Channel Section
    LOCATION_MODAL_HANDLER,
    POST_LOCATION_DETAILS,
    LOCATIONS_ERROR_HANDLER,
    GET_CHANNEL_LIST_SUCCESS,

    // Creative Upload Section
    GET_CREATIVE_DETAILS,
    POST_CREATIVE_DETAILS,
    UPDATE_CREATIVE_UPLOAD_LIST,
    CREATIVE_UPLOAD_ERROR_HANDLER,
    DELETE_CREATIVE_DETAILS,
    CLOSE_CREATIVE_UPLOAD_POPUP,
    SUBMIT_CREATIVE_DETAILS_FOR_REVIEW,
	HANDLE_WARNING_BOX,

	// Campaign dark day
	UPDATE_SHOW_DARK_DAY,
	ADD_CAMPIGN_DARK_DAY_START,
	ADD_CAMPIGN_DARK_DAY_FAIL,
	ADD_CAMPIGN_DARK_DAY_SUCCESS,
	UPDATE_SHOW_EDIT_DARK_DAY,
	UPDATE_CAMPIGN_DARK_DAY_START,
	UPDATE_CAMPIGN_DARK_DAY_SUCCESS,
	UPDATE_CAMPIGN_DARK_DAY_FAIL,

	// customer request revision
	REQUEST_REVISION_START,
	REQUEST_REVISION_SUCCESS,
	REQUEST_REVISION_FAIL
} from './actionTypes';

import {
    getFlightingOptions
} from '../services/campaignDate';

import {
    getCampaignTasks
} from './adminCampaignDetails';

import {
    getFlightingDetails,
    postPromotion,
    updatePromotion,
    deletePromotion,
    postLocationGroupDetails,
    getLocationDetails,
    updatePriorityWeeksOption,
    getCreativeUploadDetails,
    postCreativeUploadDetails,
    updateCreativeUploadDetails,
    deleteCreativeUploadDetails,
    updateAdditionalDetails,
    submitCreativeForReview,
    requestRevisionOrTraffic,
    checkApplyToAllLocation,
	updateShowWarning,
	getPromotionLocationGroupForChannelService,
	addCampaignDarkDayService,
	getCampaignDarkDayService,
	updateCampaignDarkDayService,
	acceptOrRejectRequestRevisionService
} from '../services/adminFlightingTable';

import {
    getMediaChannelsService
} from '../services/mediaChannels';

import {
    cloneObject,
    isObjectEmpty,
    isArrayEmpty,
    setErrorMessage,
    dateFormatter,
    checkIsBetweenDates,
    getDifferenceBetweenDates,
    addDaysToDate
} from '../../utils/utility_helpers';

import {
    creativeUploadTv,
    creativeChannelList,
    taskStatusDetails
} from '../../components/Constants';
import { markAsCompleteTaskService } from '../services/campaign';

// Making promotions array in this format [[], []]
const buildPromotionDetails = (promotions = {}) => {
    let promotionDates = [];
    // const firstPromotionRow = [];
    // const secondPromotionRow = [];

    console.log('promotions', promotions);

    promotions.map((promotion) => {
        const {
            startDate,
            endDate,
            createdDateTime,
            id,
            promotionName,
            campaignLocationGroupDetail
        } = promotion;

        const locationGroups = [];
        const data = {};
        data.promotionStartDate = startDate || '';
        data.startDate = startDate ? dateFormatter(startDate, 'MM-DD-YYYY') : '';
        data.endDate = endDate ? dateFormatter(endDate, 'MM-DD-YYYY') : '';
        data.createdDateTime = createdDateTime ? dateFormatter(createdDateTime, 'utcToLocalTimeFormat') : '';
        data.promotionId = id || '';
        data.promotionName = promotionName || '';
        campaignLocationGroupDetail.map(((item) => {
            const locationInfo = {};
            const {
                campaignLocationGroupName,
                campaignLocationGroupId
            } = item;

            locationInfo.label = campaignLocationGroupName;
            locationInfo.value = campaignLocationGroupId;

            locationGroups.push(locationInfo);
            return null;
        }));
        data.promotionLocations = locationGroups || [];

        // if (firstPromotionRow && !isArrayEmpty(firstPromotionRow)) {
        //     let isExitsInFirstArray = false;

        //     firstPromotionRow.map((item) => {
        //         if (checkIsBetweenDates(data.startDate, item.startDate, item.endDate)) {
        //             isExitsInFirstArray = true;
        //         } else if (checkIsBetweenDates(data.endDate, item.startDate, item.endDate)) {
        //             isExitsInFirstArray = true;
        //         } else if (checkIsBetweenDates(item.startDate, data.startDate, data.endDate)) {
        //             isExitsInFirstArray = true;
        //         } else if (checkIsBetweenDates(item.endDate, data.startDate, data.endDate)) {
        //             isExitsInFirstArray = true;
        //         }

        //         return null;
        //     });

        //     if (!isExitsInFirstArray) {
        //         data.id = `firstRow ${data.startDate}`;
        //         firstPromotionRow.push(data);
        //     } else if (isExitsInFirstArray) {
        //         data.id = `secondRow ${data.startDate}`;
        //         secondPromotionRow.push(data);
        //     }
        // } else {
        //     data.id = `firstRow ${data.startDate}`;
        //     firstPromotionRow.push(data);
		// }
		data.id = `${id} ${data.startDate}`;
        const difference = getDifferenceBetweenDates(dateFormatter(createdDateTime, 'MM-DD-YYYY'), data.startDate);

        if (difference > 15) {
            data.uploadStatus = 'pending';
        } else {
            data.uploadStatus = 'creativeDue';
		}

		if (promotionDates.length === 0) {
			promotionDates.push([data]);
		} else {
			let isAddedInExistingRow = false;
			for (let i = 0; i < promotionDates.length; i++) {
				let isExitInInnerArray = false;
				for (let j = 0; j < promotionDates[i].length; j++) {
					if (!isAddedInExistingRow) {
						if (checkIsBetweenDates(data.startDate, promotionDates[i][j].startDate, promotionDates[i][j].endDate)) {
							isExitInInnerArray = true;
						} else if (!isAddedInExistingRow && checkIsBetweenDates(data.endDate, promotionDates[i][j].startDate, promotionDates[i][j].endDate)) {
							isExitInInnerArray = true;
						} else if (!isAddedInExistingRow && checkIsBetweenDates(promotionDates[i][j].startDate, data.startDate, data.endDate)) {
							isExitInInnerArray = true;
						} else if (!isAddedInExistingRow && checkIsBetweenDates(promotionDates[i][j].endDate, data.startDate, data.endDate)) {
							isExitInInnerArray = true;
						}
					}
				}
				if (!isExitInInnerArray && !isAddedInExistingRow) {
					promotionDates[i].push(data);
					isAddedInExistingRow = true;
					break;
				}
			}
			if (!isAddedInExistingRow) {
				promotionDates.push([data]);
			}
		}


        console.log('promotionDates', promotionDates);
        return null;
    });

    // firstPromotionRow.sort((a, b) => {
    //     return ((new Date(a.startDate) > new Date(b.startDate)) ? 1 : -1);
    // });

    // secondPromotionRow.sort((a, b) => {
    //     return ((new Date(a.startDate) > new Date(b.startDate)) ? 1 : -1);
    // });

    // promotionDates.push(firstPromotionRow, secondPromotionRow);
	promotionDates = promotionDates.sort((a, b) => { return ((new Date(a.startDate) > new Date(b.startDate)) ? 1 : -1); });
	return promotionDates;
};

// Making channel level task array in this format [[], []]
// const buildTaskDetails = (details = {}, campaignLocationGroups = [], channelsId = 0) => {
//     const taskDates = [];
//     const firstTaskRow = [];
//     const secondTaskRow = [];

//     details.map((task) => {
//         const {
//             promotionStartDate,
//             promotionEndDate,
//             locationGroupChannelId,
//             locationGroupPromotionId,
//             campaignPromotionId,
//             promotionLocationGroupChannelId,
//             promotionName,
//             parentLocationGroupChannelId,
//             tasks,
//             parentCampaignLocationGroupName,
//             parentCampaignLocationGroupId
//         } = task;

//         const data = {};
//         data.startDate = dateFormatter(promotionStartDate, 'MM-DD-YYYY');
//         data.endDate = dateFormatter(promotionEndDate, 'MM-DD-YYYY');
//         data.locationGroupChannelId = locationGroupChannelId || '';
//         data.locationGroupPromotionId = locationGroupPromotionId || '';
//         data.promotionLocationGroupChannelId = promotionLocationGroupChannelId || '';
//         data.campaignPromotionId = campaignPromotionId || '';
//         data.promotionName = promotionName || '';
//         data.parentLocationGroupChannelId = parentLocationGroupChannelId || '';
//         data.parentCampaignLocationGroupName = parentCampaignLocationGroupName || '';
//         data.parentCampaignLocationGroupId = parentCampaignLocationGroupId || '';

//         // Checking channel task is parent or child
//         data.applyToAllLocationsParent = !data.parentLocationGroupChannelId || (data.parentLocationGroupChannelId === data.promotionLocationGroupChannelId);
//         data.tasks = tasks;

//         data.childChannelTaskStatus = '';

//         // Channel task is child and getting parent channel task info
//         if (!data.applyToAllLocationsParent && parentCampaignLocationGroupId) {
//             const parentLocationGroups = campaignLocationGroups.filter((item) => {
//                 return item.campaignLocationGroupId === parentCampaignLocationGroupId;
//             });

//             let parentChannels = [];

//             // parent channel info
//             if (parentLocationGroups && !isArrayEmpty(parentLocationGroups)) {
//                 parentLocationGroups.map((location) => {
//                     if (location.campaignChannels && !isArrayEmpty(location.campaignChannels)) {
//                         parentChannels = location.campaignChannels.find((channel) => {
//                             return channel.channelId === channelsId;
//                         });
//                     }

//                     return null;
//                 });
//             }

//             if (parentChannels && !isObjectEmpty(parentChannels)) {
//                 if (parentChannels.taskList && !isArrayEmpty(parentChannels.taskList)) {
//                     parentChannels.taskList.map((item) => {
//                         if (item.parentLocationGroupChannelId && item.parentLocationGroupChannelId === parentLocationGroupChannelId) {
//                             if (item.tasks && !isArrayEmpty(item.tasks)) {
//                                 item.tasks.map((parentTask) => {
//                                     // Parent channel task is completed or approved status check
//                                     if (!parentTask.predecessorWorkFlowTaskId && parentTask.status
//                                         && parentTask.status.toLowerCase() === taskStatusDetails.complete.taskStatus.toLowerCase()) {
//                                         data.childChannelTaskStatus = taskStatusDetails.complete.taskStatus.toLowerCase();
//                                     } else if (parentTask.predecessorWorkFlowTaskId && parentTask.status
//                                         && parentTask.status.toLowerCase() === taskStatusDetails.approved.taskStatus.toLowerCase()) {
//                                         data.childChannelTaskStatus = taskStatusDetails.approved.taskStatus.toLowerCase();
//                                     }

//                                     return null;
//                                 });
//                             }
//                         }

//                         return null;
//                     });
//                 }
//             }
//         }

//         if (firstTaskRow && !isArrayEmpty(firstTaskRow)) {
//             let isExitsInFirstArray = false;

//             firstTaskRow.map((item) => {
//                 if (checkIsBetweenDates(data.startDate, item.startDate, item.endDate)) {
//                     isExitsInFirstArray = true;
//                 } else if (checkIsBetweenDates(data.endDate, item.startDate, item.endDate)) {
//                     isExitsInFirstArray = true;
//                 } else if (checkIsBetweenDates(item.startDate, data.startDate, data.endDate)) {
//                     isExitsInFirstArray = true;
//                 } else if (checkIsBetweenDates(item.endDate, data.startDate, data.endDate)) {
//                     isExitsInFirstArray = true;
//                 }

//                 return null;
//             });

//             if (!isExitsInFirstArray) {
//                 data.id = `firstRow ${data.startDate}`;
//                 firstTaskRow.push(data);
//             } else if (isExitsInFirstArray) {
//                 data.id = `secondRow ${data.startDate}`;
//                 secondTaskRow.push(data);
//             }
//         } else {
//             data.id = `firstRow ${data.startDate}`;
//             firstTaskRow.push(data);
//         }

//         return null;
//     });

//     firstTaskRow.sort((a, b) => {
//         return ((new Date(a.startDate) > new Date(b.startDate)) ? 1 : -1);
//     });

//     secondTaskRow.sort((a, b) => {
//         return ((new Date(a.startDate) > new Date(b.startDate)) ? 1 : -1);
//     });

//     taskDates.push(firstTaskRow, secondTaskRow);

//     return taskDates;
// };

const buildTaskDetails = (details = {}, campaignLocationGroups = [], channels = {}) => {
	let taskDates = [];
	// const firstTaskRow = [];
	// const secondTaskRow = [];

	details.map((task) => {
		const {
			promotionStartDate,
			promotionEndDate,
			locationGroupChannelId,
			locationGroupPromotionId,
			campaignPromotionId,
			promotionLocationGroupChannelId,
			promotionName,
			parentLocationGroupChannelId,
			tasks,
			parentCampaignLocationGroupName,
			parentCampaignLocationGroupId
		} = task;

		if (!tasks.length && channels.channelName.toLowerCase() !== creativeChannelList.paidSearch.toLowerCase()) {
			return null;
		}
		const data = {};
		data.startDate = dateFormatter(promotionStartDate, 'MM-DD-YYYY');
		data.endDate = dateFormatter(promotionEndDate, 'MM-DD-YYYY');
		data.locationGroupChannelId = locationGroupChannelId || '';
		data.locationGroupPromotionId = locationGroupPromotionId || '';
		data.promotionLocationGroupChannelId = promotionLocationGroupChannelId || '';
		data.campaignPromotionId = campaignPromotionId || '';
		data.promotionName = promotionName || '';
		data.parentLocationGroupChannelId = parentLocationGroupChannelId || '';
		data.parentCampaignLocationGroupName = parentCampaignLocationGroupName || '';
		data.parentCampaignLocationGroupId = parentCampaignLocationGroupId || '';

		// Checking channel task is parent or child
		data.applyToAllLocationsParent = !data.parentLocationGroupChannelId || (data.parentLocationGroupChannelId === data.promotionLocationGroupChannelId);
		data.tasks = tasks;

		data.childChannelTaskStatus = '';

		// Channel task is child and getting parent channel task info
		if (!data.applyToAllLocationsParent && parentCampaignLocationGroupId) {
			const parentLocationGroups = campaignLocationGroups.filter((item) => {
				return item.campaignLocationGroupId === parentCampaignLocationGroupId;
			});

			let parentChannels = [];

			// parent channel info
			if (parentLocationGroups && !isArrayEmpty(parentLocationGroups)) {
				parentLocationGroups.map((location) => {
					if (location.campaignChannels && !isArrayEmpty(location.campaignChannels)) {
						parentChannels = location.campaignChannels.find((channel) => {
							return channel.channelId === channels.channelsId;
						});
					}

					return null;
				});
			}

			if (parentChannels && !isObjectEmpty(parentChannels)) {
				if (parentChannels.taskList && !isArrayEmpty(parentChannels.taskList)) {
					parentChannels.taskList.map((item) => {
						if (item.parentLocationGroupChannelId && item.parentLocationGroupChannelId === parentLocationGroupChannelId) {
							if (item.tasks && !isArrayEmpty(item.tasks)) {
								item.tasks.map((parentTask) => {
									// Parent channel task is completed or approved status check
									if (!parentTask.predecessorWorkFlowTaskId && parentTask.status
										&& parentTask.status.toLowerCase() === taskStatusDetails.complete.taskStatus.toLowerCase()) {
										data.childChannelTaskStatus = taskStatusDetails.complete.taskStatus.toLowerCase();
									} else if (parentTask.predecessorWorkFlowTaskId && parentTask.status
										&& parentTask.status.toLowerCase() === taskStatusDetails.approved.taskStatus.toLowerCase()) {
										data.childChannelTaskStatus = taskStatusDetails.approved.taskStatus.toLowerCase();
									}

									return null;
								});
							}
						}

						return null;
					});
				}
			}
		}

		// if (firstTaskRow && !isArrayEmpty(firstTaskRow)) {
		// 	let isExitsInFirstArray = false;

		// 	firstTaskRow.map((item) => {
		// 		if (checkIsBetweenDates(data.startDate, item.startDate, item.endDate)) {
		// 			isExitsInFirstArray = true;
		// 		} else if (checkIsBetweenDates(data.endDate, item.startDate, item.endDate)) {
		// 			isExitsInFirstArray = true;
		// 		} else if (checkIsBetweenDates(item.startDate, data.startDate, data.endDate)) {
		// 			isExitsInFirstArray = true;
		// 		} else if (checkIsBetweenDates(item.endDate, data.startDate, data.endDate)) {
		// 			isExitsInFirstArray = true;
		// 		}

		// 		return null;
		// 	});

		// 	if (!isExitsInFirstArray) {
		// 		data.id = `${campaignPromotionId} ${data.startDate}`;
		// 		firstTaskRow.push(data);
		// 	} else if (isExitsInFirstArray) {
		// 		data.id = `${campaignPromotionId} ${data.startDate}`;
		// 		secondTaskRow.push(data);
		// 	}
		// } else {
		// 	data.id = `${campaignPromotionId} ${data.startDate}`;
		// 	firstTaskRow.push(data);
		// }
		data.id = `${campaignPromotionId} ${data.startDate}`;
		if (taskDates.length === 0) {
			taskDates.push([data]);
		} else {
			let isAddedInExistingRow = false;
			for (let i = 0; i < taskDates.length; i++) {
				let isExitInInnerArray = false;
				for (let j = 0; j < taskDates[i].length; j++) {
					if (!isAddedInExistingRow) {
						if (checkIsBetweenDates(data.startDate, taskDates[i][j].startDate, taskDates[i][j].endDate)) {
							isExitInInnerArray = true;
						} else if (!isAddedInExistingRow && checkIsBetweenDates(data.endDate, taskDates[i][j].startDate, taskDates[i][j].endDate)) {
							isExitInInnerArray = true;
						} else if (!isAddedInExistingRow && checkIsBetweenDates(taskDates[i][j].startDate, data.startDate, data.endDate)) {
							isExitInInnerArray = true;
						} else if (!isAddedInExistingRow && checkIsBetweenDates(taskDates[i][j].endDate, data.startDate, data.endDate)) {
							isExitInInnerArray = true;
						}
					}
				}
				if (!isExitInInnerArray && !isAddedInExistingRow) {
					taskDates[i].push(data);
					isAddedInExistingRow = true;
					break;
				}
			}
			if (!isAddedInExistingRow) {
				taskDates.push([data]);
			}
		}
		return null;
	});

	taskDates = taskDates.sort((a, b) => { return ((new Date(a.startDate) > new Date(b.startDate)) ? 1 : -1); });
	// taskDates.push(firstTaskRow, secondTaskRow);

	return taskDates;
};

const getAlignedDarkDays = (groupedDarkDaysByChannel) => {
	let taskDates = [];
	groupedDarkDaysByChannel.map((darkDay) => {
		if (taskDates.length === 0) {
			taskDates.push([darkDay]);
		} else {
			let isAddedInExistingRow = false;
			for (let i = 0; i < taskDates.length; i++) {
				let isExitInInnerArray = false;
				for (let j = 0; j < taskDates[i].length; j++) {
					if (!isAddedInExistingRow) {
						if (checkIsBetweenDates(darkDay.startDate, taskDates[i][j].startDate, taskDates[i][j].endDate)) {
							isExitInInnerArray = true;
						} else if (!isAddedInExistingRow && checkIsBetweenDates(darkDay.endDate, taskDates[i][j].startDate, taskDates[i][j].endDate)) {
							isExitInInnerArray = true;
						} else if (!isAddedInExistingRow && checkIsBetweenDates(taskDates[i][j].startDate, darkDay.startDate, darkDay.endDate)) {
							isExitInInnerArray = true;
						} else if (!isAddedInExistingRow && checkIsBetweenDates(taskDates[i][j].endDate, darkDay.startDate, darkDay.endDate)) {
							isExitInInnerArray = true;
						}
					}
				}
				if (!isExitInInnerArray && !isAddedInExistingRow) {
					taskDates[i].push(darkDay);
					isAddedInExistingRow = true;
					break;
				}
			}
			if (!isAddedInExistingRow) {
				taskDates.push([darkDay]);
			}
		}
		return darkDay;
	});
	taskDates = taskDates.sort((a, b) => { return ((new Date(a.startDate) > new Date(b.startDate)) ? 1 : -1); });
	return taskDates;
};

// Making location group
const buildLocationDetails = (campaignLocationGroups = [], darkDays = []) => {
    let tableLocationDetails = [];
    campaignLocationGroups.map((item) => {
        const locationInfo = {};

        const {
            campaignLocationGroupName,
            campaignLocationGroupId,
            campaignChannels
        } = item;

        locationInfo.locationGroupName = campaignLocationGroupName || '';
        locationInfo.campaignLocationGroupId = campaignLocationGroupId || '';
        locationInfo.selectedChannels = [];

        if (campaignChannels && !isArrayEmpty(campaignChannels)) {
            campaignChannels.map((channels) => {
                const channel = {};
                channel.id = channels.channelId || '';
                channel.channelName = channels.channelName || '';
                channel.locationGroupName = campaignLocationGroupName || '';
				channel.campaignLocationGroupId = campaignLocationGroupId || '';
				channel.locationGroupChannelId = channels.locationGroupChannelId || '';
                channel.taskList = [];

                if (channels.taskList && !isArrayEmpty(channels.taskList)) {
                    channel.taskList = buildTaskDetails(channels.taskList, campaignLocationGroups, channels);
                }

                locationInfo.selectedChannels.push(channel);

                return null;
            });
        }

        tableLocationDetails.push(locationInfo);

        return null;
	});

	const tempTableLocationDetails = [...tableLocationDetails];
	const tempDarkDays = [];
	darkDays.map((darkday) => {
		const {
			id,
			locationName,
			campaignLocationGroupId,
			channelName,
			startDate,
			endDate,
			locationGroupChannelId
		} = darkday;
		const darkDayStartDate = dateFormatter(startDate, 'MM-DD-YYYY');
		const darkDayEndDate = dateFormatter(endDate, 'MM-DD-YYYY');
		const data = {};
		data.id = `${id} ${startDate}`;
		data.startDate = darkDayStartDate;
		data.endDate = darkDayEndDate;
		data.campaignLocationGroupId = campaignLocationGroupId || '';
		data.locationGroupChannelId = locationGroupChannelId || '';
		data.darkDayId = id;
		data.channelName = channelName;
		data.locationName = locationName;
		data.isDarkDay = true;
		tempDarkDays.push(data);
		return data;
	});
	let groupedDarkDaysByLocation = groupBy(tempDarkDays, (b) => {
		return b.campaignLocationGroupId;
	});
	console.log(groupedDarkDaysByLocation);
	groupedDarkDaysByLocation = Object.values(groupedDarkDaysByLocation);
	groupedDarkDaysByLocation.map((darkDayByLocation) => {
		let groupedDarkDaysBychannel = groupBy(darkDayByLocation, (b) => {
			return b.locationGroupChannelId;
		});
		groupedDarkDaysBychannel = Object.values(groupedDarkDaysBychannel);
		const locIndex = tempTableLocationDetails.findIndex((x) => { return x.campaignLocationGroupId === darkDayByLocation[0].campaignLocationGroupId; });
		if (locIndex !== -1) {
			// const sortedDarkDays = [];
			groupedDarkDaysBychannel.map((groupedDarkDaysByChannel) => {
				const alignedDarkDays = getAlignedDarkDays(groupedDarkDaysByChannel);
				const channelIndex = tempTableLocationDetails[locIndex].selectedChannels.findIndex((x) => {
					return x.locationGroupChannelId === groupedDarkDaysByChannel[0].locationGroupChannelId;
				});
				console.log(alignedDarkDays);
				console.log(channelIndex);
				if (channelIndex !== -1) {
					tempTableLocationDetails[locIndex].selectedChannels[channelIndex].taskList = tempTableLocationDetails[locIndex].selectedChannels[channelIndex].taskList.concat(alignedDarkDays);
				} else {
					const {
						locationName,
						campaignLocationGroupId,
						channelName,
						locationGroupChannelId
					} = groupedDarkDaysByChannel[0];
					const channel = {};
					// channel.id = channels.channelId || '';
					channel.channelName = channelName || '';
					channel.locationGroupName = locationName || '';
					channel.campaignLocationGroupId = campaignLocationGroupId || '';
					channel.locationGroupChannelId = locationGroupChannelId || '';
					channel.taskList = alignedDarkDays;
					tempTableLocationDetails[locIndex].selectedChannels.push(channel);
				}
				return groupedDarkDaysByChannel;
			});
		} else {
			const locationInfo = {};
			locationInfo.locationGroupName = groupedDarkDaysBychannel[0][0].locationName || '';
			locationInfo.campaignLocationGroupId = groupedDarkDaysBychannel[0][0].campaignLocationGroupId || '';
			locationInfo.selectedChannels = [];

			groupedDarkDaysBychannel.map((groupedDarkDaysByChannel) => {
				const alignedDarkDays = getAlignedDarkDays(groupedDarkDaysByChannel);
				const {
					locationName,
					campaignLocationGroupId,
					channelName,
					locationGroupChannelId
				} = groupedDarkDaysByChannel[0];
				const channel = {};
				channel.channelName = channelName || '';
				channel.locationGroupName = locationName || '';
				channel.campaignLocationGroupId = campaignLocationGroupId || '';
				channel.locationGroupChannelId = locationGroupChannelId || '';

				channel.taskList = alignedDarkDays;
				locationInfo.selectedChannels.push(channel);
				return groupedDarkDaysByChannel;
			});
			tempTableLocationDetails.push(locationInfo);
		}
		return darkDayByLocation;
	});
	// let isAddedInExistingRow = false;
	// for (let i = 0; i < darkDays.length; i++) {
	// 	let isExitInInnerArray = false;
	// 	for (let j = 0; j < darkDays[i].length; j++) {
	// 		if (!isAddedInExistingRow) {
	// 			if (checkIsBetweenDates(darkDays[i].startDate, darkDays[j].startDate, darkDays[j].endDate)) {
	// 				isExitInInnerArray = true;
	// 			} else if (!isAddedInExistingRow && checkIsBetweenDates(darkDays[i].endDate, darkDays[j].startDate, darkDays[j].endDate)) {
	// 				isExitInInnerArray = true;
	// 			} else if (!isAddedInExistingRow && checkIsBetweenDates(darkDays[j].startDate, darkDays[i].startDate, darkDays[i].endDate)) {
	// 				isExitInInnerArray = true;
	// 			} else if (!isAddedInExistingRow && checkIsBetweenDates(darkDays[j].endDate, darkDays[i].startDate, darkDays[i].endDate)) {
	// 				isExitInInnerArray = true;
	// 			}
	// 		}
	// 	}
	// 	if (!isExitInInnerArray && !isAddedInExistingRow) {
	// 		tempDarkDays.push(darkDays[i]);
	// 		isAddedInExistingRow = true;
	// 		break;
	// 	}
	// }
	// // if (!isAddedInExistingRow) {
	// // 	tempDarkDays.push([darkDays[i]]);
	// // }
	// console.log(tempDarkDays);
	// darkDays.map((darkday) => {
	// 	const {
	// 		id,
	// 		locationName,
	// 		campaignLocationGroupId,
	// 		channelName,
	// 		startDate,
	// 		endDate,
	// 		locationGroupChannelId
	// 	} = darkday;
	// 	const darkDayStartDate = dateFormatter(startDate, 'MM-DD-YYYY');
	// 	const darkDayEndDate = dateFormatter(endDate, 'MM-DD-YYYY');
	// 	const locIndex = tempTableLocationDetails.findIndex((x) => { return x.campaignLocationGroupId === campaignLocationGroupId; });
	// 	if (locIndex !== -1) {
	// 		const channelIndex = tempTableLocationDetails[locIndex].selectedChannels.findIndex((x) => {
	// 			return x.locationGroupChannelId === locationGroupChannelId;
	// 		});
	// 		if (channelIndex !== -1) {
	// 			const data = {};
	// 			data.id = `${id} ${startDate}`;
	// 			data.startDate = darkDayStartDate;
	// 			data.endDate = darkDayEndDate;
	// 			data.locationGroupChannelId = locationGroupChannelId || '';
	// 			data.darkDayId = id;
	// 			data.isDarkDay = true;
	// 			tempTableLocationDetails[locIndex].selectedChannels[channelIndex].taskList.push([data]);
	// 		} else {
	// 			const channel = {};
	// 			// channel.id = channels.channelId || '';
	// 			channel.channelName = channelName || '';
	// 			channel.locationGroupName = locationName || '';
	// 			channel.campaignLocationGroupId = campaignLocationGroupId || '';
	// 			channel.locationGroupChannelId = locationGroupChannelId || '';

	// 			const data = {};
	// 			data.id = `${id} ${startDate}`;
	// 			data.startDate = darkDayStartDate;
	// 			data.endDate = darkDayEndDate;
	// 			data.locationGroupChannelId = locationGroupChannelId || '';
	// 			data.darkDayId = id;
	// 			data.isDarkDay = true;
	// 			const taskDates = [];
	// 			taskDates.push([data]);
	// 			channel.taskList = taskDates;
	// 			tempTableLocationDetails[locIndex].selectedChannels.push(channel);
	// 		}
	// 	} else {
	// 		const locationInfo = {};
	// 		locationInfo.locationGroupName = locationName || '';
	// 		locationInfo.campaignLocationGroupId = campaignLocationGroupId || '';
	// 		locationInfo.selectedChannels = [];

	// 		const channel = {};
	// 		// channel.id = channels.channelId || '';
	// 		channel.channelName = channelName || '';
	// 		channel.locationGroupName = locationName || '';
	// 		channel.campaignLocationGroupId = campaignLocationGroupId || '';
	// 		channel.locationGroupChannelId = locationGroupChannelId || '';

	// 		const data = {};
	// 		data.id = `${id} ${startDate}`;
	// 		data.startDate = darkDayStartDate;
	// 		data.endDate = darkDayEndDate;
	// 		data.locationGroupChannelId = locationGroupChannelId || '';
	// 		data.isDarkDay = true;
	// 		data.darkDayId = id;
	// 		const taskDates = [];
	// 		taskDates.push([data]);
	// 		channel.taskList = taskDates;
	// 		locationInfo.selectedChannels.push(channel);
	// 		tempTableLocationDetails.push(locationInfo);
	// 	}
	// 	return darkday;
	// });
	tableLocationDetails = [...tempTableLocationDetails];
    return tableLocationDetails;
};

// Making flighting table
const buildFlightingDetails = (data = {}) => {
    const campaignDetails = {};

    if (!isObjectEmpty(data)) {
        const {
            campaignStartDate,
            campaignEndDate,
            optionList,
            campaignPriorityWeeksDetailList,
            campaignPromotionDetailList,
			campaignLocationGroups,
			darkDays
        } = data;

        campaignDetails.startDate = campaignStartDate ? dateFormatter(campaignStartDate, 'MM-DD-YYYY') : '';
        campaignDetails.endDate = campaignEndDate ? dateFormatter(campaignEndDate, 'MM-DD-YYYY') : '';

        campaignDetails.totalWeeks = [];
        campaignDetails.optionList = optionList || [];
        campaignDetails.weeks = [];
        campaignDetails.weekOptions = [];
        campaignDetails.totalDays = [];
        campaignDetails.promotionDates = [[], []];
        campaignDetails.tableLocationDetails = [];
        campaignDetails.campaignLocationGroups = [];
        const totalDays = [];

        // Priority weeks option
        if (campaignPriorityWeeksDetailList && !isArrayEmpty(campaignPriorityWeeksDetailList)) {
            campaignPriorityWeeksDetailList.map((item, index) => {
                const startDate = dateFormatter(item.startDate, 'MM-DD-YYYY');
                const endDate = dateFormatter(item.endDate, 'MM-DD-YYYY');
                const formattedStartWeek = dateFormatter(startDate, 'MMM D');
                const formattedEndWeek = dateFormatter(endDate, 'MMM D');
                const weekStartDate = startDate;
                const weekEndDate = endDate;
                const addedWeek = formattedStartWeek + ' - ' + formattedEndWeek;
                const week = `week ${[index + 1]}`;
                const weekObject = {};

                weekObject.id = `week ${index}`;
                weekObject.dbId = item.id;
                weekObject.label = week;
                weekObject.dateLabel = addedWeek;
                weekObject.defaultOption = 'on';
                weekObject.currentState = item.flightingStatus ? item.flightingStatus.toLowerCase() : 'on';
                weekObject.startDate = weekStartDate;
                weekObject.endDate = weekEndDate;
                weekObject.flightingStatusId = item.flightingStatusId ? item.flightingStatusId : 1;
                campaignDetails.totalWeeks.push(weekObject);

                return campaignDetails;
            });

            // Extra copy of priority week
            campaignDetails.totalWeeks.map((item, i) => {
                const clone = cloneObject(item);
                campaignDetails.weeks.push(clone);

                return campaignDetails.weeks;
            });
        }

        campaignDetails.totalWeeks.map((item, i) => {
            const weekData = item;
            const clone = cloneObject(weekData);
            clone.id = item.dateLabel;
            campaignDetails.weekOptions.push(clone);

            return campaignDetails.weekOptions;
        });

        const dayDiff = getDifferenceBetweenDates(campaignDetails.startDate, campaignDetails.endDate);
        let startDay = dateFormatter(campaignStartDate, '');

        // Individual day
        for (let i = 0; i <= dayDiff; i++) {
            const nextDay = addDaysToDate(startDay, 1);
            const formattedStartDay = dateFormatter(startDay, 'D');
            const day = dateFormatter(startDay, 'MM-DD-YYYY');
            startDay = nextDay;

            const dateObject = {};
            dateObject.formattedStartDay = formattedStartDay;
            dateObject.day = day;
            dateObject.id = day;
            totalDays.push(dateObject);
        }

        campaignDetails.totalDays = totalDays;

        if (campaignPromotionDetailList && !isArrayEmpty(campaignPromotionDetailList)) {
            campaignDetails.promotionDates = buildPromotionDetails(campaignPromotionDetailList);
        }

        if (campaignLocationGroups && !isArrayEmpty(campaignLocationGroups)) {
            campaignDetails.tableLocationDetails = buildLocationDetails(campaignLocationGroups, darkDays);
            campaignDetails.campaignLocationGroups = campaignLocationGroups;
        }
    }

    return campaignDetails;
};


/* Flighting Table Section Start */
const getFlighting = (campaignId = 0) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ADMIN_GET_FLIGHTING_START
            });

            const channelResponse = await getMediaChannelsService();
            const response = await getFlightingDetails(campaignId);
            const flightingOptions = await getFlightingOptions();
			const darkDaysResponse = await getCampaignDarkDayService(campaignId);
            const { data: responseData } = response;
            const { data: flightingOptionsData } = flightingOptions;
            const { data: channelData } = channelResponse;

            if (responseData && responseData.resultCount > 0 && flightingOptionsData && flightingOptionsData.resultCount > 0
				&& channelData && channelData.resultCount > 0 && darkDaysResponse.status === 200) {
				const { data } = response.data;
				const darkDays = [...darkDaysResponse.data.data];
				console.log(darkDays);
                const channelList = [];
                const optionList = flightingOptions.data ? flightingOptions.data.data : [];
				data.optionList = optionList;
				data.darkDays = darkDays;

                if (!isObjectEmpty(channelData.data)) {
                    channelData.data.map((item) => {
                        const { id, channelName } = item;
                        const channel = {};

                        channel.id = id || 0;
                        channel.channelName = channelName || '';
                        channel.isAssigned = false;
                        channel.isChecked = false;
                        channelList.push(channel);

                        return null;
                    });
                }

                dispatch({
                    type: GET_FLIGHTING_SUCCESS,
                    data: buildFlightingDetails(data)
                });

                dispatch({
                    type: GET_CHANNEL_LIST_SUCCESS,
                    data: channelList
                });
            } else {
                dispatch({
                    type: FLIGHTING_DETAILS_ERROR_HANDLER,
                    data: setErrorMessage(responseData)
                });
            }
            dispatch({
                type: STORE_CAMPAIGN_ID,
                data: campaignId
            });
        } catch (error) {
            console.log(error);

            dispatch({
                type: FLIGHTING_DETAILS_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
        }
    };
};

const updateFlightingStatus = (postData = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });

            const {
                weekIndex,
                flightingOption,
                flightingStatusId,
                dbId,
                campaignId
            } = postData;

            const data = {};
            data.flightingStatusId = flightingStatusId;

            const response = await updatePriorityWeeksOption(campaignId, dbId, data);

            if (response && response.status === 204) {
                dispatch({
                    type: UPDATE_FLIGHTING_STATUS,
                    data: {
                        weekIndex,
                        flightingOption,
                        flightingStatusId
                    }
                });
            } else {
                dispatch({
                    type: UPDATE_FLIGHTING_STATUS_ERROR,
                    data: setErrorMessage(response)
                });
            }

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            console.log(error);

            dispatch({
                type: UPDATE_FLIGHTING_STATUS_ERROR,
                data: setErrorMessage(error)
            });

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        }
    };
};
/* Flighting Table Section End */

/* Promotion Section Start */
const buildLocationGroupDropdown = (data = {}) => {
    const locationGroups = [];
    if (data && data.data && data.data.resultCount > 0 && data.data.data) {
        const locationGroupsDrowndown = data.data.data;
        locationGroupsDrowndown.map(((item) => {
            const locationInfo = {};
            const {
                campaignLocationGroupName,
                id
            } = item;

            locationInfo.label = campaignLocationGroupName;
            locationInfo.value = id;

            locationGroups.push(locationInfo);
            return null;
        }));
        return locationGroups;
    }
    return locationGroups;
};

const handlePromotionPopup = (data = {}) => {
    return async (dispatch) => {
        try {
            const {
                campaignId,
                showPromotion,
                editPromotionData,
                promotionType,
                locationGroupsDrowndown: locationGroup
            } = data;
            if (locationGroup && isArrayEmpty(locationGroup)) {
                dispatch({
                    type: CAMPAIGN_DETAILS_START
                });
                const response = await getLocationDetails(campaignId);

                if (response && response.data && response.data.resultCount > 0 && response.data.data) {
                    const locationGroupsDrowndown = response.data.data;
                    const locationGroups = [];
                    locationGroupsDrowndown.map(((item) => {
                        const locationInfo = {};
                        const {
                            campaignLocationGroupName,
                            campaignLocationGroupId
                        } = item;

                        locationInfo.label = campaignLocationGroupName;
                        locationInfo.value = campaignLocationGroupId;

                        locationGroups.push(locationInfo);
                        return null;
                    }));
                    dispatch({
                        type: PROMOTION_MODAL_HANDLER,
                        data: {
                            showPromotion,
                            editPromotionData,
                            promotionType,
                            locationGroupsDrowndown: locationGroups
                        }
                    });
                } else {
                    dispatch({
                        type: PROMOTION_MODAL_HANDLER,
                        data: {
                            showPromotion,
                            editPromotionData,
                            promotionType,
                            locationGroupsDrowndown: locationGroup
                        }
                    });
                }
            } else {
                dispatch({
                    type: PROMOTION_MODAL_HANDLER,
                    data: {
                        showPromotion,
                        editPromotionData,
                        promotionType,
                        locationGroupsDrowndown: locationGroup
                    }
                });
            }

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            console.log(error);

            dispatch({
                type: PROMOTION_LOCATIONS_GROUP_ERROR_HANDLER,
                data: setErrorMessage(error)
            });

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        }
    };
};

const postPromotionDetails = (postData = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });

            const {
                promotionDate,
                // firstPromotionRow,
                // secondPromotionRow,
                promotionType,
                deletePromotion: isDeletePromotion,
                campaignId
                // tableLocationDetails: tableLocations
            } = postData;
            console.log('postData', postData);
            const {
                startDate,
                endDate,
                promotionName,
                promotionId,
                // id,
                campaignLocationGroupIds,
                promotionLocationGroupDetails
            } = promotionDate;

            const promotions = {};
            // const promotion = [];
            promotions.startDate = startDate;
            promotions.endDate = endDate;
            promotions.promotionName = promotionName;
            promotions.campaignLocationGroupIds = campaignLocationGroupIds;
            promotions.promotionLocationGroupDetails = promotionLocationGroupDetails;
            // let tableLocationDetails = [];

            if (promotionType === 'newPromotion') {
                const response = await postPromotion(promotions, campaignId);
                // let getResponse = '';

                // Get Flighting Api to get task created on creating promotion
                // if (tableLocations && !isArrayEmpty(tableLocations)) {
                //     getResponse = await getFlightingDetails(campaignId);
                // }

                if (response && response.data && response.data.resultCount > 0) {
                    const { data } = response.data;

					if (data) {
						dispatch(getFlighting(campaignId));
                        // const firstPromotion = [...firstPromotionRow];
                        // const secondPromotion = [...secondPromotionRow];
                        // const promotionFirstRowIndex = firstPromotionRow.findIndex((item) => { return item.id === id; });
                        // const promotionSecondRowIndex = secondPromotionRow.findIndex((item) => { return item.id === id; });

                        // if (promotionFirstRowIndex >= 0) {
                        //     firstPromotion[promotionFirstRowIndex].promotionId = data.id;
                        // }

                        // if (promotionSecondRowIndex >= 0) {
                        //     secondPromotion[promotionSecondRowIndex].promotionId = data.id;
                        // }

                        // promotion.push(firstPromotion, secondPromotion);

                        // if (tableLocations && !isArrayEmpty(tableLocations)) {
                        //     const { data: getData } = getResponse.data;
                        //     if (!isObjectEmpty(getData)) {
                        //         const {
                        //             campaignLocationGroups
                        //         } = getData;

                        //         if (campaignLocationGroups && !isArrayEmpty(campaignLocationGroups)) {
                        //             tableLocationDetails = buildLocationDetails(campaignLocationGroups);
                        //         }
                        //     }
                        // }

                        // Get Tak Api to get task created on creating promotion
                        dispatch(getCampaignTasks(campaignId));

                        dispatch({
                            type: POST_PROMOTION_DETAILS
                            // data: { promotionDates: promotion, tableLocationDetails }
                        });
                    }
                } else {
                    dispatch({
                        type: PROMOTION_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });

                    dispatch({
                        type: CAMPAIGN_DETAILS_END
                    });
                }
            } else if (isDeletePromotion) {
                const response = await deletePromotion(promotionId);
                // let getResponse = '';

                // Get Flighting Api to get task created on deleting promotion
                // if (tableLocations && !isArrayEmpty(tableLocations)) {
                //     getResponse = await getFlightingDetails(campaignId);
                // }

                if (response && response.status === 204) {
                    // promotion.push(firstPromotionRow, secondPromotionRow);

                    // if (tableLocations && !isArrayEmpty(tableLocations)) {
                    //     const { data: getData } = getResponse.data;
                    //     if (!isObjectEmpty(getData)) {
                    //         const {
                    //             campaignLocationGroups
                    //         } = getData;

                    //         if (campaignLocationGroups && !isArrayEmpty(campaignLocationGroups)) {
                    //             tableLocationDetails = buildLocationDetails(campaignLocationGroups);
                    //         }
                    //     }
                    // }
					dispatch(getFlighting(campaignId));
                    // Get Task Api to get task created on deleting promotion
                    dispatch(getCampaignTasks(campaignId));

                    dispatch({
                        type: POST_PROMOTION_DETAILS
                        // data: { promotionDates: promotion, tableLocationDetails }
                    });
                } else {
                    dispatch({
                        type: PROMOTION_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });

                    dispatch({
                        type: CAMPAIGN_DETAILS_END
                    });
                }
            } else {
                console.log('promotions', promotions);
                promotions.promotionId = promotionId;
                const response = await updatePromotion(promotions, promotionId, campaignId);
                if (response && response.status === 204) {
                    // promotion.push(firstPromotionRow, secondPromotionRow);

                    // Updating the task name on updating promotion name
                    // if (tableLocations && !isArrayEmpty(tableLocations)) {
                    //     tableLocations.map((item) => {
                    //         const locationInfo = item;
                    //         const selectedChannels = cloneDeep(locationInfo.selectedChannels);
                    //         locationInfo.selectedChannels = [];

                    //         if (selectedChannels && !isArrayEmpty(selectedChannels)) {
                    //             selectedChannels.map((channels) => {
                    //                 const channel = channels;
                    //                 channel.promotionDates = promotion;
                    //                 channel.locationGroupName = locationInfo.locationGroupName;
                    //                 channel.campaignLocationGroupId = locationInfo.campaignLocationGroupId;

                    //                 if (channels.taskList && !isArrayEmpty(channels.taskList)) {
                    //                     channels.taskList.map((list) => {
                    //                         if (list) {
					// 							const tasks = list;
					// 							if (tasks.campaignPromotionId === promotionId) {
					// 								tasks.startDate = startDate;
					// 								tasks.endDate = endDate;
					// 								tasks.promotionName = promotionName;
					// 							}
                    //                         }

                    //                         return null;
                    //                     });
                    //                 }

                    //                 channel.taskList = channels.taskList;
                    //                 locationInfo.selectedChannels.push(channel);

                    //                 return null;
                    //             });
                    //         }

                    //         tableLocationDetails.push(locationInfo);

                    //         return null;
                    //     });
                    // }
					dispatch(getFlighting(campaignId));
                    // Get Task Api to get task created on Updating promotion
                    dispatch(getCampaignTasks(campaignId));

                    dispatch({
                        type: POST_PROMOTION_DETAILS
                        // data: { promotionDates: promotion, tableLocationDetails }
                    });
                } else {
                    dispatch({
                        type: PROMOTION_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });

                    dispatch({
                        type: CAMPAIGN_DETAILS_END
                    });
                }
            }
        } catch (error) {
            console.log(error);

            dispatch({
                type: PROMOTION_ERROR_HANDLER,
                data: setErrorMessage(error)
            });

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        }
    };
};
/* Promotion Section End */

/* Location And Channel Section Start */
const handleLocationPopup = (locationData = {}) => {
    console.log('handleLocationPopup', locationData);
    return async (dispatch) => {
        try {
            const {
                campaignId,
                showLocation,
                showPromotion = false,
                locationGroups: locationGroup,
                channelList,
                locationList,
                callback = false
            } = locationData;
            if ((locationGroup && isArrayEmpty(locationGroup) && (showLocation || showPromotion)) || callback) {
                dispatch({
                    type: CAMPAIGN_DETAILS_START
                });

                const response = await getLocationDetails(campaignId);

                if (response && response.data && response.data.resultCount > 0 && response.data.data) {
                    const { data } = response.data;
                    const locationGroups = [];

                    data.map(((item) => {
                        const locationInfo = {};
                        const {
                            campaignLocationGroupName,
                            campaignLocationGroupId,
                            campaignGroupLocations,
                            campaignChannels
                        } = item;

                        locationInfo.locationGroupName = campaignLocationGroupName || '';
                        locationInfo.campaignLocationGroupId = campaignLocationGroupId || '';
                        locationInfo.locations = [];
                        locationInfo.channelList = cloneDeep(channelList);
                        locationInfo.selectedChannels = [];
                        locationInfo.id = campaignLocationGroupId || '';
                        locationInfo.errorMessages = {
                            groupNameRequired: '',
                            locationRequired: '',
                            channelRequired: '',
                            uniqueLocationGroupName: ''
                        };
                        locationInfo.isEditable = false;
                        locationInfo.isAllowedToDelete = true;

                        if (campaignGroupLocations && !isArrayEmpty(campaignGroupLocations)) {
                            campaignGroupLocations.map((locations) => {
                                const location = {};
                                location.id = locations.locationId;
                                location.storeLocationName = locations.locationName;
                                location.isAssigned = true;
                                location.isChecked = true;
                                locationInfo.locations.push(location);

                                const index = locationList.findIndex((list) => {
                                    return list.id === location.id;
                                });

                                if (index >= 0) {
                                    locationList[index].isAssigned = true;
                                    locationList[index].isChecked = true;
                                }

                                return null;
                            });
                        }

                        if (campaignChannels && !isArrayEmpty(campaignChannels)) {
                            campaignChannels.map((channels) => {
                                const channel = {};
                                channel.id = channels.channelId;
                                channel.channelName = channels.channelName;
                                channel.locationGroupChannelId = channels.locationGroupChannelId;
                                channel.isAssigned = true;
                                channel.isChecked = true;
                                locationInfo.selectedChannels.push(channel);

                                const index = locationInfo.channelList.findIndex((list) => { return list.id === channel.id; });

                                if (index >= 0) {
                                    locationInfo.channelList[index].isAssigned = true;
                                    locationInfo.channelList[index].isChecked = true;
                                }

                                return null;
                            });
                        }

                        locationGroups.push(locationInfo);

                        return null;
                    }));
                    console.log('locationGroups!!', locationGroups);
                    dispatch({
                        type: LOCATION_MODAL_HANDLER,
                        data: {
                            showLocation,
                            locationGroups,
                            locationList,
                            channelList
                        }
                    });
                } else {
                    dispatch({
                        type: LOCATION_MODAL_HANDLER,
                        data: {
                            showLocation,
                            locationGroups: locationGroup,
                            locationList,
                            channelList
                        }
                    });
                }
            } else {
                dispatch({
                    type: LOCATION_MODAL_HANDLER,
                    data: {
                        showLocation,
                        locationGroups: locationGroup,
                        locationList,
                        channelList
                    }
                });
            }

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            console.log(error);

            dispatch({
                type: LOCATIONS_ERROR_HANDLER,
                data: setErrorMessage(error)
            });

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        }
    };
};

const postLocationDetails = (campaignId, locationGroups = [], locations = [], promotionDates = [[], []], channelList = []) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });

            const data = [];
            if (locationGroups && !isArrayEmpty(locationGroups)) {
                locationGroups.map((item) => {
                    const location = {};
                    const locationIds = [];
                    const channelId = [];
                    location.campaignLocationGroupName = item.locationGroupName;
                    location.campaignLocationGroupId = item.campaignLocationGroupId;

                    if (item.locations && !isArrayEmpty(item.locations)) {
                        item.locations.map((locationData) => {
                            locationIds.push(locationData.id);

                            return null;
                        });
                    }

                    if (item.selectedChannels && !isArrayEmpty(item.selectedChannels)) {
                        item.selectedChannels.map((channelData) => {
                            channelId.push(channelData.id);

                            return null;
                        });
                    }

                    location.locationIds = locationIds;
                    location.channelIds = channelId;
                    data.push(location);

                    return null;
                });
            }

            const response = await postLocationGroupDetails(data, campaignId);

            // Get Flighting Api to get task created on creating location group
            const getResponse = await getFlightingDetails(campaignId);
            console.log('after');
            await dispatch(handleLocationPopup({
                campaignId,
                showLocation: true,
                showPromotion: true,
                locationGroups: [],
                channelList,
                locationList: locations,
                callback: true
            }));
            const { data: responseData } = response;

            if (responseData && responseData.resultCount > 0 && responseData.data) {
                const { data: locationGroupData } = responseData;
                const updateLocationGroups = [];
                let promotionDatesTemp = [[], []];
                let tableLocationDetails = [];
                if (locationGroupData && !isArrayEmpty(locationGroupData)) {
                    locationGroups.map((item) => {
                        const temp = locationGroupData.find((element) => {
                            return element.campaignLocationGroupName === item.locationGroupName;
                        });

                        const group = item;
                        group.isEditable = false;
                        group.isAllowedToDelete = true;
                        group.campaignLocationGroupId = temp.id;
                        group.id = temp.id;
                        updateLocationGroups.push(group);

                        return null;
                    });

                    const { data: getData } = getResponse.data;

                    if (!isObjectEmpty(getData)) {
                        const {
                            campaignLocationGroups,
                            campaignPromotionDetailList
                        } = getData;

                        if (campaignLocationGroups && !isArrayEmpty(campaignLocationGroups)) {
                            tableLocationDetails = buildLocationDetails(campaignLocationGroups);
                        }
                        if (campaignPromotionDetailList && !isArrayEmpty(campaignPromotionDetailList)) {
                            promotionDatesTemp = buildPromotionDetails(campaignPromotionDetailList);
                        }
                    }

                    // Get Task Api to get task created on creating location group
                    dispatch(getCampaignTasks(campaignId));
                    // Build Locations groups dropdown for promotion popup after delete or insertion
                    dispatch({
                        type: PROMOTION_LOCATIONS_GROUP,
                        data: { locationGroupsDrowndown: buildLocationGroupDropdown(response) }
                    });
                    dispatch({
                        type: POST_LOCATION_DETAILS,
                        data: {
                            locations, tableLocationDetails, promotionDates: promotionDatesTemp
                        }
                    });
                }
            } else {
                dispatch({
                    type: LOCATIONS_ERROR_HANDLER,
                    data: setErrorMessage(response)
                });

                dispatch({
                    type: CAMPAIGN_DETAILS_END
                });
            }
        } catch (error) {
            console.log(error);

            dispatch({
                type: LOCATIONS_ERROR_HANDLER,
                data: setErrorMessage(error)
            });

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        }
    };
};
/* Location And Channel Section End */

const buildChannelCreativeLibraryData = (basicCreativeDetails = {}, creativeList = []) => {
    const uploadedCreativeList = [];

    const {
        channelName,
        promotionStartDate,
        promotionEndDate
    } = basicCreativeDetails;

    creativeList.map((item) => {
        const creatives = {};

        const {
            id,
            metaData
        } = item;

        const getMetaData = JSON.parse(metaData);

        const {
            adId,
            adIdName,
            rotation,
            traffickingStartDate,
            traffickingEndDate,
            selectedDuration,
            selectedUploadOption,
            fileUrl,
            clickThruURL,
            youTubeLink,
            headline,
            mainAdCopy,
            destinationUrl,
            uploadedUrlList,
            usePreviousAssetNotes,
            notes
        } = getMetaData;

        creatives.keyList = Array(10).fill().map(((key) => { return uuIdv4(); }));
        creatives.usePreviousAssetNotes = usePreviousAssetNotes;
        switch (channelName.toLowerCase()) {
        case creativeChannelList.tv.toLowerCase():
        case creativeChannelList.radio.toLowerCase():
        case creativeChannelList.onlineAudio.toLowerCase(): {
            creatives.dbId = id || '';
            creatives.adId = adId || '';
            creatives.adIdName = adIdName || '';
            creatives.rotation = rotation || '';
            creatives.traffickingStartDate = traffickingStartDate || '';
            creatives.traffickingEndDate = traffickingEndDate || '';
            creatives.minStartDate = promotionStartDate || '';
            creatives.minEndDate = promotionStartDate || '';
            creatives.maxEndDate = promotionEndDate || '';
            creatives.selectedDuration = selectedDuration || '';
            creatives.selectedUploadOption = selectedUploadOption || '';
            creatives.fileUrl = '';

            if (fileUrl) {
                creatives.fileUrl = fileUrl;
            }
            break;
        }

        case creativeChannelList.onlineVideo.toLowerCase(): {
            creatives.dbId = id || '';
            creatives.clickThruURL = clickThruURL || '';
            creatives.youTubeLink = youTubeLink || '';
            creatives.rotation = rotation || '';
            creatives.traffickingStartDate = traffickingStartDate || '';
            creatives.traffickingEndDate = traffickingEndDate || '';
            creatives.minStartDate = promotionStartDate || '';
            creatives.minEndDate = promotionStartDate || '';
            creatives.maxEndDate = promotionEndDate || '';
            creatives.selectedDuration = selectedDuration || '';
            creatives.selectedUploadOption = selectedUploadOption || '';
            break;
        }

        case creativeChannelList.newsPaper.toLowerCase():
        case creativeChannelList.magazine.toLowerCase():
        case creativeChannelList.outOfHome.toLowerCase(): {
            creatives.dbId = id || '';
            creatives.traffickingStartDate = traffickingStartDate || '';
            creatives.traffickingEndDate = traffickingEndDate || '';
            creatives.minStartDate = promotionStartDate || '';
            creatives.minEndDate = promotionStartDate || '';
            creatives.maxEndDate = promotionEndDate || '';
            creatives.selectedUploadOption = selectedUploadOption || '';
            break;
        }

        case creativeChannelList.digitalDisplay.toLowerCase(): {
            creatives.dbId = id || '';
            creatives.clickThruURL = clickThruURL || '';
            creatives.traffickingStartDate = traffickingStartDate || '';
            creatives.traffickingEndDate = traffickingEndDate || '';
            creatives.minStartDate = promotionStartDate || '';
            creatives.minEndDate = promotionStartDate || '';
            creatives.maxEndDate = promotionEndDate || '';
            creatives.selectedUploadOption = selectedUploadOption || '';
            break;
        }

        case creativeChannelList.paidSocial.toLowerCase(): {
            creatives.dbId = id || '';
            creatives.headline = headline || '';
            creatives.headlineLength = creatives.headline.length;
            creatives.mainAdCopy = mainAdCopy || '';
            creatives.mainAdCopyLength = creatives.mainAdCopy.length;
            creatives.destinationUrl = destinationUrl || '';
            creatives.traffickingStartDate = traffickingStartDate || '';
            creatives.traffickingEndDate = traffickingEndDate || '';
            creatives.minStartDate = promotionStartDate || '';
            creatives.minEndDate = promotionStartDate || '';
            creatives.maxEndDate = promotionEndDate || '';
            creatives.selectedUploadOption = selectedUploadOption || '';
            creatives.uploadedUrlList = [];

            if (uploadedUrlList) {
                uploadedUrlList.map((urlList) => {
                    if (urlList.uploadedUrl) {
                        const url = {};
                        url.uploadedUrl = urlList.uploadedUrl;
                        creatives.uploadedUrlList.push(url);
                    }

                    return null;
                });
            }
            break;
        }

        default: {
            creatives.dbId = id || '';
            creatives.adId = adId || '';
            creatives.adIdName = adIdName || '';
            creatives.rotation = rotation || '';
            creatives.traffickingStartDate = traffickingStartDate || '';
            creatives.traffickingEndDate = traffickingEndDate || '';
            creatives.minStartDate = promotionStartDate || '';
            creatives.minEndDate = promotionStartDate || '';
            creatives.maxEndDate = promotionEndDate || '';
            creatives.selectedDuration = selectedDuration || '';
            creatives.selectedUploadOption = selectedUploadOption || '';
            creatives.fileUrl = '';

            if (fileUrl) {
                creatives.fileUrl = fileUrl;
            }
        }
        }

        creatives.uploadedFiles = [];
        creatives.newFiles = [];
        creatives.notes = notes;

        if (item.files && !isArrayEmpty(item.files)) {
            item.files.map((list) => {
                const file = list;
                let fileType = list.mimeType && list.mimeType.split('/')[0];

                let src = 'preview-icon unsupported-image';

                if (fileType) {
                    if (fileType === 'image') {
                        src = 'preview-image image';

                        if (list.mimeType.includes('tiff') || list.mimeType.includes('eps')
                                || list.mimeType.includes('ai') || list.mimeType.includes('vnd')) {
                            fileType = '';
                            src = 'preview-icon unsupported-image';
                        }
                    } else if (fileType === 'video') {
                        src = 'preview-icon video';
                    } else if (fileType === 'audio') {
                        src = 'preview-icon audio';
                    } else if (fileType === 'application') {
                        if (list.mimeType.includes('pdf')) {
                            src = 'preview-icon pdf';
                        } else if (list.mimeType.includes('vnd')) {
                            src = 'preview-icon doc';
                        }
                    }
                }

                file.src = src;
                file.fileType = fileType;

                creatives.uploadedFiles.push(file);

                return null;
            });
        }

        creatives.isEditable = false;
        creatives.isAllowedToDelete = true;
        uploadedCreativeList.push(creatives);

        return null;
    });

    return uploadedCreativeList;
};

const buildCampaignLoactionGroupsData = (data = {}) => {
    const locationGroups = [];
    data.map(((item) => {
        const locationInfo = {};
        const {
            campaignLocationGroupName,
            campaignLocationGroupId
        } = item;
        locationInfo.label = campaignLocationGroupName;
        locationInfo.value = campaignLocationGroupId;
        locationGroups.push(locationInfo);
        return null;
    }));
    return locationGroups;
};

const getLocationsGroupsDetails = (tableLocationDetails = [], campaignLocationGroups = []) => {
    let applyToAllLocations = false;
    let locationGroupsType = '';
    let campaignLocationGroupDetail = [];
    if (tableLocationDetails.length === campaignLocationGroups.length) {
        applyToAllLocations = true;
        locationGroupsType = 'all-locations';
        campaignLocationGroupDetail = [];
    } else if (tableLocationDetails.length !== campaignLocationGroups.length && campaignLocationGroups.length > 0) {
        applyToAllLocations = false;
        locationGroupsType = 'multi-locations';
        campaignLocationGroupDetail = campaignLocationGroups ? buildCampaignLoactionGroupsData(campaignLocationGroups) : [];
    } else {
        applyToAllLocations = false;
        locationGroupsType = 'this-location';
        campaignLocationGroupDetail = [];
    }
    return {
        applyToAllLocations,
        locationGroupsType,
        campaignLocationGroupDetail
    };
};

const buildMultiSelectLocationGroupsData = (data) => {
    const locationGroups = [];
    const locationGroupsDrowndown = data;
    locationGroupsDrowndown.map(((item) => {
        const locationInfo = {};
        const {
            locationGroupName,
            campaignLocationGroupId
        } = item;
        locationInfo.label = locationGroupName;
        locationInfo.value = campaignLocationGroupId;
        locationGroups.push(locationInfo);
        return null;
    }));
    return locationGroups;
};

const buildInitialCreativeData = (data = {}, tableLocationDetails = []) => {
    const creativeDataObject = {};
    const basicCreativeDetails = {};
    creativeDataObject.basicCreativeDetails = {};
    creativeDataObject.uploadedCreativeList = [];
    creativeDataObject.numberOfLocations = 0;

    const {
        taskId,
        additionalInfo,
        promotionLocationGroupChannelId,
        parentLocationGroupChannelId,
        promotionId,
        promotionName,
        promotionStartDate,
        promotionEndDate,
        campaignLocationGroupId,
        campaignLocationGroupName,
        channelId,
        channelName,
        isApplyToAllLocations,
        predecessorWorkFlowTaskId,
        showAllLocationWarning,
        showSubmitreviewWarning,
        showThisLocationWarning,
        campaignLocationGroupDetail,
		isRequestedRevision
	} = data;

    const locationsGroupsDetails = getLocationsGroupsDetails(tableLocationDetails, campaignLocationGroupDetail);
    const getLocations = buildMultiSelectLocationGroupsData(tableLocationDetails);
    let numberOfLocations = 0;

    if (isApplyToAllLocations) {
        // Since it is already implemented where we checking numberOfLocations > 1
        numberOfLocations = 2;
    }

    creativeDataObject.numberOfLocations = numberOfLocations;

    basicCreativeDetails.taskId = taskId || '';
    basicCreativeDetails.additionalInfo = additionalInfo || '';
    basicCreativeDetails.promotionLocationGroupChannelId = promotionLocationGroupChannelId || '';
    basicCreativeDetails.parentLocationGroupChannelId = parentLocationGroupChannelId || '';
    // basicCreativeDetails.applyToAllLocations = parentLocationGroupChannelId && promotionLocationGroupChannelId ? parentLocationGroupChannelId === promotionLocationGroupChannelId : false;
    basicCreativeDetails.promotionId = promotionId || '';
    basicCreativeDetails.promotionName = promotionName || '';
    basicCreativeDetails.promotionStartDate = promotionStartDate ? dateFormatter(promotionStartDate, 'MM-DD-YYYY') : '';
    basicCreativeDetails.promotionEndDate = promotionEndDate ? dateFormatter(promotionEndDate, 'MM-DD-YYYY') : '';
    basicCreativeDetails.formattedPromotionStartDate = basicCreativeDetails.promotionStartDate ? dateFormatter(basicCreativeDetails.promotionStartDate, 'MMMM Do') : '';
    basicCreativeDetails.formattedPromotionEndDate = basicCreativeDetails.promotionEndDate ? dateFormatter(basicCreativeDetails.promotionEndDate, 'MMMM Do') : '';
    basicCreativeDetails.campaignLocationGroupId = campaignLocationGroupId || '';
    basicCreativeDetails.campaignLocationGroupName = campaignLocationGroupName || '';
    basicCreativeDetails.channelId = channelId || '';
    basicCreativeDetails.channelName = channelName || '';
    basicCreativeDetails.predecessorWorkFlowTaskId = predecessorWorkFlowTaskId || 0;
    basicCreativeDetails.showAllLocationWarning = showAllLocationWarning || false;
    basicCreativeDetails.showSubmitReviewWarning = showSubmitreviewWarning || false;
    basicCreativeDetails.showThisLocationWarning = showThisLocationWarning || false;
    basicCreativeDetails.applyToAllLocations = locationsGroupsDetails.applyToAllLocations;
    basicCreativeDetails.locationGroupsType = locationsGroupsDetails.locationGroupsType;
	basicCreativeDetails.campaignLocationGroupDetail = locationsGroupsDetails.campaignLocationGroupDetail;
	basicCreativeDetails.isRequestedRevision = isRequestedRevision;
    if (data.creatives && !isArrayEmpty(data.creatives)) {
        creativeDataObject.uploadedCreativeList = buildChannelCreativeLibraryData(basicCreativeDetails, data.creatives);
    }
    creativeDataObject.locationGroups = getLocations;
    creativeDataObject.basicCreativeDetails = basicCreativeDetails;

    return creativeDataObject;
};

const checkApplyToLocations = (campaignLocationGroupIds, id, applyToAllLocations) => {
    let checkApplyToAllLocations = true;
    let checkForLocationGroups = false;
    if (campaignLocationGroupIds.length > 0 && campaignLocationGroupIds !== '') {
        checkForLocationGroups = campaignLocationGroupIds.split(',').map(Number).includes(id);
        checkApplyToAllLocations = true;
    } else if (applyToAllLocations) {
        checkApplyToAllLocations = false;
        checkForLocationGroups = false;
    } else {
        checkApplyToAllLocations = true;
        checkForLocationGroups = false;
    }
    return {
        checkApplyToAllLocations,
        checkForLocationGroups
    };
};

// Updating task status upon submit for review or traffic
const updateTaskStatus = (data = {}, campaignLocationGroupId = '') => {
    const {
        tableLocationDetails: locationGroups,
        basicCreativeDetails,
        applyToAllLocations,
        type
    } = data;
    const tableLocationDetails = cloneDeep(locationGroups);

    if (tableLocationDetails && !isArrayEmpty(tableLocationDetails)) {
        tableLocationDetails.map((locationInfo) => {
            const { selectedChannels } = locationInfo;

            // Updating submitted channel task status
            if (locationInfo.campaignLocationGroupId !== basicCreativeDetails.campaignLocationGroupId) {
                if (selectedChannels && !isArrayEmpty(selectedChannels)) {
                    selectedChannels.map((channels) => {
                        const applyToLocationsDetails = checkApplyToLocations(campaignLocationGroupId, channels.campaignLocationGroupId, applyToAllLocations);
                        if (channels.id === basicCreativeDetails.channelId) {
                            if (channels.taskList && !isArrayEmpty(channels.taskList)) {
                                channels.taskList.map((list) => {
                                    if (list && !isArrayEmpty(list)) {
                                        list.map((taskLists) => {
                                            const taskList = taskLists;
                                            if (taskList.campaignPromotionId === basicCreativeDetails.promotionId) {
                                                taskList.applyToAllLocationsParent = applyToLocationsDetails.checkForLocationGroups ? false : applyToLocationsDetails.checkApplyToAllLocations;
                                                taskList.parentCampaignLocationGroupName = basicCreativeDetails.campaignLocationGroupName;
                                                taskList.parentCampaignLocationGroupId = basicCreativeDetails.campaignLocationGroupId;
                                                taskList.parentLocationGroupChannelId = basicCreativeDetails.parentLocationGroupChannelId;
                                                if (type === 'submitReview') {
                                                    taskList.childChannelTaskStatus = taskStatusDetails.complete.taskStatus.toLowerCase();
                                                } else if (type === 'traffic') {
                                                    taskList.childChannelTaskStatus = taskStatusDetails.approved.taskStatus.toLowerCase();
                                                }
                                            }
                                            return null;
                                        });
                                    }
                                    return null;
                                });
                            }
                        }
                        return null;
                    });
                }
            }

            // Updating the child chanel task status
            if (locationInfo.campaignLocationGroupId === basicCreativeDetails.campaignLocationGroupId && (type === 'submitReview' || type === 'traffic')) {
                if (selectedChannels && !isArrayEmpty(selectedChannels)) {
                    selectedChannels.map((channels) => {
                        if (channels.id === basicCreativeDetails.channelId) {
                            if (channels.taskList && !isArrayEmpty(channels.taskList)) {
                                channels.taskList.map((list) => {
                                    if (list && !isArrayEmpty(list)) {
                                        list.map((taskList) => {
                                            if (taskList && taskList.tasks && !isArrayEmpty(taskList.tasks)) {
                                                taskList.tasks.map((items) => {
                                                    const item = items;
                                                    if (item.workFlowTaskId === basicCreativeDetails.taskId) {
                                                        if (type === 'submitReview') {
                                                            item.status = taskStatusDetails.complete.taskStatus.toLowerCase();
                                                            item.statusId = taskStatusDetails.complete.taskStatusId;
                                                        } else if (type === 'traffic') {
                                                            item.status = taskStatusDetails.approved.taskStatus.toLowerCase();
                                                            item.statusId = taskStatusDetails.approved.taskStatusId;
                                                        }
                                                    }

                                                    return null;
                                                });
                                            }

                                            return null;
                                        });
                                    }

                                    return null;
                                });
                            }
                        }

                        return null;
                    });
                }
            }

            return null;
        });
    }

    return tableLocationDetails;
};

// const chunkUpload = async (file, campaignId) => {
//     return new Promise((resolve, reject) => {
//         console.log(file);
//         const sliceSize = 500 * 1024;

//         const reader = new FileReader();
//         function uploadFile(start) {
//             console.log(start);
//             const nextSlice = start + sliceSize + 1;
//             const blob = file.slice(start, nextSlice);

//             reader.onloadend = async (event) => {
//                 if (event.target.readyState !== FileReader.DONE) {
//                     return;
//                 }

//                 try {
//                     const base64Data = reader.result.split(',')[1];
//                     const data = {};
//                     data.relativeUrl = '600/3/creative/120/test1234567890';
//                     data.chunkId = 'raghu';
//                     data.size = '';
//                     data.isCompleted = !(nextSlice < file.size);
//                     data.originalChunkId = data.chunkId;
//                     data.chunkData = base64Data;

//                     const response = await uploadChunkFile(data);
//                     console.log(event.target.result);
//                     if (response) {
//                         if (nextSlice < file.size) {
//                             // More to upload, call function recursively
//                             uploadFile(nextSlice);
//                         } else {
//                             // Update upload progress
//                             console.log('completed');
//                             resolve({
//                                 fileName: file.name,
//                                 base64: reader.result
//                             });
//                         }
//                     }
//                 } catch (error) {
//                     console.log(error);
//                 }
//             };

//             reader.readAsDataURL(blob);
//         }
//         uploadFile(0);
//     });
// };
const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            return resolve({
                fileName: file.name,
                base64: reader.result
            });
        };

        reader.onerror = (error) => {
            console.log(error);
            return reject(error);
        };
    });
};

const buildPostCreativeData = async (data = {}, campaignId) => {
    const postData = {};
    const metaData = {};

    const {
        promotionLocationGroupChannelId,
        traffickingInstructions,
        traffickingDates,
        creativeUploadOptions,
        selectedDuration,
		selectedChannelForUpload,
		isFilesFromCreativeLibrary,
		selectedCreativeLibraryFiles
    } = data;
    postData.promotionLocationGroupChannelId = promotionLocationGroupChannelId;

    switch (selectedChannelForUpload) {
    case creativeChannelList.tv.toLowerCase():
    case creativeChannelList.radio.toLowerCase(): {
        metaData.adId = traffickingInstructions.adId;
        metaData.adIdName = traffickingInstructions.adIdName;
        metaData.rotation = traffickingInstructions.rotation;
        metaData.traffickingStartDate = dateFormatter(traffickingDates.traffickingStartDate, 'MM-DD-YYYY');
        metaData.traffickingEndDate = dateFormatter(traffickingDates.traffickingEndDate, 'MM-DD-YYYY');
        metaData.selectedDuration = selectedDuration.value;
        metaData.selectedUploadOption = creativeUploadOptions.selectedUploadOption;
        if (creativeUploadOptions.fileUrl) {
            metaData.fileUrl = creativeUploadOptions.fileUrl;
        }
        break;
    }

    case creativeChannelList.onlineAudio.toLowerCase(): {
        metaData.adIdName = traffickingInstructions.adIdName;
        metaData.rotation = traffickingInstructions.rotation;
        metaData.traffickingStartDate = dateFormatter(traffickingDates.traffickingStartDate, 'MM-DD-YYYY');
        metaData.traffickingEndDate = dateFormatter(traffickingDates.traffickingEndDate, 'MM-DD-YYYY');
        metaData.selectedDuration = selectedDuration.value;
        metaData.selectedUploadOption = creativeUploadOptions.selectedUploadOption;
        break;
    }

    case creativeChannelList.onlineVideo.toLowerCase(): {
        metaData.clickThruURL = traffickingInstructions.clickThruURL;
        metaData.youTubeLink = traffickingInstructions.youTubeLink;
        metaData.rotation = traffickingInstructions.rotation;
        metaData.traffickingStartDate = dateFormatter(traffickingDates.traffickingStartDate, 'MM-DD-YYYY');
        metaData.traffickingEndDate = dateFormatter(traffickingDates.traffickingEndDate, 'MM-DD-YYYY');
        metaData.selectedDuration = selectedDuration.value;
        metaData.selectedUploadOption = creativeUploadOptions.selectedUploadOption;
        break;
    }

    case creativeChannelList.newsPaper.toLowerCase():
    case creativeChannelList.magazine.toLowerCase():
    case creativeChannelList.outOfHome.toLowerCase(): {
        metaData.traffickingStartDate = dateFormatter(traffickingDates.traffickingStartDate, 'MM-DD-YYYY');
        metaData.traffickingEndDate = dateFormatter(traffickingDates.traffickingEndDate, 'MM-DD-YYYY');
        metaData.selectedUploadOption = creativeUploadOptions.selectedUploadOption;
        break;
    }

    case creativeChannelList.digitalDisplay.toLowerCase(): {
        metaData.clickThruURL = traffickingInstructions.clickThruURL;
        metaData.traffickingStartDate = dateFormatter(traffickingDates.traffickingStartDate, 'MM-DD-YYYY');
        metaData.traffickingEndDate = dateFormatter(traffickingDates.traffickingEndDate, 'MM-DD-YYYY');
        metaData.selectedUploadOption = creativeUploadOptions.selectedUploadOption;
        break;
    }

    case creativeChannelList.paidSocial.toLowerCase(): {
        metaData.headline = traffickingInstructions.headline;
        metaData.mainAdCopy = traffickingInstructions.mainAdCopy;
        metaData.destinationUrl = traffickingInstructions.destinationUrl;
        metaData.traffickingStartDate = dateFormatter(traffickingDates.traffickingStartDate, 'MM-DD-YYYY');
        metaData.traffickingEndDate = dateFormatter(traffickingDates.traffickingEndDate, 'MM-DD-YYYY');
        metaData.selectedUploadOption = creativeUploadOptions.selectedUploadOption;

        if (creativeUploadOptions.uploadedUrlList && !isArrayEmpty(creativeUploadOptions.uploadedUrlList)) {
            metaData.uploadedUrlList = [];
            creativeUploadOptions.uploadedUrlList.map((item) => {
                if (item.uploadedUrl) {
                    const url = {};
                    url.uploadedUrl = item.uploadedUrl;
                    metaData.uploadedUrlList.push(url);
                }
                return null;
            });
        }
        break;
    }

    default: {
        metaData.adId = traffickingInstructions.adId;
        metaData.adIdName = traffickingInstructions.adIdName;
        metaData.rotation = traffickingInstructions.rotation;
        metaData.traffickingStartDate = dateFormatter(traffickingDates.traffickingStartDate, 'MM-DD-YYYY');
        metaData.traffickingEndDate = dateFormatter(traffickingDates.traffickingEndDate, 'MM-DD-YYYY');
        metaData.selectedDuration = selectedDuration.value;
        metaData.selectedUploadOption = creativeUploadOptions.selectedUploadOption;

        if (creativeUploadOptions.fileUrl) {
            metaData.fileUrl = creativeUploadOptions.fileUrl;
        }
    }
    }
    if (creativeUploadOptions.usePreviousAssetNotes) {
        metaData.usePreviousAssetNotes = creativeUploadOptions.usePreviousAssetNotes;
    }
    if (traffickingInstructions.notes) {
        metaData.notes = traffickingInstructions.notes;
    }
    postData.metaData = JSON.stringify(metaData);
	postData.isFilesFromCreativeLibrary = isFilesFromCreativeLibrary;
    postData.locationGroupChannelFiles = [];

    if (creativeUploadOptions.uploadedFiles && !isArrayEmpty(creativeUploadOptions.uploadedFiles)) {
        await Promise.all(creativeUploadOptions.uploadedFiles.map(async (item) => {
            // const file = await chunkUpload(item, campaignId);
            const file = await toBase64(item);
            const base64Data = file.base64.split(',')[1];

            const fileData = {};

            fileData.media = base64Data;
            fileData.fileName = file.fileName;
            fileData.mimeType = item.type;
            fileData.dimension = item.width && item.height ? item.width + 'x' + item.height : '';
            postData.locationGroupChannelFiles.push(fileData);
        }));
	}
	if (isFilesFromCreativeLibrary) {
		if (selectedCreativeLibraryFiles && !isArrayEmpty(selectedCreativeLibraryFiles)) {
			selectedCreativeLibraryFiles.map((file) => {
				const fileData = {};
				fileData.fileId = file.fileId;
				postData.locationGroupChannelFiles.push(fileData);
				return fileData;
			});
		}
	}
    return postData;
};

const buildUpdateCreativeData = async (data = {}) => {
    const postData = {};
    let metaData = {};
    const {
        selectedChannelForUpload,
		updatedData,
        isFilesFromCreativeLibrary,
        isMultiCreativeUpload = false
    } = data;

    const {
        adId,
        adIdName,
        rotation,
        traffickingStartDate,
        traffickingEndDate,
        selectedDuration,
        selectedUploadOption,
        fileUrl,
        clickThruURL,
        youTubeLink,
        headline,
        mainAdCopy,
        destinationUrl,
        uploadedUrlList,
        uploadedFiles,
        newFiles,
        usePreviousAssetNotes,
        notes
    } = updatedData;

    console.log('uploadedFiles', uploadedFiles, isMultiCreativeUpload);
    console.log('newFiles', newFiles);
    switch (selectedChannelForUpload) {
    case creativeChannelList.tv.toLowerCase():
    case creativeChannelList.radio.toLowerCase(): {
        metaData.adId = adId;
        metaData.adIdName = adIdName;
        metaData.rotation = rotation;
        metaData.traffickingStartDate = traffickingStartDate;
        metaData.traffickingEndDate = traffickingEndDate;
        metaData.selectedDuration = selectedDuration;
        metaData.selectedUploadOption = selectedUploadOption;

        if (fileUrl) {
            metaData.fileUrl = fileUrl;
        }
        break;
    }

    case creativeChannelList.onlineAudio.toLowerCase(): {
        metaData.adIdName = adIdName;
        metaData.rotation = rotation;
        metaData.traffickingStartDate = traffickingStartDate;
        metaData.traffickingEndDate = traffickingEndDate;
        metaData.selectedDuration = selectedDuration;
        metaData.selectedUploadOption = selectedUploadOption;
        break;
    }

    case creativeChannelList.onlineVideo.toLowerCase(): {
        metaData.clickThruURL = clickThruURL;
        metaData.youTubeLink = youTubeLink;
        metaData.rotation = rotation;
        metaData.traffickingStartDate = traffickingStartDate;
        metaData.traffickingEndDate = traffickingEndDate;
        metaData.selectedDuration = selectedDuration;
        metaData.selectedUploadOption = selectedUploadOption;
        break;
    }

    case creativeChannelList.newsPaper.toLowerCase():
    case creativeChannelList.magazine.toLowerCase():
    case creativeChannelList.outOfHome.toLowerCase(): {
        metaData.traffickingStartDate = traffickingStartDate;
        metaData.traffickingEndDate = traffickingEndDate;
        metaData.selectedUploadOption = selectedUploadOption;
        break;
    }

    case creativeChannelList.digitalDisplay.toLowerCase(): {
        metaData.clickThruURL = clickThruURL;
        metaData.traffickingStartDate = traffickingStartDate;
        metaData.traffickingEndDate = traffickingEndDate;
        metaData.selectedUploadOption = selectedUploadOption;
        break;
    }

    case creativeChannelList.paidSocial.toLowerCase(): {
        metaData.headline = headline;
        metaData.mainAdCopy = mainAdCopy;
        metaData.destinationUrl = destinationUrl;
        metaData.traffickingStartDate = traffickingStartDate;
        metaData.traffickingEndDate = traffickingEndDate;
        metaData.selectedUploadOption = selectedUploadOption;

        if (uploadedUrlList && !isArrayEmpty(uploadedUrlList)) {
            metaData.uploadedUrlList = [];
            uploadedUrlList.map((item) => {
                if (item.uploadedUrl) {
                    const url = {};
                    url.uploadedUrl = item.uploadedUrl;
                    metaData.uploadedUrlList.push(url);
                }

                return null;
            });
        }
        break;
    }

    default: {
        metaData = {};
    }
    }
    if (usePreviousAssetNotes) {
        metaData.usePreviousAssetNotes = usePreviousAssetNotes;
    }

    metaData.notes = notes;

	postData.metaData = JSON.stringify(metaData);
	postData.isFilesFromCreativeLibrary = isFilesFromCreativeLibrary;
    postData.locationGroupChannelFiles = [];

	if (newFiles && !isArrayEmpty(newFiles) && !isFilesFromCreativeLibrary && !isMultiCreativeUpload) {
        await Promise.all(newFiles.map(async (item) => {
            const file = await toBase64(item);
            const base64Data = file.base64.split(',')[1];

            const fileData = {};
            fileData.locationGroupChannelFileId = null;
            fileData.media = base64Data;
            fileData.fileName = file.fileName;
            fileData.mimeType = item.type;
            fileData.dimension = item.width && item.height ? item.width + 'x' + item.height : '';
            postData.locationGroupChannelFiles.push(fileData);
        }));
    }

	if (uploadedFiles && !isArrayEmpty(uploadedFiles) && !isFilesFromCreativeLibrary && !isMultiCreativeUpload) {
        uploadedFiles.map((item) => {
            const fileData = {};
            fileData.locationGroupChannelFileId = item.locationGroupChannelFileId;
            fileData.media = null;
            fileData.fileName = item.fileName;
            fileData.mimeType = item.mimeType;
            fileData.dimension = item.dimension;
            postData.locationGroupChannelFiles.push(fileData);

            return null;
        });
    }

	if (isFilesFromCreativeLibrary && uploadedFiles && !isArrayEmpty(uploadedFiles && !isMultiCreativeUpload)) {
		uploadedFiles.map((item) => {
			const fileData = {};
			fileData.locationGroupChannelFileId = item.locationGroupChannelFileId;
			fileData.fileId = item.fileId;
			postData.locationGroupChannelFiles.push(fileData);

			return null;
		});
    }

    if (newFiles && !isArrayEmpty(newFiles) && isMultiCreativeUpload) {
        await Promise.all(newFiles.map(async (item) => {
            const file = await toBase64(item);
            const base64Data = file.base64.split(',')[1];

            const fileData = {};
            fileData.locationGroupChannelFileId = null;
            fileData.media = base64Data;
            fileData.fileName = file.fileName;
            fileData.mimeType = item.type;
            fileData.dimension = item.width && item.height ? item.width + 'x' + item.height : '';
            postData.locationGroupChannelFiles.push(fileData);
        }));
    }

    if (uploadedFiles && !isArrayEmpty(uploadedFiles) && isMultiCreativeUpload) {
        uploadedFiles.map((item) => {
            const fileData = {};
            if (item.creativeLibraryFileId) {
                fileData.locationGroupChannelFileId = item.locationGroupChannelFileId;
                fileData.fileId = item.fileId;
                postData.locationGroupChannelFiles.push(fileData);
            } else {
                fileData.locationGroupChannelFileId = item.locationGroupChannelFileId;
                fileData.media = null;
                fileData.fileName = item.fileName;
                fileData.mimeType = item.mimeType;
                fileData.dimension = item.dimension;
                postData.locationGroupChannelFiles.push(fileData);
            }
            return null;
        });
    }

    return postData;
};

/* Creative Upload Section Start */
const handleCreativeUploadPopup = (data = {}) => {
    return async (dispatch, getState) => {
        try {
            const creativeDetails = data;
            const {
                campaignId,
                showCreativeUpload,
                isChannelCreativeSelected,
                taskId,
				isCompleted,
				channelTaskDetails
			} = creativeDetails;
			let tempTableLocationDetails = creativeDetails.tableLocationDetails;
			if (!creativeDetails.tableLocationDetails) {
				const { adminFlightingTable } = getState();
				tempTableLocationDetails = adminFlightingTable.tableLocationDetails;
			}
			const tableLocationDetails = tempTableLocationDetails;
            creativeDetails.numberOfLocations = 0;
            creativeDetails.basicCreativeDetails = {};
            creativeDetails.uploadedCreativeList = [];
            creativeDetails.unChangedUploadedCreativeList = [];
            creativeDetails.selectedCreativeUploadChannel = '';
			creativeDetails.taskCompletedFlag = isCompleted;
			creativeDetails.channelTaskDetails = {};
			// const channelTaskDetails = {};
            if (showCreativeUpload && isChannelCreativeSelected) {
                dispatch({
                    type: CAMPAIGN_DETAILS_START
                });
                const getResponse = await getCreativeUploadDetails(campaignId, taskId);

                if (getResponse && getResponse.data.resultCount > 0) {
                    const { data: getResponseData } = getResponse.data;

                    const response = await checkApplyToAllLocation(campaignId, getResponseData.channelId);

                    getResponseData.isApplyToAllLocations = response.data.data || false;

                    const getData = buildInitialCreativeData(getResponseData, tableLocationDetails);
                    creativeDetails.numberOfLocations = getData.numberOfLocations;
                    creativeDetails.basicCreativeDetails = getData.basicCreativeDetails;
                    creativeDetails.uploadedCreativeList = getData.uploadedCreativeList;
                    creativeDetails.unChangedUploadedCreativeList = cloneDeep(getData.uploadedCreativeList);
                    creativeDetails.selectedCreativeUploadChannel = getData.basicCreativeDetails.channelName.toLowerCase();
					creativeDetails.locationGroupsSelect = getData.locationGroups;
					creativeDetails.channelTaskDetails = channelTaskDetails;
					const channel = {
						id: getData.basicCreativeDetails.channelId,
						channelName: getData.basicCreativeDetails.channelName,
						campaignLocationGroupId: getData.basicCreativeDetails.campaignLocationGroupId
					};
					creativeDetails.channelTaskDetails.channel = channel;
                }

                dispatch({
                    type: CAMPAIGN_DETAILS_END
                });
            }

            dispatch({
                type: GET_CREATIVE_DETAILS,
                data: creativeDetails
            });
        } catch (error) {
            console.log(error);

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });

            dispatch({
                type: CREATIVE_UPLOAD_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
        }
    };
};

const closeCreativeUploadPopUp = (details = {}) => {
    return async (dispatch) => {
        try {
            const data = details;
            const {
                campaignId,
                taskId,
                additionalDetails,
                applyToAllLocations,
                isDetailsUpdated,
                tableLocationDetails,
                basicCreativeDetails,
                campaignLocationGroupId
            } = data;

            if (isDetailsUpdated) {
                dispatch({
                    type: CAMPAIGN_DETAILS_START
                });

                const locationData = {};
                locationData.additionalInfo = additionalDetails;
                locationData.isAllLocation = campaignLocationGroupId && campaignLocationGroupId.length > 0 ? false : applyToAllLocations;
                locationData.campaignLocationGroupIds = applyToAllLocations ? '' : campaignLocationGroupId;

                const response = await updateAdditionalDetails(campaignId, taskId, locationData);
                await dispatch(getCampaignTasks(campaignId));
                if (response && response.status === 204) {
                    const taskData = {};
                    taskData.tableLocationDetails = tableLocationDetails;
                    taskData.basicCreativeDetails = basicCreativeDetails;
                    taskData.applyToAllLocations = applyToAllLocations;
                    taskData.type = 'closeCreative';

                    data.tableLocationDetails = updateTaskStatus(taskData, campaignLocationGroupId);

                    dispatch({
                        type: CLOSE_CREATIVE_UPLOAD_POPUP,
                        data
                    });
                } else {
                    dispatch({
                        type: CREATIVE_UPLOAD_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });
                }

                dispatch({
                    type: CAMPAIGN_DETAILS_END
                });
            } else {
                dispatch({
                    type: CLOSE_CREATIVE_UPLOAD_POPUP,
                    data
                });
            }
        } catch (error) {
            console.log(error);

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });

            dispatch({
                type: CREATIVE_UPLOAD_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
        }
    };
};

const postMarkAsCompleteCreative = (data = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });

            const {
                campaignId,
                taskId,
                applyToAllLocations,
                basicCreativeDetails,
                tableLocationDetails,
                type
            } = data;

            const details = data;

            const response = await markAsCompleteTaskService(taskId);

            if (response) {
                const taskData = {};
                taskData.tableLocationDetails = tableLocationDetails;
                taskData.basicCreativeDetails = basicCreativeDetails;
                taskData.applyToAllLocations = applyToAllLocations;
                taskData.type = type;
                details.tableLocationDetails = updateTaskStatus(taskData);

                dispatch(getCampaignTasks(campaignId));

                dispatch({
                    type: SUBMIT_CREATIVE_DETAILS_FOR_REVIEW,
                    data: details
                });
            } else {
                dispatch({
                    type: CREATIVE_UPLOAD_ERROR_HANDLER,
                    data: setErrorMessage(response)
                });
            }

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            console.log(error);

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });

            dispatch({
                type: CREATIVE_UPLOAD_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
        }
    };
};

const postCreativeDetails = (data = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });

            const {
                campaignId,
                taskId,
                additionalDetails,
                applyToAllLocations,
                addAnotherCreative,
                basicCreativeDetails,
                tableLocationDetails,
                isDetailsUpdated,
                onlyAdditionalInfo,
                campaignLocationGroupId
            } = data;

            if (onlyAdditionalInfo) {
                await dispatch(closeCreativeUploadPopUp(data));
            } else {
                const locationData = {};
                locationData.additionalInfo = additionalDetails;
                locationData.isAllLocation = applyToAllLocations;
                locationData.campaignLocationGroupIds = campaignLocationGroupId;

                const postData = await buildPostCreativeData(data);
                const response = await postCreativeUploadDetails(campaignId, postData, taskId);

                if (response) {
                    if (!addAnotherCreative && isDetailsUpdated) {
                        await updateAdditionalDetails(campaignId, taskId, locationData);
                        await dispatch(getCampaignTasks(campaignId));
                    }

                    const getResponse = await getCreativeUploadDetails(campaignId, taskId);

                    const uploadedCreativeDetails = {};
                    uploadedCreativeDetails.basicCreativeDetails = {};
                    uploadedCreativeDetails.uploadedCreativeList = [];
                    uploadedCreativeDetails.unChangedUploadedCreativeList = [];
                    uploadedCreativeDetails.selectedCreativeUploadChannel = '';

                    if (getResponse && getResponse.data.resultCount > 0) {
                        const getData = buildInitialCreativeData(getResponse.data.data, tableLocationDetails);

                        uploadedCreativeDetails.basicCreativeDetails = getData.basicCreativeDetails;
                        uploadedCreativeDetails.uploadedCreativeList = getData.uploadedCreativeList;
                        uploadedCreativeDetails.unChangedUploadedCreativeList = cloneDeep(getData.uploadedCreativeList);
                        uploadedCreativeDetails.selectedCreativeUploadChannel = getData.basicCreativeDetails.channelName.toLowerCase();
                        uploadedCreativeDetails.tableLocationDetails = tableLocationDetails;

                        if (isDetailsUpdated) {
                            const taskData = {};
                            taskData.tableLocationDetails = tableLocationDetails;
                            taskData.basicCreativeDetails = basicCreativeDetails;
                            taskData.applyToAllLocations = applyToAllLocations;
                            taskData.type = 'postCreative';
                            uploadedCreativeDetails.tableLocationDetails = updateTaskStatus(taskData, campaignLocationGroupId);
                        }

                        dispatch({
                            type: POST_CREATIVE_DETAILS,
                            data: uploadedCreativeDetails
                        });
                    } else {
                        dispatch({
                            type: CREATIVE_UPLOAD_ERROR_HANDLER,
                            data: setErrorMessage(response)
                        });
                    }
                } else {
                    dispatch({
                        type: CREATIVE_UPLOAD_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });
                }
            }


            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            console.log(error);

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });

            dispatch({
                type: CREATIVE_UPLOAD_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
        }
    };
};

const updateCreativeDetails = (data = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });

            const {
                updatedData,
                campaignId
            } = data;

            const postData = await buildUpdateCreativeData(data);

            console.log('postData', postData);

            const response = await updateCreativeUploadDetails(campaignId, postData, updatedData.dbId);

            if (response && response.data.resultCount && response.data.data) {
                const { data: details } = response.data;

                updatedData.uploadedFiles = [];
                updatedData.newFiles = [];
                if (details.locationGroupChannelFiles && !isArrayEmpty(details.locationGroupChannelFiles)) {
                    details.locationGroupChannelFiles.map((list) => {
                        const file = list;
                        let fileType = list.mimeType && list.mimeType.split('/')[0];

                        let src = 'preview-icon unsupported-image';

                        if (fileType) {
                            if (fileType === 'image') {
                                src = 'preview-image image';

                                if (list.mimeType.includes('tiff') || list.mimeType.includes('eps')
                                            || list.mimeType.includes('ai') || list.mimeType.includes('vnd')) {
                                    fileType = '';
                                    src = 'preview-icon unsupported-image';
                                }
                            } else if (fileType === 'video') {
                                src = 'preview-icon video';
                            } else if (fileType === 'audio') {
                                src = 'preview-icon audio';
                            } else if (fileType === 'application') {
                                if (list.mimeType.includes('pdf')) {
                                    src = 'preview-icon pdf';
                                } else if (list.mimeType.includes('vnd')) {
                                    src = 'preview-icon doc';
                                }
                            }
                        }

                        file.src = src;
                        file.fileType = fileType;

                        updatedData.uploadedFiles.push(file);

                        return null;
                    });
                }

                dispatch({
                    type: UPDATE_CREATIVE_UPLOAD_LIST,
                    data
                });
            } else {
                dispatch({
                    type: CREATIVE_UPLOAD_ERROR_HANDLER,
                    data: setErrorMessage(response)
                });
            }

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            console.log(error);

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });

            dispatch({
                type: CREATIVE_UPLOAD_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
        }
    };
};

const submitReviewOrDeleteCreativeDetails = (postDetails = {}, type = 'delete') => {
    return async (dispatch) => {
        try {
            const details = postDetails;
            const {
                campaignId,
                dbId,
                taskId,
                isComplete,
                isDetailsUpdated,
                additionalDetails,
                applyToAllLocations,
                tableLocationDetails,
                basicCreativeDetails,
                requestRevisionReason,
                recipientEmail,
                trafficSubject,
                showSubmitReviewWarning,
                campaignLocationGroupId
            } = details;

            if (type === 'delete') {
                dispatch({
                    type: CAMPAIGN_DETAILS_START
                });
                const response = await deleteCreativeUploadDetails(campaignId, dbId);

                if (response && response.status === 204) {
                    dispatch({
                        type: DELETE_CREATIVE_DETAILS,
                        data: details
                    });

                    dispatch({
                        type: CAMPAIGN_DETAILS_END
                    });
                } else {
                    dispatch({
                        type: CREATIVE_UPLOAD_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });

                    dispatch({
                        type: CAMPAIGN_DETAILS_END
                    });
                }
            } else if (type === 'submitReview') {
                dispatch({
                    type: CAMPAIGN_DETAILS_START,
                    data: creativeUploadTv.loadingMessage
                });

                const data = {};
                let updateResponse = 'response';
                data.isCompleted = isComplete;

                if (isDetailsUpdated) {
                    let applyToAllLocationsCheck;
                    if (campaignLocationGroupId !== '') {
                        applyToAllLocationsCheck = false;
                    } else {
                        applyToAllLocationsCheck = applyToAllLocations;
                    }
                    const locationData = {};
                    locationData.additionalInfo = additionalDetails;
                    locationData.isAllLocation = applyToAllLocationsCheck;
                    locationData.CampaignLocationGroupIds = campaignLocationGroupId;

                    updateResponse = await updateAdditionalDetails(campaignId, taskId, locationData);
                }

                if (showSubmitReviewWarning) {
                    const patchData = {};
                    const { promotionId } = basicCreativeDetails;
                    patchData.showSubmitreviewWarning = showSubmitReviewWarning;
                    await updateShowWarning(campaignId, promotionId, patchData);
                }

                const response = await submitCreativeForReview(campaignId, taskId, data);

                if (response && response.status === 204 && (updateResponse || updateResponse.status === 204)) {
                    const taskData = {};
                    taskData.tableLocationDetails = tableLocationDetails;
                    taskData.basicCreativeDetails = basicCreativeDetails;
                    taskData.applyToAllLocations = applyToAllLocations;
                    taskData.type = 'submitReview';
                    details.tableLocationDetails = updateTaskStatus(taskData, campaignLocationGroupId);

                    dispatch(getCampaignTasks(campaignId));

                    dispatch({
                        type: SUBMIT_CREATIVE_DETAILS_FOR_REVIEW,
                        data: details
                    });
                } else {
                    dispatch({
                        type: CREATIVE_UPLOAD_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });

                    dispatch({
                        type: CAMPAIGN_DETAILS_END
                    });
                }
            } else if (type === 'requestRevision' || type === 'traffic') {
                dispatch({
                    type: CAMPAIGN_DETAILS_START
                });

                const data = {};
                data.creativeTraffic = {};
                let updateResponse = 'response';

                if (type === 'requestRevision') {
                    const creativeTrafficReject = {};
                    creativeTrafficReject.note = requestRevisionReason;
                    data.creativeTraffic.creativeTrafficReject = creativeTrafficReject;
                } else if (type === 'traffic') {
                    const creativeTrafficApprove = {};
                    creativeTrafficApprove.email = recipientEmail;
                    creativeTrafficApprove.additionalInfo = trafficSubject;
                    data.creativeTraffic.creativeTrafficApprove = creativeTrafficApprove;
                }

                if (isDetailsUpdated) {
                    let applyToAllLocationsCheck;
                    if (campaignLocationGroupId !== '') {
                        applyToAllLocationsCheck = false;
                    } else {
                        applyToAllLocationsCheck = applyToAllLocations;
                    }
                    const locationData = {};
                    locationData.additionalInfo = additionalDetails;
                    locationData.isAllLocation = applyToAllLocationsCheck;
                    locationData.CampaignLocationGroupIds = campaignLocationGroupId;

                    updateResponse = await updateAdditionalDetails(campaignId, taskId, locationData);
                }

                const response = await requestRevisionOrTraffic(campaignId, taskId, data);
                let getResponse = '';

                if (type === 'requestRevision') {
                    getResponse = await getFlightingDetails(campaignId);
                }

                if (response && response.status === 201 && (updateResponse || updateResponse.status === 204)) {
                    if (type === 'traffic') {
                        const taskData = {};
                        taskData.tableLocationDetails = tableLocationDetails;
                        taskData.basicCreativeDetails = basicCreativeDetails;
                        taskData.applyToAllLocations = applyToAllLocations;
                        taskData.type = 'traffic';
                        details.tableLocationDetails = updateTaskStatus(taskData, campaignLocationGroupId);
                    }
                    if (type === 'requestRevision') {
                        const { data: getData } = getResponse.data;
                        if (!isObjectEmpty(getData)) {
                            const {
                                campaignLocationGroups
                            } = getData;

                            if (campaignLocationGroups && !isArrayEmpty(campaignLocationGroups)) {
                                details.tableLocationDetails = buildLocationDetails(campaignLocationGroups);
                                details.campaignLocationGroups = campaignLocationGroups;
                            }
                        }
                    }

                    dispatch(getCampaignTasks(campaignId));

                    dispatch({
                        type: SUBMIT_CREATIVE_DETAILS_FOR_REVIEW,
                        data: details
                    });
                } else {
                    dispatch({
                        type: CREATIVE_UPLOAD_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });

                    dispatch({
                        type: CAMPAIGN_DETAILS_END
                    });
                }
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });

            dispatch({
                type: CREATIVE_UPLOAD_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
        }
    };
};

const handleWarningBox = (data = {}) => {
    return async (dispatch) => {
        try {
            const {
                campaignId,
                promotionId,
                type
            } = data;
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });

            const patchData = {};

            if (type === 'allLocation') {
                patchData.showAllLocationWarning = true;
            } else if (type === 'thisLocationOnly') {
                patchData.showThisLocationWarning = true;
            }

            const response = await updateShowWarning(campaignId, promotionId, patchData);

            if (response) {
                dispatch({
                    type: HANDLE_WARNING_BOX,
                    data
                });
            } else {
                dispatch({
                    type: CREATIVE_UPLOAD_ERROR_HANDLER,
                    data: setErrorMessage(response)
                });
            }

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            console.log(error);

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });

            dispatch({
                type: CREATIVE_UPLOAD_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
        }
    };
};

const getPromotionLocationGroupForChannel = (campaignId, campaignPromotionId, channelId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_START
			});
			const response = await getPromotionLocationGroupForChannelService(campaignId, campaignPromotionId, channelId);
			if (response) {
				const data = [...response.data.data];
				const locations = [];
				data.map((loc, index) => {
					if (!locations.filter((x) => {
						return x.locationGroupId === loc.locationGroupId;
					}).length) {
						locations.push(loc);
					}
					return loc;
				});
				dispatch({
					type: GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_COMPLETE,
					data: locations
				});
			}
		} catch (error) {
			dispatch({
				type: GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_FAIL,
				data: setErrorMessage(error)
			});
		}
	};
};

/* Creative Upload Section End */

/* Campaign Dark day start */

const updateShowDarkDay = (value) => {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_SHOW_DARK_DAY,
			data: value
		});
	};
};

const postCampaignDarkDay = (campaignId, data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: ADD_CAMPIGN_DARK_DAY_START
			});

			const response = await addCampaignDarkDayService(campaignId, data);

			if (response) {
				dispatch({
					type: ADD_CAMPIGN_DARK_DAY_SUCCESS
				});
				dispatch(getFlighting(campaignId));
			} else {
				dispatch({
					type: ADD_CAMPIGN_DARK_DAY_FAIL,
					data: setErrorMessage(response)
				});
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: ADD_CAMPIGN_DARK_DAY_FAIL,
				data: setErrorMessage(error)
			});
		}
	};
};

const handleEditDarkDayPopup = (data) => {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_SHOW_EDIT_DARK_DAY,
			data
		});
	};
};

const putCampaignDarkDay = (campaignId, locationGroupChannelDarkDayId, data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_CAMPIGN_DARK_DAY_START
			});

			const response = await updateCampaignDarkDayService(campaignId, locationGroupChannelDarkDayId, data);

			if (response.status === 204) {
				dispatch({
					type: UPDATE_CAMPIGN_DARK_DAY_SUCCESS
				});
				dispatch(getFlighting(campaignId));
			} else {
				dispatch({
					type: UPDATE_CAMPIGN_DARK_DAY_FAIL,
					data: setErrorMessage(response)
				});
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: UPDATE_CAMPIGN_DARK_DAY_FAIL,
				data: setErrorMessage(error)
			});
		}
	};
};

/* Campaign Dark day end */

/* Request Revision start */
const acceptOrRejectRequestRevision = (data, campiagnId, taskId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: REQUEST_REVISION_START
			});

			await acceptOrRejectRequestRevisionService(data, campiagnId, taskId);
			if (data.isRequestedRevisionApproved) {
				dispatch(getFlighting(campiagnId));
			}

			dispatch({
				type: REQUEST_REVISION_SUCCESS
			});
		} catch (error) {
			dispatch({
				type: REQUEST_REVISION_FAIL,
				data: setErrorMessage(error)
			});
		}
	};
};
/* Request Revision end */

export {
    getFlighting,
    updateFlightingStatus,
    postPromotionDetails,
    handlePromotionPopup,
    handleLocationPopup,
    postLocationDetails,
    handleCreativeUploadPopup,
    postCreativeDetails,
    updateCreativeDetails,
    submitReviewOrDeleteCreativeDetails,
    closeCreativeUploadPopUp,
    handleWarningBox,
	postMarkAsCompleteCreative,
	getPromotionLocationGroupForChannel,
	updateShowDarkDay,
	postCampaignDarkDay,
	handleEditDarkDayPopup,
	putCampaignDarkDay,
	acceptOrRejectRequestRevision
};
