import { updateObject } from '../../utils/reducer_utility';
import { isObjectEmpty } from '../../utils/utility_helpers';

import {
    CLEAR_ALL_PACKAGE_RELATED_STORES,
    DISCARD_UNSAVED_OBJECTIVES,
    OBJECTIVE_DETAIL_UPDATED,
    OBJECTIVE_ERROR,
    OBJECTIVE_GET_ERROR,
    OBJECTIVE_INITIATION,
    OBJECTIVE_OPTIONS,
    OBJECTIVE_SAVE_AND_CONTINUE,
    UPDATE_SELECTED_OBJECTIVE
} from '../actions/actionTypes';

const initialState = {
    errorMessage: '',
    getErrorMessage: '',
    isFormSubmitted: false,
    loading: false,
    mediaOptionList: [],
    objectiveComments: '',
    objectiveOptions: [],
    isObjectiveDetailsUpdated: false,
    selectedApproach: '',
    selectedObjective: '',
    savedObjective: {}
};


const objectiveAndApproachReducer = (state = initialState, action) => {
    switch (action.type) {
    case OBJECTIVE_ERROR:
        return updateObject(state, { errorMessage: action.data, loading: false });
    case OBJECTIVE_GET_ERROR:
        return updateObject(state, { getErrorMessage: action.data, loading: false });
    case OBJECTIVE_INITIATION:
        return updateObject(state, { loading: true });
    case OBJECTIVE_DETAIL_UPDATED:
        return updateObject(state, { isObjectiveDetailsUpdated: action.data });
    case UPDATE_SELECTED_OBJECTIVE: {
        const {
            type,
            option
        } = action.data;

        let {
            selectedApproach,
            selectedObjective
        } = state;

        if (type === 'objective') {
            selectedObjective = option;
        } else {
            selectedApproach = option;
        }

        return updateObject(state, {
            selectedApproach,
            selectedObjective,
            isObjectiveDetailsUpdated: true
        });
    }
    case OBJECTIVE_OPTIONS: {
        const {
            mediaOptionList,
            objectiveOptionList,
            selectedApproach,
            selectedObjective,
            objectiveComments,
            savedObjective
        } = action.data;

        return updateObject(state, {
            objectiveOptions: objectiveOptionList,
            mediaOptionList,
            loading: false,
            errorMessage: '',
            selectedApproach,
            selectedObjective,
            objectiveComments,
            savedObjective,
            isObjectiveDetailsUpdated: false,
            isFormSubmitted: !!(selectedObjective && selectedApproach),
            getErrorMessage: ''
        });
    }
    case OBJECTIVE_SAVE_AND_CONTINUE: {
        const {
            postData
        } = action.data;

        return updateObject(state, {
            savedObjective: postData,
            loading: false,
            isObjectiveDetailsUpdated: false,
            isFormSubmitted: true,
            errorMessage: '',
            objectiveComments: postData.objectiveComments,
            getErrorMessage: ''
        });
    }
    case DISCARD_UNSAVED_OBJECTIVES: {
        const { savedObjective } = state;

        let {
            selectedApproach,
            selectedObjective,
            objectiveComments
        } = state;

        selectedApproach = '';
        selectedObjective = '';
        objectiveComments = '';

        if (!isObjectEmpty(savedObjective)) {
            selectedApproach = savedObjective.selectedApproach;
            selectedObjective = savedObjective.selectedObjective;
            objectiveComments = savedObjective.objectiveComments;
        }

        return updateObject(state, {
            selectedObjective,
            selectedApproach,
            isObjectiveDetailsUpdated: false,
            objectiveComments
        });
    }
    case CLEAR_ALL_PACKAGE_RELATED_STORES:
        return initialState;
    default:
        return state;
    }
};

export default objectiveAndApproachReducer;
