import { API } from '../../helpers/apiHelpers';

const getDeliverablesService = (campaignId) => {
    if (campaignId) {
        const url = `/campaigns/${campaignId}/deliverables`;
        return API.get(url)
            .then((response) => {
                console.log(response);
                return response;
            }).catch((error) => {
                return Promise.reject(error);
            });
    }
    return null;
};

const deleteDeliverable = async (campaignId, deliverableId) => {
    if (campaignId && deliverableId) {
        const url = `/campaigns/${campaignId}/files/${deliverableId}`;
        try {
            return await API.delete(url);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const postFileUploadDetails = async (campaignId, data) => {
    if (campaignId) {
        const url = `/campaigns/${campaignId}/files`;
        try {
            return await API.post(url, data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

export { getDeliverablesService, deleteDeliverable, postFileUploadDetails };
