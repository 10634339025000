/* eslint-disable no-tabs */
/* eslint-disable indent */
import { API } from '../../helpers/apiHelpers';

const getCompanyService = (companyId) => {
    const url = '/company/' + companyId;
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updateCompanyservice = (formData) => {
    const url = '/company/' + formData.companyId;
    return API.put(url, formData)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getCompanyLocations = (companyId) => {
    const url = `company/${companyId}/locations`;
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const saveCompanyLocationsService = (locations, companyId) => {
    const url = `company/${companyId}/locations`;
    return API.post(url, locations)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updateCompanyDetailsService = (data, companyId) => {
    const url = `company/${companyId}`;
    return API.patch(url, data)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updateCompanyPackageDiscountsService = (data, companyId) => {
    const url = `company/${companyId}/packagecompanydiscounts`;
    return API.post(url, data)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getCompanyPromotions = (companyId) => {
	const url = `companies/${companyId}/promotions`;
	return API.get(url)
		.then((response) => {
			console.log(response);
			return response;
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export {
    getCompanyService,
    updateCompanyservice,
    getCompanyLocations,
    saveCompanyLocationsService,
    updateCompanyDetailsService,
	updateCompanyPackageDiscountsService,
	getCompanyPromotions
};
