import * as actionTypes from './actionTypes';
import { isArrayEmpty, setErrorMessage } from '../../utils/utility_helpers';
import { getDeliverablesService, deleteDeliverable, postFileUploadDetails } from '../services/adminDeliverables';

export const adminDeliverableListStart = () => {
    return {
        type: actionTypes.ADMIN_DELIVERABLE_LIST_START
    };
};

export const adminDeliverableListFail = (errorMessage) => {
    return {
        type: actionTypes.ADMIN_DELIVERABLE_LIST_FAIL,
        errorMessage
    };
};

export const getAdminDeliverableListSuccess = (data) => {
    return {
        type: actionTypes.GET_ADMIN_DELIVERABLE_LIST_SUCCESS,
        data
    };
};

const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            return resolve({
                fileName: file.name,
                base64: reader.result
            });
        };
        reader.onerror = (error) => {
            console.log(error);
            return reject(error);
        };
        console.log(reader);
    });
};

export const getAdminDeliverableList = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: actionTypes.ADMIN_GET_DELIVERABLE_LIST_START
            });
            const response = await getDeliverablesService(campaignId);
            const savedData = {};
            savedData.deliverableList = response.data.data;
            dispatch(getAdminDeliverableListSuccess(savedData));
            console.log(savedData);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(adminDeliverableListFail(errorMessage));
        }
    };
};

export const deleteDeliverables = (campaignId, deliverableId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: actionTypes.CAMPAIGN_DETAILS_START
            });
            const deleteResponse = await deleteDeliverable(campaignId, deliverableId);
            if (deleteResponse.status === 204 || deleteResponse.status === 200) {
                dispatch({ type: actionTypes.ADMIN_DELIVERABLE_DELETE_SUCCESS });
                try {
                    dispatch({
                        type: actionTypes.ADMIN_GET_DELIVERABLE_LIST_START
                    });
                    const response = await getDeliverablesService(campaignId);
                    const savedData = {};
                    savedData.deliverableList = response.data.data;
                    dispatch(getAdminDeliverableListSuccess(savedData));
                    console.log(savedData);
                } catch (error) {
                    const errorMessage = setErrorMessage(error);
                    dispatch(adminDeliverableListFail(errorMessage));
                }
            } else {
                dispatch({ type: actionTypes.ADMIN_DELIVERABLE_DELETE_FAIL });
            }
            dispatch({
                type: actionTypes.CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({ type: actionTypes.ADMIN_DELIVERABLE_DELETE_FAIL, data: errorMessage });
            dispatch({
                type: actionTypes.CAMPAIGN_DETAILS_END
            });
        }
    };
};

export const postDeliverableDetails = (data = {}) => {
    console.log(data);
    return async (dispatch) => {
        try {
            dispatch({
                type: actionTypes.CAMPAIGN_DETAILS_START
            });
            dispatch({
                type: actionTypes.ADMIN_DELIVERABLE_POST_START
            });
            const {
                campaignId,
                url,
                fileType,
                deliverableUploadOptions
            } = data;

            const postData = {};
            postData.fileType = fileType;
            postData.url = url;
            postData.files = null;
            if (deliverableUploadOptions.uploadedFiles
                && !isArrayEmpty(deliverableUploadOptions.uploadedFiles)) {
                await Promise.all(deliverableUploadOptions.uploadedFiles.map(async (item) => {
                    const file = await toBase64(item);
                    console.log(file);
                    const base64Data = file.base64.split(',')[1];
                    const fileData = {};
                    fileData.media = base64Data;
                    fileData.fileName = file.fileName;
                    fileData.mimeType = item.type;
                    fileData.dimension = item.width && item.height ? item.width + 'x' + item.height : '';
                    postData.files = fileData;
                    console.log('PostData', postData);
                }));
            }

            const postResponse = await postFileUploadDetails(campaignId, postData);

            if (postResponse) {
                dispatch({ type: actionTypes.ADMIN_DELIVERABLE_POST_SUCCESS });
                try {
                    dispatch({
                        type: actionTypes.ADMIN_GET_DELIVERABLE_LIST_START
                    });
                    const getResponse = await getDeliverablesService(campaignId);
                    const savedData = {};
                    savedData.deliverableList = getResponse.data.data;
                    dispatch({
                        type: actionTypes.GET_ADMIN_DELIVERABLE_LIST_SUCCESS,
                        data: savedData
                    });
                    console.log(savedData);
                    dispatch({
                        type: actionTypes.CAMPAIGN_DETAILS_END
                    });
                } catch (error) {
                    console.log(setErrorMessage(error));
                    dispatch({ type: actionTypes.ADMIN_DELIVERABLE_LIST_FAIL });
                    dispatch({
                        type: actionTypes.CAMPAIGN_DETAILS_END
                    });
                }
            } else {
                dispatch({
                    type: actionTypes.ADMIN_DELIVERABLE_POST_ERROR_HANDLER,
                    data: setErrorMessage(postResponse)
                });
                dispatch({
                    type: actionTypes.CAMPAIGN_DETAILS_END
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: actionTypes.ADMIN_DELIVERABLE_POST_END
            });
            dispatch({
                type: actionTypes.ADMIN_DELIVERABLE_POST_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
            dispatch({
                type: actionTypes.CAMPAIGN_DETAILS_END
            });
        }
    };
};
