/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import { HashLink } from 'react-router-hash-link';
// import AuthHeader from './AuthHeader';
import SignInLeftSidePanel from '../../common/SignInLeftSidePanel';
import Spinner from '../../common/Loader';
import { loginError } from '../../Constants';
import { login as loginService, logout as logoutService } from '../../../store/actions/auth';
import { isLoggedInIsAdmin, isAuthenticated } from '../../../utils/utility_helpers';

class SignIn extends Component {
    constructor() {
        super();
        this.state = {
            userName: '',
            password: '',
            isKeepMeLoggedIn: false,
            errorMessage: null,
            loading: false,
            redirect: null,
            userNameError: '',
            passwordError: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (
            props.errorMessage !== state.errorMessage
            || props.loading !== state.loading
            || props.redirect !== state.redirect
        ) {
            return {
                errorMessage: props.errorMessage,
                loading: props.loading,
                redirect: props.redirect
            };
        }
        return null;
    }

    componentDidMount() {
        console.log('sign in mount @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        if (!isAuthenticated()) {
            const { onLogout } = this.props;
            onLogout();
        }
    }


    onUserNameChange = (event) => {
        this.setState({
            userName: event.target.value,
            userNameError: ''
        });
    }

    onPasswordChange = (event) => {
        this.setState({
            password: event.target.value,
            passwordError: ''
        });
    }

    onIsKeepMeLoggedInChange = (event) => {
        this.setState({ isKeepMeLoggedIn: event.target.checked });
    }

    onSignIn =(event) => {
        const { login } = this.props;
        const { userName, password, isKeepMeLoggedIn } = this.state;
        event.preventDefault();
        if (!userName) {
            this.setState({
                userNameError: loginError.userNameRequired
            });
        }
        if (!password) {
            this.setState({
                passwordError: loginError.passwordRequired
            });
        }
        if (password && userName) {
            login({ userName, password, isKeepMeLoggedIn });
        }
        console.log(this.props);
    }

    render() {
        const {
            userName,
            password,
            isKeepMeLoggedIn,
            userNameError,
            errorMessage,
            passwordError
        } = this.state;
        const {
            isLoggedIn,
            loading,
            isIntakeDetailsPending,
            numberOfIntakeCompletedCampaigns
        } = this.props;
        let isAdmin = false;
        const isAuthorized = isAuthenticated() && isLoggedIn;
        if (isLoggedIn) {
            const roles = JSON.parse(localStorage.getItem('Roles') || '[]');
            isAdmin = roles.some((x) => { return x.roleName === 'Admin'; });
        }
        let redirectUrl = '/what-to-expect';
        if (numberOfIntakeCompletedCampaigns > 0) {
            redirectUrl = '/customer/campaign-details';
        } else if (isIntakeDetailsPending !== null && numberOfIntakeCompletedCampaigns === 0) {
            redirectUrl = '/packages';
        }

        console.log(isAuthenticated(), isAdmin, 'sign in page');

        return (
            <>
                {/* <AuthHeader /> */}
                <div className="sign-in-flow-main sign-in-flow-new-theme">
                    <div className="container sign-in-flow-wrapper">
                        <div className="row">
                            <h1 className="sign-in-intro-heading">
                                LOGIN.
                            </h1>
                        </div>
                        <div className="row">
                            <SignInLeftSidePanel />
                            <div className="col sign-in-flow-right">
                                <form className="sign-in-form">
                                    <div className="sign-in-wrapper">
                                        {/* <h2 className="sign-in-form-heading">Sign In</h2> */}
                                        {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
                                        <div className="form-group">
                                            <label htmlFor="signinUsername">
                                                Username
                                            </label>
                                            <input
                                                id="signinUsername"
                                                className={`form-control ${
                                                    userNameError
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                type="text"
                                                value={userName}
                                                placeholder="Enter username here"
                                                onChange={(event) => {
                                                    this.onUserNameChange(event);
                                                }}
                                            />
                                            {userNameError && <span className="invalid-feedback d-block">{userNameError}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="signinPassword">
                                                Password
                                            </label>
                                            <input
                                                id="signinPassword"
                                                className={`form-control ${
                                                    passwordError
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                type="password"
                                                value={password}
                                                placeholder="Enter password"
                                                onChange={(event) => {
                                                    this.onPasswordChange(event);
                                                }}
                                            />
                                        </div>
                                        <div className="signed-in-forgot-password-wrapper">
                                            <div className="custom-control custom-checkbox signed-in-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="signedIn"
                                                    checked={isKeepMeLoggedIn}
                                                    onChange={(event) => {
                                                        this.onIsKeepMeLoggedInChange(event);
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="signedIn">Keep me signed in</label>
                                            </div>
                                            {passwordError && <span className="invalid-feedback d-block">{passwordError}</span>}
                                            <p className="forgot-password-text">
                                                <a href="/forgot-password"> Forgot your password? </a>
                                            </p>
                                        </div>
                                        <button
                                            className="primary-btn-royal-blue sign-in-btn"
                                            type="submit"
                                            onClick={(event) => { this.onSignIn(event); }}
                                            aria-label="sign in"
                                        >
                                            sign in
                                        </button>
                                        <p className="more-info-text">
                                            Don’t have an account?
                                            {' '}
                                            <a href="https://mediaagent.wpengine.com/get-in-touch/">
                                                Request more information.
                                            </a>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    { isAuthorized ? isAdmin
                        ? (
                            <>
                                {isLoggedInIsAdmin()
                                && document.body.classList.add('admin')}
                                {document.body.classList.remove('customer')}

                                {isLoggedInIsAdmin() && <Redirect to="/admin/list-customers" />}
                            </>
                        )
                        : (
                            <>
                                {document.body.classList.remove('admin')}
                                {document.body.classList.add('customer')}
                                <Redirect to={redirectUrl} />
                            </>
                        ) : null}
                    {loading ? <Spinner loaderClass="loader-fullscreen" /> : null}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token !== null,
        loading: state.auth.loading,
        errorMessage: state.auth.errorMessage,
        isLoggedIn: state.auth.isLoggedIn,
        redirect: state.auth.isLoggedIn,
        isIntakeDetailsPending: state.auth.isIntakeDetailsPending,
        numberOfIntakeCompletedCampaigns: state.auth.numberOfIntakeCompletedCampaigns
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (values) => {
            return dispatch(loginService(values));
        },
        onLogout: () => {
            return dispatch(logoutService());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
