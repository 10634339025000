// #### AUTH ACTION TYPES ####
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_COMPANY_NAME = 'AUTH_COMPANY_NAME';
export const LOGOUT_START = 'LOGOUT_START';

// #### PACKAGE ACTION TYPES ####
export const PACKAGE_START = 'PACKAGE_START';
export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_PACKAGES_FAIL = 'GET_PACKAGES_FAIL';

// ## CAMPAIGN START ##//
export const CAMPAIGN_START = 'CAMPAIGN_START';
export const CAMPAIGN_FAIL = 'START_CAMPAIGN_FAIL';
export const CAMPAIGN_SUCCESS = 'START_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_REDIRECT = 'UPDATE_CAMPAIGN_REDIRECT';
export const GET_CAMAPIGN_SUCCESS = 'GET_CAMAPIGN_SUCCESS';

// #### NAVIGATION #### //
export const UPDATE_NAVIGATION = 'UPDATE_NAVIGATION';

// ## LOCATION START ##//
export const LOCATION_START = 'LOCATION_START';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';
export const LOCATION_FAIL = 'LOCATION_FAIL';
export const IS_SAVED = 'IS_SAVED';
export const LOCATION_FINISHED = 'LOCATION_FINISHED';
export const UPDATE_LOCATION_AND_MEDIA_BUDGET_STATE = 'UPDATE_LOCATION_AND_MEDIA_BUDGET_STATE';
export const SET_LOCATION_INITIAL_STATE = 'SET_LOCATION_INITIAL_STATE';
export const SET_INDIVIDUAL_LOCATION_DETAILS = 'SET_INDIVIDUAL_LOCATION_DETAILS';
export const SET_LOCATION_PERSON_DETAILS = 'SET_LOCATION_PERSON_DETAILS';

// ## PERSON START ##//
export const PERSON_START = 'PERSON_START';
export const PERSON_FAIL = 'PERSON_FAIL';
export const PERSON_SUCCESS = 'PERSON_SUCCESS';
export const PERSON_BY_ENCRYPTED_ID = 'PERSON_BY_ENCRYPTED_ID';

// ## COMPANY START ##//
export const COMPANY_START = 'COMPANY_START';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_FAIL = 'COMPANY_FAIL';
export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';
export const GET_COMPANY_PROMOTIONS_SUCCESS = 'GET_COMPANY_PROMOTIONS_SUCCESS';

// ## MEDIA CHANNELS ##//
export const CONSTRUCT_MEDIA_CHANNEL_OBJECT = 'CONSTRUCT_MEDIA_CHANNEL_OBJECT';
export const MEDIA_CHANNELS_START = 'MEDIA_CHANNELS_START';
export const MEDIA_CHANNELS_SUCCESS = 'MEDIA_CHANNELS_SUCCESS';
export const MEDIA_CHANNELS_FAIL = 'MEDIA_CHANNELS_FAIL';
export const IS_MEDIA_CHANNEL_SAVED = 'IS_MEDIA_CHANNEL_SAVED';
export const UPDATE_LOCAL_STORE_STATE = 'UPDATE_LOCAL_STORE_STATE';
export const SET_MEDIA_MIX_INITIAL_STATE = 'SET_MEDIA_MIX_INITIAL_STATE';
export const MEDIA_UNSAVED_CHANGES = 'MEDIA_UNSAVED_CHANGES';

// #### CLEAR_ALL_PACKAGE_STORES #### //
export const CLEAR_ALL_PACKAGE_RELATED_STORES = 'CLEAR_ALL_PACKAGE_RELATED_STORES';

// #### CAMPAIGN DATES #### //
export const CAMPAIGN_DATE_CHANGES = 'CAMPAIGN_DATE_CHANGES';
export const CAMPAIGN_DETAIL_UPDATED = 'CAMPAIGN_DETAIL_UPDATED';
export const CAMPAIGN_ERROR_HANDLER = 'CAMPAIGN_ERROR_HANDLER';
export const CAMPAIGN_GET_ERROR_HANDLE = 'CAMPAIGN_GET_ERROR_HANDLE';
export const CAMPAIGN_INITIATION = 'CAMPAIGN_INITIATION';
export const CAMPAIGN_OPTION_CHANGE = 'CAMPAIGN_OPTION_CHANGE';
export const CAMPAIGN_SAVE_AND_CONTINUE = 'CAMPAIGN_SAVE_AND_CONTINUE';
export const DISCARD_UNSAVED_CAMPAIGN = 'DISCARD_UNSAVED_CAMPAIGN';
export const INITIAL_CAMPAIGN_DATES = 'INITIAL_CAMPAIGN_DATES';
export const SAVED_CAMPAIGN_DETAILS = 'SAVED_CAMPAIGN_DETAILS';
export const UPDATE_FLIGHTING_SCHEDULE = 'UPDATE_FLIGHTING_SCHEDULE';

// #### OBJECTIVE AND APPROACH #### //
export const DISCARD_UNSAVED_OBJECTIVES = 'DISCARD_UNSAVED_OBJECTIVES';
export const OBJECTIVE_DETAIL_UPDATED = 'OBJECTIVE_DETAIL_UPDATED';
export const OBJECTIVE_ERROR = 'OBJECTIVE_ERROR';
export const OBJECTIVE_GET_ERROR = 'OBJECTIVE_GET_ERROR';
export const OBJECTIVE_INITIATION = 'OBJECTIVE_INITIATION';
export const OBJECTIVE_OPTIONS = 'OBJECTIVE_OPTIONS';
export const OBJECTIVE_SAVE_AND_CONTINUE = 'OBJECTIVE_SAVE_AND_CONTINUE';
export const UPDATE_SELECTED_OBJECTIVE = 'UPDATE_SELECTED_OBJECTIVE';

// #### REVIEW AND SUBMIT #### //
export const REVIEW_INITIATION = 'REVIEW_INITIATION';
export const REVIEW_SUCCESS_DETAILS = 'REVIEW_SUCCESS_DETAILS';
export const REVIEW_ERROR_HANDLER = 'REVIEW_ERROR_HANDLER';
export const REVIEW_FORM_SUBMITTED = 'REVIEW_FORM_SUBMITTED';
export const UPDATE_PACKAGE_SUBMISSION = 'UPDATE_PACKAGE_SUBMISSION';
export const POST_REVIEW_INITIATIONS = 'POST_REVIEW_INITIATIONS';
export const SHOW_DISCOUNT_ALERT = 'SHOW_DISCOUNT_ALERT';

// #### AUDIENCE AND DEMOGRAPHICS #### //
export const DISCARD_UNSAVED_AUDIENCES = 'DISCARD_UNSAVED_AUDIENCES';
export const AUDIENCE_START = 'AUDIENCE_START';
export const AUDIENCE_FAIL = 'AUDIENCE_FAIL';
export const AUDIENCE_POST_SUCCESS = 'AUDIENCE_POST_SUCCESS';
export const GET_DEMOGRAPHICS_SUCCESS = 'GET_DEMOGRAPHICS_SUCCESS';
export const AUDIENCE_DETAILS_UPDATED = 'AUDIENCE_DETAILS_UPDATED';

// #### MODAL MARKETING #### //
export const MARKET_START = 'MARKET_START';
export const MARKET_SUCCESS = 'MARKET_SUCCESS';
export const MARKET_FAIL = 'MARKET_FAIL';
export const DELETE_MARKETING_MODEL = 'DELETE_MARKETING_MODEL';
export const MARKETING_MODEL_DISCOUNT_START = 'MARKETING_MODEL_DISCOUNT_START';
export const MARKETING_MODEL_DISCOUNT_END = 'MARKETING_MODEL_DISCOUNT_END';


// #### BUSINESS PROFILE #### //
export const BUSINESS_PROFILE_START = 'BUSINESS_PROFILE_START';
export const BUSINESS_PROFILE_SUCCESS = 'BUSINESS_PROFILE_SUCCESS';
export const BUSINESS_PROFILE_FAIL = 'BUSINESS_PROFILE_FAIL';
export const UPDATE_BUSINESS_PROFILE_SUCCESS = 'UPDATE_BUSINESS_PROFILE_SUCCESS';
export const BUSINESS_PROFILE_IS_SAVED = 'BUSINESS_PROFILE_IS_SAVED';

// #### ADMIN CUSTOMER LIST #### //
export const ADMIN_CUSTOMER_LIST_START = 'ADMIN_CUSTOMER_LIST_START';
export const ADMIN_CUSTOMER_LIST_FAIL = 'ADMIN_CUSTOMER_LIST_FAIL';
export const GET_ADMIN_CUSTOMER_LIST_SUCCESS = 'GET_ADMIN_CUSTOMER_LIST_SUCCESS';
export const GET_ALL_ACTION_ITEMS_START = 'GET_ALL_ACTION_ITEMS_START';
export const GET_ALL_ACTION_ITEMS_SUCCESS = 'GET_ALL_ACTION_ITEMS_SUCCESS';
export const GET_ALL_ACTION_ITEMS_FAIL = 'GET_ALL_ACTION_ITEMS_FAIL';

// #### ADMIN CAMPAIGN DETAILS #### //
export const CAMPAIGN_DETAILS_START = 'CAMPAIGN_DETAILS_START';
export const GET_ADMIN_CAMPAIGN_DETAILS_SUCCESS = 'GET_ADMIN_CAMPAIGN_DETAILS_SUCCESS';
export const GET_ADMIN_CAMPAIGN_DETAILS_FAIL = 'GET_ADMIN_CAMPAIGN_DETAILS_FAIL';
export const GET_CAMPAIGN_REWARDS_SUCCESS = 'GET_CAMPAIGN_REWARDS_SUCCESS';
export const STORE_CAMPAIGN_ID = 'STORE_CAMPAIGN_ID';
export const CAMPAIGN_DETAILS_END = 'CAMPAIGN_DETAILS_END';
export const GET_CAMPAIGN_TASKS_SUCCESS = 'GET_CAMPAIGN_TASKS_SUCCESS';
export const CAMPAIGN_DOCUMENTS_FAIL = 'CAMPAIGN_DOCUMENTS_FAIL';
export const GET_CAMPAIGN_TASKS_FAIL = 'GET_CAMPAIGN_TASKS_FAIL';
export const GET_CAMPAIGN_DOCUMENTS_SUCCESS = 'GET_CAMPAIGN_DOCUMENTS_SUCCESS';
export const DELETE_CAMPAIGN_DOCUMENT_SUCCESS = 'DELETE_CAMPAIGN_DOCUMENT_SUCCESS';
export const CAMPAIGN_REWARDS_FAIL = 'CAMPAIGN_REWARDS_FAIL';
export const SHOW_ADD_SUBTRACT_REWARD_POINTS = 'SHOW_ADD_SUBTRACT_REWARD_POINTS';
export const SHOW_UPDATE_TASK_DETAILS_POPUP = 'SHOW_UPDATE_TASK_DETAILS_POPUP';
export const UPDATE_REWARD_POINT_SUCCESS = 'UPDATE_REWARD_POINT_SUCCESS';
export const UPDATE_CAMPAIGN_REWARDS_FAIL = 'UPDATE_CAMPAIGN_REWARDS_FAIL';
export const HANDLE_ADD_NEW_TASK_POPUP = 'HANDLE_ADD_NEW_TASK_POPUP';
export const NEW_TASK_ERROR_HANDLER = 'NEW_TASK_ERROR_HANDLER';
export const UPDATE_TASK_DETAILS_SUCCESS = 'UPDATE_TASK_DETAILS_SUCCESS';
export const UPDATE_TASK_DETAILS_ERROR = 'UPDATE_TASK_DETAILS_ERROR';
export const UPDATE_CAMPAIGN_DATES = 'UPDATE_CAMPAIGN_DATES';
export const UPDATE_CAMPAIGN_DATES_ERROR = 'UPDATE_CAMPAIGN_DATES_ERROR';
export const FETCH_CAMPAIGN_DETAILS_START = 'FETCH_CAMPAIGN_DETAILS_START';
export const FETCH_CAMPAIGN_DETAILS_END = 'FETCH_CAMPAIGN_DETAILS_END';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const ADMIN_CLEAR_ALL_CAMPAIGN_STORE = 'ADMIN_CLEAR_ALL_CAMPAIGN_STORE';

// #### ADMIN CUSTOMER DETAILS #### //
export const ADMIN_CUSTOMER_DETAILS_START = 'ADMIN_CUSTOMER_DETAILS_START';
export const ADMIN_CUSTOMER_DETAILS_FAIL = 'ADMIN_CUSTOMER_DETAILS_FAIL';
export const GET_ADMIN_CUSTOMER_DETAILS_SUCCESS = 'GET_ADMIN_CUSTOMER_DETAILS_SUCCESS';
export const UPDATE_COMPANY_PROFILE_IS_SAVED = 'UPDATE_COMPANY_PROFILE_IS_SAVED';
export const ADMIN_CUSTOMER_DETAILS_SUCCESS = 'ADMIN_CUSTOMER_DETAILS_SUCCESS';
export const SAVE_ADMIN_LOCATION_SUCCESS = 'SAVE_ADMIN_LOCATION_SUCCESS';
export const UPDATE_COMPANY_PROFILE_SUCCESS = 'UPDATE_COMPANY_PROFILE_SUCCESS';
export const UPDATE_IS_COMPANY_EDITABLE = 'UPDATE_IS_COMPANY_EDITABLE';
export const UPDATE_IS_LOCATION_EDITABLE = 'UPDATE_IS_LOCATION_EDITABLE';
export const MOVE_TO_ARCHIVE_SUCCESS = 'MOVE_TO_ARCHIVE_SUCCESS';
export const UPDATE_IS_MOVED_TO_ARCHIVE_STATUS = 'UPDATE_IS_MOVED_TO_ARCHIVE_STATUS';
export const UPDATE_IS_ACCOUNT_SETTINGS_EDITABLE = 'UPDATE_IS_ACCOUNT_SETTINGS_EDITABLE';
export const SAVE_ACCOUNT_SETTINGS_SUCCESS = 'SAVE_ACCOUNT_SETTINGS_SUCCESS';
export const CUSTOMER_REWARD_NOTIFICATION = 'CUSTOMER_REWARD_NOTIFICATION';
export const UPDATE_COMPANY_PROFILE_INITIAL_STATE = 'UPDATE_COMPANY_PROFILE_INITIAL_STATE';

// #### ADMIN MESSAGE LIST #### //
export const ADMIN_GET_MESSAGE_LIST_START = 'ADMIN_GET_MESSAGE_LIST_START';
export const ADMIN_MESSAGE_LIST_FAIL = 'ADMIN_MESSAGE_LIST_FAIL';
export const GET_ADMIN_MESSAGE_LIST_SUCCESS = 'GET_ADMIN_MESSAGE_LIST_SUCCESS';
export const ADMIN_SHOW_COMPOSE_MESSAGE = 'ADMIN_SHOW_COMPOSE_MESSAGE';
export const ADMIN_MESSAGE_ERROR_HANDLER = 'ADMIN_MESSAGE_ERROR_HANDLER';
export const ADMIN_SHOW_VIEW_MESSAGE = 'ADMIN_SHOW_VIEW_MESSAGE';

// #### ADMIN DELIVERABLE LIST #### //
export const ADMIN_GET_DELIVERABLE_LIST_START = 'ADMIN_GET_DELIVERABLE_LIST_START';
export const ADMIN_DELIVERABLE_LIST_START = 'ADMIN_DELIVERABLE_LIST_START';
export const ADMIN_DELIVERABLE_LIST_FAIL = 'ADMIN_DELIVERABLE_LIST_FAIL';
export const GET_ADMIN_DELIVERABLE_LIST_SUCCESS = 'GET_ADMIN_DELIVERABLE_LIST_SUCCESS';
export const ADMIN_DELIVERABLE_DELETE_START = 'ADMIN_DELIVERABLE_DELETE_START';
export const ADMIN_DELIVERABLE_DELETE_FAIL = 'ADMIN_DELIVERABLE_DELETE_FAIL';
export const ADMIN_DELIVERABLE_DELETE_SUCCESS = 'ADMIN_DELIVERABLE_DELETE_SUCCESS';

export const ADMIN_DELIVERABLE_POST_START = 'ADMIN_DELIVERABLE_POST_START';
export const ADMIN_DELIVERABLE_POST_FAIL = 'ADMIN_DELIVERABLE_POST_FAIL';
export const ADMIN_DELIVERABLE_POST_SUCCESS = 'ADMIN_DELIVERABLE_POST_SUCCESS';
export const ADMIN_DELIVERABLE_POST_END = 'ADMIN_DELIVERABLE_POST_END';
export const ADMIN_DELIVERABLE_POST_ERROR_HANDLER = 'ADMIN_DELIVERABLE_POST_ERROR_HANDLER';

// #### ADMIN FLIGHTING TABLE #### //
export const ADMIN_GET_FLIGHTING_START = 'ADMIN_GET_FLIGHTING_START';
export const GET_FLIGHTING_SUCCESS = 'GET_FLIGHTING_SUCCESS';
export const FLIGHTING_DETAILS_ERROR_HANDLER = 'FLIGHTING_DETAILS_ERROR_HANDLER';
export const UPDATE_FLIGHTING_STATUS = 'UPDATE_FLIGHTING_STATUS';
export const POST_PROMOTION_DETAILS = 'POST_PROMOTION_DETAILS';
export const PROMOTION_MODAL_HANDLER = 'PROMOTION_MODAL_HANDLER';
export const PROMOTION_ERROR_HANDLER = 'PROMOTION_ERROR_HANDLER';
export const LOCATION_MODAL_HANDLER = 'LOCATION_MODAL_HANDLER';
export const POST_LOCATION_DETAILS = 'POST_LOCATION_DETAILS';
export const LOCATIONS_ERROR_HANDLER = 'LOCATIONS_ERROR_HANDLER';
export const GET_LOCATION_LIST_SUCCESS = 'GET_LOCATION_LIST_SUCCESS';
export const GET_LOCATION_LIST_ERROR_HANDLER = 'GET_LOCATION_LIST_ERROR_HANDLER';
export const GET_CHANNEL_LIST_SUCCESS = 'GET_CHANNEL_LIST_SUCCESS';
export const UPDATE_FLIGHTING_STATUS_ERROR = 'UPDATE_FLIGHTING_STATUS_ERROR';
export const POST_CREATIVE_DETAILS = 'POST_CREATIVE_DETAILS';
export const GET_CREATIVE_DETAILS = 'GET_CREATIVE_DETAILS';
export const UPDATE_CREATIVE_UPLOAD_LIST = 'UPDATE_CREATIVE_UPLOAD_LIST';
export const CREATIVE_UPLOAD_ERROR_HANDLER = 'CREATIVE_UPLOAD_ERROR_HANDLER';
export const DELETE_CREATIVE_DETAILS = 'DELETE_CREATIVE_DETAILS';
export const CLOSE_CREATIVE_UPLOAD_POPUP = 'CLOSE_CREATIVE_UPLOAD_POPUP';
export const SUBMIT_CREATIVE_DETAILS_FOR_REVIEW = 'SUBMIT_CREATIVE_DETAILS_FOR_REVIEW';
export const HANDLE_WARNING_BOX = 'HANDLE_WARNING_BOX';
export const PROMOTION_LOCATIONS_GROUP = 'PROMOTION_LOCATIONS_GROUP';
export const PROMOTION_LOCATIONS_GROUP_ERROR_HANDLER = 'PROMOTION_LOCATIONS_GROUP_ERROR_HANDLER';
export const GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_START = 'GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_START';
export const GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_COMPLETE = 'GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_COMPLETE';
export const GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_FAIL = 'GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_FAIL';

// #### ADMIN PROMOTION AND CHANNEL LIST #### //
export const ADMIN_PROMOTION_LIST_START = 'ADMIN_PROMOTION_LIST_START';
export const ADMIN_PROMOTION_LIST_FAIL = 'ADMIN_PROMOTION_LIST_FAIL';
export const ADMIN_PROMOTION_LIST_SUCCESS = 'ADMIN_PROMOTION_LIST_SUCCESS';
export const ADMIN_CHANNEL_LIST_START = 'ADMIN_CHANNEL_LIST_START';
export const ADMIN_CHANNEL_LIST_FAIL = 'ADMIN_CHANNEL_LIST_FAIL';
export const ADMIN_CHANNEL_LIST_SUCCESS = 'ADMIN_CHANNEL_LIST_SUCCESS';
export const ADMIN_CREATIVE_LIST_START = 'ADMIN_CREATIVE_LIST_START';
export const ADMIN_CREATIVE_LIST_FAIL = 'ADMIN_CREATIVE_LIST_FAIL';
export const ADMIN_CREATIVE_LIST_SUCCESS = 'ADMIN_CREATIVE_LIST_SUCCESS';

export const ADMIN_DOCUMENT_POST_START = 'ADMIN_DOCUMENT_POST_START';
export const ADMIN_DOCUMENT_POST_FAIL = 'ADMIN_DOCUMENT_POST_FAIL';
export const ADMIN_DOCUMENT_POST_SUCCESS = 'ADMIN_DOCUMENT_POST_SUCCESS';
export const ADMIN_DOCUMENT_POST_END = 'ADMIN_DOCUMENT_POST_END';
export const ADMIN_DOCUMENT_POST_ERROR_HANDLER = 'ADMIN_DOCUMENT_POST_ERROR_HANDLER';

// #### CUSTOMER CAMPAIGN DETAILS #### //
export const CUSTOMER_CAMPAIGN_START = 'CUSTOMER_CAMPAIGN_START';
export const CUSTOMER_CAMPAIGN_END = 'CUSTOMER_CAMPAIGN_END';
export const CHANGE_IS_NEW_CAMPAIGN_FLAG = 'CHANGE_IS_NEW_CAMPAIGN_FLAG';
export const GET_CUSTOMER_COMPANY_LOCATION_SUCCESS = 'GET_CUSTOMER_COMPANY_LOCATION_SUCCESS';
export const GET_CUSTOMER_CAMPAIGN_LIST = 'GET_CUSTOMER_CAMPAIGN_LIST';
export const GET_CUSTOMER_CAMPAIGN_LIST_ERROR = 'GET_CUSTOMER_CAMPAIGN_LIST_ERROR';
export const CUSTOMER_CAMPAIGN_DATA = 'CUSTOMER_CAMPAIGN_DATA';
export const GET_CAMPAIGN_TOTAL_REWARD_POINTS_SUCCESS = 'GET_CAMPAIGN_TOTAL_REWARD_POINTS_SUCCESS';
export const GET_CUSTOMER_CAMPAIGN_MESSAGE_SUCCESS = 'GET_CUSTOMER_CAMPAIGN_MESSAGE_SUCCESS';
export const GET_CUSTOMER_CAMPAIGN_MESSAGE_FAIL = 'GET_CUSTOMER_CAMPAIGN_MESSAGE_FAIL';
export const CUSTOMER_UPDATE_MESSAGE_FAIL = 'CUSTOMER_UPDATE_MESSAGE_FAIL';
export const CUSTOMER_GET_DELIVERABLE_START = 'CUSTOMER_GET_DELIVERABLE_START';
export const CUSTOMER_DELIVERABLES_LIST_SUCCESS = 'CUSTOMER_DELIVERABLES_LIST_SUCCESS';
export const CUSTOMER_DELIVERABLE_LIST_ERROR = 'CUSTOMER_DELIVERABLE_LIST_ERROR';
export const CUSTOMER_GET_CAMPAIGN_TASKS_START = 'CUSTOMER_GET_CAMPAIGN_TASKS_START';
export const CUSTOMER_GET_CAMPAIGN_TASKS_SUCCESS = 'CUSTOMER_GET_CAMPAIGN_TASKS_SUCCESS';
export const CUSTOMER_GET_CAMPAIGN_TASKS_FAIL = 'CUSTOMER_GET_CAMPAIGN_TASKS_FAIL';
export const CUSTOMER_SHOW_UPDATE_TASK_DETAILS_POPUP = 'CUSTOMER_SHOW_UPDATE_TASK_DETAILS_POPUP';
export const CUSTOMER_UPDATE_TASK_DETAILS_SUCCESS = 'CUSTOMER_UPDATE_TASK_DETAILS_SUCCESS';
export const CUSTOMER_UPDATE_TASK_DETAILS_ERROR = 'CUSTOMER_UPDATE_TASK_DETAILS_ERROR';
export const CUSTOMER_CAMPAIGN_DOCUMENT_SUCCESS = 'CUSTOMER_CAMPAIGN_DOCUMENT_SUCCESS';
export const CUSTOMER_CAMPAIGN_DOCUMENT_FAIL = 'CUSTOMER_CAMPAIGN_DOCUMENT_FAIL';
export const CUSTOMER_GET_CALL_TO_ACTION_ITEMS = 'CUSTOMER_GET_CALL_TO_ACTION_ITEMS';
export const CUSTOMER_SHOW_COMPOSE_MESSAGE = 'CUSTOMER_SHOW_COMPOSE_MESSAGE';
export const CUSTOMER_MESSAGE_ERROR_HANDLER = 'CUSTOMER_MESSAGE_ERROR_HANDLER';
export const CUSTOMER_SHOW_VIEW_MESSAGE = 'CUSTOMER_SHOW_VIEW_MESSAGE';
export const CUSTOMER_FETCH_CAMPAIGN_DETAILS_START = 'CUSTOMER_FETCH_CAMPAIGN_DETAILS_START';
export const CUSTOMER_FETCH_CAMPAIGN_DETAILS_END = 'CUSTOMER_FETCH_CAMPAIGN_DETAILS_END';

// #### CUSTOMER FLIGHTING TABLE #### //
export const CUSTOMER_GET_FLIGHTING_START = 'CUSTOMER_GET_FLIGHTING_START';
export const CUSTOMER_GET_FLIGHTING_SUCCESS = 'CUSTOMER_GET_FLIGHTING_SUCCESS';
export const CUSTOMER_FLIGHTING_DETAILS_ERROR_HANDLER = 'CUSTOMER_FLIGHTING_DETAILS_ERROR_HANDLER';
export const CUSTOMER_UPDATE_FLIGHTING_STATUS = 'CUSTOMER_UPDATE_FLIGHTING_STATUS';
export const CUSTOMER_POST_PROMOTION_DETAILS = 'CUSTOMER_POST_PROMOTION_DETAILS';
export const CUSTOMER_PROMOTION_MODAL_HANDLER = 'CUSTOMER_PROMOTION_MODAL_HANDLER';
export const CUSTOMER_PROMOTION_ERROR_HANDLER = 'CUSTOMER_PROMOTION_ERROR_HANDLER';
export const CUSTOMER_LOCATION_MODAL_HANDLER = 'CUSTOMER_LOCATION_MODAL_HANDLER';
export const CUSTOMER_POST_LOCATION_DETAILS = 'CUSTOMER_POST_LOCATION_DETAILS';
export const CUSTOMER_LOCATIONS_ERROR_HANDLER = 'CUSTOMER_LOCATIONS_ERROR_HANDLER';
export const CUSTOMER_GET_LOCATION_LIST_SUCCESS = 'CUSTOMER_GET_LOCATION_LIST_SUCCESS';
export const CUSTOMER_GET_LOCATION_LIST_ERROR_HANDLER = 'CUSTOMER_GET_LOCATION_LIST_ERROR_HANDLER';
export const CUSTOMER_GET_CHANNEL_LIST_SUCCESS = 'CUSTOMER_GET_CHANNEL_LIST_SUCCESS';
export const CUSTOMER_UPDATE_FLIGHTING_STATUS_ERROR = 'CUSTOMER_UPDATE_FLIGHTING_STATUS_ERROR';
export const CUSTOMER_POST_CREATIVE_DETAILS = 'CUSTOMER_POST_CREATIVE_DETAILS';
export const CUSTOMER_GET_CREATIVE_DETAILS = 'CUSTOMER_GET_CREATIVE_DETAILS';
export const CUSTOMER_UPDATE_CREATIVE_UPLOAD_LIST = 'CUSTOMER_UPDATE_CREATIVE_UPLOAD_LIST';
export const CUSTOMER_CREATIVE_UPLOAD_ERROR_HANDLER = 'CUSTOMER_CREATIVE_UPLOAD_ERROR_HANDLER';
export const CUSTOMER_DELETE_CREATIVE_DETAILS = 'CUSTOMER_DELETE_CREATIVE_DETAILS';
export const CUSTOMER_CLOSE_CREATIVE_UPLOAD_POPUP = 'CUSTOMER_CLOSE_CREATIVE_UPLOAD_POPUP';
export const CUSTOMER_SUBMIT_CREATIVE_DETAILS_FOR_REVIEW = 'CUSTOMER_SUBMIT_CREATIVE_DETAILS_FOR_REVIEW';
export const CUSTOMER_HANDLE_WARNING_BOX = 'CUSTOMER_HANDLE_WARNING_BOX';
export const CREATIVE_LIBRARY_POPUP_HANDLER = 'CREATIVE_LIBRARY_POPUP_HANDLER';
export const COPY_CREATIVE_POPUP_HANDLER = 'COPY_CREATIVE_POPUP_HANDLER';

// #### CUSTOMER TASK LIST #### //
export const CUSTOMER_TASK_LIST_START = 'CUSTOMER_TASK_LIST_START';
export const CUSTOMER_TASK_LIST_FAIL = 'CUSTOMER_TASK_LIST_FAIL';
export const GET_CUSTOMER_TASK_LIST_SUCCESS = 'GET_CUSTOMER_TASK_LIST_SUCCESS';


// #### REQUEST_FLIGHTING_CHANGE #### //
export const REQUEST_FLIGHTING_CHANGE_POST_START = 'REQUEST_FLIGHTING_CHANGE_POST_START';
export const REQUEST_FLIGHTING_CHANGE_POST_FAIL = 'REQUEST_FLIGHTING_CHANGE_POST_FAIL';
export const REQUEST_FLIGHTING_CHANGE_POST_SUCCESS = 'REQUEST_FLIGHTING_CHANGE_POST_SUCCESS';
export const REQUEST_FLIGHTING_CHANGE_POST_RESET = 'REQUEST_FLIGHTING_CHANGE_POST_RESET';


// #### CUSTOMER PROFILE #### //
export const CUSTOMER_PROFILE_START = 'CUSTOMER_PROFILE_START';
export const CUSTOMER_PROFILE_FAIL = 'CUSTOMER_PROFILE_FAIL';
export const GET_CUSTOMER_PROFILE_SUCCESS = 'GET_CUSTOMER_PROFILE_SUCCESS';

export const PUT_CUSTOMER_PROFILE_START = 'PUT_CUSTOMER_PROFILE_START';
export const PUT_CUSTOMER_PROFILE_FAIL = 'PUT_CUSTOMER_PROFILE_FAIL';
export const PUT_CUSTOMER_PROFILE_SUCCESS = 'PUT_CUSTOMER_PROFILE_SUCCESS';
export const PUT_CUSTOMER_PROFILE_SUCCESS_RESET = 'PUT_CUSTOMER_PROFILE_SUCCESS_RESET';

export const PUT_CUSTOMER_CREDENTIALS_START = 'PUT_CUSTOMER_CREDENTIALS_START';
export const PUT_CUSTOMER_CREDENTIALS_FAIL = 'PUT_CUSTOMER_CREDENTIALS_FAIL';
export const PUT_CUSTOMER_CREDENTIALS_SUCCESS = 'PUT_CUSTOMER_CREDENTIALS_SUCCESS';
export const PUT_CUSTOMER_CREDENTIALS_SUCCESS_RESET = 'PUT_CUSTOMER_CREDENTIALS_SUCCESS_RESET';

// #### CREATIVE LIBRARY #### //
export const CREATIVE_LIBRARY_START = 'CUSTOMER_PROFILE_START';
export const CREATIVE_LIBRARY_FAIL = 'CREATIVE_LIBRARY_FAIL';

export const GET_CREATIVE_LIBRARY_SUCCESS = 'GET_CUSTOMER_PROFILE_SUCCESS';
export const GET_CREATIVE_LIBRARY_FILES_SUCCESS = 'GET_CREATIVE_LIBRARY_FILES_SUCCESS';

export const GET_CREATIVE_LIBRARY_FOLDERS_SUCCESS = 'GET_CREATIVE_LIBRARY_FOLDERS_SUCCESS';
export const GET_CREATIVE_LIBRARY_FOLDERS_FAIL = 'GET_CREATIVE_LIBRARY_FOLDERS_FAIL';

export const GET_CREATIVE_LIBRARY_FILE_PREVIEW_SUCCESS = 'GET_CREATIVE_LIBRARY_FILE_PREVIEW_SUCCESS';
export const GET_CREATIVE_LIBRARY_FILE_PREVIEW_FAIL = 'GET_CREATIVE_LIBRARY_FILE_PREVIEW_FAIL';

export const RENAME_CREATIVE_LIBRARY_FILE_NAME_SUCCESS = 'RENAME_CREATIVE_LIBRARY_FILE_NAME_SUCCESS';
export const RENAME_POPUP_CHANGE = 'RENAME_POPUP_CHANGE';
export const DELETE_CREATIVE_LIBRARY_FILE_START = 'DELETE_CREATIVE_LIBRARY_FILE_START';
export const DELETE_CREATIVE_LIBRARY_FILE_SUCCESS = 'DELETE_CREATIVE_LIBRARY_FILE_SUCCESS';
export const DELETE_CREATIVE_LIBRARY_FILE_FAIL = 'DELETE_CREATIVE_LIBRARY_FILE_FAIL';
export const UPDATE_SELECTED_FILE_ID = 'UPDATE_SELECTED_FILE_ID';
export const UPDATE_DELETE_CONFIRMATION_POPUP = 'UPDATE_DELETE_CONFIRMATION_POPUP';
export const UPDATE_DRAG_AND_DROP_POPUP = 'UPDATE_DRAG_AND_DROP_POPUP';
export const UPLOAD_CREATIVE_LIBARARY_FILES_SUCCESS = 'UPLOAD_CREATIVE_LIBARARY_FILES_SUCCESS';
export const UPLOAD_CREATIVE_LIBARARY_FILES_FAIL = 'UPLOAD_CREATIVE_LIBARARY_FILES_FAIL';
export const UPDATE_ADDED_FILES = 'UPDATE_ADDED_FILES';
export const UPLOAD_CREATIVE_LIBRARY_FILE_START = 'UPLOAD_CREATIVE_LIBRARY_FILE_START';
export const UPLOAD_CREATIVE_LIBRARY_FILE_COMPLETE = 'UPLOAD_CREATIVE_LIBRARY_FILE_COMPLETE';
export const UPDATE_FILE_UPLOAD_LIST_POPUP = 'UPDATE_FILE_UPLOAD_LIST_POPUP';
export const CANCEL_FILE_UPLOAD = 'CANCEL_FILE_UPLOAD';
export const FILE_UPLOAD_START = 'FILE_UPLOAD_START';
export const DOWNLOAD_CREATIVE_LIBRARY_FILES = 'DOWNLOAD_CREATIVE_LIBRARY_FILES';
export const UPDATE_MULTIPLE_FILES = 'UPDATE_MULTIPLE_FILES';
export const CREATIVE_LIBRARY_MULTI_FILE_DOWNLOAD_FAIL = 'CREATIVE_LIBRARY_MULTI_FILE_DOWNLOAD_FAIL';
export const ADD_NEW_TAG_SUCCESS = 'ADD_NEW_TAG_SUCCESS';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_NAME = 'UPDATE_TAG_NAME';
export const UPDATE_TAG_EDIT_MODE = 'UPDATE_TAG_EDIT_MODE';
export const GET_COMPANY_TAG_SUCCESS = 'GET_COMPANY_TAG_SUCCESS';
export const UPDATE_TAG_OPTIONS = 'UPDATE_TAG_OPTIONS';
export const UPDATE_NEW_TAG_VALUE = 'UPDATE_NEW_TAG_VALUE';
export const DOWNLOAD_CREATIVE_LIBRARY_FILES_SUCCESS = 'DOWNLOAD_CREATIVE_LIBRARY_FILES_SUCCESS';

// ** Campaign Dark day start //
export const UPDATE_SHOW_DARK_DAY = 'UPDATE_SHOW_DARK_DAY';
export const ADD_CAMPIGN_DARK_DAY_START = 'ADD_CAMPIGN_DARK_DAY';
export const ADD_CAMPIGN_DARK_DAY_FAIL = 'ADD_CAMPIGN_DARK_DAY_FAIL';
export const ADD_CAMPIGN_DARK_DAY_SUCCESS = 'ADD_CAMPIGN_DARK_DAY_SUCCESS';
export const UPDATE_CAMPIGN_DARK_DAY_START = 'UPDATE_CAMPIGN_DARK_DAY_START';
export const UPDATE_CAMPIGN_DARK_DAY_SUCCESS = 'UPDATE_CAMPIGN_DARK_DAY_SUCCESS';
export const UPDATE_CAMPIGN_DARK_DAY_FAIL = 'UPDATE_CAMPIGN_DARK_DAY_FAIL';
export const UPDATE_SHOW_EDIT_DARK_DAY = 'UPDATE_SHOW_EDIT_DARK_DAY';

// Request revision start //
export const REQUEST_REVISION_START = 'REQUEST_REVISION_START';
export const REQUEST_REVISION_SUCCESS = 'REQUEST_REVISION_SuCCESS';
export const REQUEST_REVISION_FAIL = 'REQUEST_REVISION_FAIL';
