import { API } from '../../helpers/apiHelpers';

const forgotUsernameService = (formData) => {
    const url = '/notifications/username';
    return API.post(url, formData)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const forgotPasswordService = (formData) => {
    const url = '/notifications/forgot-password';
    return API.post(url, formData)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export { forgotUsernameService, forgotPasswordService };
