/* eslint-disable max-len */
import * as audienceTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    demographicId: 0,
    demographicList: [],
    audienceComment: '',
    errorMessage: null,
    loading: false,
    redirect: false,
    campaignId: null,
    savedAudience: {},
    isAudienceSaved: null
};

const audienceReducer = (state = initialState, action) => {
    switch (action.type) {
    case audienceTypes.AUDIENCE_START:
        return updateObject(state, {
            errorMessage: null,
            loading: true
        });
    case audienceTypes.AUDIENCE_FAIL:
        return updateObject(state, {
            errorMessage: action.errorMessage,
            loading: false
        });
    case audienceTypes.AUDIENCE_POST_SUCCESS:
        return updateObject(state, {
            redirect: true,
            savedAudience: action.data,
            loading: false,
            isAudienceSaved: true
        });
    case audienceTypes.GET_DEMOGRAPHICS_SUCCESS: {
        let isAudienceSaved = !!action.data.gender;
        isAudienceSaved = isAudienceSaved ? true : null;

        return updateObject(state, {
            isAudienceSaved,
            loading: false,
            savedAudience: action.data,
            demographicList: action.data.demographicList
        });
    }

    case audienceTypes.AUDIENCE_DETAILS_UPDATED: {
        let { data } = action;
        if (data === null) {
            data = null;
        } else {
            data = !data;
        }
        return updateObject(state, {
            isAudienceSaved: data
        });
    }

    case audienceTypes.DISCARD_UNSAVED_AUDIENCES: {
        let { isAudienceSaved } = state;

        if (action.data.demographicId) {
            isAudienceSaved = true;
        } else {
            isAudienceSaved = null;
        }
        return updateObject(state, {
            isAudienceSaved
        });
    }
    case audienceTypes.CLEAR_ALL_PACKAGE_RELATED_STORES:
        return initialState;
    default:
        return state;
    }
};

export default audienceReducer;
