import _ from 'lodash';
import {
    CUSTOMER_PROFILE_START,
    CUSTOMER_PROFILE_FAIL,
    GET_CUSTOMER_PROFILE_SUCCESS,
    PUT_CUSTOMER_PROFILE_START,
    PUT_CUSTOMER_PROFILE_FAIL,
    PUT_CUSTOMER_PROFILE_SUCCESS,
    PUT_CUSTOMER_CREDENTIALS_START,
    PUT_CUSTOMER_CREDENTIALS_FAIL,
    PUT_CUSTOMER_CREDENTIALS_SUCCESS,
    PUT_CUSTOMER_PROFILE_SUCCESS_RESET,
    PUT_CUSTOMER_CREDENTIALS_SUCCESS_RESET
} from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';
import { cloneObject } from '../../utils/utility_helpers';

const initialState = {
    customerProfileDetails: null,
    loading: false,
    errorMessage: null,
    credentialsLoading: false,
    credentialsErrorMessage: null,
    profileLoading: false,
    profileErrorMessage: null,
    unChangedCompanyDetails: {
        unChangedCompanyId: null,
        unChangedCompanyName: null,
        unChangedCompanyLegalName: null,
        unChangedPersonDetail: {
            personId: null,
            firstName: null,
            lastName: null,
            userName: null,
            contactNumber: null,
            email: null
        },
        unChangedAccountsPayableName: null,
        unChangedAccountsPayableEmail: null,
        unChangedTaxId: null,
        unChangedTimeZone: null,
        unChangedIsAdmin: false,
        unChangedCustomerSuccessPersonId: null,
        unChangedAdditionalEmail: null,
        unChangedCompanyAddresses: [{
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            isBillingAddress: false,
            isPrimary: false
        }]
    }
};

const customerProfileStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        errorMessage: null
    });
};

const customerProfileFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: action.errorMessage
    });
};

const getCustomerProfileSuccess = (state, action) => {
    const data = {};
    if (action.data) {
        data.unChangedCompanyId = _.cloneDeep(action.data.companyId);
        data.unChangedCompanyName = _.cloneDeep(action.data.companyName);
        data.unChangedCompanyLegalName = _.cloneDeep(action.data.companyLegalName);
        data.unChangedPersonDetail = _.cloneDeep(action.data.personDetail);
        data.unChangedAccountsPayableName = _.cloneDeep(action.data.accountsPayableName);
        data.unChangedAccountsPayableEmail = _.cloneDeep(action.data.accountsPayableEmail);
        data.unChangedTaxId = _.cloneDeep(action.data.taxId);
        data.unChangedTimeZone = _.cloneDeep(action.data.timeZone);
        data.unChangedIsAdmin = _.cloneDeep(action.data.isAdmin);
        data.unChangedCustomerSuccessPersonId = _.cloneDeep(action.data.customerSuccessPersonId);
        data.unChangedAdditionalEmail = _.cloneDeep(action.data.additionalEmail);

        const tempAddresses = [];
        action.data.companyAddresses.map((address, i) => {
            const addressDate = address;
            const clone = cloneObject(addressDate);
            tempAddresses.push(clone);
            return address;
        });

        data.unChangedCompanyAddresses = tempAddresses;
    }

    return updateObject(state, {
        customerProfileDetails: action.data,
        unChangedCompanyDetails: data,
        loading: false,
        errorMessage: null
    });
};

const customerProfileUpdateStart = (state, action) => {
    return updateObject(state, {
        profileLoading: true,
        profileErrorMessage: null
    });
};

const customerProfileUpdateFail = (state, action) => {
    return updateObject(state, {
        profileLoading: false,
        profileErrorMessage: action.data
    });
};

const putCustomerProfileUpdateSuccess = (state, action) => {
    return updateObject(state, {
        profileLoading: false,
        profileErrorMessage: null
    });
};

const putCustomerProfileUpdateSuccessReset = (state, action) => {
    console.log('Action', action);
    return updateObject(state, {
        profileLoading: false,
        profileErrorMessage: null
    });
};


const customerProfileCredentialsUpdateStart = (state, action) => {
    return updateObject(state, {
        credentialsLoading: true,
        credentialsErrorMessage: null
    });
};

const customerProfileCredentialsUpdateFail = (state, action) => {
    console.log(action);
    console.log(action.data);
    return updateObject(state, {
        credentialsLoading: false,
        credentialsErrorMessage: _.cloneDeep(action.data)
    });
};

const putCustomerProfileCredentialsUpdateSuccess = (state, action) => {
    return updateObject(state, {
        credentialsLoading: false,
        credentialsErrorMessage: action.data
    });
};

const putCustomerProfileCredentialsUpdateSuccessReset = (state, action) => {
    return updateObject(state, {
        credentialsLoading: false,
        credentialsErrorMessage: null
    });
};

const customerProfileReducer = (state = initialState, action) => {
    switch (action.type) {
    case CUSTOMER_PROFILE_START:
        return customerProfileStart(state, action);
    case GET_CUSTOMER_PROFILE_SUCCESS:
        return getCustomerProfileSuccess(state, action);
    case CUSTOMER_PROFILE_FAIL:
        return customerProfileFail(state, action);

    case PUT_CUSTOMER_PROFILE_START:
        return customerProfileUpdateStart(state, action);
    case PUT_CUSTOMER_PROFILE_SUCCESS:
        return putCustomerProfileUpdateSuccess(state, action);
    case PUT_CUSTOMER_PROFILE_FAIL:
        return customerProfileUpdateFail(state, action);
    case PUT_CUSTOMER_PROFILE_SUCCESS_RESET:
        return putCustomerProfileUpdateSuccessReset(state, action);

    case PUT_CUSTOMER_CREDENTIALS_START:
        return customerProfileCredentialsUpdateStart(state, action);
    case PUT_CUSTOMER_CREDENTIALS_FAIL:
        return putCustomerProfileCredentialsUpdateSuccess(state, action);
    case PUT_CUSTOMER_CREDENTIALS_SUCCESS:
        return customerProfileCredentialsUpdateFail(state, action);
    case PUT_CUSTOMER_CREDENTIALS_SUCCESS_RESET:
        return putCustomerProfileCredentialsUpdateSuccessReset(state, action);

    default:
        return state;
    }
};

export default customerProfileReducer;
