import {
    AUTH_START,
    AUTH_LOGOUT,
    AUTH_COMPANY_NAME,
    AUTH_SUCCESS,
    AUTH_FAIL,
    LOGOUT_START
} from './actionTypes';

import {
    loginService,
    logoutService,
    resetPassword,
    getCompanyDetailsById,
    resetCredentials
} from '../services/auth';

import {
    setErrorMessage
} from '../../utils/utility_helpers';

const logout = () => {
    if (localStorage.getItem('x-token')) {
        return async (dispatch) => {
            try {
                dispatch({
                    type: LOGOUT_START
                });

                await logoutService();

                localStorage.clear();

                dispatch({
                    type: AUTH_LOGOUT
                });
            } catch (err) {
                localStorage.clear();

                dispatch({
                    type: AUTH_LOGOUT
                });
            }
        };
    }
    localStorage.clear();
    return { type: AUTH_LOGOUT };
};

const login = (formData) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: AUTH_START
            });

            const response = await loginService(formData);

            // store user info in localstorage
            localStorage.setItem('x-token', response.data.data.token);
            localStorage.setItem('UserName', response.data.data.userName);
            localStorage.setItem('PersonId', response.data.data.personId);
            localStorage.setItem('CompanyId', response.data.data.companies && response.data.data.companies[0].companyId);
            localStorage.setItem('CompanyName', response.data.data.companies && response.data.data.companies[0].companyName);
            localStorage.setItem('FirstName', response.data.data.firstName);
            localStorage.setItem('LastName', response.data.data.lastName);
            localStorage.setItem('Email', response.data.data.email);
            localStorage.setItem('Roles', JSON.stringify(response.data.data.roles));
            localStorage.setItem('expirationTime', formData.isKeepMeLoggedIn ? response.data.data.keepMeLoggedInHours : response.data.data.defaultLoggedInHours);
            localStorage.setItem('remainingTime', formData.isKeepMeLoggedIn ? response.data.data.keepMeLoggedInHours : response.data.data.defaultLoggedInHours);
            localStorage.setItem(
                'ContactNumber',
                response.data.data.contactNumber == null ? '' : response.data.data.contactNumber
            );
            localStorage.setItem(
                'IntakeStepId',
                response.data.data.incompleteIntakeDetails != null
                    ? response.data.data.incompleteIntakeDetails.intakeStepId
                    : ''
            );
            localStorage.setItem(
                'PackageId',
                response.data.data.incompleteIntakeDetails != null
                    ? response.data.data.incompleteIntakeDetails.packageId
                    : ''
            );
            localStorage.setItem(
                'CampaignId',
                response.data.data.incompleteIntakeDetails != null
                    ? response.data.data.incompleteIntakeDetails.campaignId
                    : ''
            );
            localStorage.setItem(
                'numberOfIntakeCompletedCampaigns',
                response.data.data.numberOfIntakeCompletedCampaigns
                    ? response.data.data.numberOfIntakeCompletedCampaigns
                    : 0
            );

            dispatch({
                type: AUTH_SUCCESS,
                data: response.data.data
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);

            dispatch({
                type: AUTH_FAIL,
                errorMessage
            });
        }
    };
};

const resetAccountCredentials = (formData) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: AUTH_START
            });

            const response = await resetCredentials(formData);

            if (response.status === 204) {
                const loginData = {
                    userName: formData.userName,
                    password: formData.password
                };

                dispatch(login(loginData));
            }
        } catch (error) {
            const errorMessage = setErrorMessage(error);


            dispatch({
                type: AUTH_FAIL,
                errorMessage
            });
        }
    };
};

const getCompanyInfoById = (companyId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: AUTH_START
            });

            const response = await getCompanyDetailsById(companyId);

            if (response.data.resultCount > 0) {
                dispatch({
                    type: AUTH_COMPANY_NAME,
                    companyLegalName: response.data.data.companyLegalName
                });
            }
        } catch (error) {
            const errorMessage = setErrorMessage(error);


            dispatch({
                type: AUTH_FAIL,
                errorMessage
            });
        }
    };
};

const userResetPassword = (formData = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: AUTH_START
            });

            const data = {
                userName: formData.userName,
                tempPassword: formData.temporaryPassword,
                password: formData.newPassword
            };

            const response = await resetPassword(data);

            if (response.status === 204) {
                const loginData = {
                    userName: formData.userName,
                    password: formData.newPassword,
                    isKeepMeLoggedIn: formData.keepMeLoggedIn
                };
                dispatch(login(loginData));
            }
        } catch (error) {
            const errorMessage = setErrorMessage(error);


            dispatch({
                type: AUTH_FAIL,
                errorMessage
            });
        }
    };
};

export {
    login,
    logout,
    userResetPassword,
    getCompanyInfoById,
    resetAccountCredentials
};
