import {
    CUSTOMER_PROFILE_START,
    CUSTOMER_PROFILE_FAIL,
    GET_CUSTOMER_PROFILE_SUCCESS,
    PUT_CUSTOMER_PROFILE_START,
    PUT_CUSTOMER_PROFILE_FAIL,
    PUT_CUSTOMER_PROFILE_SUCCESS,
    PUT_CUSTOMER_CREDENTIALS_START,
    PUT_CUSTOMER_CREDENTIALS_FAIL,
    PUT_CUSTOMER_CREDENTIALS_SUCCESS,
    PUT_CUSTOMER_PROFILE_SUCCESS_RESET,
    PUT_CUSTOMER_CREDENTIALS_SUCCESS_RESET
} from './actionTypes';
import { setErrorMessage } from '../../utils/utility_helpers';
import {
    getCustomerProfileDetails,
    updateBusinessProfileService,
    updateCredentials
} from '../services/customerProfile';
// import { updateObject } from '../../utils/reducer_utility';

const bindCustomerData = (data) => {
    const resultData = {
        companyId: null,
        companyName: null,
        companyLegalName: null,
        personDetail: {
            personId: null,
            firstName: null,
            lastName: null,
            userName: null,
            contactNumber: null,
            email: null
        },
        accountsPayableName: null,
        accountsPayableEmail: null,
        taxId: null,
        timeZone: null,
        isAdmin: false,
        customerSuccessPersonId: null,
        additionalEmail: null,
        companyAddresses: [{
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            isBillingAddress: false,
            isPrimary: false
        }]
    };

    const tempCompanyAddresses = [];

    resultData.companyId = data.companyId;
    resultData.companyName = data.companyName;
    resultData.companyLegalName = data.companyLegalName;
    resultData.personDetail = data.personDetail;
    resultData.accountsPayableName = data.accountsPayableName;
    resultData.accountsPayableEmail = data.accountsPayableEmail;
    resultData.taxId = data.taxId;
    resultData.timeZone = data.timeZone;
    resultData.isAdmin = data.isAdmin;
    resultData.customerSuccessPersonId = data.customerSuccessPersonId;
    resultData.additionalEmail = data.additionalEmail;

    const billingAddress = data
    && data.companyAddresses
    && data.companyAddresses
        .filter((x) => { return x.isPrimary === true; });
    console.log('billingAddress :', billingAddress);
    if (billingAddress && billingAddress.length > 0) {
        tempCompanyAddresses.push(billingAddress[0]);
    } else {
        const primaryCompanyAddresses = {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            isBillingAddress: false,
            isPrimary: false
        };
        primaryCompanyAddresses.address1 = '';
        primaryCompanyAddresses.address2 = '';
        primaryCompanyAddresses.city = '';
        primaryCompanyAddresses.state = '';
        primaryCompanyAddresses.zipCode = '';
        primaryCompanyAddresses.isBillingAddress = true;
        primaryCompanyAddresses.isPrimary = true;

        tempCompanyAddresses.push(primaryCompanyAddresses);
    }

    const mailingAddress = data
        && data.companyAddresses
         && data.companyAddresses
             .filter((x) => { return x.isPrimary === false; });
    console.log('mailingAddress :', mailingAddress);
    if (mailingAddress && mailingAddress.length > 0) {
        tempCompanyAddresses.push(mailingAddress[0]);
    } else {
        const mailingCompanyAddresses = {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            isBillingAddress: false,
            isPrimary: false
        };
        mailingCompanyAddresses.address1 = '';
        mailingCompanyAddresses.address2 = '';
        mailingCompanyAddresses.city = '';
        mailingCompanyAddresses.state = '';
        mailingCompanyAddresses.zipCode = '';
        mailingCompanyAddresses.isBillingAddress = false;
        mailingCompanyAddresses.isPrimary = false;
        tempCompanyAddresses.push(mailingCompanyAddresses);
    }

    console.log('TempCompanyAddresses :', tempCompanyAddresses);
    resultData.companyAddresses = tempCompanyAddresses;

    return resultData;
};

// const customerProfileCredentialsUpdateFail = (state, action) => {
//     console.log(action);
//     console.log(action.data);
//     return updateObject(state, {
//         credentialsLoading: false,
//         credentialsErrorMessage: action.data
//     });
// };

export const getCustomerProfile = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: CUSTOMER_PROFILE_START });
            const response = await getCustomerProfileDetails();
            console.log('Profile response', response);

            if (response.status === 200) {
                const bindData = bindCustomerData(response.data.data);
                dispatch({
                    type: GET_CUSTOMER_PROFILE_SUCCESS,
                    data: bindData
                });
                console.log(bindData);
            } else {
                const errorMessage = setErrorMessage(response);
                dispatch({ type: CUSTOMER_PROFILE_FAIL, data: errorMessage });
            }
        } catch (error) {
            console.log('Profile error', error);
            const errorMessage = setErrorMessage(error);
            dispatch({ type: CUSTOMER_PROFILE_FAIL, data: errorMessage });
        }
    };
};

export const updateCompanyProfile = (profileDetails) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PUT_CUSTOMER_PROFILE_START });
            const response = await updateBusinessProfileService(
                profileDetails.companyObject
            );
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                dispatch(getCustomerProfile());
                dispatch({ type: PUT_CUSTOMER_PROFILE_SUCCESS, data: response.data.message });
                console.log(response);
            } else {
                const errorMessage = setErrorMessage(response);
                dispatch({ type: PUT_CUSTOMER_PROFILE_FAIL, data: errorMessage });
            }
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({ type: PUT_CUSTOMER_PROFILE_FAIL, data: errorMessage });
        }
    };
};

export const updateCustomerCredentials = (credentialObject, personId, option) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PUT_CUSTOMER_CREDENTIALS_START });
            const response = await updateCredentials(credentialObject, personId, option);
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                dispatch(getCustomerProfile());
                dispatch({ type: PUT_CUSTOMER_CREDENTIALS_SUCCESS, data: response.data.message });
                console.log(response);
            } else {
                const errorMessage = setErrorMessage(response);
                // dispatch(customerProfileCredentialsUpdateFail(
                //     { type: PUT_CUSTOMER_CREDENTIALS_FAIL, data: errorMessage }
                // ));
                dispatch({ type: PUT_CUSTOMER_CREDENTIALS_FAIL, data: errorMessage });
            }
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({ type: PUT_CUSTOMER_CREDENTIALS_FAIL, data: errorMessage });
        }
    };
};

export const updateCompanyProfileReset = () => {
    return async (dispatch) => {
        dispatch({ type: PUT_CUSTOMER_PROFILE_SUCCESS_RESET, data: null });
    };
};

export const updateCustomerCredentialsReset = () => {
    return async (dispatch) => {
        dispatch({ type: PUT_CUSTOMER_CREDENTIALS_SUCCESS_RESET, data: true });
    };
};
