import { updateObject } from '../../utils/reducer_utility';

import {
    CLEAR_ALL_PACKAGE_RELATED_STORES,
    CAMPAIGN_DATE_CHANGES,
    CAMPAIGN_DETAIL_UPDATED,
    CAMPAIGN_ERROR_HANDLER,
    CAMPAIGN_GET_ERROR_HANDLE,
    CAMPAIGN_INITIATION,
    CAMPAIGN_OPTION_CHANGE,
    CAMPAIGN_SAVE_AND_CONTINUE,
    DISCARD_UNSAVED_CAMPAIGN,
    INITIAL_CAMPAIGN_DATES,
    SAVED_CAMPAIGN_DETAILS,
    UPDATE_FLIGHTING_SCHEDULE
} from '../actions/actionTypes';

const initialState = {
    loading: false,
    startDate: null,
    endDate: null,
    selectedOption: '',
    totalWeeks: [],
    campaignComments: '',
    isCampaignDetailsUpdated: false,
    minStartDate: null,
    maxStartDate: null,
    minEndDate: null,
    maxEndDate: null,
    optionList: [],
    errorMessage: '',
    savedCampaign: {},
    isFormSubmitted: false,
    getErrorMessage: '',
    packageInfo: {},
    isDetailsFetched: false,
    isValidStartDateOverride: false
};

const updateCampaignDates = (state, action) => {
    const {
        endDate,
        minEndDate,
        maxEndDate,
        optionList,
        selectedOption,
        startDate,
        startType,
        totalWeeks,
        type
    } = action.data;

    if (type === 'startDate' && startType === 'start') {
        return updateObject(state, {
            isCampaignDetailsUpdated: true,
            endDate,
            minEndDate,
            maxEndDate,
            optionList,
            selectedOption,
            startDate,
            totalWeeks
        });
    }

    if (type === 'startDate' && startType === 'end') {
        return updateObject(state, {
            startDate,
            minEndDate,
            totalWeeks,
            optionList,
            selectedOption,
            isCampaignDetailsUpdated: true,
            maxEndDate
        });
    }

    return updateObject(state, {
        endDate,
        totalWeeks,
        optionList,
        selectedOption,
        isCampaignDetailsUpdated: true
    });
};

const updateFlightingSchedule = (state, action) => {
    const {
        weekIndex,
        flightingOption,
        flightingStatusId
    } = action.data;

    const totalWeeks = [...state.totalWeeks];
    totalWeeks[weekIndex].currentState = flightingOption;
    totalWeeks[weekIndex].flightingStatusId = flightingStatusId;

    return updateObject(state, {
        totalWeeks,
        isCampaignDetailsUpdated: true
    });
};

const campaignDatesReducer = (state = initialState, action) => {
    switch (action.type) {
    case CAMPAIGN_INITIATION:
        return updateObject(state, { loading: true });
    case CAMPAIGN_DATE_CHANGES:
        return updateCampaignDates(state, action);
    case UPDATE_FLIGHTING_SCHEDULE:
        return updateFlightingSchedule(state, action);
    case CAMPAIGN_ERROR_HANDLER:
        return updateObject(state, {
            loading: false,
            errorMessage: action.data,
            isDetailsFetched: false
        });
    case CAMPAIGN_GET_ERROR_HANDLE:
        return updateObject(state, {
            loading: false,
            getErrorMessage: action.data,
            isDetailsFetched: false
        });
    case INITIAL_CAMPAIGN_DATES: {
        const {
            endDate,
            maxEndDate,
            minEndDate,
            maxStartDate,
            minStartDate,
            startDate,
            optionList,
            packageInfo,
            isValidStartDateOverride
        } = action.data;

        return updateObject(state, {
            startDate,
            endDate,
            minEndDate,
            maxEndDate,
            maxStartDate,
            minStartDate,
            optionList,
            loading: false,
            packageInfo,
            isDetailsFetched: true,
            isValidStartDateOverride
        });
    }
    case CAMPAIGN_OPTION_CHANGE: {
        const {
            optionList,
            selectedOption,
            totalWeeks
        } = action.data;

        return updateObject(state, {
            errorMessage: '',
            selectedOption,
            totalWeeks,
            optionList,
            isCampaignDetailsUpdated: true,
            loading: false,
            getErrorMessage: ''
        });
    }
    case CAMPAIGN_SAVE_AND_CONTINUE: {
        const { campaignSaved } = action.data;

        return updateObject(state, {
            isCampaignDetailsUpdated: false,
            loading: false,
            savedCampaign: campaignSaved,
            isFormSubmitted: true,
            errorMessage: '',
            campaignComments: campaignSaved.comments,
            getErrorMessage: ''
        });
    }
    case DISCARD_UNSAVED_CAMPAIGN: {
        const {
            endDate,
            selectedOption,
            startDate,
            totalWeeks,
            comments
        } = action.data;

        return updateObject(state, {
            campaignComments: comments,
            loading: false,
            startDate,
            endDate,
            selectedOption,
            totalWeeks,
            isCampaignDetailsUpdated: false,
            errorMessage: '',
            getErrorMessage: ''
        });
    }
    case CAMPAIGN_DETAIL_UPDATED: {
        return updateObject(state, { isCampaignDetailsUpdated: action.data });
    }
    case SAVED_CAMPAIGN_DETAILS: {
        const {
            endDate,
            optionList,
            selectedOption,
            startDate,
            totalWeeks,
            comments,
            weeks,
            minEndDate,
            minStartDate,
            maxEndDate,
            maxStartDate,
            packageInfo,
            isValidStartDateOverride
        } = action.data;

        const savedCampaign = {};
        savedCampaign.endDate = endDate;
        savedCampaign.selectedOption = selectedOption;
        savedCampaign.startDate = startDate;
        savedCampaign.weeks = weeks;
        savedCampaign.comments = comments;

        return updateObject(state, {
            selectedOption,
            totalWeeks,
            startDate,
            endDate,
            optionList,
            isCampaignDetailsUpdated: false,
            loading: false,
            isFormSubmitted: true,
            savedCampaign,
            campaignComments: comments,
            minStartDate,
            minEndDate,
            maxStartDate,
            maxEndDate,
            packageInfo,
            isDetailsFetched: true,
            errorMessage: '',
            getErrorMessage: '',
            isValidStartDateOverride
        });
    }
    case CLEAR_ALL_PACKAGE_RELATED_STORES:
        return initialState;
    default:
        return state;
    }
};

export default campaignDatesReducer;
