/* eslint-disable array-callback-return */
import * as actionTypes from './actionTypes';
import { setErrorMessage, isObjectEmpty } from '../../utils/utility_helpers';
import { getAdminCustomerListService } from '../services/adminCustomerList';
import { getAllActionItemsService } from '../services/customerCampaignDetails';

export const adminCustomerListStart = () => {
    return {
        type: actionTypes.ADMIN_CUSTOMER_LIST_START
    };
};

export const adminCustomerListFail = (errorMessage) => {
    return {
        type: actionTypes.ADMIN_CUSTOMER_LIST_FAIL,
        errorMessage
    };
};

const getAdminCustomerListSuccess = (data) => {
    return {
        type: actionTypes.GET_ADMIN_CUSTOMER_LIST_SUCCESS,
        data
    };
};

const setActiveActionItems = (campaignActionItems) => {
    const activeActionItems = [];
    campaignActionItems.map((activeItems, index) => {
        activeItems.activeTaskActionItems.map((item) => {
            activeActionItems.push(item);
        });
    });
    return activeActionItems;
};

const setDeliverableActionItems = (campaignActionItems) => {
    const deliverableActionItems = [];
    campaignActionItems.map((activeItems, index) => {
        activeItems.deliverableActionItems.map((item) => {
            deliverableActionItems.push(item);
        });
    });
    return deliverableActionItems;
};

const setMessageActionItems = (campaignActionItems) => {
    const messageActionItems = [];
    campaignActionItems.map((activeItems, index) => {
        activeItems.messageActionItems.map((item) => {
            messageActionItems.push(item);
        });
    });
    return messageActionItems;
};

const setPastDueTaskActionItems = (campaignActionItems) => {
    const pastDueActionItems = [];
    campaignActionItems.map((activeItems, index) => {
        activeItems.pastDueTaskActionItems.map((item) => {
            pastDueActionItems.push(item);
        });
    });
    return pastDueActionItems;
};

const getAllActionItemsSuccess = (data = {}) => {
    const allActionItems = [];
    if (!isObjectEmpty(data)) {
        data.map((company, index) => {
            allActionItems.push({
                companyId: company.companyId,
                actionItems: {
                    activeTaskActionItems: setActiveActionItems(company.campaignActionItems),
                    deliverableActionItems: setDeliverableActionItems(company.campaignActionItems),
                    messageActionItems: setMessageActionItems(company.campaignActionItems),
                    pastDueTaskActionItems: setPastDueTaskActionItems(company.campaignActionItems)
                }
            });
        });
    }
    return {
        type: actionTypes.GET_ALL_ACTION_ITEMS_SUCCESS,
        data: allActionItems
    };
};

export const getAdminCustomerList = () => {
    return async (dispatch) => {
        try {
            dispatch(adminCustomerListStart());
            const response = await getAdminCustomerListService();
            dispatch(getAdminCustomerListSuccess(response.data.data));
            console.log(response.data.data);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(adminCustomerListFail(errorMessage));
        }
    };
};

export const getAllActionItems = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: actionTypes.GET_ALL_ACTION_ITEMS_START
            });
            const response = await getAllActionItemsService();
            dispatch(getAllActionItemsSuccess(response.data.data));
            console.log(response.data.data);
        } catch (error) {
            console.log(error);
            dispatch({
                type: actionTypes.GET_ALL_ACTION_ITEMS_FAIL,
                errorMessage: error
            });
        }
    };
};
