import * as actiontypes from './actionTypes';
import {
    getMediaChannelsService,
    getCampaignMediaChannelsService,
    saveMediaChannelsService
} from '../services/mediaChannels';
import { setErrorMessage } from '../../utils/utility_helpers';
import { updateNavigation } from './navigation';
import { updateCampaignService, getCampaignSercvice } from '../services/campaign';
import {
    navigationTabList
} from '../../components/Constants';

const mediaChannelsStart = () => {
    return {
        type: actiontypes.MEDIA_CHANNELS_START
    };
};

const mediaChannelsSuccess = (channelData) => {
    return {
        type: actiontypes.MEDIA_CHANNELS_SUCCESS,
        data: channelData
    };
};

const mediaChannelsFail = (error) => {
    return {
        type: actiontypes.MEDIA_CHANNELS_FAIL,
        errorMessage: error
    };
};

const constructMediaChannelObject = (data) => {
    return {
        type: actiontypes.CONSTRUCT_MEDIA_CHANNEL_OBJECT,
        data
    };
};

const updateLocalStoreState = (data) => {
    return {
        type: actiontypes.UPDATE_LOCAL_STORE_STATE,
        data
    };
};

export const updateIsMediaChannelSaved = (data) => {
    return {
        type: actiontypes.IS_MEDIA_CHANNEL_SAVED,
        data
    };
};

export const setMediaMixInitialState = () => {
    return {
        type: actiontypes.SET_MEDIA_MIX_INITIAL_STATE
    };
};

export const getMediaChannels = () => {
    return async (dispatch) => {
        try {
            dispatch(mediaChannelsStart());
            const response = await getMediaChannelsService();
            dispatch(mediaChannelsSuccess(response.data.data));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(mediaChannelsFail(errorMessage));
        }
    };
};

export const getCampaignMediaChannels = () => {
    return async (dispatch) => {
        try {
            dispatch(mediaChannelsStart());
            const campaignId = localStorage.getItem('CampaignId');
            const campaignChannels = await getCampaignMediaChannelsService(campaignId);
            const channels = await getMediaChannelsService();
            const campaign = await getCampaignSercvice(campaignId);
            dispatch(constructMediaChannelObject(
                {
                    campaignChannels: campaignChannels.data.data.campaignChannels,
                    channels: channels.data.data,
                    mediaMixComment: campaign.data.data.mediaMixComment
                }
            ));
            console.log(campaignChannels);
            console.log(channels);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(mediaChannelsFail(errorMessage));
        }
    };
};

export const saveMediaChannels = (formData) => {
    return async (dispatch) => {
        try {
            const campaignId = localStorage.getItem('CampaignId');
            dispatch(mediaChannelsStart());
            await saveMediaChannelsService(campaignId, formData.channels);
            await updateCampaignService(campaignId, formData.campaignData);
            dispatch(updateLocalStoreState(formData.localState));
            dispatch(updateNavigation(navigationTabList.audiences, 5));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(mediaChannelsFail(errorMessage));
        }
    };
};

export const discardUnSavedMediaMix = (navigation, type, intakeStepId) => {
    return (dispatch) => {
        if (type === 'delete') {
            dispatch({
                type: actiontypes.MEDIA_UNSAVED_CHANGES
            });
        }

        dispatch(updateNavigation(navigation, intakeStepId));
    };
};
