/* eslint-disable no-tabs */
/* eslint-disable indent */
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const intialState = {
    companyName: null,
    companyLegalName: null,
    id: null,
    errorMessage: null,
	loading: null,
	companyPromotions: []
};

const companyStart = (state, action) => {
    return updateObject(state, {
        errorMessage: null,
        loading: true
    });
};
const companySuccess = (state, action) => {
    return updateObject(state, {
        companyName: action.data.companyName,
        companyLegalName: action.data.companyLegalName,
        id: action.data.id,
        errorMessage: null,
        loading: false
    });
};

const companyPromotionsSuccess = (state, action) => {
	return updateObject(state, {
		companyPromotions: action.data.data,
		errorMessage: null,
		loading: false
	});
};

const companyFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.errorMessage,
        loading: false
    });
};

const reducer = (state = intialState, action) => {
    switch (action.type) {
    case actionTypes.COMPANY_START: return companyStart(state, action);
    case actionTypes.COMPANY_SUCCESS: return companySuccess(state, action);
    case actionTypes.COMPANY_FAIL: return companyFail(state, action);
	case actionTypes.GET_COMPANY_PROMOTIONS_SUCCESS: return companyPromotionsSuccess(state, action);
    default:
        return state;
    }
};

export default reducer;
