/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Select from 'react-select';
import { StateCodes, LocationFeilds } from '../../Constants';

class MailingAddress extends React.Component {
    getStates = () => {
        const options = [];
        options.push({ value: '', label: 'Select' });
        StateCodes.map((item) => {
            options.push({ value: item.StateCode, label: item.StateCode });
            return options;
        });
        return options;
    };

    render() {
        const {
            isEditable, mailingAddress, mailingAddressError, mailingIndex,
            onMailingAddressChange, validateZipCode, setMailingAddressRef
        } = this.props;
        let address = null;
        if (mailingAddress) {
            // eslint-disable-next-line prefer-destructuring
            address = mailingAddress[0];
        }

        let state = null;
        if (address) {
            state = address.state.trim() === ''
                ? 'Select' : address.state;
        }
        const stateSelectedValue = {
            value: state === 'Select' ? '' : state,
            label: state === 'Select' ? 'Select' : state
        };
        return (
            <div className="cd-mailing-address">
                <h2 className="cd-sub-heading">
                    Mailing Address (if different than above)
                </h2>
                <span ref={setMailingAddressRef} />
                <div className="form-group">
                    <label htmlFor="cdAddress1">Address 1</label>
                    <input
                        className={`form-control ${
                            mailingAddressError.address1 === '' ? '' : 'is-invalid'
                        }`}
                        id="cdAddress1"
                        type="text"
                        placeholder="Address 1"
                        disabled={!isEditable}
                        value={address && address.address1}
                        onChange={(event) => {
                            onMailingAddressChange(event, LocationFeilds.Address1, mailingIndex);
                        }}
                    />
                    <span className="invalid-feedback">
                        {mailingAddressError.address1}
                    </span>
                </div>
                <div className="form-group">
                    <label htmlFor="cdAddress2">Address 2</label>
                    <input
                        className="form-control"
                        id="cdAddress2"
                        type="text"
                        placeholder="Address 2"
                        disabled={!isEditable}
                        value={address && address.address2}
                        onChange={(event) => {
                            onMailingAddressChange(event, LocationFeilds.Address2, mailingIndex);
                        }}
                    />
                </div>
                <div className="city-state-wrapper">
                    <div className="form-group">
                        <label htmlFor="cdCity">City</label>
                        <input
                            className={`form-control input-city ${
                                mailingAddressError.city === '' ? '' : 'is-invalid'
                            }`}
                            id="cdCity"
                            type="text"
                            placeholder="City"
                            disabled={!isEditable}
                            value={address && address.city}
                            onChange={(event) => {
                                onMailingAddressChange(event, LocationFeilds.City, mailingIndex);
                            }}
                        />
                        <span className="invalid-feedback">
                            {mailingAddressError.city}
                        </span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cdState">State</label>
                        <Select
                            className={`custom-react-select custom-react-state-select ${
                                mailingAddressError.state === '' ? '' : 'is-invalid'
                            }`}
                            id="cdState"
                            aria-label="cdState"
                            options={this.getStates()}
                            value={stateSelectedValue}
                            isDisabled={!isEditable}
                            classNamePrefix="custom-react-dropdown"
                            onChange={(event) => {
                                onMailingAddressChange(event, LocationFeilds.State, mailingIndex);
                            }}
                        />
                        <span className="invalid-feedback">
                            {mailingAddressError.state}
                        </span>
                    </div>
                </div>
                <div className="form-group cd-zip-code">
                    <label htmlFor="cdZipCode">Zip Code</label>
                    <input
                        className={`form-control input-zipcode ${
                            mailingAddressError.zipCode === '' ? '' : 'is-invalid'
                        }`}
                        id="cdZipCode"
                        type="text"
                        maxLength="5"
                        pattern="\d*"
                        placeholder="Zip Code"
                        disabled={!isEditable}
                        value={address && address.zipCode}
                        onChange={(event) => {
                            onMailingAddressChange(event, LocationFeilds.ZipCode, mailingIndex);
                        }}
                        onBlur={validateZipCode}
                    />
                    <span className="invalid-feedback">
                        {mailingAddressError.zipCode}
                    </span>
                </div>
            </div>
        );
    }
}

export default MailingAddress;
