import React from 'react';
import ReviewSuccessPopUp from '../PreIntakeComponent/packages/campaignComponents/container/ReviewSuccessPopUp';

const UserWelcomeLayout = (props) => {
    return (
        <>
            <ReviewSuccessPopUp />
        </>
    );
};

export default UserWelcomeLayout;
