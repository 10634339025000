/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import {
    // Campaign List Section
    CUSTOMER_CAMPAIGN_START,
    CUSTOMER_CAMPAIGN_END,
    GET_CUSTOMER_CAMPAIGN_LIST,
    GET_CUSTOMER_CAMPAIGN_LIST_ERROR,
    CUSTOMER_CAMPAIGN_DATA,
    CUSTOMER_GET_CALL_TO_ACTION_ITEMS,
    CUSTOMER_REWARD_NOTIFICATION,
    CUSTOMER_FETCH_CAMPAIGN_DETAILS_START,
    CUSTOMER_FETCH_CAMPAIGN_DETAILS_END,

    // New Campaign
    CHANGE_IS_NEW_CAMPAIGN_FLAG,
    GET_CUSTOMER_COMPANY_LOCATION_SUCCESS,

    // Reward Points Section
    GET_CAMPAIGN_TOTAL_REWARD_POINTS_SUCCESS,

    // Message Section
    GET_CUSTOMER_CAMPAIGN_MESSAGE_SUCCESS,
    GET_CUSTOMER_CAMPAIGN_MESSAGE_FAIL,
    CUSTOMER_UPDATE_MESSAGE_FAIL,
    CUSTOMER_SHOW_COMPOSE_MESSAGE,
    CUSTOMER_MESSAGE_ERROR_HANDLER,
    CUSTOMER_SHOW_VIEW_MESSAGE,

    // Deliverable Section
    CUSTOMER_GET_DELIVERABLE_START,
    CUSTOMER_DELIVERABLE_LIST_ERROR,
    CUSTOMER_DELIVERABLES_LIST_SUCCESS,

    // Task Section
    CUSTOMER_GET_CAMPAIGN_TASKS_START,
    CUSTOMER_GET_CAMPAIGN_TASKS_SUCCESS,
    CUSTOMER_GET_CAMPAIGN_TASKS_FAIL,
    CUSTOMER_SHOW_UPDATE_TASK_DETAILS_POPUP,
    CUSTOMER_UPDATE_TASK_DETAILS_SUCCESS,
    CUSTOMER_UPDATE_TASK_DETAILS_ERROR,

    // Document Section
    CUSTOMER_CAMPAIGN_DOCUMENT_SUCCESS,
    CUSTOMER_CAMPAIGN_DOCUMENT_FAIL
} from './actionTypes';

import {
    getFlighting,
    handleCreativeUploadPopup
} from './customerFlightingTable';

import {
    getCampaignDetailsList,
    getCampaignTotalRewardPointsService,
    getActionItems,
    updateMarkAsReadDeliverable,
    postNewMessage,
    getMessageDetails
} from '../services/customerCampaignDetails';

import {
    getCampaignTasksService,
    updateTaskDetailsService,
    getCampaignDocumentsService,
    updateCampaignService
} from '../services/campaign';

import { getCompanyLocations } from '../services/company';

import { getCampaignMessageListService, markAsReadOrUnreadService } from '../services/adminMessageList';

import { getDeliverablesService } from '../services/adminDeliverables';

import {
    isArrayEmpty,
    setErrorMessage,
    dateFormatter,
    toBase64,
    isObjectEmpty
} from '../../utils/utility_helpers';

import {
    deliverableType,
    TaskType,
    RegEx
} from '../../components/Constants';

const buildCampaignList = (data = []) => {
    const campaignList = data;

    if (!isArrayEmpty(campaignList)) {
        campaignList.sort((a, b) => {
            const active = b.isActive - a.isActive;

            if (active) {
                return active;
            }

            const isIntakeComplete = b.isIntakeComplete - a.isIntakeComplete;

            if (isIntakeComplete) {
                return isIntakeComplete;
            }

            const isComplete = a.isComplete - b.isComplete;

            if (isComplete) {
                return isComplete;
            }

            const packageId = a.packageId - b.packageId;

            if (packageId) {
                return packageId;
            }

            return (new Date(a.startDate) < new Date(b.startDate)) ? -1 : 1;
        });
    }

    return campaignList;
};

// New Campaign
const changeIsNewCampaignEnabledFlag = (data) => {
    return {
        type: CHANGE_IS_NEW_CAMPAIGN_FLAG,
        data
    };
};

const getCompanyLocationsList = (companyId) => {
    return async (dispatch) => {
        try {
            const locations = await getCompanyLocations(companyId);
            console.log(locations);
            dispatch({
                type: GET_CUSTOMER_COMPANY_LOCATION_SUCCESS,
                data: locations.data.data
            });
        } catch (error) {
            console.log(error);
        }
    };
};

const buildActionItems = (data = {}) => {
    const actionItems = {};
    actionItems.activeTaskActionItems = [];
    actionItems.deliverableActionItems = [];
    actionItems.messageActionItems = [];
    actionItems.pastDueTaskActionItems = [];

    if (!isObjectEmpty(data)) {
        const { campaignActionItems } = data;
        if (!isArrayEmpty(campaignActionItems)) {
            campaignActionItems.map((action) => {
                const {
                    activeTaskActionItems,
                    deliverableActionItems,
                    messageActionItems,
                    pastDueTaskActionItems
                } = action;

                actionItems.activeTaskActionItems = [...actionItems.activeTaskActionItems, ...activeTaskActionItems];
                actionItems.deliverableActionItems = [...actionItems.deliverableActionItems, ...deliverableActionItems];
                actionItems.messageActionItems = [...actionItems.messageActionItems, ...messageActionItems];
                actionItems.pastDueTaskActionItems = [...actionItems.pastDueTaskActionItems, ...pastDueTaskActionItems];

                return null;
            });

            if (actionItems.activeTaskActionItems && !isArrayEmpty(actionItems.activeTaskActionItems)) {
                actionItems.activeTaskActionItems.map((item) => {
                    const {
                        revisedEndDate,
                        revisedStartDate,
                        fixedStartDate
                    } = item;

                    item.revisedEndDate = revisedEndDate ? dateFormatter(revisedEndDate, 'MM/DD/YYYY') : '';
                    item.revisedStartDate = revisedStartDate ? dateFormatter(revisedStartDate, 'MM/DD/YYYY') : '';
                    item.fixedStartDate = fixedStartDate ? dateFormatter(fixedStartDate, 'utcToLocalTimeOtherFormat') : '';
                    return null;
                });
            }

            if (actionItems.pastDueTaskActionItems && !isArrayEmpty(actionItems.pastDueTaskActionItems)) {
                actionItems.pastDueTaskActionItems.map((item) => {
                    const {
                        revisedEndDate,
                        revisedStartDate,
                        fixedStartDate
                    } = item;

                    item.revisedEndDate = revisedEndDate ? dateFormatter(revisedEndDate, 'MM/DD/YYYY') : '';
                    item.revisedStartDate = revisedStartDate ? dateFormatter(revisedStartDate, 'MM/DD/YYYY') : '';
                    item.fixedStartDate = fixedStartDate ? dateFormatter(fixedStartDate, 'utcToLocalTimeOtherFormat') : '';
                    return null;
                });
            }

            actionItems.activeTaskActionItems.sort((a, b) => {
                return new Date(a.revisedEndDate) > new Date(b.revisedEndDate) ? 1 : new Date(a.revisedEndDate) < new Date(b.revisedEndDate) ? -1 : 0;
            });

            actionItems.pastDueTaskActionItems.sort((a, b) => {
                return new Date(a.revisedEndDate) > new Date(b.revisedEndDate) ? 1 : new Date(a.revisedEndDate) < new Date(b.revisedEndDate) ? -1 : 0;
            });

            actionItems.deliverableActionItems.sort((a, b) => {
                return new Date(a.userCreatedDatetime) > new Date(b.userCreatedDatetime) ? 1 : new Date(a.userCreatedDatetime) < new Date(b.userCreatedDatetime) ? -1 : 0;
            });

            actionItems.messageActionItems.sort((a, b) => {
                return new Date(a.createdDateTime) > new Date(b.createdDateTime) ? 1 : new Date(a.createdDateTime) < new Date(b.createdDateTime) ? -1 : 0;
            });
        }
    }

    return actionItems;
};

const getCampaignActionItems = (companyId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CUSTOMER_CAMPAIGN_START
            });

            const response = await getActionItems(companyId);
            console.log(response);
            const { data } = response;

            if (data) {
                dispatch({
                    type: CUSTOMER_GET_CALL_TO_ACTION_ITEMS,
                    data: buildActionItems(data.data)
                });
            } else {
                dispatch({
                    type: GET_CUSTOMER_CAMPAIGN_LIST_ERROR,
                    data: setErrorMessage(response)
                });
            }

            dispatch({
                type: CUSTOMER_CAMPAIGN_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);

            dispatch({
                type: GET_CUSTOMER_CAMPAIGN_LIST_ERROR,
                data: errorMessage
            });

            dispatch({
                type: CUSTOMER_CAMPAIGN_END
            });
        }
    };
};

/* Campaign List Section Start */
const getCampaignList = (companyId = 0) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CUSTOMER_CAMPAIGN_START
            });

            const response = await getCampaignDetailsList(companyId);
            const { data } = response;

            if (data) {
                dispatch({
                    type: GET_CUSTOMER_CAMPAIGN_LIST,
                    data: buildCampaignList(data.data)
                });
            } else {
                dispatch({
                    type: GET_CUSTOMER_CAMPAIGN_LIST_ERROR,
                    data: setErrorMessage(response)
                });
            }

            dispatch({
                type: CUSTOMER_CAMPAIGN_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);

            dispatch({
                type: GET_CUSTOMER_CAMPAIGN_LIST_ERROR,
                data: errorMessage
            });

            dispatch({
                type: CUSTOMER_CAMPAIGN_END
            });
        }
    };
};
/* Campaign List Section End */

/* Reward Points Section Start */
const getCampaignTotalRewardPoints = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CUSTOMER_CAMPAIGN_START
            });
            const response = await getCampaignTotalRewardPointsService(campaignId);
            console.log(response);
            if (response.data && response.data.resultCount > 0) {
                dispatch({
                    type: GET_CAMPAIGN_TOTAL_REWARD_POINTS_SUCCESS,
                    data: response.data.data
                });
            }
            dispatch({
                type: CUSTOMER_CAMPAIGN_END
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: CUSTOMER_CAMPAIGN_END
            });
        }
    };
};

const handleRewardNotification = (showRewardNotification, campaignId) => {
    return (dispatch) => {
        if (showRewardNotification) {
            updateCampaignService(campaignId, {
                isRewardNotification: false
            });
        }
        dispatch({
            type: CUSTOMER_REWARD_NOTIFICATION,
            data: {
                campaignId,
                showRewardNotification
            }
        });
    };
};
/* Reward Point Section End */

/* Message Section Start */
const getCampaignMessages = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CUSTOMER_CAMPAIGN_START
            });
            const response = await getCampaignMessageListService(campaignId);
            console.log(response);
            dispatch({
                type: GET_CUSTOMER_CAMPAIGN_MESSAGE_SUCCESS,
                data: response.data.data
            });
            dispatch({
                type: CUSTOMER_CAMPAIGN_END
            });
        } catch (error) {
            console.log(error);
            const errorMessage = setErrorMessage(error);
            dispatch({
                type: GET_CUSTOMER_CAMPAIGN_MESSAGE_FAIL,
                data: errorMessage
            });
        }
    };
};

export const markAsReadOrUnreadCustomerMessages = (messageIds, isRead, campaignId, companyId) => {
    return async (dispatch) => {
        try {
            const putData = {};
            putData.messageIds = messageIds;
            putData.isRead = isRead;
            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_START
            });
            const response = await markAsReadOrUnreadService(putData, campaignId);
            if (response.status === 204 || response.status === 200) {
                console.log(response);

                dispatch(getCampaignMessages(campaignId));
                await dispatch(getCampaignActionItems(companyId));
                dispatch({
                    type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_END
                });
            } else {
                dispatch({
                    type: CUSTOMER_UPDATE_MESSAGE_FAIL,
                    data: 'Sorry, unable to update the message.'
                });
                dispatch({
                    type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_END
                });
            }
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            console.log(errorMessage);
            dispatch({
                type: CUSTOMER_UPDATE_MESSAGE_FAIL,
                data: errorMessage
            });
            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_END
            });
        }
    };
};

const handleComposeMessage = (data) => {
    return async (dispatch) => {
        const {
            fetchMessage,
            campaignId,
            companyId
        } = data;

        if (fetchMessage) {
            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_START
            });

            dispatch(getCampaignMessages(campaignId));
            await dispatch(getCampaignActionItems(companyId));

            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_END
            });
        }

        dispatch({
            type: CUSTOMER_SHOW_COMPOSE_MESSAGE,
            data
        });
    };
};

const postNewMessageDetails = (data = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CUSTOMER_CAMPAIGN_START
            });

            const {
                campaignId,
                isHighPriority,
                subject,
                content,
                uploadedFiles,
                threadId,
                type
            } = data;

            const postData = {};
            postData.toPersonId = null;
            postData.toAddresses = null;
            postData.isHighPriority = isHighPriority;
            postData.subject = subject;
            postData.content = content;
            postData.threadId = null;
            postData.isReply = false;

            if (type === 'replayMessage') {
                postData.threadId = threadId;
                postData.isReply = true;
            }

            postData.metaData = null;
            postData.fileDetails = [];

            if (uploadedFiles
                && !isArrayEmpty(uploadedFiles)) {
                await Promise.all(uploadedFiles.map(async (item) => {
                    const file = await toBase64(item);
                    const base64Data = file.base64.split(',')[1];

                    const fileData = {};

                    fileData.media = base64Data;
                    fileData.fileName = file.fileName;
                    fileData.mimeType = item.type;
                    fileData.dimension = item.width && item.height ? item.width + 'x' + item.height : '';
                    postData.fileDetails.push(fileData);
                }));
            }

            const response = await postNewMessage(campaignId, postData);

            if (response) {
                await dispatch(getCampaignMessages(campaignId));
                if (type === 'newMessage') {
                    dispatch({
                        type: CUSTOMER_SHOW_COMPOSE_MESSAGE,
                        data: {
                            showComposeMessage: false,
                            showMessageListPopup: true
                        }
                    });
                } else {
                    dispatch({
                        type: CUSTOMER_SHOW_VIEW_MESSAGE,
                        data: {
                            showMessageDetails: false,
                            showMessageListPopup: true,
                            messageDetailsList: []
                        }
                    });
                }
            } else {
                dispatch({
                    type: CUSTOMER_MESSAGE_ERROR_HANDLER,
                    data: setErrorMessage(response)
                });
            }

            dispatch({
                type: CUSTOMER_CAMPAIGN_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            console.log(errorMessage);
            dispatch({
                type: CUSTOMER_CAMPAIGN_END
            });

            dispatch({
                type: CUSTOMER_MESSAGE_ERROR_HANDLER,
                data: errorMessage
            });
        }
    };
};

const handleViewMessage = (data) => {
    return async (dispatch) => {
        const details = data;
        const {
            showMessageDetails,
            campaignId,
            isRead,
            messageId,
            threadId,
            companyId
        } = details;

        details.messageDetailsList = [];

        if (showMessageDetails) {
            try {
                dispatch({
                    type: CUSTOMER_CAMPAIGN_START
                });

                const response = await getMessageDetails(campaignId, threadId);

                if (!isRead) {
                    await dispatch(markAsReadOrUnreadCustomerMessages(messageId, !isRead, campaignId, companyId));
                }

                if (response) {
                    const { data: responseData } = response.data;
                    console.log(responseData);
                    const messageDetailsList = responseData || [];
                    details.messageDetailsList = messageDetailsList;
                    console.log(response);
                    dispatch({
                        type: CUSTOMER_SHOW_VIEW_MESSAGE,
                        data: details
                    });
                } else {
                    dispatch({
                        type: CUSTOMER_MESSAGE_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });
                }

                dispatch({
                    type: CUSTOMER_CAMPAIGN_END
                });
            } catch (error) {
                const errorMessage = setErrorMessage(error);
                console.log(errorMessage);

                dispatch({
                    type: CUSTOMER_CAMPAIGN_END
                });

                dispatch({
                    type: CUSTOMER_MESSAGE_ERROR_HANDLER,
                    data: errorMessage
                });
            }
        } else {
            dispatch({
                type: CUSTOMER_SHOW_VIEW_MESSAGE,
                data: details
            });
        }
    };
};

/* Message Section End */

/* Document Section Start */
const getCustomerCampaignDocuments = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CUSTOMER_CAMPAIGN_START
            });
            const response = await getCampaignDocumentsService(campaignId);
            console.log(response);
            dispatch({
                type: CUSTOMER_CAMPAIGN_DOCUMENT_SUCCESS,
                data: response.data.data
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            console.log(error);
            dispatch({
                type: CUSTOMER_CAMPAIGN_DOCUMENT_FAIL,
                data: errorMessage
            });
        }
    };
};
/* Document Section End */

const storeSelectedCampaign = (data) => {
    return {
        type: CUSTOMER_CAMPAIGN_DATA,
        data
    };
};

const buildDeliverableList = (data = []) => {
    const deliverableList = [];
    if (!isArrayEmpty(data)) {
        const deliverableTypes = [deliverableType.mim, deliverableType.mediaAgentPlan, deliverableType.mediaBuyReport, deliverableType.digitalDashboard, deliverableType.executiveReport];
        if (deliverableTypes) {
            deliverableTypes.map((list) => {
                data.filter((item) => {
                    const { fileTypeDetails } = item;

                    if (fileTypeDetails && fileTypeDetails.fileTypeName
                        && fileTypeDetails.fileTypeName.toLowerCase() === list.toLowerCase()) {
                        deliverableList.push(item);
                    }
                    return null;
                });
                return null;
            });
        }
    }
    return deliverableList;
};

/* Deliverable Section Start */
const getDeliverableList = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CUSTOMER_GET_DELIVERABLE_START
            });

            const response = await getDeliverablesService(campaignId);

            if (response) {
                dispatch({
                    type: CUSTOMER_DELIVERABLES_LIST_SUCCESS,
                    data: buildDeliverableList(response.data.data)
                });
            } else {
                dispatch({
                    type: CUSTOMER_DELIVERABLE_LIST_ERROR,
                    data: setErrorMessage(response)
                });
            }
        } catch (error) {
            const errorMessage = setErrorMessage(error);

            dispatch({
                type: CUSTOMER_DELIVERABLE_LIST_ERROR,
                data: errorMessage
            });
        }
    };
};

const markAsReadDeliverable = (campaignId = 0, file = {}, companyId = 0) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_START
            });
            const data = {
                isRead: true
            };

            const response = await updateMarkAsReadDeliverable(campaignId, file.fileId, data);

            if (response && response.status === 204) {
                dispatch(getCampaignActionItems(companyId));
                await dispatch(getDeliverableList(campaignId));
                const filePath = !RegEx.urlHasHttp.test(file.filePath) ? 'http://' + file.filePath : file.filePath;
                window.open(filePath, '_blank');
            } else {
                dispatch({
                    type: CUSTOMER_DELIVERABLE_LIST_ERROR,
                    data: setErrorMessage(response)
                });
            }

            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);

            dispatch({
                type: CUSTOMER_DELIVERABLE_LIST_ERROR,
                data: errorMessage
            });

            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_END
            });
        }
    };
};
/* Deliverable Section End */

/* Task Section Start */
const getCampaignTaskList = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CUSTOMER_GET_CAMPAIGN_TASKS_START
            });

            const response = await getCampaignTasksService(campaignId);

            if (response) {
                const { data } = response.data;

                if (data && !isArrayEmpty(data)) {
                    data.map((item) => {
                        const task = item;

                        task.revisedStartDate = task.revisedStartDate ? dateFormatter(task.revisedStartDate, 'MM/DD/YYYY') : '';
                        task.revisedEndDate = task.revisedEndDate ? dateFormatter(task.revisedEndDate, 'MM/DD/YYYY') : '';
                        task.fixedStartDate = task.fixedStartDate ? dateFormatter(task.fixedStartDate, 'utcToLocalTimeOtherFormat') : '';

                        return item;
                    });

                    data.sort((a, b) => {
                        return new Date(a.revisedEndDate) > new Date(b.revisedEndDate) ? 1 : new Date(a.revisedEndDate) < new Date(b.revisedEndDate) ? -1 : 0;
                    });
                }

                dispatch({
                    type: CUSTOMER_GET_CAMPAIGN_TASKS_SUCCESS,
                    data
                });
            } else {
                dispatch({
                    type: CUSTOMER_GET_CAMPAIGN_TASKS_FAIL,
                    data: setErrorMessage(response)
                });
            }
        } catch (error) {
            const errorMessage = setErrorMessage(error);

            dispatch({
                type: CUSTOMER_GET_CAMPAIGN_TASKS_FAIL,
                data: errorMessage
            });
        }
    };
};

const showCustomerUpdateTaskDetails = (data) => {
    return {
        type: CUSTOMER_SHOW_UPDATE_TASK_DETAILS_POPUP,
        data
    };
};

const updateTaskDetails = (campaignId, task, data, fileObject, companyId) => {
    return async (dispatch) => {
        try {
            const postData = data;
            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_START
            });

            if (task.taskType === TaskType.UploadDocument
                || task.taskType === TaskType.DocumentUploaded
                || (task.taskType === TaskType.UploadFileOrSendEmail && postData.isDirectFileUpload)) {
                if (fileObject.documentUploadOptions.uploadedFiles
                    && !isArrayEmpty(fileObject.documentUploadOptions.uploadedFiles)) {
                    await Promise.all(fileObject.documentUploadOptions.uploadedFiles.map(async (item) => {
                        const file = await toBase64(item);
                        const base64Data = file.base64.split(',')[1];

                        postData.fileName = file.fileName;
                        postData.media = base64Data;
                    }));
                }
            }

            if (task.taskType === TaskType.ApproveRequestReject) {
                if (data.reviewMediaPlan.uploadedFiles
                    && !isArrayEmpty(data.reviewMediaPlan.uploadedFiles)) {
                    const fileDetails = [];
                    await Promise.all(data.reviewMediaPlan.uploadedFiles.map(async (item) => {
                        const file = await toBase64(item);
                        const base64Data = file.base64.split(',')[1];
                        const fileData = {};

                        fileData.media = base64Data;
                        fileData.fileName = file.fileName;
                        fileData.mimeType = item.type;
                        fileData.dimension = item.width && item.height ? item.width + 'x' + item.height : '';
                        fileDetails.push(fileData);
                    }));
                    data.reviewMediaPlan.fileDetails = fileDetails;
                }
            }

            const postResponse = await updateTaskDetailsService(campaignId, task.workFlowTaskId, postData);

            let isArchivedCampaign = false;

            if (postResponse.data.resultCount) {
                if ((task.taskType === TaskType.YesOrNo && !postData.isMediaBuy) || (data.reviewMediaPlan && data.reviewMediaPlan.isReject)) {
                    isArchivedCampaign = true;
                } else {
                    dispatch(getCampaignActionItems(companyId));
                    dispatch(getCampaignTaskList(campaignId));
                    if (task.taskType === TaskType.UploadDocument
                    || task.taskType === TaskType.DocumentUploaded
                    || task.taskType === TaskType.UploadFileOrSendEmail) {
                        dispatch(getCustomerCampaignDocuments(campaignId));

                        dispatch(getDeliverableList(campaignId));
                    }
                }

                dispatch({
                    type: CUSTOMER_UPDATE_TASK_DETAILS_SUCCESS,
                    data: isArchivedCampaign
                });
            } else {
                dispatch({
                    type: CUSTOMER_UPDATE_TASK_DETAILS_ERROR,
                    errorMessage: setErrorMessage(postResponse)
                });
            }

            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            dispatch({
                type: CUSTOMER_UPDATE_TASK_DETAILS_ERROR,
                errorMessage: setErrorMessage(error)
            });

            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_END
            });
        }
    };
};
/* Task Section End */

const getCampaignDetails = (selectedCampaign, selectedAction, data) => {
    return async (dispatch) => {
        try {
            const {
                campaignId,
                companyId,
                file,
                isSameCampaignSelected,
                isMIMPackageSelected
            } = data;

            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_START
            });

            dispatch(storeSelectedCampaign(selectedCampaign));
            if (!isSameCampaignSelected) {
                dispatch(changeIsNewCampaignEnabledFlag(false));
                dispatch(getCampaignTaskList(campaignId));
                dispatch(getCampaignMessages(campaignId));
                dispatch(getCustomerCampaignDocuments(campaignId));
                dispatch(getDeliverableList(campaignId));
                if (!isMIMPackageSelected) {
                    dispatch(getCampaignTotalRewardPoints(campaignId));
                    dispatch(getFlighting(campaignId));
                }
                dispatch(handleRewardNotification(selectedCampaign.isRewardNotification, campaignId));
            }

            if (selectedAction === 'taskUpdate') {
                await dispatch(showCustomerUpdateTaskDetails(data));
            } else if (selectedAction === 'creativeUpload') {
                await dispatch(handleCreativeUploadPopup(data));
            } else if (selectedAction === 'deliverable') {
                dispatch(markAsReadDeliverable(campaignId, file, companyId));
            } else if (selectedAction === 'message') {
                await dispatch(handleViewMessage(data));
            }
            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            dispatch({
                type: CUSTOMER_FETCH_CAMPAIGN_DETAILS_END
            });
        }
    };
};

export {
    getCampaignList,
    storeSelectedCampaign,
    getCampaignTotalRewardPoints,
    getCampaignMessages,
    getDeliverableList,
    getCampaignTaskList,
    showCustomerUpdateTaskDetails,
    updateTaskDetails,
    getCustomerCampaignDocuments,
    getCampaignActionItems,
    markAsReadDeliverable,
    changeIsNewCampaignEnabledFlag,
    getCampaignDetails,
    getCompanyLocationsList,
    handleComposeMessage,
    postNewMessageDetails,
    handleViewMessage,
    handleRewardNotification
};
