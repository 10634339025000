/* eslint-disable import/no-cycle */
import * as actionTypes from './actionTypes';
import {
    locationSuccess,
    updateLocationPersonDetails,
    locationFail
} from './location';
import { updateNavigation } from './navigation';
import { setErrorMessage } from '../../utils/utility_helpers';
import {
    getPersonDetailsService,
    updatePersonDetailsService,
    getPersonByEnryptedIdService
} from '../services/person';
import {
    navigationTabList
} from '../../components/Constants';

export const personStart = () => {
    return {
        type: actionTypes.PERSON_START
    };
};

const personFail = (error) => {
    return {
        type: actionTypes.PERSON_FAIL,
        errorMessage: error
    };
};

const personSuccess = (data) => {
    return {
        type: actionTypes.PERSON_SUCCESS,
        data
    };
};

const getPersonByEnryptedIdSuccess = (data) => {
    return {
        type: actionTypes.PERSON_BY_ENCRYPTED_ID,
        data
    };
};

export const updatePerson = (formData) => {
    return async (dispatch) => {
        try {
            const personId = localStorage.getItem('PersonId');
            const response = await updatePersonDetailsService(personId, formData.personData);
            dispatch(personSuccess(response.data));
            dispatch(locationSuccess(formData));
            // now it is not required.
            dispatch(updateNavigation(navigationTabList.campaignDates, 2));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(personFail(errorMessage));
        }
    };
};

export const getPersonDetails = () => {
    const personId = localStorage.getItem('PersonId');
    return async (dispatch) => {
        try {
            const response = await getPersonDetailsService(personId);
            dispatch(updateLocationPersonDetails(response.data.data));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(personFail(errorMessage));
            dispatch(locationFail(errorMessage));
        }
    };
};

export const getPersonByEnryptedId = (id) => {
    return async (dispatch) => {
        try {
            dispatch(personStart());
            const response = await getPersonByEnryptedIdService(id, true);
            dispatch(getPersonByEnryptedIdSuccess(response.data.data));
            console.log(response);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(personFail(errorMessage));
        }
    };
};
