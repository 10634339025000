/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Spinner from '../components/common/Loader';
import { isAuthenticated, isLoggedInIsAdmin, numberOfIntakeCompleted } from '../utils/utility_helpers';

const RouteWrapper = ({ component: Component, layout: Layout, ...rest }) => {
    console.log(rest);
    const {
        isPublic,
        title,
        isAdmin,
        isIntake,
        isCustomer
    } = rest;

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Route
                exact
                {...rest}
                render={(props) => {
                    if (!isAuthenticated() && isPublic) {
                        console.log('customer');
                        return (
                            <Suspense fallback={<Spinner loaderClass="loader-fullscreen" />}>
                                <Layout>
                                    <Component {...props} {...rest} />
                                </Layout>
                            </Suspense>
                        );
                    }

                    if (isAuthenticated()) {
                        if (isLoggedInIsAdmin()) {
                            console.log('admin');
                            if (isAdmin || isPublic) {
                                console.log('admin');
                                return (
                                    <Suspense fallback={<Spinner loaderClass="loader-fullscreen" />}>
                                        <Layout>
                                            <Component {...props} {...rest} />
                                        </Layout>
                                    </Suspense>
                                );
                            }
                            window.location.href = '/admin/list-customers';
                            return (null);
                        } if (!isLoggedInIsAdmin()) {
                            console.log('customer');
                            if ((isIntake || isPublic) && !numberOfIntakeCompleted()) {
                                console.log('customer');
                                return (
                                    <Suspense fallback={<Spinner loaderClass="loader-fullscreen" />}>
                                        <Layout>
                                            <Component {...props} {...rest} />
                                        </Layout>
                                    </Suspense>
                                );
                            }

                            if ((isCustomer || isPublic) && numberOfIntakeCompleted()) {
                                console.log('customer');
                                return (
                                    <Suspense fallback={<Spinner loaderClass="loader-fullscreen" />}>
                                        <Layout>
                                            <Component {...props} {...rest} />
                                        </Layout>
                                    </Suspense>
                                );
                            }

                            if (numberOfIntakeCompleted()) {
                                console.log('customer');
                                window.location.href = '/customer/campaign-details';
                                return (null);
                            }
                            window.location.href = '/packages';
                            return (null);
                        }
                    }

                    if (!isAuthenticated() && !isPublic) {
                        console.log('customer');
                        return (<Redirect to="/login" />);
                    }
                }}
            />
        </>
    );
};


export default React.memo(RouteWrapper);
