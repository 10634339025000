import { API } from '../../helpers/apiHelpers';

const getLocationsService = (campaignId) => {
    const url = '/campaigns/' + campaignId + '/location';
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updateLocationsService = (campaignId, formData) => {
    const url = '/campaigns/' + campaignId + '/locations';
    return API.post(url, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export { getLocationsService, updateLocationsService };
