import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    campaignObjectiveId: 0,
    mediaApproachId: 0,
    isSharedBudget: null,
    mediaBudgetTotal: 0,
    isDetailedFlighting: null,
    priceOverideFields: '',
    demographicId: 0,
    intakeStepId: 0,
    locationBudgetComment: '',
    datesFlightingComment: '',
    objectiveApproachComment: '',
    mediaMixComment: '',
    audienceComment: '',
    customerSuccessPersonId: 0,
    startDate: null,
    endDate: null,
    isMarketingSupport: null,
    marketingSupportComment: '',
    analysisStartDate: null,
    analysisEndDate: null,
    isIntakeComplete: null,
    errorMessage: null,
    loading: false,
    redirect: false,
    packageId: null,
    campaignId: null
};

const campaignStart = (state, action) => {
    return updateObject(state, { errorMessage: null, loading: true });
};

const startCampaignSuccess = (state, action) => {
    return updateObject(state, {
        redirect: true,
        packageId: action.data.packageId,
        campaignId: action.data.id,
        loading: false
    });
};
const startCampaignFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.errorMessage,
        loading: false
    });
};

export const updateCampaignRedirect = (state, action) => {
    return updateObject(state, {
        redirect: false,
        errorMessage: ''
    });
};

const getCampaignSuccess = (state, action) => {
    return updateObject(state, {
        packageId: action.data && action.data.packageId,
        campaignId: action.data && action.data.id
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.CAMPAIGN_START:
        return campaignStart(state, action);
    case actionTypes.CAMPAIGN_SUCCESS:
        return startCampaignSuccess(state, action);
    case actionTypes.CAMPAIGN_FAIL:
        return startCampaignFail(state, action);
    case actionTypes.UPDATE_CAMPAIGN_REDIRECT:
        return updateCampaignRedirect(state, action);
    case actionTypes.GET_CAMAPIGN_SUCCESS:
        return getCampaignSuccess(state, action);
    case actionTypes.DELETE_CAMPAIGN_SUCCESS:
        return updateObject(state, { campaignId: null });
    default:
        return state;
    }
};

export default reducer;
