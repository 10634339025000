/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import {
    CAMPAIGN_DETAILS_START,
    STORE_CAMPAIGN_ID,
    CAMPAIGN_DETAILS_END,
    ADMIN_CLEAR_ALL_CAMPAIGN_STORE,

    // Flighting Table Section
    GET_LOCATION_LIST_SUCCESS,
    GET_LOCATION_LIST_ERROR_HANDLER,
    CLOSE_CREATIVE_UPLOAD_POPUP,

    // Campaign Details Section
    GET_ADMIN_CAMPAIGN_DETAILS_SUCCESS,
    GET_ADMIN_CAMPAIGN_DETAILS_FAIL,
    UPDATE_CAMPAIGN_DATES_ERROR,
    CLEAR_ERROR_MESSAGE,

    // Reward Points Section
    CAMPAIGN_REWARDS_FAIL,
    GET_CAMPAIGN_REWARDS_SUCCESS,
    SHOW_ADD_SUBTRACT_REWARD_POINTS,
    UPDATE_REWARD_POINT_SUCCESS,
    UPDATE_CAMPAIGN_REWARDS_FAIL,

    // Document Section
    CAMPAIGN_DOCUMENTS_FAIL,
    GET_CAMPAIGN_DOCUMENTS_SUCCESS,
    DELETE_CAMPAIGN_DOCUMENT_SUCCESS,
    ADMIN_DOCUMENT_POST_START,
    ADMIN_DOCUMENT_POST_SUCCESS,
    ADMIN_DOCUMENT_POST_ERROR_HANDLER,
    ADMIN_DOCUMENT_POST_END,

    // Tasks Section
    GET_CAMPAIGN_TASKS_SUCCESS,
    GET_CAMPAIGN_TASKS_FAIL,
    SHOW_UPDATE_TASK_DETAILS_POPUP,
    HANDLE_ADD_NEW_TASK_POPUP,
    NEW_TASK_ERROR_HANDLER,
    UPDATE_TASK_DETAILS_ERROR,
    UPDATE_TASK_DETAILS_SUCCESS,

    // Message Section
    ADMIN_MESSAGE_LIST_FAIL,
    GET_ADMIN_MESSAGE_LIST_SUCCESS,
    ADMIN_GET_MESSAGE_LIST_START,
    ADMIN_SHOW_COMPOSE_MESSAGE,
    ADMIN_MESSAGE_ERROR_HANDLER,
    ADMIN_SHOW_VIEW_MESSAGE,
    UPDATE_CAMPAIGN_DATES,
    FETCH_CAMPAIGN_DETAILS_START,
    FETCH_CAMPAIGN_DETAILS_END
} from './actionTypes';

import {
    getCampaignSercvice,
    getCampaignRewardsService,
    getCampaignTasksService,
    getCampaignDocumentsService,
    deleteCampaignDocumentsService,
    updateRewardPointsService,
    postFileUploadDetails,
    postNewTask,
    getTaskDetails,
    updateTaskDetailsService,
    updateTaskInformation,
    updateCampaignDate,
    markAsCompleteTaskService
} from '../services/campaign';
import { getAdminDeliverableListSuccess, getAdminDeliverableList } from './adminDeliverables';
import { getCompanyLocations } from '../services/company';
import { getDeliverablesService } from '../services/adminDeliverables';
import { getFlighting } from './adminFlightingTable';
import { getCampaignMessageListService, markAsReadOrUnreadService } from '../services/adminMessageList';
import {
    postNewMessage,
    getMessageDetails
} from '../services/customerCampaignDetails';
import { getAllActionItems } from './adminCustomerList';
import {
    setErrorMessage,
    isArrayEmpty,
    dateFormatter,
    isObjectEmpty,
    isMonday,
    addDaysToDate,
    subtractDaysToDate,
    addWeeksToDate,
    getDateInString
} from '../../utils/utility_helpers';
import { TaskType, TaskIds } from '../../components/Constants';
import { getAdminCreativesList, getAdminPromotionsList, getAdminChannelsList } from './adminCreativeUploads';

const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            return resolve({
                fileName: file.name,
                base64: reader.result
            });
        };
        reader.onerror = (error) => {
            console.log(error);
            return reject(error);
        };
        console.log(reader);
    });
};

const getAdminCampaignDetailsSuccess = (details) => {
    const {
        startDate,
        endDate,
        createdDateTime,
        campaignCompany,
        campaignPackage,
        isHidden,
        isCompleted
    } = details;
    const data = {};
    const campaignDates = {};
    data.startDate = startDate || '';
    data.endDate = endDate || '';
    data.createdDateTime = createdDateTime ? dateFormatter(createdDateTime, 'utcToLocal') : '';
    data.companyLegalName = campaignCompany.companyLegalName || '';
    data.companyId = campaignCompany.id || '';
    data.packageType = campaignPackage.packageName || '';
    data.isArchivedCampaign = (isHidden || isCompleted) || false;
    data.isCompleted = isCompleted;
    data.campaignPackage = campaignPackage;
    campaignDates.campaignStartDate = startDate || '';
    campaignDates.campaignEndDate = endDate || '';
    campaignDates.isEditable = false;
    campaignDates.campaignCreatedDate = data.createdDateTime;
    campaignDates.isCampaignDateUpdated = true;
    let todayDate = new Date();

    console.log(isMonday(todayDate));
    while (!isMonday(todayDate)) {
        todayDate = addDaysToDate(todayDate, 1);
    }
    console.log(isMonday(todayDate));
    campaignDates.minStartDate = dateFormatter(todayDate, 'MM-DD-YYYY');
    const endDateMinimumWeeks = 1;
    const campaignEndDate = addWeeksToDate(todayDate, endDateMinimumWeeks);
    campaignDates.minEndDate = dateFormatter(subtractDaysToDate(campaignEndDate, 1), 'MM-DD-YYYY');
    data.campaignDates = campaignDates;

    return {
        type: GET_ADMIN_CAMPAIGN_DETAILS_SUCCESS,
        data
    };
};

const getAdminCampaignDetailsFail = (errorMessage) => {
    return {
        type: GET_ADMIN_CAMPAIGN_DETAILS_FAIL,
        errorMessage
    };
};

const getCampaignRewardsSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_REWARDS_SUCCESS,
        data
    };
};

const getCampaignDocumentsSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_DOCUMENTS_SUCCESS,
        data
    };
};

const updateRewardsPointSuccess = (data) => {
    return {
        type: UPDATE_REWARD_POINT_SUCCESS,
        data
    };
};

const clearAllCampaignStore = () => {
    return {
        type: ADMIN_CLEAR_ALL_CAMPAIGN_STORE
    };
};

/* Tasks Section Start */
const getCampaignTasks = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });
            const response = await getCampaignTasksService(campaignId);
            dispatch({
                type: GET_CAMPAIGN_TASKS_SUCCESS,
                data: response.data.data
            });
            console.log(response);
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({
                type: GET_CAMPAIGN_TASKS_FAIL,
                errorMessage
            });
        }
    };
};

/* Campaign Details Section Start */
const getAdminCampaignDetails = (campaignId, isMIMPackageSelected) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });
            dispatch({
                type: STORE_CAMPAIGN_ID,
                data: campaignId
            });
            const response = await getCampaignSercvice(campaignId);
            const { data } = response;
            console.log(data, isMIMPackageSelected);

            // Flighting Table Location List Section
            if (data && data.resultCount > 0 && !isMIMPackageSelected) {
                const { campaignCompany } = data.data;
                const { id: companyId } = campaignCompany;
                if (companyId) {
                    const locationResponse = await getCompanyLocations(companyId);
                    console.log(locationResponse);
                    const { data: locationData } = locationResponse;
                    const locationList = [];
                    if (locationData && locationData.resultCount > 0 && locationData.data) {
                        locationData.data.map((item) => {
                            const { id, storeLocationName } = item;
                            const locationObject = {};
                            locationObject.id = id;
                            locationObject.storeLocationName = storeLocationName;
                            locationObject.isAssigned = false;
                            locationObject.isChecked = false;
                            locationList.push(locationObject);
                            return null;
                        });
                        dispatch({
                            type: GET_LOCATION_LIST_SUCCESS,
                            data: locationList
                        });
                    }
                }
            }
            dispatch(getAdminCampaignDetailsSuccess(response.data.data));
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            console.log(error);
            const errorMessage = setErrorMessage(error);
            dispatch(getAdminCampaignDetailsFail(errorMessage));
            dispatch({
                type: GET_LOCATION_LIST_ERROR_HANDLER,
                data: errorMessage
            });
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        }
    };
};

const updateCampaignDates = (data = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: FETCH_CAMPAIGN_DETAILS_START
            });
            const { campaignDates, campaignId } = data;
            const putData = {};
            putData.startDate = campaignDates.campaignStartDate;
            putData.endDate = campaignDates.campaignEndDate;

            const response = await updateCampaignDate(campaignId, putData);
            if (response) {
                const campaign = {};
                campaign.campaignStartDate = getDateInString(campaignDates.campaignStartDate);
                campaign.campaignEndDate = getDateInString(campaignDates.campaignEndDate);
                campaign.isEditable = false;
                campaign.campaignCreatedDate = getDateInString(campaignDates.campaignCreatedDate);
                campaign.isCampaignDateUpdated = !campaignDates.isCampaignDateUpdated;
                campaign.minStartDate = campaignDates.minStartDate;
                campaign.minEndDate = campaignDates.minEndDate;
                const responseData = {};
                responseData.campaignDates = campaign;

                dispatch({
                    type: UPDATE_CAMPAIGN_DATES,
                    data: responseData
                });
                dispatch({
                    type: FETCH_CAMPAIGN_DETAILS_START
                });
                dispatch(getFlighting(campaignId));
                await dispatch(getCampaignTasks(campaignId));
            } else {
                dispatch({
                    type: UPDATE_CAMPAIGN_DATES_ERROR,
                    data: setErrorMessage(response)
                });
            }
            dispatch({
                type: FETCH_CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({
                type: FETCH_CAMPAIGN_DETAILS_END
            });
            dispatch({
                type: UPDATE_CAMPAIGN_DATES_ERROR,
                data: errorMessage
            });
        }
    };
};

const clearCampaignErrorMessage = () => {
    return {
        type: CLEAR_ERROR_MESSAGE
    };
};
/* Campaign Details Section End */

/* Reward Points Section Start */
const getCampaignRewards = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });
            const rewardResponse = await getCampaignRewardsService(campaignId);
            console.log(rewardResponse);
            dispatch(getCampaignRewardsSuccess(rewardResponse.data.data));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({
                type: CAMPAIGN_REWARDS_FAIL,
                errorMessage
            });
        }
    };
};

const updateRewardPoints = (object) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });
            const rewardResponse = await updateRewardPointsService(object);
            console.log(rewardResponse);
            dispatch(updateRewardsPointSuccess(rewardResponse.data.data));
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({
                type: UPDATE_CAMPAIGN_REWARDS_FAIL,
                errorMessage
            });
        }
    };
};

const showAddSubtractPointsPopupUpdate = (data) => {
    return {
        type: SHOW_ADD_SUBTRACT_REWARD_POINTS,
        data
    };
};
/* Reward Points Section End */

const buildEditTaskData = (details = {}) => {
    console.log(details);
    const data = {};
    if (!isObjectEmpty(details)) {
        const {
            id,
            taskOwner,
            fixedStartDate,
            isActionable,
            isCompleted,
            pastDueLag,
            reminderLeadTime,
            revisedDuration,
            taskDescription,
            pastDueDescription,
            completedDescription,
            workFlowTaskRelationshipDetails
        } = details;

        const predecessorWorkFlowTaskId = workFlowTaskRelationshipDetails && workFlowTaskRelationshipDetails.predecessorWorkFlowTaskId ? workFlowTaskRelationshipDetails.predecessorWorkFlowTaskId : 0;
        const predecessorLag = workFlowTaskRelationshipDetails && workFlowTaskRelationshipDetails.lag ? workFlowTaskRelationshipDetails.lag : 0;
        data.workFlowTaskId = id || 0;
        data.taskOwner = taskOwner || '';
        data.fixedStartDate = fixedStartDate ? dateFormatter(fixedStartDate, 'MM-DD-YYYY') : '';
        data.isActionable = isActionable || false;
        data.isCompleted = isCompleted || false;
        data.pastDueLag = pastDueLag || 0;
        data.reminderLeadTime = reminderLeadTime || 0;
        data.revisedDuration = revisedDuration || 0;
        data.taskDescription = taskDescription || '';
        data.pastDueDescription = pastDueDescription || '';
        data.completedDescription = completedDescription || '';
        data.predecessorWorkFlowTaskId = predecessorWorkFlowTaskId;
        data.predecessorLag = predecessorLag;
    }
    return data;
};

const showUpdateTaskDetails = (data) => {
    return {
        type: SHOW_UPDATE_TASK_DETAILS_POPUP,
        data
    };
};

const handleAddNewTask = (details = {}) => {
    return async (dispatch) => {
        try {
            const {
                showNewTask,
                taskType,
                campaignId,
                task,
                creativeUploadTask,
                tableLocationDetails
            } = details;
            const data = details;
            data.editTaskDetails = {};
            if (showNewTask && taskType === 'editTask') {
                dispatch({
                    type: CAMPAIGN_DETAILS_START
                });

                const response = await getTaskDetails(campaignId, task.workFlowTaskId);

                if (response && response.data.resultCount) {
                    const { data: getData } = response.data;
                    data.editTaskDetails = getData;
                    const editTaskData = buildEditTaskData(getData);
                    data.editTaskDetails = editTaskData;
                    dispatch({
                        type: HANDLE_ADD_NEW_TASK_POPUP,
                        data
                    });

                    if (creativeUploadTask) {
                        data.tableLocationDetails = tableLocationDetails;
                        dispatch({
                            type: CLOSE_CREATIVE_UPLOAD_POPUP,
                            data
                        });
                    }

                    dispatch({
                        type: CAMPAIGN_DETAILS_END
                    });
                } else {
                    dispatch({
                        type: NEW_TASK_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });
                    dispatch({
                        type: CAMPAIGN_DETAILS_END
                    });
                }
            } else {
                dispatch({
                    type: HANDLE_ADD_NEW_TASK_POPUP,
                    data
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: NEW_TASK_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        }
    };
};

const postNewTaskDetails = (campaignId = 0, taskType, workFlowTaskId, data = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });

            if (taskType === 'newTask') {
                const postResponse = await postNewTask(campaignId, data);

                if (postResponse && postResponse.data.resultCount) {
                    dispatch(getCampaignTasks(campaignId));
                    dispatch(getFlighting(campaignId));
                } else {
                    dispatch({
                        type: NEW_TASK_ERROR_HANDLER,
                        data: setErrorMessage(postResponse)
                    });
                }
            } else if (taskType === 'editTask') {
                const response = await updateTaskInformation(campaignId, workFlowTaskId, data);

                if (response && response.status === 204) {
                    dispatch(getCampaignTasks(campaignId));
                    dispatch(getFlighting(campaignId));
                } else {
                    dispatch({
                        type: NEW_TASK_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });
                }
            }

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: NEW_TASK_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        }
    };
};

const updateTaskDetails = (campaignId, task, data, fileObject, companyId) => {
    console.log(task, 'task');
    return async (dispatch) => {
        try {
            const postData = data;
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });
            if (task.taskType === TaskType.UploadDocument
                || task.taskType === TaskType.DocumentUploaded
                || (task.taskType === TaskType.UploadFileOrSendEmail && postData.isDirectFileUpload)) {
                if (fileObject.documentUploadOptions.uploadedFiles
                    && !isArrayEmpty(fileObject.documentUploadOptions.uploadedFiles)) {
                    await Promise.all(fileObject.documentUploadOptions.uploadedFiles.map(async (item) => {
                        const file = await toBase64(item);
                        console.log(file);
                        const base64Data = file.base64.split(',')[1];
                        postData.fileName = file.fileName;
                        postData.media = base64Data;
                    }));
                }
            }

            let postResponse = {};
            if (task.isMarkAsComplete) {
                postResponse = await markAsCompleteTaskService(task.workFlowTaskId);
            } else {
                postResponse = await updateTaskDetailsService(campaignId, task.workFlowTaskId, postData);
            }

            console.log(postResponse);
            if (postResponse.data.resultCount) {
                if ((task.taskType === TaskType.YesOrNo && !postData.isMediaBuy)
                || (data.reviewMediaPlan && data.reviewMediaPlan.isReject)
                || (task.taskId === TaskIds.EndOfCampaign)
                ) {
                    window.location.href = '/admin/customer-details?id=' + btoa(companyId);
                } else {
                    dispatch(getCampaignTasks(campaignId));
                    if (task.taskType === TaskType.UploadDocument
                    || task.taskType === TaskType.DocumentUploaded
                    || task.TaskType === TaskType.UploadFileOrSendEmail) {
                        const response = await getCampaignDocumentsService(campaignId);
                        if (response.data.resultCount > 0 && response.data.data) {
                            dispatch(getCampaignDocumentsSuccess(response.data.data));
                        }
                        const deliverableResponse = await getDeliverablesService(campaignId);
                        const savedData = {};
                        savedData.deliverableList = deliverableResponse.data.data;
                        dispatch(getAdminDeliverableListSuccess(savedData));
                    // getAdminDeliverableList(campaignId);
                    }
                    dispatch({
                        type: UPDATE_TASK_DETAILS_SUCCESS
                    });
                }
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: UPDATE_TASK_DETAILS_ERROR,
                errorMessage: setErrorMessage(error)
            });
        }
    };
};
/* Tasks Section End */

/* Documents Section Start */
const getCampaignDocuments = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });
            const response = await getCampaignDocumentsService(campaignId);
            if (response.data.resultCount > 0 && response.data.data) {
                dispatch(getCampaignDocumentsSuccess(response.data.data));
            }
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({
                type: CAMPAIGN_DOCUMENTS_FAIL,
                errorMessage
            });
        }
    };
};

const deleteCampaignDocument = (campaignId, fileId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });
            const response = await deleteCampaignDocumentsService(campaignId, fileId);
            console.log(response);
            dispatch({
                type: DELETE_CAMPAIGN_DOCUMENT_SUCCESS,
                data: fileId
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({
                type: CAMPAIGN_DOCUMENTS_FAIL,
                errorMessage
            });
            console.log(errorMessage);
        }
    };
};

const postDocumentDetails = (data = {}) => {
    console.log(data);
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });
            dispatch({
                type: ADMIN_DOCUMENT_POST_START
            });
            const {
                campaignId,
                fileType,
                documentUploadOptions
            } = data;

            const postData = {};
            postData.fileType = fileType;
            postData.url = '';
            postData.files = null;
            if (documentUploadOptions.uploadedFiles
                && !isArrayEmpty(documentUploadOptions.uploadedFiles)) {
                await Promise.all(documentUploadOptions.uploadedFiles.map(async (item) => {
                    const file = await toBase64(item);
                    console.log(file);
                    const base64Data = file.base64.split(',')[1];
                    const fileData = {};
                    fileData.media = base64Data;
                    fileData.fileName = file.fileName;
                    fileData.mimeType = item.type;
                    fileData.dimension = item.width && item.height ? item.width + 'x' + item.height : '';
                    postData.files = fileData;
                    console.log('PostData', postData);
                }));
            }

            const postResponse = await postFileUploadDetails(campaignId, postData);

            if (postResponse) {
                dispatch({ type: ADMIN_DOCUMENT_POST_SUCCESS });
                dispatch(getCampaignDocuments(campaignId));
                dispatch({
                    type: CAMPAIGN_DETAILS_END
                });
            } else {
                dispatch({
                    type: ADMIN_DOCUMENT_POST_ERROR_HANDLER,
                    data: setErrorMessage(postResponse)
                });
                dispatch({
                    type: CAMPAIGN_DETAILS_END
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: ADMIN_DOCUMENT_POST_END
            });
            dispatch({
                type: ADMIN_DOCUMENT_POST_ERROR_HANDLER,
                data: setErrorMessage(error)
            });
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        }
    };
};
/* Document Section End */

/* Message Section Start */

const getAdminMessageList = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ADMIN_GET_MESSAGE_LIST_START
            });

            const response = await getCampaignMessageListService(campaignId);

            const savedData = {};
            savedData.messageList = response.data.data;

            dispatch({
                type: GET_ADMIN_MESSAGE_LIST_SUCCESS,
                data: savedData
            });
            console.log(savedData);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({
                type: ADMIN_MESSAGE_LIST_FAIL,
                data: errorMessage
            });
        }
    };
};

const markAsReadOrUnread = (messageIds, isRead, campaignId) => {
    return async (dispatch) => {
        try {
            const putData = {};
            putData.messageIds = messageIds;
            putData.isRead = isRead;
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });
            const response = await markAsReadOrUnreadService(putData, campaignId);
            if (response.status === 204 || response.status === 200) {
                console.log(response);
                dispatch(getAllActionItems());
                const responseGet = await getCampaignMessageListService(campaignId);
                const savedData = {};
                savedData.messageList = responseGet.data.data;
                dispatch({
                    type: GET_ADMIN_MESSAGE_LIST_SUCCESS,
                    data: savedData
                });
                console.log(savedData);
            } else {
                dispatch({
                    type: ADMIN_MESSAGE_LIST_FAIL,
                    data: response.data.message
                });
            }
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({
                type: ADMIN_MESSAGE_LIST_FAIL,
                data: errorMessage
            });
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        }
    };
};

const handleComposeMessage = (data) => {
    return {
        type: ADMIN_SHOW_COMPOSE_MESSAGE,
        data
    };
};

const postNewMessageDetails = (data = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CAMPAIGN_DETAILS_START
            });

            const {
                campaignId,
                isHighPriority,
                subject,
                content,
                uploadedFiles,
                threadId,
                type,
                emailList,
                isCustomerRequired,
                attachments
            } = data;

            const postData = {};
            postData.toPersonId = null;
            postData.isCustomerRequired = isCustomerRequired;
            postData.toAddresses = emailList;
            postData.isHighPriority = isHighPriority;
            postData.subject = subject;
            postData.content = content;
            postData.threadId = null;
            postData.isReply = false;
            postData.forwardFileDetails = [];
            postData.metaData = null;
            postData.fileDetails = [];

            if (type === 'replayMessage') {
                postData.threadId = threadId;
                postData.isReply = true;
            } else if (type === 'forwardMessage') {
                if (!isCustomerRequired) {
                    postData.threadId = null;
                } else {
                    postData.threadId = threadId;
                }
            }

            if (attachments && !isArrayEmpty(attachments)) {
                attachments.map((item) => {
                    const file = {};
                    file.fileId = item.fileId;
                    file.fileName = item.fileName;
                    file.url = item.filePath;
                    file.mimeType = item.mimeType;
                    postData.forwardFileDetails.push(file);

                    return null;
                });
            }

            if (uploadedFiles
                && !isArrayEmpty(uploadedFiles)) {
                await Promise.all(uploadedFiles.map(async (item) => {
                    const file = await toBase64(item);
                    const base64Data = file.base64.split(',')[1];

                    const fileData = {};

                    fileData.media = base64Data;
                    fileData.fileName = file.fileName;
                    fileData.mimeType = item.type;
                    fileData.dimension = item.width && item.height ? item.width + 'x' + item.height : '';
                    postData.fileDetails.push(fileData);
                }));
            }

            const response = await postNewMessage(campaignId, postData);

            if (response) {
                await dispatch(getAdminMessageList(campaignId));
                if (type === 'newMessage') {
                    dispatch({
                        type: ADMIN_SHOW_COMPOSE_MESSAGE,
                        data: {
                            showComposeMessage: false
                        }
                    });
                } else {
                    dispatch({
                        type: ADMIN_SHOW_VIEW_MESSAGE,
                        data: {
                            showMessageDetails: false,
                            messageDetailsList: []
                        }
                    });
                }
            } else {
                dispatch({
                    type: ADMIN_MESSAGE_ERROR_HANDLER,
                    data: setErrorMessage(response)
                });
            }

            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            console.log(errorMessage);
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });

            dispatch({
                type: ADMIN_MESSAGE_ERROR_HANDLER,
                data: errorMessage
            });
        }
    };
};

const handleViewMessage = (data) => {
    return async (dispatch) => {
        const details = data;
        const {
            showMessageDetails,
            campaignId,
            isRead,
            messageId,
            threadId
        } = details;

        details.messageDetailsList = [];

        if (showMessageDetails) {
            try {
                dispatch({
                    type: CAMPAIGN_DETAILS_START
                });

                const response = await getMessageDetails(campaignId, threadId);

                if (!isRead) {
                    await dispatch(markAsReadOrUnread(messageId, !isRead, campaignId));
                }
                dispatch(getAllActionItems());

                if (response) {
                    const { data: responseData } = response.data;
                    console.log(responseData);
                    const messageDetailsList = responseData || [];
                    details.messageDetailsList = messageDetailsList;
                    console.log(response);
                    dispatch({
                        type: ADMIN_SHOW_VIEW_MESSAGE,
                        data: details
                    });
                } else {
                    dispatch({
                        type: ADMIN_MESSAGE_ERROR_HANDLER,
                        data: setErrorMessage(response)
                    });
                }

                dispatch({
                    type: CAMPAIGN_DETAILS_END
                });
            } catch (error) {
                const errorMessage = setErrorMessage(error);
                console.log(errorMessage);

                dispatch({
                    type: CAMPAIGN_DETAILS_END
                });

                dispatch({
                    type: ADMIN_MESSAGE_ERROR_HANDLER,
                    data: errorMessage
                });
            }
        } else {
            dispatch({
                type: ADMIN_SHOW_VIEW_MESSAGE,
                data: details
            });
        }
    };
};

const getAllCampaignDetails = (data = {}) => {
    return async (dispatch) => {
        try {
            const {
                campaignId,
                isMIMPackageSelected
            } = data;

            dispatch({
                type: FETCH_CAMPAIGN_DETAILS_START
            });

            dispatch(getAdminCampaignDetails(campaignId, isMIMPackageSelected));
            dispatch(getCampaignTasks(campaignId));
            dispatch(getAdminMessageList(campaignId));
            dispatch(getAdminDeliverableList(campaignId));
            dispatch(getCampaignDocuments(campaignId));

            if (!isMIMPackageSelected) {
                dispatch(getFlighting(campaignId));
                dispatch(getCampaignRewards(campaignId));
                dispatch(getAdminChannelsList(campaignId));
                dispatch(getAdminPromotionsList(campaignId));
                dispatch(getAdminCreativesList(campaignId));
            }
            await dispatch(handleViewMessage(data));
            dispatch({
                type: FETCH_CAMPAIGN_DETAILS_END
            });
        } catch (error) {
            dispatch({
                type: CAMPAIGN_DETAILS_END
            });
            dispatch({
                type: FETCH_CAMPAIGN_DETAILS_END
            });
        }
    };
};

export {
    getAdminCampaignDetails,
    getCampaignRewards,
    getCampaignTasks,
    getCampaignDocuments,
    deleteCampaignDocument,
    showAddSubtractPointsPopupUpdate,
    updateRewardPoints,
    postDocumentDetails,
    showUpdateTaskDetails,
    handleAddNewTask,
    postNewTaskDetails,
    updateTaskDetails,
    markAsReadOrUnread,
    getAdminMessageList,
    handleComposeMessage,
    postNewMessageDetails,
    handleViewMessage,
    updateCampaignDates,
    getAllCampaignDetails,
    clearCampaignErrorMessage,
    clearAllCampaignStore
};
