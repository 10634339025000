import { API } from '../../helpers/apiHelpers';

const validateCompany = (formData) => {
    const url = '/company/search';
    return API.post(url, formData)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const validatePerson = (formData) => {
    const url = '/persons/search';
    return API.post(url, formData)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const sendInvitationService = (formData) => {
    const url = '/invitations';
    return API.post(url, formData)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export { validateCompany, validatePerson, sendInvitationService };
