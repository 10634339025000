import { API } from '../../helpers/apiHelpers';

const loginService = async (data) => {
    const url = '/login';
    try {
        return await API.post(url, data);
    } catch (error) {
        return Promise.reject(error);
    }
};

const logoutService = async () => {
    const url = '/logout';
    try {
        return await API.post(url);
    } catch (error) {
        return Promise.reject(error);
    }
};

const resetPassword = async (data) => {
    const url = '/resetpassword';
    try {
        return await API.put(url, data);
    } catch (error) {
        return Promise.reject(error);
    }
};

const getCompanyDetailsById = async (companyId) => {
    const url = `/company/${companyId}`;
    try {
        return await API.get(url);
    } catch (error) {
        return Promise.reject(error);
    }
};

const activateAccount = async (activationCode) => {
    const url = '/notifications';
    try {
        return await API.post(url, activationCode);
    } catch (error) {
        return Promise.reject(error);
    }
};

const resetCredentials = async (formData) => {
    const url = '/resetcredentials';
    try {
        return await API.put(url, formData);
    } catch (error) {
        return Promise.reject(error);
    }
};
export {
    loginService,
    logoutService,
    resetPassword,
    getCompanyDetailsById,
    activateAccount,
    resetCredentials
};
