/* eslint-disable indent */
/* eslint-disable no-tabs */
import { updateObject } from '../../utils/reducer_utility';
import {
    ADMIN_CLEAR_ALL_CAMPAIGN_STORE,

    // Flighting Table Section
    ADMIN_GET_FLIGHTING_START,
    GET_FLIGHTING_SUCCESS,
    FLIGHTING_DETAILS_ERROR_HANDLER,
    UPDATE_FLIGHTING_STATUS,
	UPDATE_FLIGHTING_STATUS_ERROR,
	GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_START,
	GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_COMPLETE,
	GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_FAIL,

    // Promotion Section
    POST_PROMOTION_DETAILS,
    PROMOTION_MODAL_HANDLER,
    PROMOTION_ERROR_HANDLER,
    PROMOTION_LOCATIONS_GROUP_ERROR_HANDLER,

    // Location Section
    LOCATION_MODAL_HANDLER,
    POST_LOCATION_DETAILS,
    LOCATIONS_ERROR_HANDLER,
    GET_LOCATION_LIST_SUCCESS,
    GET_LOCATION_LIST_ERROR_HANDLER,
    GET_CHANNEL_LIST_SUCCESS,

    // Creative Upload Section
    POST_CREATIVE_DETAILS,
    GET_CREATIVE_DETAILS,
    UPDATE_CREATIVE_UPLOAD_LIST,
    CREATIVE_UPLOAD_ERROR_HANDLER,
    DELETE_CREATIVE_DETAILS,
    CLOSE_CREATIVE_UPLOAD_POPUP,
    SUBMIT_CREATIVE_DETAILS_FOR_REVIEW,
    HANDLE_WARNING_BOX,
	PROMOTION_LOCATIONS_GROUP,

	// Dark days
	UPDATE_SHOW_DARK_DAY,
	ADD_CAMPIGN_DARK_DAY_START,
	ADD_CAMPIGN_DARK_DAY_SUCCESS,
	ADD_CAMPIGN_DARK_DAY_FAIL,
	UPDATE_SHOW_EDIT_DARK_DAY,
	UPDATE_CAMPIGN_DARK_DAY_START,
	UPDATE_CAMPIGN_DARK_DAY_SUCCESS,
	UPDATE_CAMPIGN_DARK_DAY_FAIL,

	// customer request revision
	REQUEST_REVISION_START,
	REQUEST_REVISION_SUCCESS,
	REQUEST_REVISION_FAIL

} from '../actions/actionTypes';

const initialState = {
    getFlightingLoading: false,
    loading: false,

    // Flighting Table Section
    startDate: '',
    endDate: '',
    totalWeeks: [],
    optionList: [],
    errorMessage: '',
    savedCampaign: {},
    totalDays: [],
    weekOptions: [],
    tableLocationDetails: [],
    flightingStatusError: '',
    // Promotion Section
	// promotionDates: [[], []],
	promotionDates: [],
    showPromotion: false,
    promotionType: 'newPromotion',
    promotionErrorMessage: '',
    editPromotionData: {},
    locationGroupsDrowndownErrorHandler: '',

    // Location And Channel Section
    showLocation: false,
    locationErrorMessage: '',
    locationGroups: [],
    locationList: [],
    getLocationListError: '',
    channelList: [],

    // Creative Upload Section
    showCreativeUpload: false,
    channelTaskDetails: {},
    numberOfLocations: 0,
    basicCreativeDetails: {},
    showCreativeLibrary: false,
    uploadedCreativeList: [],
    isCreativeListUpdated: false,
    addAnotherCreative: false,
    selectedChannelForUpload: '',
    creativeUploadErrorMessage: '',
    unChangedUploadedCreativeList: [],
    locationGroupsDrowndown: [],
    taskCompletedFlag: false,
	locationGroupsSelect: [],
	locationGroupsForCopy: [],

	// dark days
	showDarkDays: false,
	darkDayErrorMessage: '',
	showEditDarkDay: false,
	selectedDarkDayForEdit: null,
	campaignLocationGroups: [],

	// customer request revision
	requestRevisionError: ''
};

const updateFlightingSchedule = (state, action) => {
    const {
        weekIndex,
        flightingOption,
        flightingStatusId
    } = action.data;

    const totalWeeks = [...state.weekOptions];
    totalWeeks[weekIndex].currentState = flightingOption;
    totalWeeks[weekIndex].flightingStatusId = flightingStatusId;

    return updateObject(state, {
        weekOptions: totalWeeks,
        isCampaignDetailsUpdated: true,
        flightingStatusError: ''
    });
};

const updateCreativeUploadList = (state, action) => {
    const {
        creativeListIndex,
        updatedData,
        uploadedCreativeList
    } = action.data;
    const { isCreativeListUpdated } = state;
    const lists = [...state.unChangedUploadedCreativeList];
    lists[creativeListIndex] = updatedData;
    lists[creativeListIndex].isEditable = false;

    return updateObject(state, {
        uploadedCreativeList,
        isCreativeListUpdated: !isCreativeListUpdated,
        creativeUploadErrorMessage: '',
        unChangedUploadedCreativeList: lists
    });
};

const deleteCreativeUploadList = (state, action) => {
    const {
        deleteCreativeIndex,
        uploadedCreativeList
    } = action.data;

    const { isCreativeListUpdated } = state;

    const unChangedLists = [...state.unChangedUploadedCreativeList];
    const list = [...uploadedCreativeList];

    unChangedLists.splice(deleteCreativeIndex, 1);
    list.splice(deleteCreativeIndex, 1);
    return updateObject(state, {
        unChangedUploadedCreativeList: unChangedLists,
        uploadedCreativeList: list,
        isCreativeListUpdated: !isCreativeListUpdated,
        creativeUploadErrorMessage: ''
    });
};

const adminFlightingTable = (state = initialState, action) => {
    switch (action.type) {
    case ADMIN_CLEAR_ALL_CAMPAIGN_STORE: {
        return initialState;
    }
    case ADMIN_GET_FLIGHTING_START:
        return updateObject(state, {
            getFlightingLoading: true,
            loading: false
        });
    case FLIGHTING_DETAILS_ERROR_HANDLER:
        return updateObject(state, {
            getFlightingLoading: false,
            errorMessage: action.data
        });

    // Flighting Table Section
    case GET_FLIGHTING_SUCCESS: {
        const {
            endDate,
            optionList,
            startDate,
            totalWeeks,
            weeks,
            totalDays,
            weekOptions,
            promotionDates,
            tableLocationDetails,
            campaignLocationGroups
        } = action.data;

        const savedCampaign = {};
        savedCampaign.endDate = endDate;
        savedCampaign.startDate = startDate;
        savedCampaign.weeks = weeks;

        return updateObject(state, {
            totalWeeks,
            startDate,
            endDate,
            optionList,
            getFlightingLoading: false,
            savedCampaign,
            errorMessage: '',
            totalDays,
            weekOptions,
            promotionDates,
            tableLocationDetails,
            campaignLocationGroups
        });
    }
    case UPDATE_FLIGHTING_STATUS:
        return updateFlightingSchedule(state, action);
    case UPDATE_FLIGHTING_STATUS_ERROR:
        return updateObject(state, {
            flightingStatusError: action.data
        });

    // Promotion Section
    case PROMOTION_ERROR_HANDLER:
        return updateObject(state, {
            loading: false,
            promotionErrorMessage: action.data
        });

    case POST_PROMOTION_DETAILS: {
        return updateObject(state, {
            loading: false,
            // promotionDates: action.data.promotionDates,
            promotionErrorMessage: '',
            showPromotion: false,
            // tableLocationDetails: action.data.tableLocationDetails,
            locationGroupsDrowndownErrorHandler: ''
        });
    }
    case PROMOTION_MODAL_HANDLER: {
        const {
            showPromotion,
            promotionType,
            editPromotionData,
            locationGroupsDrowndown
        } = action.data;

        return updateObject(state, {
            showPromotion,
            promotionType,
            promotionErrorMessage: '',
            editPromotionData,
            locationGroupsDrowndown,
            locationGroupsDrowndownErrorHandler: ''
        });
    }
    case PROMOTION_LOCATIONS_GROUP: {
        const {
            locationGroupsDrowndown
        } = action.data;

        return updateObject(state, {
            locationGroupsDrowndown,
            locationGroupsDrowndownErrorHandler: ''
        });
    }
    case PROMOTION_LOCATIONS_GROUP_ERROR_HANDLER: {
        const {
            locationGroupsDrowndownErrorHandler
        } = action.data;

        return updateObject(state, {
            locationGroupsDrowndownErrorHandler
        });
    }


    // Location And Channel Section
    case LOCATIONS_ERROR_HANDLER:
        return updateObject(state, {
            loading: false,
            locationErrorMessage: action.data
        });
    case LOCATION_MODAL_HANDLER: {
        const {
            showLocation,
            locationGroups,
            locationList,
            channelList
        } = action.data;

        return updateObject(state, {
            showLocation,
            locationErrorMessage: '',
            locationGroups,
            locationList,
            channelList
        });
    }
    case POST_LOCATION_DETAILS: {
        const {
            locations,
            tableLocationDetails,
            promotionDates
        } = action.data;

        return updateObject(state, {
            loading: false,
            locationList: locations,
            showLocation: false,
            locationErrorMessage: '',
            tableLocationDetails,
            promotionDates
        });
    }
    case GET_LOCATION_LIST_SUCCESS: {
        return updateObject(state, {
            locationList: action.data,
            getLocationListError: ''
        });
    }
    case GET_LOCATION_LIST_ERROR_HANDLER: {
        return updateObject(state, {
            getLocationListError: action.data
        });
    }
    case GET_CHANNEL_LIST_SUCCESS: {
        return updateObject(state, {
            channelList: action.data,
            errorMessage: ''
        });
    }

    // Creative Upload Section
    case GET_CREATIVE_DETAILS: {
        const {
            channelTaskDetails,
            numberOfLocations,
            addOtherCreative,
            selectedCreativeUploadChannel,
            unChangedUploadedCreativeList,
            uploadedCreativeList,
            basicCreativeDetails,
            taskCompletedFlag,
            locationGroupsSelect
        } = action.data;

        let { showCreativeUpload } = action.data;

        let showCreativeLibrary = false;
        let { selectedChannelForUpload } = state;

        if (basicCreativeDetails.predecessorWorkFlowTaskId && showCreativeUpload) {
            showCreativeLibrary = true;
            showCreativeUpload = false;
        } else if (uploadedCreativeList && uploadedCreativeList.length && showCreativeUpload) {
            showCreativeLibrary = true;
            showCreativeUpload = false;
        }
        // if task is completed, show only library
        if (taskCompletedFlag && showCreativeUpload) {
            showCreativeLibrary = true;
            showCreativeUpload = false;
        }
        selectedChannelForUpload = selectedCreativeUploadChannel || selectedChannelForUpload;

        let addAnotherCreative = false;

        if (addOtherCreative) {
            addAnotherCreative = true;
            return updateObject(state, {
                addAnotherCreative,
                showCreativeUpload,
                showCreativeLibrary,
                creativeUploadErrorMessage: ''
            });
        }

        return updateObject(state, {
            showCreativeUpload,
            channelTaskDetails,
            numberOfLocations,
            showCreativeLibrary,
            basicCreativeDetails,
            uploadedCreativeList,
            addAnotherCreative,
            selectedChannelForUpload,
            creativeUploadErrorMessage: '',
            unChangedUploadedCreativeList,
            taskCompletedFlag,
            locationGroupsSelect
        });
    }
    case POST_CREATIVE_DETAILS: {
        const {
            basicCreativeDetails,
            unChangedUploadedCreativeList,
            uploadedCreativeList,
            tableLocationDetails
        } = action.data;

        return updateObject(state, {
            basicCreativeDetails,
            showCreativeUpload: false,
            showCreativeLibrary: true,
            uploadedCreativeList,
            creativeUploadErrorMessage: '',
            unChangedUploadedCreativeList,
			tableLocationDetails,
			addAnotherCreative: false

        });
    }
    case UPDATE_CREATIVE_UPLOAD_LIST: {
        return updateCreativeUploadList(state, action);
    }
    case CREATIVE_UPLOAD_ERROR_HANDLER: {
        return updateObject(state, {
            creativeUploadErrorMessage: action.data
        });
    }
    case DELETE_CREATIVE_DETAILS: {
        return deleteCreativeUploadList(state, action);
    }
    case CLOSE_CREATIVE_UPLOAD_POPUP: {
        const {
            addAnotherCreative,
            tableLocationDetails
        } = action.data;

        if (addAnotherCreative) {
            return updateObject(state, {
                showCreativeLibrary: true,
                addAnotherCreative: false,
                showCreativeUpload: false,
                creativeUploadErrorMessage: ''
            });
        }

        return updateObject(state, {
            showCreativeUpload: false,
            channelTaskDetails: {},
            numberOfLocations: 0,
            basicCreativeDetails: [],
            showCreativeLibrary: false,
            uploadedCreativeList: [],
            isCreativeListUpdated: false,
            addAnotherCreative: false,
            selectedChannelForUpload: '',
            creativeUploadErrorMessage: '',
            unChangedUploadedCreativeList: [],
            tableLocationDetails
        });
    }
    case SUBMIT_CREATIVE_DETAILS_FOR_REVIEW:
        return updateObject(state, {
            showCreativeUpload: false,
            channelTaskDetails: {},
            numberOfLocations: 0,
            basicCreativeDetails: [],
            showCreativeLibrary: false,
            uploadedCreativeList: [],
            isCreativeListUpdated: false,
            addAnotherCreative: false,
            selectedChannelForUpload: '',
            creativeUploadErrorMessage: '',
            unChangedUploadedCreativeList: [],
            tableLocationDetails: action.data.tableLocationDetails,
            campaignLocationGroups: action.data.campaignLocationGroups
        });
    case HANDLE_WARNING_BOX: {
        const { type } = action.data;
        const basicCreativeDetails = { ...state.basicCreativeDetails };

        if (type === 'allLocation') {
            basicCreativeDetails.showAllLocationWarning = true;
        } else if (type === 'thisLocationOnly') {
            basicCreativeDetails.showThisLocationWarning = true;
        }

        return updateObject(state, {
            basicCreativeDetails
        });
    }
	case GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_START: {
		return updateObject(state, {
			loading: true
		});
	}

	case GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_COMPLETE: {
			return updateObject(state, {
			locationGroupsForCopy: action.data,
			loading: false
		});
	}

	case GET_PROMOTION_LOCATION_GROUP_FOR_CHANNEL_FAIL: {
		return updateObject(state, {
			loading: false,
			errorMessage: action.data
		});
	}

	case UPDATE_SHOW_DARK_DAY: {
		return updateObject(state, {
			showDarkDays: action.data,
			darkDayErrorMessage: ''
		});
	}
	case ADD_CAMPIGN_DARK_DAY_START: {
		return updateObject(state, {
			loading: true,
			darkDayErrorMessage: ''
		});
	}
	case ADD_CAMPIGN_DARK_DAY_SUCCESS: {
		return updateObject(state, {
			loading: false,
			darkDayErrorMessage: '',
			showDarkDays: false
		});
	}
	case ADD_CAMPIGN_DARK_DAY_FAIL: {
		return updateObject(state, {
			loading: false,
			darkDayErrorMessage: action.data
		});
	}
	case UPDATE_SHOW_EDIT_DARK_DAY: {
		const {
			show,
			darkDayId,
			endDate,
			locationGroupChannelId,
			startDate,
			channelName,
			campaignLocationGroupId,
			locationGroupName,
			isDeleted
		} = action.data;
		let selectedDarkDayForEdit = null;
		if (show) {
			selectedDarkDayForEdit = {
				darkDayId,
				endDate,
				locationGroupChannelId,
				startDate,
				channelName,
				campaignLocationGroupId,
				locationGroupName,
				isDeleted
			};
		}
		return updateObject(state, {
			showEditDarkDay: show,
			selectedDarkDayForEdit
		});
	}
	case UPDATE_CAMPIGN_DARK_DAY_START: {
		return updateObject(state, {
			loading: true,
			darkDayErrorMessage: ''
		});
	}
	case UPDATE_CAMPIGN_DARK_DAY_SUCCESS: {
		return updateObject(state, {
			loading: false,
			darkDayErrorMessage: '',
			showEditDarkDay: false
		});
	}
	case UPDATE_CAMPIGN_DARK_DAY_FAIL: {
		return updateObject(state, {
			loading: false,
			darkDayErrorMessage: action.data
		});
	}
	case REQUEST_REVISION_START: {
		return updateObject(state, {
			loading: true,
			requestRevisionError: ''
		});
	}
	case REQUEST_REVISION_SUCCESS: {
		const searchQuery = new URLSearchParams(window.location.search);
		searchQuery.delete('workflowTaskId');
		return updateObject(state, {
			loading: false,
			requestRevisionError: '',
			showCreativeLibrary: false
		});
	}
	case REQUEST_REVISION_FAIL: {
		return updateObject(state, {
			loading: false,
			requestRevisionError: action.data
		});
	}
    default:
        return state;
    }
};

export default adminFlightingTable;
