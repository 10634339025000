import { API } from '../../helpers/apiHelpers';

const getAdminCustomerListService = () => {
    const url = '/Companies';
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export { getAdminCustomerListService };
