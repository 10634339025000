import * as actionTypes from './actionTypes';
import { getPackageSercvice, getCompanyPackageSercvice } from '../services/package';

export const packageStart = () => {
    return {
        type: actionTypes.PACKAGE_START
    };
};

const packageFail = (error) => {
    return {
        type: actionTypes.GET_PACKAGES_FAIL,
        errorMessage: error
    };
};

export const getPackages = () => {
    return async (dispatch) => {
        try {
            dispatch(packageStart());
            const response = await getPackageSercvice();
            dispatch({
                type: actionTypes.GET_PACKAGES,
                data: response.data.data
            });
        } catch (error) {
            const errorData = error && error.response
                ? error.response.data
                : { Message: 'please try again' };
            dispatch(packageFail(errorData));
        }
    };
};

export const getCompanyPackages = () => {
    return async (dispatch) => {
        try {
            const companyId = localStorage.getItem('CompanyId');
            dispatch(packageStart());
            const response = await getCompanyPackageSercvice(companyId);
            dispatch({
                type: actionTypes.GET_PACKAGES,
                data: response.data.data
            });
        } catch (error) {
            const errorData = error && error.response
                ? error.response.data
                : { Message: 'please try again' };
            dispatch(packageFail(errorData));
        }
    };
};
