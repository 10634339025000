/* eslint-disable indent */
/* eslint-disable no-tabs */
import { updateObject } from '../../utils/reducer_utility';
import {
    GET_LOCATION_LIST_SUCCESS,
    GET_LOCATION_LIST_ERROR_HANDLER,
    CUSTOMER_REWARD_NOTIFICATION,

    // Flighting Table Section
    CUSTOMER_GET_FLIGHTING_START,
    CUSTOMER_GET_FLIGHTING_SUCCESS,
    CUSTOMER_FLIGHTING_DETAILS_ERROR_HANDLER,
    CUSTOMER_UPDATE_FLIGHTING_STATUS,
    CUSTOMER_UPDATE_FLIGHTING_STATUS_ERROR,

    // Promotion Section
    CUSTOMER_POST_PROMOTION_DETAILS,
    CUSTOMER_PROMOTION_MODAL_HANDLER,
    CUSTOMER_PROMOTION_ERROR_HANDLER,

    // Location Section
    CUSTOMER_LOCATION_MODAL_HANDLER,
    CUSTOMER_POST_LOCATION_DETAILS,
    CUSTOMER_LOCATIONS_ERROR_HANDLER,
    CUSTOMER_GET_CHANNEL_LIST_SUCCESS,

    // Creative Upload Section
    CUSTOMER_POST_CREATIVE_DETAILS,
    CUSTOMER_GET_CREATIVE_DETAILS,
    CUSTOMER_UPDATE_CREATIVE_UPLOAD_LIST,
    CUSTOMER_CREATIVE_UPLOAD_ERROR_HANDLER,
    CUSTOMER_DELETE_CREATIVE_DETAILS,
    CUSTOMER_CLOSE_CREATIVE_UPLOAD_POPUP,
    CUSTOMER_SUBMIT_CREATIVE_DETAILS_FOR_REVIEW,
	CUSTOMER_HANDLE_WARNING_BOX,
	CREATIVE_LIBRARY_POPUP_HANDLER,
	COPY_CREATIVE_POPUP_HANDLER,

	// customer request revision
	REQUEST_REVISION_START,
	REQUEST_REVISION_SUCCESS,
	REQUEST_REVISION_FAIL
} from '../actions/actionTypes';

const initialState = {
    getFlightingLoading: false,
    loading: false,

    // Flighting Table Section
    startDate: '',
    endDate: '',
    totalWeeks: [],
    optionList: [],
    errorMessage: '',
    savedCampaign: {},
    totalDays: [],
    weekOptions: [],
    tableLocationDetails: [],
    flightingStatusError: '',
    // Promotion Section
    promotionDates: [],
    showPromotion: false,
    promotionType: 'newPromotion',
    promotionErrorMessage: '',
    editPromotionData: {},

    // Location And Channel Section
    showLocation: false,
    locationErrorMessage: '',
    locationGroups: [],
    locationList: [],
    getLocationListError: '',
    channelList: [],

    // Creative Upload Section
    showCreativeUpload: false,
    channelTaskDetails: {},
    numberOfLocations: 0,
    basicCreativeDetails: {},
    showCreativeLibrary: false,
    uploadedCreativeList: [],
    isCreativeListUpdated: false,
    addAnotherCreative: false,
    selectedChannelForUpload: '',
    creativeUploadErrorMessage: '',
    unChangedUploadedCreativeList: [],
    activateFlyingPig: false,
	locationGroupsSelect: [],
	showCreativeLibraryPopup: false,
	showCopyCreativePopup: false,

	// customer request revision
	requestRevisionError: ''
};

const updateFlightingSchedule = (state, action) => {
    const {
        weekIndex,
        flightingOption,
        flightingStatusId
    } = action.data;

    const totalWeeks = [...state.weekOptions];
    totalWeeks[weekIndex].currentState = flightingOption;
    totalWeeks[weekIndex].flightingStatusId = flightingStatusId;

    return updateObject(state, {
        weekOptions: totalWeeks,
        isCampaignDetailsUpdated: true,
        flightingStatusError: ''
    });
};

const updateCreativeUploadList = (state, action) => {
    const {
        creativeListIndex,
        updatedData,
        uploadedCreativeList
    } = action.data;
    const { isCreativeListUpdated } = state;
    const lists = [...state.unChangedUploadedCreativeList];
    lists[creativeListIndex] = updatedData;
    lists[creativeListIndex].isEditable = false;

    return updateObject(state, {
        uploadedCreativeList,
        isCreativeListUpdated: !isCreativeListUpdated,
        creativeUploadErrorMessage: '',
        unChangedUploadedCreativeList: lists
    });
};

const deleteCreativeUploadList = (state, action) => {
    const {
        deleteCreativeIndex,
        uploadedCreativeList
    } = action.data;

    const { isCreativeListUpdated } = state;

    const unChangedLists = [...state.unChangedUploadedCreativeList];
    const list = [...uploadedCreativeList];

    unChangedLists.splice(deleteCreativeIndex, 1);
    list.splice(deleteCreativeIndex, 1);
    return updateObject(state, {
        unChangedUploadedCreativeList: unChangedLists,
        uploadedCreativeList: list,
        isCreativeListUpdated: !isCreativeListUpdated,
        creativeUploadErrorMessage: ''
    });
};

const customerFlightingTable = (state = initialState, action) => {
    switch (action.type) {
    case CUSTOMER_GET_FLIGHTING_START:
        return updateObject(state, {
            getFlightingLoading: true,
            loading: false,
            startDate: '',
            endDate: '',
            totalWeeks: [],
            optionList: [],
            errorMessage: '',
            savedCampaign: {},
            getErrorMessage: '',
            totalDays: [],
            weekOptions: [],
            promotionDates: [],
            showPromotion: false,
            promotionType: 'newPromotion',
            promotionErrorMessage: '',
            editPromotionData: {},
            showLocation: false,
            locationErrorMessage: '',
            locationGroups: [],
            locationList: [],
            getLocationListError: '',
            channelList: [],
            tableLocationDetails: [],
            flightingStatusError: '',
            showCreativeUpload: false,
            channelTaskDetails: {},
            numberOfLocations: 0,
            basicCreativeDetails: {},
            showCreativeLibrary: false,
            isCreativeListUpdated: false,
            addAnotherCreative: false,
            selectedChannelForUpload: '',
            creativeUploadErrorMessage: '',
            activateFlyingPig: false
        });
    case CUSTOMER_FLIGHTING_DETAILS_ERROR_HANDLER:
        return updateObject(state, {
            getFlightingLoading: false,
            errorMessage: action.data
        });

    // Flighting Table Section
    case CUSTOMER_GET_FLIGHTING_SUCCESS: {
        const {
            endDate,
            optionList,
            startDate,
            totalWeeks,
            weeks,
            totalDays,
            weekOptions,
            promotionDates,
            tableLocationDetails
        } = action.data;

        const savedCampaign = {};
        savedCampaign.endDate = endDate;
        savedCampaign.startDate = startDate;
        savedCampaign.weeks = weeks;

        return updateObject(state, {
            totalWeeks,
            startDate,
            endDate,
            optionList,
            getFlightingLoading: false,
            savedCampaign,
            errorMessage: '',
            totalDays,
            weekOptions,
            promotionDates,
            tableLocationDetails
        });
    }
    case CUSTOMER_UPDATE_FLIGHTING_STATUS:
        return updateFlightingSchedule(state, action);
    case CUSTOMER_UPDATE_FLIGHTING_STATUS_ERROR:
        return updateObject(state, {
            flightingStatusError: action.data
        });

    // Promotion Section
    case CUSTOMER_PROMOTION_ERROR_HANDLER:
        return updateObject(state, {
            loading: false,
            promotionErrorMessage: action.data
        });

    case CUSTOMER_POST_PROMOTION_DETAILS: {
        return updateObject(state, {
            loading: false,
            promotionDates: action.data.promotionDates,
            promotionErrorMessage: '',
            showPromotion: false,
            tableLocationDetails: action.data.tableLocationDetails
        });
    }
    case CUSTOMER_PROMOTION_MODAL_HANDLER: {
        const {
            showPromotion,
            promotionType,
            editPromotionData
        } = action.data;

        return updateObject(state, {
            showPromotion,
            promotionType,
            promotionErrorMessage: '',
            editPromotionData
        });
    }

    // Location And Channel Section
    case CUSTOMER_LOCATIONS_ERROR_HANDLER:
        return updateObject(state, {
            loading: false,
            locationErrorMessage: action.data
        });
    case CUSTOMER_LOCATION_MODAL_HANDLER: {
        const {
            showLocation,
            locationGroups,
            locationList,
            channelList
        } = action.data;

        return updateObject(state, {
            showLocation,
            locationErrorMessage: '',
            locationGroups,
            locationList,
            channelList
        });
    }
    case CUSTOMER_POST_LOCATION_DETAILS: {
        const {
            updateLocationGroups,
            locations,
            tableLocationDetails
        } = action.data;

        return updateObject(state, {
            loading: false,
            locationGroups: updateLocationGroups,
            locationList: locations,
            showLocation: false,
            locationErrorMessage: '',
            tableLocationDetails
        });
    }
    case GET_LOCATION_LIST_SUCCESS: {
        return updateObject(state, {
            locationList: action.data,
            getLocationListError: ''
        });
    }
    case GET_LOCATION_LIST_ERROR_HANDLER: {
        return updateObject(state, {
            getLocationListError: action.data
        });
    }
    case CUSTOMER_GET_CHANNEL_LIST_SUCCESS: {
        return updateObject(state, {
            channelList: action.data,
            errorMessage: ''
        });
    }

    // Creative Upload Section
    case CUSTOMER_GET_CREATIVE_DETAILS: {
        const {
            channelTaskDetails,
            numberOfLocations,
            addOtherCreative,
            selectedCreativeUploadChannel,
            unChangedUploadedCreativeList,
            uploadedCreativeList,
            basicCreativeDetails,
            locationGroupsSelect
        } = action.data;

        let { showCreativeUpload } = action.data;

        let showCreativeLibrary = false;
        let { selectedChannelForUpload } = state;

        if (basicCreativeDetails.predecessorWorkFlowTaskId && showCreativeUpload) {
            showCreativeLibrary = true;
            showCreativeUpload = false;
        } else if (uploadedCreativeList && uploadedCreativeList.length && showCreativeUpload) {
            showCreativeLibrary = true;
            showCreativeUpload = false;
        }
        selectedChannelForUpload = selectedCreativeUploadChannel || selectedChannelForUpload;

        let addAnotherCreative = false;

        if (addOtherCreative) {
            addAnotherCreative = true;
            return updateObject(state, {
                addAnotherCreative,
                showCreativeUpload,
                showCreativeLibrary,
                creativeUploadErrorMessage: '',
                activateFlyingPig: false
            });
        }

        return updateObject(state, {
            showCreativeUpload,
            channelTaskDetails,
            numberOfLocations,
            showCreativeLibrary,
            basicCreativeDetails,
            uploadedCreativeList,
            addAnotherCreative,
            selectedChannelForUpload,
            creativeUploadErrorMessage: '',
            unChangedUploadedCreativeList,
            activateFlyingPig: false,
            locationGroupsSelect
        });
    }
    case CUSTOMER_POST_CREATIVE_DETAILS: {
        const {
            basicCreativeDetails,
            unChangedUploadedCreativeList,
            uploadedCreativeList,
            tableLocationDetails
        } = action.data;

        return updateObject(state, {
            basicCreativeDetails,
            showCreativeUpload: false,
            showCreativeLibrary: true,
            uploadedCreativeList,
            creativeUploadErrorMessage: '',
            unChangedUploadedCreativeList,
            tableLocationDetails,
            activateFlyingPig: false
        });
    }
    case CUSTOMER_UPDATE_CREATIVE_UPLOAD_LIST: {
        return updateCreativeUploadList(state, action);
    }
    case CUSTOMER_CREATIVE_UPLOAD_ERROR_HANDLER: {
        return updateObject(state, {
            creativeUploadErrorMessage: action.data
        });
    }
    case CUSTOMER_DELETE_CREATIVE_DETAILS: {
        return deleteCreativeUploadList(state, action);
    }
    case CUSTOMER_CLOSE_CREATIVE_UPLOAD_POPUP: {
        const {
            addAnotherCreative,
            tableLocationDetails
        } = action.data;

        if (addAnotherCreative) {
            return updateObject(state, {
                showCreativeLibrary: true,
                addAnotherCreative: false,
                showCreativeUpload: false,
                creativeUploadErrorMessage: '',
                activateFlyingPig: false
            });
        }

        return updateObject(state, {
            showCreativeUpload: false,
            channelTaskDetails: {},
            numberOfLocations: 0,
            basicCreativeDetails: [],
            showCreativeLibrary: false,
            uploadedCreativeList: [],
            isCreativeListUpdated: false,
            addAnotherCreative: false,
            selectedChannelForUpload: '',
            creativeUploadErrorMessage: '',
            unChangedUploadedCreativeList: [],
            tableLocationDetails,
            activateFlyingPig: false
        });
    }
    case CUSTOMER_SUBMIT_CREATIVE_DETAILS_FOR_REVIEW:
        return updateObject(state, {
            showCreativeUpload: false,
            channelTaskDetails: {},
            numberOfLocations: 0,
            basicCreativeDetails: [],
            showCreativeLibrary: false,
            uploadedCreativeList: [],
            isCreativeListUpdated: false,
            addAnotherCreative: false,
            selectedChannelForUpload: '',
            creativeUploadErrorMessage: '',
            unChangedUploadedCreativeList: [],
            tableLocationDetails: action.data.tableLocationDetails,
            activateFlyingPig: action.data.activateFlyingPig
        });
    case CUSTOMER_HANDLE_WARNING_BOX: {
        const { type } = action.data;
        const basicCreativeDetails = { ...state.basicCreativeDetails };

        if (type === 'allLocation') {
            basicCreativeDetails.showAllLocationWarning = true;
        } else if (type === 'thisLocationOnly') {
            basicCreativeDetails.showThisLocationWarning = true;
        }

        return updateObject(state, {
            basicCreativeDetails
        });
    }

    case CUSTOMER_REWARD_NOTIFICATION: {
        return updateObject(state, {
            activateFlyingPig: action.data.showRewardNotification !== false
        });
    }
	case CREATIVE_LIBRARY_POPUP_HANDLER: {
		return updateObject(state, {
			showCreativeLibraryPopup: action.data
		});
	}

	case COPY_CREATIVE_POPUP_HANDLER: {
		return updateObject(state, {
			showCopyCreativePopup: action.data
		});
	}
	case REQUEST_REVISION_START: {
		return updateObject(state, {
			loading: true,
			requestRevisionError: ''
		});
	}
	case REQUEST_REVISION_SUCCESS: {
		return updateObject(state, {
			loading: false,
			requestRevisionError: '',
			showCreativeLibrary: false
		});
	}
	case REQUEST_REVISION_FAIL: {
		return updateObject(state, {
			loading: false,
			requestRevisionError: action.data
		});
	}
    default:
        return state;
    }
};

export default customerFlightingTable;
