/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import { API } from '../../helpers/apiHelpers';

const uploadChunkFile = async (data) => {
	const url = '/chunkupload';
	try {
		return await API.post(url, data);
	} catch (error) {
		return Promise.reject(error);
	}
};

const getFlightingDetails = async (campaignId) => {
	if (Number(campaignId)) {
		const url = `campaigns/${campaignId}/flightingdetail`;
		try {
			return await API.get(url);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};
const postPromotion = async (data, campaignId) => {
	if (Number(campaignId)) {
		const url = `/campaigns/${campaignId}/promotions`;
		try {
			return await API.post(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const updatePromotion = async (data, promotionId, campaignId) => {
	if (Number(campaignId)) {
		const url = `campaigns/${campaignId}/promotions/${promotionId}`;
		try {
			return await API.put(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const deletePromotion = async (promotionId) => {
	if (promotionId) {
		const url = `/promotions/${promotionId}`;
		try {
			return await API.delete(url);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const postLocationGroupDetails = async (data, campaignId) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/locationgroups`;
		try {
			return await API.post(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const getLocationDetails = async (campaignId) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/locationgroups`;
		try {
			return await API.get(url);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const updatePriorityWeeksOption = async (campaignId, priorityWeeksId, data) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/priorityweek/${priorityWeeksId}`;
		try {
			return await API.put(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const isCreativeSubmittedForReview = async (campaignId, taskId, campaignLocationGroupIds = '') => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/task/${taskId}/iscreativesubmitted?campaignLocationGroupIds=${campaignLocationGroupIds}`;
		try {
			return await API.get(url);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const postCreativeUploadDetails = async (campaignId, data, taskId) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/task/${taskId}/creatives`;
		try {
			return await API.post(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const getCreativeUploadDetails = async (campaignId, taskId) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/task/${taskId}/creatives`;
		try {
			return await API.get(url);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const updateCreativeUploadDetails = async (campaignId, data, creativeId) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/creatives/${creativeId}`;
		try {
			return await API.put(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const deleteCreativeUploadDetails = async (campaignId, creativeId) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/creatives/${creativeId}`;
		try {
			return await API.delete(url);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const updateAdditionalDetails = async (campaignId, taskId, data) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/task/${taskId}/groupchannelcreative`;
		try {
			return await API.put(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const submitCreativeForReview = async (campaignId, taskId, data) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/task/${taskId}/creatives`;
		try {
			return await API.patch(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const requestRevisionOrTraffic = async (campaignId, taskId, data) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/tasks/${taskId}`;
		try {
			return await API.post(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const checkApplyToAllLocation = async (campaignId, channelId) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/channels/${channelId}`;
		try {
			return await API.get(url);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const updateShowWarning = async (campaignId, promotionId, data) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/promotions/${promotionId}`;
		try {
			return await API.patch(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const copyCreative = async (campaignId, data) => {
	if (campaignId) {
		const url = `/campaigns/${campaignId}/copycreatives`;
		try {
			return await API.post(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const getPromotionLocationGroupForChannelService = async (campaignId, campaignPromotionId, channelId) => {
	const url = `/campaigns/${campaignId}/promotions/${campaignPromotionId}/channels/${channelId}`;
	try {
		return await API.get(url);
	} catch (error) {
		return Promise.reject(error);
	}
};

const addCampaignDarkDayService = async (campaignId, data) => {
	if (campaignId) {
		const url = `campaigns/${campaignId}/darkday`;
		try {
			return await API.post(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const getCampaignDarkDayService = async (campaignId) => {
	const url = `/campaigns/${campaignId}/darkday`;
	try {
		return await API.get(url);
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateCampaignDarkDayService = async (campaignId, locationGroupChannelDarkDayId, data) => {
	if (campaignId) {
		const url = `campaigns/${campaignId}/darkday/${locationGroupChannelDarkDayId}`;
		try {
			return await API.put(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const sendRequestRevisionService = async (data, id, taskId) => {
	if (id) {
		const url = `campaigns/${id}/tasks/${taskId}/requestrevision`;
		try {
			return await API.post(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

const acceptOrRejectRequestRevisionService = async (data, id, taskId) => {
	if (id) {
		const url = `campaigns/${id}/tasks/${taskId}/acceptorrejectrequestrevision`;
		try {
			return await API.post(url, data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return null;
};

export {
	getFlightingDetails,
	postPromotion,
	updatePromotion,
	deletePromotion,
	postLocationGroupDetails,
	getLocationDetails,
	updatePriorityWeeksOption,
	isCreativeSubmittedForReview,
	postCreativeUploadDetails,
	getCreativeUploadDetails,
	updateCreativeUploadDetails,
	deleteCreativeUploadDetails,
	updateAdditionalDetails,
	submitCreativeForReview,
	requestRevisionOrTraffic,
	uploadChunkFile,
	checkApplyToAllLocation,
	updateShowWarning,
	copyCreative,
	getPromotionLocationGroupForChannelService,
	addCampaignDarkDayService,
	getCampaignDarkDayService,
	updateCampaignDarkDayService,
	sendRequestRevisionService,
	acceptOrRejectRequestRevisionService
};
