import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    userName: null,
    email: null,
    firstName: null,
    lastName: null,
    contactNumber: null,
    errorMessage: null,
    loading: false,
    companyLegalName: null
};

const personStart = (state, action) => {
    return updateObject(state, { errorMessage: null, loading: true });
};

const personSuccess = (state, action) => {
    return updateObject(state, {
        // packageId: action.data.packageId,
        // campaignId: action.data.id
    });
};
const personFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.errorMessage,
        loading: false,
        isSaved: true
    });
};

const getPersonByEnryptedIdSuccess = (state, action) => {
    return updateObject(state, {
        userName: action.data.userName,
        companyLegalName: action.data.companyLegalName,
        loading: false,
        errorMessage: null,
        isEmailVerified: action.data.isEmailVerified
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.PERSON_START:
        return personStart(state, action);
    case actionTypes.PERSON_SUCCESS:
        return personSuccess(state, action);
    case actionTypes.PERSON_FAIL:
        return personFail(state, action);
    case actionTypes.PERSON_BY_ENCRYPTED_ID:
        return getPersonByEnryptedIdSuccess(state, action);
    default:
        return state;
    }
};

export default reducer;
