import { API } from '../../helpers/apiHelpers';

const getCampaignDetailsList = async (companyId) => {
    if (companyId) {
        const url = `companies/${companyId}/campaigns`;
        try {
            return await API.get(url);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const getActionItems = async (companyId) => {
    if (companyId) {
        const url = `companies/${companyId}/actionitems`;
        try {
            return await API.get(url);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const getAllActionItemsService = async () => {
    const url = 'companies/actionitems';
    try {
        return await API.get(url);
    } catch (error) {
        return Promise.reject(error);
    }
};

const getCampaignTotalRewardPointsService = async (campaignId) => {
    if (campaignId) {
        const url = `campaigns/${campaignId}/totalrewards/`;
        try {
            return await API.get(url);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const updateMarkAsReadDeliverable = async (campaignId, fileId, data) => {
    if (campaignId) {
        const url = `campaigns/${campaignId}/files/${fileId}/isread`;
        try {
            return await API.put(url, data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const postNewMessage = async (campaignId, data) => {
    if (campaignId) {
        const url = `campaigns/${campaignId}/messages`;
        try {
            return await API.post(url, data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const getMessageDetails = async (campaignId, threadId) => {
    if (campaignId) {
        const url = `campaigns/${campaignId}/messages/${threadId}`;
        try {
            return await API.get(url);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

export {
    getCampaignDetailsList,
    getCampaignTotalRewardPointsService,
    getActionItems,
    updateMarkAsReadDeliverable,
    postNewMessage,
    getMessageDetails,
    getAllActionItemsService
};
