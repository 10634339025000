import { API } from '../../helpers/apiHelpers';

const getPersonDetailsService = (personId) => {
    const url = '/persons/' + personId;
    return API.get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updatePersonDetailsService = (personId, formData) => {
    const url = '/persons/' + personId;
    return API.put(url, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getSuccessManagerList = (roleName) => {
    const url = '/persons/role?roleName=' + roleName;
    return API.get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getPersonByEnryptedIdService = (id, isIdEncrypted) => {
    const url = '/persons/detail?isIdEncrypted=' + isIdEncrypted + '&id=' + id;
    return API.get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export {
    getPersonDetailsService,
    updatePersonDetailsService,
    getSuccessManagerList,
    getPersonByEnryptedIdService
};
