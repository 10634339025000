/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import getMarketingPersonDetails from '../services/modelMarketingService';
import * as actionTypes from './actionTypes';
import { updateCompanyservice } from '../services/company';
import { updatePerson } from './person';
import { setErrorMessage } from '../../utils/utility_helpers';
import { locationFail } from './location';
import { updateCampaignService } from '../services/campaign';
import { updateLocationsService } from '../services/location';
import { postReviewData, validateCampaignDiscount } from '../services/review';
import { updateBusinessProfileService } from '../services/businessProfile';

export const marketStart = () => {
    return {
        type: actionTypes.MARKET_START
    };
};

const marketSuccess = (data) => {
    return {
        type: actionTypes.MARKET_SUCCESS,
        data
    };
};

const marketFail = (errorMessage) => {
    return {
        type: actionTypes.MARKET_FAIL,
        errorMessage
    };
};

const companyFail = (errorMessage) => {
    return {
        type: actionTypes.COMPANY_FAIL,
        errorMessage
    };
};

const packageFail = (error) => {
    return {
        type: actionTypes.GET_PACKAGES_FAIL,
        errorMessage: error
    };
};

export const deleteMIMPackageSuccess = () => {
    return {
        type: actionTypes.DELETE_MARKETING_MODEL
    };
};

export const getPackages = () => {
    return async (dispatch) => {
        try {
            dispatch(marketStart());
            const response = await getMarketingPersonDetails();
            dispatch({
                type: actionTypes.GET_PACKAGES,
                data: response.data.data
            });
        } catch (error) {
            const errorData = error && error.response
                ? error.response.data
                : { Message: 'please try again' };
            dispatch(packageFail(errorData));
        }
    };
};


export const updateMarketingModalCompany = (formData) => {
    return async (dispatch) => {
        try {
            await updateCompanyservice(formData.personData);
            dispatch(updatePerson(formData));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(locationFail(errorMessage));
            dispatch(companyFail(errorMessage));
        }
    };
};


export const updateMarketLocations = (data) => {
    return async (dispatch) => {
        try {
            dispatch(marketStart());
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(locationFail(errorMessage));
        }
    };
};

export const updateMarketModal = (data, showDiscountAlert) => {
    const formData = data;
    return async (dispatch) => {
        try {
            if (showDiscountAlert) {
                const campaignId = localStorage.getItem('CampaignId');
                dispatch(marketStart());
                const response = await updateLocationsService(campaignId, formData.locations);
                await updateCampaignService(campaignId, formData.campaignData);
                await updateBusinessProfileService(formData.businessProfile);
                formData.locations.locationDetails = response;
                const reviewRresponse = await postReviewData(formData.agreementData);

                if (reviewRresponse && reviewRresponse.data.resultCount > 0) {
                    dispatch({
                        type: actionTypes.REVIEW_FORM_SUBMITTED,
                        data: reviewRresponse.data.data
                    });
                }
                dispatch(marketSuccess(formData));
            } else {
                dispatch({
                    type: actionTypes.MARKETING_MODEL_DISCOUNT_START
                });
                const getResponse = await validateCampaignDiscount();
                console.log(getResponse);

                if (getResponse && getResponse.data.resultCount) {
                    const { status } = getResponse.data.data;

                    if (!status) {
                        dispatch({
                            type: actionTypes.SHOW_DISCOUNT_ALERT,
                            data: true
                        });
                    } else {
                        const campaignId = localStorage.getItem('CampaignId');
                        // const personId = localStorage.getItem('PersonId');
                        dispatch(marketStart());
                        const response = await updateLocationsService(campaignId, formData.locations);
                        await updateCampaignService(campaignId, formData.campaignData);
                        // await updateCompanyservice(formData.companyObject);
                        // const personResponse = await updatePersonDetailsService(personId, formData.personData);
                        await updateBusinessProfileService(formData.businessProfile);
                        formData.locations.locationDetails = response;
                        const reviewRresponse = await postReviewData(formData.agreementData);

                        if (reviewRresponse && reviewRresponse.data.resultCount > 0) {
                            dispatch({
                                type: actionTypes.REVIEW_FORM_SUBMITTED,
                                data: reviewRresponse.data.data
                            });
                        }
                        // console.log(personResponse);
                        dispatch(marketSuccess(formData));
                    }
                }
                dispatch({
                    type: actionTypes.MARKETING_MODEL_DISCOUNT_END
                });
            }
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(marketFail(errorMessage));
            dispatch({
                type: actionTypes.MARKETING_MODEL_DISCOUNT_END
            });
        }
    };
};
