import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

export const getInitialComapnyAddressList = () => {
    const tempAddressList = [];
    tempAddressList.push({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        isBillingAddress: true,
        isPrimary: true
    });
    tempAddressList.push({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        isBillingAddress: false,
        isPrimary: false
    });
    return tempAddressList;
};

const initialState = {
    loading: false,
    redirect: false,
    companyId: null,
    companyName: null,
    companyLegalName: null,
    firstName: '',
    lastName: '',
    contactNumber: '',
    personId: 0,
    accountsPayableName: '',
    accountsPayableEmail: '',
    companyAddressPostDetails: getInitialComapnyAddressList(),
    email: '',
    isSaved: false,
    isFormSubmitted: false,
    errorMessage: null,
    additionalEmail: null
};

const businessProfileStart = (state, action) => {
    return updateObject(state, { errorMessage: null, loading: true });
};

const createAddress = (address) => {
    return {
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        isBillingAddress: true,
        isPrimary: true
    };
};
const createCompanyaddressObject = (campaignCompanyAddress) => {
    let tempCampaignCompanyAddress = [];
    if (campaignCompanyAddress.length === 0) {
        tempCampaignCompanyAddress = getInitialComapnyAddressList();
    } else if (campaignCompanyAddress.length === 1) {
        tempCampaignCompanyAddress.push(createAddress(campaignCompanyAddress[0]));
        tempCampaignCompanyAddress.push({
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            isBillingAddress: false,
            isPrimary: false
        });
    } else if (campaignCompanyAddress.length === 2) {
        tempCampaignCompanyAddress = campaignCompanyAddress;
    }
    return tempCampaignCompanyAddress;
};

const businessProfileSuccess = (state, action) => {
    return updateObject(state, {
        isSaved: true,
        loading: false,
        errorMessage: null,
        firstName: action.data.person.firstName,
        lastName: action.data.person.lastName,
        personId: action.data.person.id,
        contactNumber: action.data.person.contactNumber,
        email: action.data.person.email,
        companyName: action.data.campaignCompany.companyName,
        companyLegalName: action.data.campaignCompany.companyLegalName,
        accountsPayableName: action.data.campaignCompany.accountsPayableName,
        accountsPayableEmail: action.data.campaignCompany.accountsPayableEmail,
        taxId: action.data.campaignCompany.taxId,
        businessProfileComment: action.data.businessProfileComment,
        companyAddressPostDetails: createCompanyaddressObject(action.data.campaignCompanyAddress),
        additionalEmail: action.data.campaignCompany.additionalEmail
    });
};

const updateBusinessProfileSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: null,
        companyName: action.data.businessProfile.companyName,
        companyLegalName: action.data.businessProfile.companyLegalName,
        firstName: action.data.businessProfile.personDetailDTO.firstName,
        lastName: action.data.businessProfile.personDetailDTO.lastName,
        personId: action.data.businessProfile.personDetailDTO.personId,
        contactNumber: action.data.businessProfile.personDetailDTO.contactNumber,
        email: action.data.businessProfile.personDetailDTO.email,
        accountsPayableName: action.data.businessProfile.accountsPayableName,
        accountsPayableEmail: action.data.businessProfile.accountsPayableEmail,
        taxId: action.data.businessProfile.taxId,
        businessProfileComment: action.data.comment,
        companyAddressPostDetails: createCompanyaddressObject(
            action.data.businessProfile.companyAddressPostDetailsDTO
        ),
        isSaved: true,
        additionalEmail: action.data.businessProfile.additionalEmail
    });
};

const businessProfileFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.errorMessage,
        loading: false
    });
};

const updateBusinessProfileIsSaved = (state, action) => {
    return updateObject(state, { isSaved: action.data });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.BUSINESS_PROFILE_START:
        return businessProfileStart(state, action);
    case actionTypes.BUSINESS_PROFILE_SUCCESS:
        return businessProfileSuccess(state, action);
    case actionTypes.BUSINESS_PROFILE_FAIL:
        return businessProfileFail(state, action);
    case actionTypes.UPDATE_BUSINESS_PROFILE_SUCCESS:
        return updateBusinessProfileSuccess(state, action);
    case actionTypes.BUSINESS_PROFILE_IS_SAVED:
        return updateBusinessProfileIsSaved(state, action);
    default:
        return state;
    }
};

export default reducer;
