import { API } from '../../helpers/apiHelpers';

const getCampaignDates = async () => {
    const campaignId = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : '';

    if (campaignId) {
        const url = `campaigns/${campaignId}/flightingstatus`;
        try {
            return await API.get(url);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const getFlightingOptions = async () => {
    const url = '/flightingstatuses';
    try {
        return await API.get(url);
    } catch (error) {
        return Promise.reject(error);
    }
};

const getCampaignComments = async () => {
    const campaignId = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : '';

    if (campaignId) {
        const url = `/campaigns/${campaignId}?isDetailed=false`;
        try {
            return await API.get(url);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const postCampaignFlighting = async (data) => {
    const campaignId = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : '';

    if (campaignId) {
        const url = `/campaigns/${campaignId}/flightingstatus`;
        try {
            return await API.post(url, data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const postCampaignComments = async (data) => {
    const campaignId = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : '';

    if (campaignId) {
        const url = `/campaigns/${campaignId}`;
        try {
            return await API.patch(url, data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const getCampaignPackageDetails = async () => {
    const companyId = localStorage.getItem('CompanyId') ? localStorage.getItem('CompanyId') : '';

    if (companyId) {
        const url = '/packages/company?companyId=' + companyId;
        try {
            const response = await API.get(url);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

export {
    getCampaignDates,
    getCampaignComments,
    getFlightingOptions,
    postCampaignComments,
    postCampaignFlighting,
    getCampaignPackageDetails
};
