/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import Routes from './Routes';
import {
    logout
} from '../store/actions/auth';

class App extends PureComponent {
    constructor(props) {
        super(props);
        this.idleTimer = null;
    }

    onAction = (e) => {
        const xToken = localStorage.getItem('x-token') ? localStorage.getItem('x-token') : '';
        if (xToken) {
            const time = localStorage.getItem('expirationTime') ? Number(localStorage.getItem('expirationTime')) : 0;
            localStorage.setItem('remainingTime', time);
        }
    }

    onActive = (e) => {
        const xToken = localStorage.getItem('x-token') ? localStorage.getItem('x-token') : '';
        if (xToken) {
            const time = localStorage.getItem('expirationTime') ? Number(localStorage.getItem('expirationTime')) : 0;
            localStorage.setItem('remainingTime', time);
        }
    }

    onIdle = (e) => {
        const { logoutUser } = this.props;

        logoutUser();
    }

    render() {
        return (
            <main className="App">
                {localStorage.getItem('x-token') && (
                    <IdleTimer
                        ref={(ref) => { this.idleTimer = ref; }}
                        element={document}
                        onActive={this.onActive}
                        onIdle={this.onIdle}
                        onAction={this.onAction}
                        debounce={300}
                        timeout={localStorage.getItem('remainingTime') ? Number(localStorage.getItem('remainingTime')) * 3600 * 1000 : 0}
                    />
                )}
                <Routes />
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        loading: state.auth.logoutLoading,
        redirect: state.auth.redirect
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => {
            return dispatch(logout());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
