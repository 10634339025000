/* eslint-disable prefer-rest-params */
const Debounce = (func, delay) => {
    let inDebounce;
    return function () {
        const context = this;
        // const args = {...arguments};
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => {
            func.apply(context, arguments);
        }, delay);
    };
};

export default Debounce;
