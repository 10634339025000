/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component, lazy } from 'react';
import {
    Switch, Redirect, BrowserRouter as Router
} from 'react-router-dom';

import RouteWrapper from './RouteWrapper';
import ReviewSuccessPopUp from '../components/PreIntakeComponent/packages/campaignComponents/container/ReviewSuccessPopUp';
import SignIn from '../components/account/auth/SignIn';
import ForgotUserNamePassword from '../components/account/auth/ForgotUsernamePassword';
import ResetPassword from '../components/account/auth/ResetPassword';
import DefaultLayout from '../components/layout/DefaultLayout';
import PartialLayout from '../components/layout/PartialLayout';
import UserWelcomeLayout from '../components/layout/UserWelcomeLayout';
import AdminLayout from '../components/layout/AdminLayout';
import SignInLayout from '../components/layout/SignInLayout';
import CustomerLayout from '../components/layout/CustomerLayout';

import { isLoggedInIsAdmin, numberOfIntakeCompleted } from '../utils/utility_helpers';

const CreativeLibrary = lazy(() => { return import('../components/creativeLibrary/CreativeLibrary'); });
const AdminCreativeLibrary = lazy(() => { return import('../components/admin/creativeLibrary/AdminCreativeLibrary'); });
const AddCustomer = lazy(() => { return import('../components/admin/addCustomer/index'); });
const ListCustomers = lazy(() => { return import('../components/admin/listCustomers/Index'); });
const CustomerDetailsIndex = lazy(() => { return import('../components/admin/customerDetails/CustomerDetailsIndex'); });
const TaskListsIndex = lazy(() => { return import('../components/admin/customersTaskLists/TaskListsIndex'); });
const CampaignDetailsIndex = lazy(() => { return import('../components/admin/campaignDetails/CampaignDetailsIndex'); });

const CustomerActivationForm = lazy(() => { return import('../components/account/customerRegistration/UserActivationForm'); });
const CustomerVerificationLink = lazy(() => { return import('../components/account/customerRegistration/UserVerificationLink'); });
const CustomerCreateAccountForm = lazy(() => { return import('../components/account/customerRegistration/UserCreateAccount'); });
// const Overview = lazy(() => { return import('../components/PreIntakeComponent/Overview/Overview'); });
const PrivacyPolicy = lazy(() => { return import('../components/common/PrivacyPolicy'); });
const HowItWorks = lazy(() => { return import('../components/PreIntakeComponent/howItWorks/HowItworks'); });
const Packages = lazy(() => { return import('../components/PreIntakeComponent/packages/index'); });
const WhatToExpect = lazy(() => { return import('../components/PreIntakeComponent/whatToExpect/WhatToExpect'); });
const CustomerCampaignDetails = lazy(() => { return import('../components/customer/CustomerCampaignDetails'); });

const renderRegistrationRoutes = () => {
    const registrationRouteList = [
        {
            path: '/account/activate',
            component: CustomerActivationForm,
            layout: PartialLayout,
            exact: true,
            isPublic: true,
            title: 'Activation - Empower'
        },
        {
            path: '/account/verification-notice',
            component: CustomerVerificationLink,
            layout: PartialLayout,
            exact: true,
            isPublic: true,
            title: 'Verification - Empower'
        },
        {
            path: '/account/register',
            component: CustomerCreateAccountForm,
            layout: PartialLayout,
            exact: true,
            isPublic: true,
            title: 'Registration - Empower'
        }
    ];

    return registrationRouteList.map((_admin) => {
        return (<RouteWrapper {..._admin} />);
    });
};


const renderRegisteredUsersRoutes = () => {
    const registeredUserRouteList = [
        {
            path: '/packages',
            component: Packages,
            layout: DefaultLayout,
            exact: true,
            isPublic: true,
            title: 'Packages - Empower',
            isIntake: true
        },
        {
            path: '/how-it-works',
            component: HowItWorks,
            layout: DefaultLayout,
            exact: true,
            isPublic: true,
            title: 'How It Works - Empower',
            isIntake: true
        },
        {
            path: '/what-to-expect',
            component: WhatToExpect,
            layout: DefaultLayout,
            exact: true,
            isPublic: true,
            title: 'What To Expect - Empower',
            isIntake: true
        },
        {
            path: '/user/welcome',
            component: ReviewSuccessPopUp,
            layout: UserWelcomeLayout,
            exact: true,
            isPublic: false,
            title: 'User Welcome - Empower',
            isIntake: true
        },
        {
            path: '/customer/campaign-details',
            component: CustomerCampaignDetails,
            exact: true,
            layout: CustomerLayout,
            isPublic: false,
            title: 'Customer Campaign Details - Empower',
            isCustomer: true
        },
        {
            path: '/new-campaign/confirmation',
            component: ReviewSuccessPopUp,
            layout: UserWelcomeLayout,
            exact: true,
            isPublic: false,
            title: 'User Welcome - Empower',
            isCustomer: true
		},
		{
			path: '/customer/creative-library',
			component: CreativeLibrary,
			exact: true,
			layout: CustomerLayout,
			isPublic: false,
			title: 'Creative Library - Empower',
			isCustomer: true
		}
    ];

    return registeredUserRouteList.map((_admin) => {
        return (<RouteWrapper {..._admin} />);
    });
};

const renderAdminRoutes = () => {
    const adminUserRouteList = [
        {
            path: '/admin/customer-setup',
            component: AddCustomer,
            exact: true,
            layout: AdminLayout,
            isPublic: false,
            title: 'Customer Setup - Empower',
            isAdmin: true
        },
        {
            path: '/admin/list-customers',
            component: ListCustomers,
            exact: true,
            layout: AdminLayout,
            isPublic: false,
            title: 'Customers List - Empower',
            isAdmin: true
        },
        {
            path: '/admin/customer-details',
            component: CustomerDetailsIndex,
            exact: true,
            layout: AdminLayout,
            isPublic: false,
            title: 'Customers Details - Empower',
            isAdmin: true
        },
        {
            path: '/admin/customer-task-lists',
            component: TaskListsIndex,
            exact: true,
            layout: AdminLayout,
            isPublic: false,
            title: 'Customers Task Lists - Empower',
            isAdmin: true
        },
        {
            path: '/admin/customer-campaign-details',
            component: CampaignDetailsIndex,
            exact: true,
            layout: AdminLayout,
            isPublic: false,
            title: 'Campaign Details - Empower',
            isAdmin: true
		},
		{
			path: '/admin/creative-library',
			component: AdminCreativeLibrary,
			exact: true,
			layout: AdminLayout,
			isPublic: false,
			title: 'Campaign Details - Empower',
			isAdmin: true
		},
        {
            path: '/privacy-policy',
            component: PrivacyPolicy,
            exact: true,
            layout: AdminLayout,
            isPublic: false,
            title: 'Privacy Policy - Empower',
            isAdmin: true
        }
    ];

    return adminUserRouteList.map((_admin) => {
        return (<RouteWrapper {..._admin} />);
    });
};

const renderAuthenticationRoutes = () => {
    const authRouteList = [
        {
            path: '/login',
            component: SignIn,
            layout: SignInLayout,
            exact: true,
            isPublic: true,
            title: 'Login - Empower'
        },
        {
            path: '/forgot-password',
            component: ForgotUserNamePassword,
            layout: SignInLayout,
            exact: true,
            isPublic: true,
            title: 'Forgot Password - Empower'
        },
        {
            path: '/reset-password',
            component: ResetPassword,
            layout: SignInLayout,
            exact: true,
            isPublic: true,
            title: 'Reset Password - Empower'
        }

    ];

    return authRouteList.map((_admin) => {
        return (<RouteWrapper {..._admin} />);
    });
};

class Routes extends Component {
    render() {
        console.log(isLoggedInIsAdmin());
        return (
            <Router>
                <Switch>
                    {renderRegistrationRoutes()}

                    {renderAuthenticationRoutes()}

                    {!isLoggedInIsAdmin() && renderRegisteredUsersRoutes()}

                    {isLoggedInIsAdmin() && renderAdminRoutes()}

                    {(!isLoggedInIsAdmin()) && (
                        <RouteWrapper
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                            exact
                            layout={numberOfIntakeCompleted() ? CustomerLayout : DefaultLayout}
                            isPublic
                            title="Privacy Policy - Empower"
                        />
                    )}

                    {(!isLoggedInIsAdmin() && !numberOfIntakeCompleted()) && (
                        <RouteWrapper
                            path="/"
                            component={SignIn}
                            exact
                            layout={SignInLayout}
                            isPublic
                            title="Empower Media Agent"
                            isIntake
                        />
                    )}
                    {isLoggedInIsAdmin() && <Redirect to="/admin/list-customers" />}
                    {numberOfIntakeCompleted() ? <Redirect to="/customer/campaign-details" /> : <Redirect to="/login" />}
                    <Redirect to="/" />
                </Switch>
            </Router>
        );
    }
}

export default Routes;
