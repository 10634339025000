/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    Formik,
    Form,
    Field,
    ErrorMessage
} from 'formik';
import * as Yup from 'yup';

import SignInLeftSidePanel from '../../common/SignInLeftSidePanel';
import Spinner from '../../common/Loader';
import {
    createAccountForm
} from '../../Constants';
import {
    userResetPassword
} from '../../../store/actions/auth';


const resetPasswordValidationSchema = Yup.object().shape({
    userName: Yup.string().required(createAccountForm.userNameRequired),
    temporaryPassword: Yup.string().required(createAccountForm.temporaryPasswordRequired),
    newPassword: Yup.string()
        .matches(
            createAccountForm.passwordRegex,
            createAccountForm.newPasswordType
        )
        .required(createAccountForm.newPasswordRequired),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], createAccountForm.confirmNewPasswordMatch)
        .required(createAccountForm.confirmNewPasswordRequired)
});

class ResetPassword extends Component {
    handleResetPasswordFormSubmit = (formData) => {
        const { resetPasswordFields } = this.props;
        resetPasswordFields(formData);
    }

    render() {
        const {
            isLoggedIn,
            loading,
            errorMessage
        } = this.props;

        return (
            <div className="sign-in-flow-main reset-password-main sign-in-flow-new-theme">
                <div className="container sign-in-flow-wrapper">
                    <div className="row">
                        <h1 className="sign-in-intro-heading">
                            Reset Password
                        </h1>
                    </div>
                    <div className="row">
                        <SignInLeftSidePanel />
                        <div className="col sign-in-flow-right">
                            <div className="sign-in-form">
                                <div className="sign-in-wrapper">
                                    {/* <h2 className="sign-in-form-heading">Reset Password</h2> */}

                                    {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}

                                    <Formik
                                        initialValues={{
                                            userName: '',
                                            temporaryPassword: '',
                                            newPassword: '',
                                            confirmNewPassword: '',
                                            keepMeLoggedIn: false
                                        }}

                                        validationSchema={resetPasswordValidationSchema}

                                        onSubmit={(fields, actions) => {
                                            actions.setSubmitting(true);
                                            this.handleResetPasswordFormSubmit(fields, actions);
                                            actions.setSubmitting(false);
                                        }}
                                    >
                                        {({
                                            errors,
                                            touched
                                        }) => {
                                            return (
                                                <Form className="activation-form user-create-account-form">
                                                    <div className="form-group">
                                                        <label htmlFor="userName">
                                                            Username
                                                        </label>

                                                        <Field
                                                            id="userName"
                                                            className={`form-control ${
                                                                errors.userName
                                                                && touched.userName
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            name="userName"
                                                            type="text"
                                                            placeholder="Enter username here"
                                                        />

                                                        <ErrorMessage
                                                            name="userName"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="temporaryPassword">
                                                            Temporary password
                                                        </label>

                                                        <Field
                                                            id="temporaryPassword"
                                                            className={`form-control ${
                                                                errors.temporaryPassword
                                                                && touched.temporaryPassword
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            name="temporaryPassword"
                                                            type="text"
                                                            placeholder="Please enter temporary password"
                                                        />

                                                        <ErrorMessage
                                                            name="temporaryPassword"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>

                                                    <div
                                                        className={`form-group ${
                                                            errors.newPassword
                                                                ? errors.newPassword
                                                                !== 'Please enter new password.'
                                                            && touched.newPassword
                                                                    ? 'password-group'
                                                                    : ''
                                                                : ''
                                                        }`}
                                                    >
                                                        <label htmlFor="newPassword"> New password </label>

                                                        <Field
                                                            id="newPassword"
                                                            className={`form-control ${
                                                                errors.newPassword
                                                                && touched.newPassword
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            name="newPassword"
                                                            type="password"
                                                            autoComplete="new-password"
                                                            placeholder="Please choose a new password"
                                                        />

                                                        <ErrorMessage
                                                            name="newPassword"
                                                            component="div"
                                                            className={`invalid-feedback ${
                                                                errors.newPassword
                                                                !== 'Please enter new password.'
                                                                    ? 'invalid-password'
                                                                    : ''
                                                            }`}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="confirmNewPassword">
                                                            {' '}
                                                            Confirm new password
                                                            {' '}
                                                        </label>

                                                        <Field
                                                            id="confirmNewPassword"
                                                            className={`form-control ${
                                                                errors.confirmNewPassword
                                                                && touched.confirmNewPassword
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            name="confirmNewPassword"
                                                            type="password"
                                                            placeholder="Re-enter your new password"
                                                        />

                                                        <ErrorMessage
                                                            name="confirmNewPassword"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>

                                                    <button
                                                        className="primary-btn-royal-blue sign-in-btn"
                                                        type="submit"
                                                    >
                                                        submit
                                                    </button>

                                                    <div className="custom-control custom-checkbox signed-in-checkbox">
                                                        <Field
                                                            id="keepMeLoggedIn"
                                                            className="custom-control-input"
                                                            name="keepMeLoggedIn"
                                                            type="checkbox"
                                                        />
                                                        <label className="custom-control-label" htmlFor="keepMeLoggedIn">Keep me signed in</label>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>

                                {isLoggedIn && <Redirect to="/" />}

                                {loading && <Spinner loaderClass="loader-fullscreen" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        errorMessage: state.auth.errorMessage,
        isLoggedIn: state.auth.isLoggedIn
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetPasswordFields: (formData) => {
            return dispatch(userResetPassword(formData));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
