import axios from 'axios';

const { ENV } = process.env;
console.log(ENV.API_BASE_URL);
const API = axios.create({
    baseURL: ENV.API_BASE_URL,
    timeout: 9999999,
    headers: {
        'Content-Type': 'application/json'
    }
});

API.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('x-token') ? localStorage.getItem('x-token') : '';
        config.headers['x-token'] = token;
        config.headers['application-key'] = ENV.APPLICATION_KEY;
        // config.headers.Expires = '-1';
        // config.headers['Cache-Control'] = 'no-cache,no-store,must-revalidate,max-age=-1,private';
        // config.headers.Pragma = 'no-cache';
        // Do something before request is sent
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { response } = error;
        const { status, data } = response;

        if (status === 403 && data && data.message.toLowerCase() === 'token is expired.') {
            localStorage.clear();
            window.location.href = '/login';
        }

        return Promise.reject(error);
    }
);

export { API };
