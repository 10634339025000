import { API } from '../../helpers/apiHelpers';

const getReviewDetails = async () => {
    const campaignId = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : '';

    if (campaignId) {
        const url = `/campaigns/${campaignId}?isDetailed=true`;
        try {
            return await API.get(url);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const postReviewData = async (data) => {
    const campaignId = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : '';

    if (campaignId) {
        const url = `agreements/campaigns/${campaignId}`;
        try {
            return await API.post(url, data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

const validateCampaignDiscount = async () => {
    const campaignId = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : '';

    if (campaignId) {
        const url = `campaigns/${campaignId}/validatecampaigndiscount`;
        try {
            return await API.get(url);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return null;
};

export {
    getReviewDetails,
    postReviewData,
    validateCampaignDiscount
};
