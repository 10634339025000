/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import _ from 'lodash';
import { updateObject } from '../../utils/reducer_utility';
import {
    CAMPAIGN_DETAILS_START,
    STORE_CAMPAIGN_ID,
    CAMPAIGN_DETAILS_END,
    FETCH_CAMPAIGN_DETAILS_START,
    FETCH_CAMPAIGN_DETAILS_END,
    ADMIN_CLEAR_ALL_CAMPAIGN_STORE,

    // Campaign Details Section
    GET_ADMIN_CAMPAIGN_DETAILS_SUCCESS,
    GET_ADMIN_CAMPAIGN_DETAILS_FAIL,
    UPDATE_CAMPAIGN_DATES_ERROR,
    UPDATE_CAMPAIGN_DATES,

    // Tasks Section
    GET_CAMPAIGN_TASKS_SUCCESS,
    GET_CAMPAIGN_TASKS_FAIL,
    SHOW_UPDATE_TASK_DETAILS_POPUP,
    HANDLE_ADD_NEW_TASK_POPUP,
    ADMIN_DOCUMENT_POST_START,
    NEW_TASK_ERROR_HANDLER,
    UPDATE_TASK_DETAILS_ERROR,
    UPDATE_TASK_DETAILS_SUCCESS,

    // Documents Section
    GET_CAMPAIGN_DOCUMENTS_SUCCESS,
    DELETE_CAMPAIGN_DOCUMENT_SUCCESS,
    CAMPAIGN_DOCUMENTS_FAIL,

    // Reward Points Section
    GET_CAMPAIGN_REWARDS_SUCCESS,
    CAMPAIGN_REWARDS_FAIL,
    SHOW_ADD_SUBTRACT_REWARD_POINTS,
    UPDATE_REWARD_POINT_SUCCESS,
    UPDATE_CAMPAIGN_REWARDS_FAIL,

    // Message Section
    ADMIN_MESSAGE_LIST_FAIL,
    GET_ADMIN_MESSAGE_LIST_SUCCESS,
    ADMIN_GET_MESSAGE_LIST_START,
    ADMIN_SHOW_COMPOSE_MESSAGE,
    ADMIN_MESSAGE_ERROR_HANDLER,
    ADMIN_SHOW_VIEW_MESSAGE,
    CLEAR_ERROR_MESSAGE
} from '../actions/actionTypes';
import { formatDate, formatSize } from '../../utils/utility_helpers';

const initialState = {
    loading: false,
    loadingMessage: '',
    isArchivedCampaign: false,
    campaignId: '',

    // Campaign Details Section
    campaignStartDate: '',
    campaignEndDate: '',
    campaignCreatedDate: '',
    companyLegalName: '',
    packageType: '',
    companyId: null,
    isCampaignCompleted: null,
    campaignDates: {},
    campaignPackage: {},
    campaignDateErrorMessage: '',
    fetchLoading: false,

    // Tasks Section
    tasks: [{
        taskId: null,
        taskName: '',
        revisedStartDate: null,
        revisedEndDate: null,
        originalDuration: null,
        taskOwner: '',
        isComplete: false,
        isPastDue: false,
        workFlowTaskId: null,
        predecessorWorkFlowTaskId: null,
        predecessorTaskName: null,
        taskType: ''
    }],
    unChangedTaskList: [],
    tasksErrorMessage: null,
    showUpdateTaskDetailsPopup: false,
    showAddNewTask: false,
    newTaskErrorMessage: '',
    taskType: 'newTask',
    selectedTask: {},
    editTaskDetails: {},
    // Documents Section
    documents: [],
    unUsedDocuments: [],
    documentsErrorMessage: null,

    // Reward Pont Section
    showAddSubtractPointsPopup: false,
    pointsEarned: null,
    pointsRedeemed: null,
    rewardsPopupErrorMessage: null,
    rewards: [],
    isNewDocumentListChanged: false,

    // Message Section
    messageList: [],
    getMessageError: '',
    getMessageLoading: false,
    showComposeMessage: false,
    messageError: '',
    showMessageDetails: false,
    messageDetailsList: []
};

const buildMessageList = (messageList) => {
    const list = [];
    messageList.map((item) => {
        const customer = (item.messages.filter((x) => { return x.fromPersonRole === 'Customer'; })).sort((a, b) => {
            return ((b.createdDateTime > a.createdDateTime) ? 1 : -1);
        });
        if (customer.length > 0) {
            list.push(customer[0]);
        }
        const admin = (item.messages.filter((x) => { return x.fromPersonRole !== 'Customer'; })).sort((a, b) => {
            return ((b.createdDateTime > a.createdDateTime) ? 1 : -1);
        });
        if (admin.length > 0) {
            list.push(admin[0]);
        }
    });
    return list.sort((a, b) => {
        return ((a.createdDateTime < b.createdDateTime) ? 1 : -1);
    });
};

const createDocumentObject = (data) => {
    const tempDocuments = [];
    data.map((item) => {
        const document = item;
        document.formatDates = formatDate(item.modifiedDate);
        document.formatedSize = formatSize(item.fileSize);
        console.log(document.formatDates);
        tempDocuments.push(document);
    });
    tempDocuments.sort((a, b) => {
        return ((a.modifiedDate < b.modifiedDate) ? 1 : -1);
    });
    return tempDocuments;
};

const adminCampaignDetails = (state = initialState, action) => {
    switch (action.type) {
    case CAMPAIGN_DETAILS_START: {
        return updateObject(state, {
            loading: true,
            loadingMessage: action.data ? action.data : ''
        });
    }
    case CAMPAIGN_DETAILS_END: {
        return updateObject(state, {
            loading: false,
            loadingMessage: ''
        });
    }
    case STORE_CAMPAIGN_ID: {
        return updateObject(state, {
            campaignId: Number(action.data)
        });
    }
    case ADMIN_CLEAR_ALL_CAMPAIGN_STORE: {
        return initialState;
    }

    // Campaign Details Section
    case GET_ADMIN_CAMPAIGN_DETAILS_SUCCESS: {
        return updateObject(state, {
            campaignStartDate: action.data.startDate,
            campaignEndDate: action.data.endDate,
            loading: false,
            campaignCreatedDate: action.data.createdDateTime,
            companyLegalName: action.data.companyLegalName,
            companyId: action.data.companyId,
            packageType: action.data.packageType,
            isArchivedCampaign: action.data.isArchivedCampaign,
            isCampaignCompleted: action.data.isCompleted,
            campaignDates: action.data.campaignDates,
            campaignPackage: action.data.campaignPackage,
            campaignDateErrorMessage: ''
        });
    }
    case GET_ADMIN_CAMPAIGN_DETAILS_FAIL: {
        return updateObject(state, {
            errorMessage: action.errorMessage,
            loading: false
        });
    }
    case UPDATE_CAMPAIGN_DATES_ERROR: {
        return updateObject(state, {
            campaignDateErrorMessage: action.data,
            loading: false
        });
    }
    case UPDATE_CAMPAIGN_DATES: {
        return updateObject(state, {
            campaignDateErrorMessage: '',
            campaignDates: action.data.campaignDates,
            campaignStartDate: action.data.campaignDates.campaignStartDate,
            campaignEndDate: action.data.campaignDates.campaignEndDate,
            loading: false,
            campaignCreatedDate: action.data.campaignDates.campaignCreatedDate
        });
    }
    case CLEAR_ERROR_MESSAGE: {
        return updateObject(state, {
            campaignDateErrorMessage: ''
        });
    }
    case FETCH_CAMPAIGN_DETAILS_START: {
        return updateObject(state, {
            fetchLoading: true
        });
    }
    case FETCH_CAMPAIGN_DETAILS_END: {
        return updateObject(state, {
            fetchLoading: false
        });
    }

    // Reward Points Section
    case GET_CAMPAIGN_REWARDS_SUCCESS: {
        let pointsEarned = 0;
        let pointsRedeemed = 0;
        const tempRewards = action.data;
        tempRewards.sort((a, b) => {
            return ((a.dateOfTranscation < b.dateOfTranscation) ? 1 : -1);
        });
        tempRewards.map((item) => {
            if (!item.isRedeemed) {
                pointsEarned += item.points;
            }
            if (item.isRedeemed) {
                pointsRedeemed += item.points;
            }
        });

        return updateObject(state, {
            rewards: tempRewards,
            pointsEarned,
            pointsRedeemed,
            loading: false,
            rewardsErrorMessage: null
        });
    }
    case CAMPAIGN_REWARDS_FAIL: {
        return updateObject(state, {
            loading: false,
            rewardsErrorMessage: action.errorMessage
        });
    }
    case SHOW_ADD_SUBTRACT_REWARD_POINTS: {
        return updateObject(state, {
            showAddSubtractPointsPopup: action.data,
            rewardsPopupErrorMessage: null
        });
    }
    case UPDATE_REWARD_POINT_SUCCESS: {
        const tempRewards = state.rewards;
        tempRewards.push(action.data);

        let pointEarned = 0;
        let pointRedeemed = 0;
        tempRewards.map((item) => {
            if (!item.isRedeemed) {
                pointEarned += item.points;
            }
            if (item.isRedeemed) {
                pointRedeemed += item.points;
            }
        });

        tempRewards.sort((a, b) => {
            return ((a.dateOfTranscation < b.dateOfTranscation) ? 1 : -1);
        });

        return updateObject(state, {
            rewards: tempRewards,
            pointsEarned: pointEarned,
            pointsRedeemed: pointRedeemed,
            loading: false,
            rewardsPopupErrorMessage: null,
            showAddSubtractPointsPopup: false
        });
    }
    case UPDATE_CAMPAIGN_REWARDS_FAIL: {
        return updateObject(state, {
            loading: false,
            rewardsPopupErrorMessage: action.errorMessage
        });
    }

    // Tasks Section
    case GET_CAMPAIGN_TASKS_SUCCESS: {
        let taskList = action.data;
        const unChangedTaskList = _.cloneDeep(action.data);
        let previousOwner = '';
        if (action.data.length > 0) {
            taskList = taskList.sort((a, b) => {
                return Date.parse(a.revisedEndDate) - Date.parse(b.revisedEndDate);
            });
            previousOwner = taskList[0].taskOwner;
            // taskList.map((item, index) => {
            //     // if (index > 1) {
            //     if (item.taskOwner === 'Customer' && item.taskId && item.taskOwner === previousOwner) {
            //         taskList.splice(index + 1, 0, { taskOwner: 'MA Team Member', workFlowTaskId: null });
            //     }
            //     if (item.taskOwner === 'MA Team Member' && item.taskId && item.taskOwner === previousOwner) {
            //         taskList.splice(index + 1, 0, { taskOwner: 'Customer', workFlowTaskId: null });
            //     }
            //     // }
            //     previousOwner = item.taskId ? item.taskOwner : previousOwner;
            // });

            for (let index = 0; index < taskList.length; index++) {
                // if (index > 1) {
                if (taskList[index].taskOwner === 'Customer' && taskList[index].workFlowTaskId && taskList[index].taskOwner === previousOwner) {
                    if (index === 0 && taskList[index].taskOwner === 'Customer') {
                        console.log('index is 0 ');
                    } else {
                        taskList.splice(index + 1, 0, { tskOwner: 'MA Team Member', workFlowTaskId: null });
                    }
                }
                if (taskList[index].taskOwner === 'MA Team Member' && taskList[index].workFlowTaskId && taskList[index].taskOwner === previousOwner) {
                    taskList.splice(index + 1, 0, { taskOwner: 'Customer', workFlowTaskId: null });
                }
                // }
                // }
                previousOwner = taskList[index].workFlowTaskId ? taskList[index].taskOwner : previousOwner;
            }
        }
        return updateObject(state, {
            tasks: taskList,
            tasksErrorMessage: null,
            showAddNewTask: false,
            newTaskErrorMessage: '',
            unChangedTaskList
        });
    }
    case GET_CAMPAIGN_TASKS_FAIL: {
        return updateObject(state, {
            loading: false,
            tasksErrorMessage: action.errorMessage
        });
    }
    case SHOW_UPDATE_TASK_DETAILS_POPUP: {
        return updateObject(state, {
            showUpdateTaskDetailsPopup: action.data.showUpdateTaskDetailsPopup,
            selectedTask: action.data.selectedTask,
            tasksErrorMessage: null
        });
    }
    case UPDATE_TASK_DETAILS_SUCCESS: {
        return updateObject(state, {
            showUpdateTaskDetailsPopup: false
        });
    }
    case HANDLE_ADD_NEW_TASK_POPUP: {
        return updateObject(state, {
            showAddNewTask: action.data.showNewTask,
            taskType: action.data.taskType,
            editTaskDetails: action.data.editTaskDetails,
            newTaskErrorMessage: '',
            showUpdateTaskDetailsPopup: false
        });
    }
    case NEW_TASK_ERROR_HANDLER: {
        return updateObject(state, {
            newTaskErrorMessage: action.data
        });
    }
    case UPDATE_TASK_DETAILS_ERROR: {
        return updateObject(state, {
            loading: false,
            tasksErrorMessage: action.errorMessage
        });
    }

    // Documents Section
    case GET_CAMPAIGN_DOCUMENTS_SUCCESS: {
        return updateObject(state, {
            documents: createDocumentObject(action.data),
            unUsedDocuments: _.cloneDeep(createDocumentObject(action.data)),
            documentsErrorMessage: null,
            isNewDocumentListChanged: !state.isNewDocumentListChanged
        });
    }
    case CAMPAIGN_DOCUMENTS_FAIL: {
        return updateObject(state, {
            loading: false,
            documentsErrorMessage: action.errorMessage
        });
    }
    case DELETE_CAMPAIGN_DOCUMENT_SUCCESS: {
        const tempDocuments = state.documents;
        const index = state.documents.length > 0 && state.documents.findIndex((x) => {
            return x.fileId === action.data;
        });
        tempDocuments.splice(index, 1);
        return updateObject(state, {
            loading: false,
            documents: tempDocuments,
            unUsedDocuments: _.cloneDeep(tempDocuments),
            isNewDocumentListChanged: !state.isNewDocumentListChanged
        });
    }
    case ADMIN_DOCUMENT_POST_START: {
        return updateObject(state, {
            isNewDocumentListChanged: !state.isNewDocumentListChanged
        });
    }

    // Message Section
    case ADMIN_GET_MESSAGE_LIST_START:
        return updateObject(state, {
            getMessageLoading: true,
            getMessageError: ''
        });
    case GET_ADMIN_MESSAGE_LIST_SUCCESS:
        return updateObject(state, {
            messageList: buildMessageList(action.data.messageList),
            loading: false,
            getMessageError: '',
            getMessageLoading: false
        });
    case ADMIN_MESSAGE_LIST_FAIL:
        return updateObject(state, {
            loading: false,
            getMessageError: action.data,
            getMessageLoading: false
        });
    case ADMIN_SHOW_COMPOSE_MESSAGE: {
        return updateObject(state, {
            showComposeMessage: action.data.showComposeMessage,
            showMessageDetails: action.data.showMessageDetails,
            messageError: ''
        });
    }
    case ADMIN_MESSAGE_ERROR_HANDLER: {
        return updateObject(state, {
            messageError: action.data
        });
    }
    case ADMIN_SHOW_VIEW_MESSAGE: {
        return updateObject(state, {
            showMessageDetails: action.data.showMessageDetails,
            messageError: '',
            messageDetailsList: action.data.messageDetailsList || []
        });
    }
    default:
        return state;
    }
};

export default adminCampaignDetails;
