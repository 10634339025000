import React from 'react';
import Header from '../common/Header';
import HeaderMobile from '../common/HeaderMobile';
import Footer from '../common/Footer';
import { Default, Mobile } from '../common/DeviceDetector';

const DefaultLayout = (props) => {
    const { children } = props;
    return (
        <>
            <Default>
                <Header />
                {children}
                <Footer />
            </Default>
            <Mobile>
                <HeaderMobile />
                {children}
                <Footer />
            </Mobile>

        </>
    );
};

export default DefaultLayout;
