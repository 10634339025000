/* eslint-disable import/no-cycle */
import * as actionTypes from './actionTypes';
// import { updateCompany } from './company';
import { getCampaign } from './campaign';
import * as actions from './index';
import { setErrorMessage } from '../../utils/utility_helpers';
import { updateCampaignService } from '../services/campaign';
import { getLocationsService, updateLocationsService } from '../services/location';
// import { getPersonDetails } from './person';
import { navigationTabList } from '../../components/Constants';
import { updateNavigation } from './navigation';

export const locationStart = () => {
    return {
        type: actionTypes.LOCATION_START
    };
};

export const locationFail = (error) => {
    return {
        type: actionTypes.LOCATION_FAIL,
        errorMessage: error
    };
};

export const locationFinished = () => {
    return {
        type: actionTypes.LOCATION_FINISHED
    };
};

export const locationSuccess = (data) => {
    return {
        type: actionTypes.LOCATION_SUCCESS,
        data
    };
};

export const updateIsSaved = (data) => {
    return {
        type: actionTypes.IS_SAVED,
        data
    };
};
export const deleteCampaignSuccess = () => {
    return {
        type: actionTypes.DELETE_CAMPAIGN_SUCCESS
    };
};
export const updateCompanyDetails = (data) => {
    return {
        type: actionTypes.UPDATE_COMPANY_DETAILS,
        data
    };
};
export const updateLocationAndMediaBudgetState = (data) => {
    return {
        type: actionTypes.UPDATE_LOCATION_AND_MEDIA_BUDGET_STATE,
        data
    };
};

export const setIndividualLocationDetails = (data) => {
    return {
        type: actionTypes.SET_INDIVIDUAL_LOCATION_DETAILS,
        data
    };
};

export const updateLocationPersonDetails = (data) => {
    return {
        type: actionTypes.SET_LOCATION_PERSON_DETAILS,
        data
    };
};

export const setLocationInitialState = () => {
    return {
        type: actionTypes.SET_LOCATION_INITIAL_STATE
    };
};

export const updateLocations = (data) => {
    const formData = data;
    const campaignId = localStorage.getItem('CampaignId');
    return async (dispatch) => {
        try {
            dispatch(locationStart());
            const response = await updateLocationsService(campaignId, formData.locations);
            formData.locations.locationDetails = response.data.data;
            dispatch(locationSuccess(formData));
            dispatch(updateNavigation(navigationTabList.campaignDates, 2));
            // dispatch(updateCompany(formData));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(locationFail(errorMessage));
        }
    };
};

export const updateCampaign = (formData) => {
    return async (dispatch) => {
        try {
            const campaignId = localStorage.getItem('CampaignId');
            dispatch(locationStart());
            await updateCampaignService(campaignId, {
                locationBudgetComment: formData.locationBudgetComment,
                intakeStepId: 2
            });
            dispatch(updateLocations(formData));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(locationFail(errorMessage));
        }
    };
};

export const getLocations = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch(locationStart());
            const response = await getLocationsService(campaignId);
            // dispatch(getPersonDetails());
            dispatch(getCampaign(campaignId));
            dispatch(setIndividualLocationDetails(response.data.data));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(locationFail(errorMessage));
        }
    };
};

export const clearLocations = (navigate, type, intakeStepId) => {
    return (dispatch) => {
        if (type === 'changed') {
            dispatch(updateIsSaved(true));
        }

        dispatch(actions.updateNavigation(navigate, intakeStepId));
    };
};
