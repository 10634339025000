import * as actionTypes from './actionTypes';
import { setErrorMessage } from '../../utils/utility_helpers';
import { getAdminPromotionsService, getAdminCreativesService } from '../services/adminCreativeUploads';
import { getMediaChannelsService } from '../services/mediaChannels';

export const getAdminPromotionsList = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: actionTypes.ADMIN_PROMOTION_LIST_START });
            const response = await getAdminPromotionsService(campaignId);
            const savedData = {};
            savedData.promotionList = response.data.data;
            dispatch({ type: actionTypes.ADMIN_PROMOTION_LIST_SUCCESS, data: savedData });
            console.log(savedData);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({ type: actionTypes.ADMIN_PROMOTION_LIST_FAIL, errorMessage });
        }
    };
};

export const getAdminChannelsList = () => {
    return async (dispatch) => {
        try {
            // dispatch({ type: actionTypes.ADMIN_CHANNEL_LIST_START });
            const response = await getMediaChannelsService();
            const savedData = {};
            savedData.channelList = response.data.data;
            dispatch({ type: actionTypes.ADMIN_CHANNEL_LIST_SUCCESS, data: savedData });
            console.log(savedData);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({ type: actionTypes.ADMIN_CHANNEL_LIST_FAIL, errorMessage });
        }
    };
};

export const getAdminCreativesListData = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: actionTypes.ADMIN_CREATIVE_LIST_START });
            // const response = await getAdminCreativesService(campaignId);
            const savedData = {};
            savedData.creativeList = [
                {
                    id: 1,
                    promotion: 'Pro1',
                    channel: 'TV',
                    dueDate: '2020-04-12T00:00:00',
                    submittedOnTime: '2020-04-12T14:15:03.987',
                    dateSubmittedCorrectly: '2020-04-12T00:00:00',
                    filePath: 'https://mediaagentdev.blob.core.windows.net/empowerpdf/4/425/Agreement_20200421_132319265208491483_89.pdf'
                },
                {
                    id: 2,
                    promotion: 'raghu',
                    channel: 'TV',
                    dueDate: '2020-04-13T00:00:00',
                    submittedOnTime: '2020-04-12T14:15:03.987',
                    dateSubmittedCorrectly: '2020-04-12T00:00:00',
                    filePath: 'https://mediaagentdev.blob.core.windows.net/empowerpdf/4/425/Agreement_20200421_132319265208491483_89.pdf'
                },
                {
                    id: 3,
                    promotion: 'Pro1',
                    channel: 'ONLINE VIDEO',
                    dueDate: '2020-04-15T00:00:00',
                    submittedOnTime: '2020-04-12T20:15:03.987',
                    dateSubmittedCorrectly: '2020-04-12T00:00:00',
                    filePath: 'https://mediaagentdev.blob.core.windows.net/empowerpdf/4/425/Agreement_20200421_132319265208491483_89.pdf'
                },
                {
                    id: 4,
                    promotion: 'Pro1',
                    channel: 'RADIO',
                    dueDate: '2020-04-18T00:00:00',
                    submittedOnTime: '2020-04-12T10:15:03.987',
                    dateSubmittedCorrectly: '2020-04-12T00:00:00',
                    filePath: 'https://mediaagentdev.blob.core.windows.net/empowerpdf/4/425/Agreement_20200421_132319265208491483_89.pdf'
                },
                {
                    id: 5,
                    promotion: 'raghu',
                    channel: 'NEWSPAPER',
                    dueDate: '2020-04-20T00:00:00',
                    submittedOnTime: '2020-04-12T05:15:03.987',
                    dateSubmittedCorrectly: '2020-04-12T00:00:00',
                    filePath: 'https://mediaagentdev.blob.core.windows.net/empowerpdf/4/425/Agreement_20200421_132319265208491483_89.pdf'
                },
                {
                    id: 6,
                    promotion: 'raghuram',
                    channel: 'MAGAZINE',
                    dueDate: '2020-04-12T00:00:00',
                    submittedOnTime: '2020-04-21T02:15:03.987',
                    dateSubmittedCorrectly: '2020-04-12T00:00:00',
                    filePath: 'https://mediaagentdev.blob.core.windows.net/empowerpdf/4/425/Agreement_20200421_132319265208491483_89.pdf'
                },
                {
                    id: 7,
                    promotion: 'raghu',
                    channel: 'MAGAZINE',
                    dueDate: '2020-04-12T00:00:00',
                    submittedOnTime: '2020-04-23T14:25:03.987',
                    dateSubmittedCorrectly: '2020-04-12T00:00:00',
                    filePath: 'https://mediaagentdev.blob.core.windows.net/empowerpdf/4/425/Agreement_20200421_132319265208491483_89.pdf'
                },
                {
                    id: 8,
                    promotion: 'raghuram',
                    channel: 'NEWSPAPER',
                    dueDate: '2020-04-12T00:00:00',
                    submittedOnTime: '2020-04-12T23:15:03.987',
                    dateSubmittedCorrectly: '2020-04-22T00:00:00',
                    filePath: 'https://mediaagentdev.blob.core.windows.net/empowerpdf/4/425/Agreement_20200421_132319265208491483_89.pdf'
                },
                {
                    id: 9,
                    promotion: 'Pro1',
                    channel: 'RADIO',
                    dueDate: '2020-04-12T00:00:00',
                    submittedOnTime: '2020-04-12T18:45:03.987',
                    dateSubmittedCorrectly: '2020-04-23T00:00:00',
                    filePath: 'https://mediaagentdev.blob.core.windows.net/empowerpdf/4/425/Agreement_20200421_132319265208491483_89.pdf'
                }
            ];
            dispatch({ type: actionTypes.ADMIN_CREATIVE_LIST_SUCCESS, data: savedData });
            console.log(savedData, getAdminCreativesService);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({ type: actionTypes.ADMIN_CREATIVE_LIST_FAIL, errorMessage });
        }
    };
};

export const getAdminCreativesList = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: actionTypes.ADMIN_CREATIVE_LIST_START });
            const response = await getAdminCreativesService(campaignId);
            const savedData = {};
            savedData.creativeUploads = response.data.data;
            dispatch({ type: actionTypes.ADMIN_CREATIVE_LIST_SUCCESS, data: savedData });
            console.log(savedData, getAdminCreativesService);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch({ type: actionTypes.ADMIN_CREATIVE_LIST_FAIL, errorMessage });
        }
    };
};

export const deleteCreatives = (campaignId, creativeId) => {
    return async (dispatch) => {
        try {
            getAdminCreativesList(campaignId);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            console.log(errorMessage);
        }
    };
};
