import { updateObject } from '../../utils/reducer_utility';

import {
    CLEAR_ALL_PACKAGE_RELATED_STORES,

    REVIEW_ERROR_HANDLER,
    REVIEW_FORM_SUBMITTED,
    REVIEW_INITIATION,
    REVIEW_SUCCESS_DETAILS,
    UPDATE_PACKAGE_SUBMISSION,
    POST_REVIEW_INITIATIONS,
    SHOW_DISCOUNT_ALERT
} from '../actions/actionTypes';

const initialState = {
    reviewDetails: {},
    loading: false,
    errorMessage: '',
    pdfLink: '',
    redirect: false,
    postLoading: false,
    showDiscountAlert: false
};

const reviewReducer = (state = initialState, action) => {
    switch (action.type) {
    case REVIEW_ERROR_HANDLER:
        return updateObject(state, {
            loading: false,
            errorMessage: action.data,
            postLoading: false
        });
    case REVIEW_FORM_SUBMITTED:
        return updateObject(state, {
            loading: false,
            pdfLink: action.data,
            redirect: true,
            errorMessage: '',
            postLoading: false,
            showDiscountAlert: false
        });
    case REVIEW_INITIATION:
        return updateObject(state, {
            loading: true
        });
    case REVIEW_SUCCESS_DETAILS:
        return updateObject(state, {
            reviewDetails: action.data,
            loading: false,
            errorMessage: ''
        });
    case UPDATE_PACKAGE_SUBMISSION:
        return updateObject(state, {
            redirect: false
        });
    case CLEAR_ALL_PACKAGE_RELATED_STORES:
        return initialState;
    case POST_REVIEW_INITIATIONS:
        return updateObject(state, {
            postLoading: true
        });
    case SHOW_DISCOUNT_ALERT:
        return updateObject(state, {
            postLoading: false,
            loading: false,
            errorMessage: '',
            showDiscountAlert: action.data
        });
    default:
        return state;
    }
};

export default reviewReducer;
