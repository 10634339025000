/* eslint-disable indent */
/* eslint-disable no-tabs */
import { updateObject } from '../../utils/reducer_utility';
import { cloneObject } from '../../utils/utility_helpers';
import {
	CREATIVE_LIBRARY_START,
	GET_CREATIVE_LIBRARY_FILES_SUCCESS,
	CREATIVE_LIBRARY_FAIL,
	GET_CREATIVE_LIBRARY_FOLDERS_SUCCESS,
	GET_CREATIVE_LIBRARY_FILE_PREVIEW_SUCCESS,
	GET_CREATIVE_LIBRARY_FILE_PREVIEW_FAIL,
	RENAME_CREATIVE_LIBRARY_FILE_NAME_SUCCESS,
	RENAME_POPUP_CHANGE,
	DELETE_CREATIVE_LIBRARY_FILE_SUCCESS,
	UPDATE_SELECTED_FILE_ID,
	DELETE_CREATIVE_LIBRARY_FILE_START,
	DELETE_CREATIVE_LIBRARY_FILE_FAIL,
	UPDATE_DELETE_CONFIRMATION_POPUP,
	UPDATE_DRAG_AND_DROP_POPUP,
	UPLOAD_CREATIVE_LIBARARY_FILES_SUCCESS,
	UPLOAD_CREATIVE_LIBRARY_FILE_START,
	UPDATE_ADDED_FILES,
	UPLOAD_CREATIVE_LIBRARY_FILE_COMPLETE,
	UPDATE_FILE_UPLOAD_LIST_POPUP,
	CANCEL_FILE_UPLOAD,
	UPLOAD_CREATIVE_LIBARARY_FILES_FAIL,
	FILE_UPLOAD_START,
	UPDATE_MULTIPLE_FILES,
	CREATIVE_LIBRARY_MULTI_FILE_DOWNLOAD_FAIL,
	ADD_NEW_TAG_SUCCESS,
	UPDATE_TAG_NAME,
	UPDATE_TAG_EDIT_MODE,
	UPDATE_TAG_SUCCESS,
	GET_COMPANY_TAG_SUCCESS,
	UPDATE_TAG_OPTIONS,
	UPDATE_NEW_TAG_VALUE,
	DOWNLOAD_CREATIVE_LIBRARY_FILES,
	DOWNLOAD_CREATIVE_LIBRARY_FILES_SUCCESS
} from '../actions/actionTypes';

const initialState = {
	loading: false,
	loadingMessage: '',
	companyId: 0,
	creativeFiles: [],
	errorMessage: '',
	creativeLibraryFolders: [],
	creativeLibraryFileDetails: null,
	showRenamePopup: false,
	selectedFileId: null,
	isFileDeleting: false,
	showDragAndDrop: false,
	addedFiles: [],
	showFileUploading: false,
	uploadingFile: {
		isUploading: false,
		id: null
	},
	showDuplicateFilePopup: false,
	allFilesUploadingStatus: null,
	duplicateFileId: null,
	duplicateFileName: null,
	isUploadingCancelled: false,
	creativeLibraryMultipleFileDetails: [],
	fileTags: [],
	tagEditMode: false,
	companyTags: [],
	newTagValue: ''
};

const creativeLibraryStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		errorMessage: ''
	});
};

const creativeLibraryFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		errorMessage: action.errorMessage
	});
};

const getCreativeLibraryFileSuccess = (state, action) => {
	return updateObject(state, {
		// creativeFiles: action.data.data,
		creativeFiles: action.data.data.sort((a, b) => {
				return new Date(b.fileCreatedDateTime) - new Date(a.fileCreatedDateTime);
		}),
		errorMessage: '',
		loading: false
	});
};

const creativeLibraryFoldersSuccess = (state, action) => {
	return updateObject(state, {
		creativeLibraryFolders: action.data.data,
		errorMessage: ''
	});
};

const creativeLibraryFilePreviewFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		errorMessage: action.data
	});
};

const creativeLibraryFilePreviewSuccess = (state, action) => {
	const list = [];
	list.push(action.data.data);

	const tempTags = [];
	action.data.data.fileTags.map((tag, i) => {
		const tagTemp = tag;
		const clone = cloneObject(tagTemp);
		clone.isEdited = false;
		tempTags.push(clone);
		return tag;
	});
	return updateObject(state, {
		creativeLibraryFileDetails: action.data.data,
		fileTags: tempTags,
		// creativeLibraryMultipleFileDetails: [...state.creativeLibraryMultipleFileDetails, ...list],
		errorMessage: '',
		loading: false
	});
};

const renameCreativeLibraryFileSuccess = (state, action) => {
	const temp = [...state.creativeFiles];
	const { data, fileId } = action.data;
	const tempObj = {};
	const tempCreativeDetails = { ...state.creativeLibraryFileDetails };
	if (Object.keys(tempCreativeDetails).length !== 0) {
		tempCreativeDetails.fileName = data.fileName;
		tempObj.creativeLibraryFileDetails = { ...tempCreativeDetails };
	}
	const fileIndex = temp.findIndex((x) => { return x.fileId === fileId; });
	if (fileIndex !== -1) {
		temp[fileIndex].fileName = data.fileName;
	}
	return updateObject(state, {
		creativeFiles: temp,
		loading: false,
		errorMessage: '',
		showRenamePopup: false,
		...tempObj
	});
};

const renamePopupChange = (state, action) => {
	const showRenamePopupTemp = state.showRenamePopup;
	return updateObject(state, {
		showRenamePopup: !showRenamePopupTemp
	});
};

const deleteCreativeLibraryFile = (state, action) => {
	const temp = [...state.creativeFiles];
	const { creativeLibraryFileId } = action.data;
	const creativeFilesTemp = temp.filter((x) => {
		return x.creativeLibraryFileId !== creativeLibraryFileId;
	});
	return updateObject(state, {
		creativeFiles: creativeFilesTemp,
		creativeLibraryFileDetails: null,
		creativeLibraryMultipleFileDetails: [],
		selectedFileId: null,
		errorMessage: '',
		loading: false,
		isFileDeleting: false
	});
};

const updateSelectedFileId = (state, action) => {
	return updateObject(state, {
		selectedFileId: action.data
	});
};

const deleteCreativeLibraryFileStart = (state, action) => {
	return updateObject(state, {
		errorMessage: ''
		// isFileDeleting: true
	});
};

const updateDeleteFileConfirmPopup = (state, action) => {
	return updateObject(state, {
		isFileDeleting: !state.isFileDeleting
	});
};

const deleteCreativeLibraryFileFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		errorMessage: action.data,
		isFileDeleting: true
	});
};

const updateDragAndDropPopup = (state, action) => {
	return updateObject(state, {
		showDragAndDrop: !state.showDragAndDrop
	});
};

const uploadCreativeLibrarySuccess = (state, action) => {
	const { status, id, errorMessage } = action.data;
	const addedFilesTemp = [...state.addedFiles];
	const index = addedFilesTemp.findIndex((file) => {
		return file.id === id;
	});
	let showDuplicateFilePopupTemp = false;
	let duplicateFileIdTemp = null;
	let duplicateFileNameTemp = null;
	if (index !== -1) {
		addedFilesTemp[index].status = status;
		addedFilesTemp[index].errorMessage = errorMessage;
		if (status === 'duplicateFile') {
			showDuplicateFilePopupTemp = true;
			duplicateFileIdTemp = id;
			duplicateFileNameTemp = addedFilesTemp[index].fileName;
		}
	}
	return updateObject(state, {
		loading: false,
		addedFiles: addedFilesTemp,
		showDuplicateFilePopup: showDuplicateFilePopupTemp,
		showFileUploading: !showDuplicateFilePopupTemp,
		duplicateFileId: duplicateFileIdTemp,
		duplicateFileName: duplicateFileNameTemp
	});
};

const uploadCreativeLibraryFail = (state, action) => {
	const { status, id, errorMessage } = action.data;
	const addedFilesTemp = [...state.addedFiles];
	const index = addedFilesTemp.findIndex((file) => {
		return file.id === id;
	});
	if (index !== -1) {
		addedFilesTemp[index].status = status;
		addedFilesTemp[index].errorMessage = errorMessage;
	}
	return updateObject(state, {
		addedFiles: addedFilesTemp
	});
};

const updateAddedFiles = (state, action) => {
	const addedFilesTemp = [...state.addedFiles];
	const index = addedFilesTemp.findIndex((file) => {
		return file.id === action.data.id;
	});
	if (index !== -1) {
		addedFilesTemp[index] = action.data;
	} else {
		addedFilesTemp.push(action.data);
	}
	return updateObject(state, {
		addedFiles: addedFilesTemp
	});
};

const uploadCreativeLibraryFileStart = (state, action) => {
	return updateObject(state, {
		showFileUploading: true,
		showDragAndDrop: false,
		allFilesUploadingStatus: 'start',
		showDuplicateFilePopup: false,
		addedFiles: action.data,
		isUploadingCancelled: false
		// uploadingFile: {
		// 	isUploading: true
		// }
	});
};

const uploadCreativeLibraryFileComplete = (state, action) => {
	return updateObject(state, {
		allFilesUploadingStatus: 'complete'
	});
};

const updateFileUploadListPopup = (state, action) => {
	return updateObject(state, {
		showFileUploading: false,
		allFilesUploadingStatus: '',
		addedFiles: [],
		isUploadingCancelled: false
	});
};

const cancelFilesUpload = (state, action) => {
	return updateObject(state, {
		isUploadingCancelled: true,
		allFilesUploadingStatus: '',
		showFileUploading: false,
		addedFiles: []
	});
};

const fileUploadStart = (state, action) => {
	const { status, id, errorMessage } = action.data;
	const addedFilesTemp = [...state.addedFiles];
	const index = addedFilesTemp.findIndex((file) => {
		return file.id === id;
	});
	if (index !== -1) {
		addedFilesTemp[index].status = status;
		addedFilesTemp[index].errorMessage = errorMessage;
	}
	return updateObject(state, {
		addedFiles: addedFilesTemp,
		allFilesUploadingStatus: 'start'
	});
};

const postUpdateMulFiles = (state, action) => {
	return updateObject(state, {
		creativeLibraryMultipleFileDetails: action.data
	});
};

const creativeLibraryMultiFileDownloadFail = (state, action) => {
	return updateObject(state, {
		errorMessage: action.data,
		loading: false
	});
};

const addNewTagSuccess = (state, action) => {
	return updateObject(state, {
		newTagValue: '',
		errorMessage: '',
		loading: false
	});
};


const updateTageName = (state, action) => {
	return updateObject(state, {
		fileTags: action.data
	});
};

const updateTageEditMode = (state, action) => {
	return updateObject(state, {
		tagEditMode: action.data
	});
};

const updateTageEditSuccess = (state, action) => {
	return updateObject(state, {
		errorMessage: '',
		loading: false
	});
};

const getCompanyTagSuccess = (state, action) => {
	const tags = [];
	action.data.map((tag) => {
		const option = {
			label: tag.tagName,
			value: tag.tagName
		};
		const tagIndex = tags.findIndex((x) => { return x.value === tag.tagName; });
		if (tagIndex === -1) {
			tags.push(option);
		}
		return tag;
	});
	return updateObject(state, {
		companyTags: tags,
		errorMessage: '',
		loading: false
	});
};

const updateTagOptions = (state, action) => {
	return updateObject(state, {
		companyTags: [...new Set(action.data)]
	});
};

const updateNewTagValue = (state, action) => {
	return updateObject(state, {
		newTagValue: action.data
	});
};

const downloadCreativeFiles = (state, action) => {
	return updateObject(state, {
		loading: true,
		errorMessage: ''
	});
};

const downloadCreativeFilesSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		errorMessage: ''
	});
};


const creativeLibraryReducer = (state = initialState, action) => {
	switch (action.type) {
	case CREATIVE_LIBRARY_START:
		return creativeLibraryStart(state, action);
	case GET_CREATIVE_LIBRARY_FILES_SUCCESS:
		return getCreativeLibraryFileSuccess(state, action);
	case CREATIVE_LIBRARY_FAIL:
		return creativeLibraryFail(state, action);
	case GET_CREATIVE_LIBRARY_FOLDERS_SUCCESS:
		return creativeLibraryFoldersSuccess(state, action);
	case GET_CREATIVE_LIBRARY_FILE_PREVIEW_SUCCESS:
		return creativeLibraryFilePreviewSuccess(state, action);
	case GET_CREATIVE_LIBRARY_FILE_PREVIEW_FAIL:
		return creativeLibraryFilePreviewFail(state, action);
	case RENAME_CREATIVE_LIBRARY_FILE_NAME_SUCCESS:
		return renameCreativeLibraryFileSuccess(state, action);
	case RENAME_POPUP_CHANGE:
		return renamePopupChange(state, action);
	case DELETE_CREATIVE_LIBRARY_FILE_SUCCESS:
		return deleteCreativeLibraryFile(state, action);
	case DELETE_CREATIVE_LIBRARY_FILE_START:
		return deleteCreativeLibraryFileStart(state, action);
	case DELETE_CREATIVE_LIBRARY_FILE_FAIL:
		return deleteCreativeLibraryFileFail(state, action);
	case UPDATE_SELECTED_FILE_ID:
		return updateSelectedFileId(state, action);
	case UPDATE_DELETE_CONFIRMATION_POPUP:
		return updateDeleteFileConfirmPopup(state, action);
	case UPDATE_DRAG_AND_DROP_POPUP:
		return updateDragAndDropPopup(state, action);
	case UPLOAD_CREATIVE_LIBRARY_FILE_START:
		return uploadCreativeLibraryFileStart(state, action);
	case UPLOAD_CREATIVE_LIBARARY_FILES_SUCCESS:
		return uploadCreativeLibrarySuccess(state, action);
	case UPLOAD_CREATIVE_LIBARARY_FILES_FAIL:
		return uploadCreativeLibraryFail(state, action);
	case UPDATE_ADDED_FILES:
		return updateAddedFiles(state, action);
	case UPLOAD_CREATIVE_LIBRARY_FILE_COMPLETE:
		return uploadCreativeLibraryFileComplete(state, action);
	case UPDATE_FILE_UPLOAD_LIST_POPUP:
		return updateFileUploadListPopup(state, action);
	case CANCEL_FILE_UPLOAD:
		return cancelFilesUpload(state, action);
	case FILE_UPLOAD_START:
		return fileUploadStart(state, action);
    case UPDATE_MULTIPLE_FILES:
		return postUpdateMulFiles(state, action);
	case CREATIVE_LIBRARY_MULTI_FILE_DOWNLOAD_FAIL:
		return creativeLibraryMultiFileDownloadFail(state, action);
	case ADD_NEW_TAG_SUCCESS:
		return addNewTagSuccess(state, action);
	case UPDATE_TAG_NAME:
		return updateTageName(state, action);
	case UPDATE_TAG_EDIT_MODE:
		return updateTageEditMode(state, action);
	case UPDATE_TAG_SUCCESS:
		return updateTageEditSuccess(state, action);
	case GET_COMPANY_TAG_SUCCESS:
		return getCompanyTagSuccess(state, action);
	case UPDATE_TAG_OPTIONS:
			return updateTagOptions(state, action);
	case UPDATE_NEW_TAG_VALUE:
		return updateNewTagValue(state, action);
	case DOWNLOAD_CREATIVE_LIBRARY_FILES:
		return downloadCreativeFiles(state, action);
	case DOWNLOAD_CREATIVE_LIBRARY_FILES_SUCCESS:
		return downloadCreativeFilesSuccess(state, action);
	default:
		return state;
	}
};

export default creativeLibraryReducer;
