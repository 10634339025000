/* eslint-disable import/no-cycle */
import * as actionTypes from './actionTypes';
import {
    deleteCampaignSuccess,
    locationStart,
    setLocationInitialState,
    locationFail,
    updateLocationAndMediaBudgetState,
    locationFinished
} from './location';
import { startCampaignSercvice, deleteCampaignService, getCampaignSercvice } from '../services/campaign';
import { setErrorMessage } from '../../utils/utility_helpers';
import { updateNavigation, clearAllPackageStores } from './navigation';
import {
    navigationTabList
} from '../../components/Constants';
import { deleteMIMPackageSuccess } from './marketingModal';

export const campaignStart = () => {
    return {
        type: actionTypes.CAMPAIGN_START
    };
};

const campaignFail = (error) => {
    return {
        type: actionTypes.CAMPAIGN_FAIL,
        errorMessage: error
    };
};

const startCampaignSuccess = (data) => {
    return {
        type: actionTypes.CAMPAIGN_SUCCESS,
        data
    };
};

export const updateCampaignRedirect = () => {
    return { type: actionTypes.UPDATE_CAMPAIGN_REDIRECT };
};

const getCampaignSuccess = (data) => {
    return {
        type: actionTypes.GET_CAMAPIGN_SUCCESS,
        data
    };
};

export const startCampaign = (formData) => {
    return async (dispatch) => {
        try {
            const response = await startCampaignSercvice(formData);
            localStorage.setItem('PackageId', response.data.data.packageId);
            localStorage.setItem('IntakeStepId', response.data.data.intakeStepId);
            localStorage.setItem('CampaignId', response.data.data.id);
            dispatch(startCampaignSuccess(response.data.data));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(campaignFail(errorMessage));
        }
    };
};

export const deleteCampaign = () => {
    return async (dispatch) => {
        try {
            dispatch(locationStart());
            const campaignId = localStorage.getItem('CampaignId');
            await deleteCampaignService(campaignId);
            localStorage.removeItem('CampaignId');
            localStorage.removeItem('IntakeStepId');
            localStorage.removeItem('PackageId');
            dispatch(deleteCampaignSuccess());
            dispatch(deleteMIMPackageSuccess());
            dispatch(setLocationInitialState());
            dispatch(clearAllPackageStores());
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(locationFail(errorMessage));
            dispatch(campaignFail(errorMessage));
        }
    };
};

export const getCampaign = (campaignId) => {
    return async (dispatch) => {
        try {
            const response = await getCampaignSercvice(campaignId);
            dispatch(updateLocationAndMediaBudgetState(response.data.data));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(locationFail(errorMessage));
            dispatch(campaignFail(errorMessage));
        }
    };
};

export const getCampaignForPackagePage = (campaignIdFromCustomer) => {
    return async (dispatch) => {
        try {
            const campaignId = campaignIdFromCustomer || localStorage.getItem('CampaignId');
            const response = await getCampaignSercvice(campaignId);
            const intakeStep = response.data.data.campaignIntakeStep.intakeStepDesc;
            const intakeStepId = response.data.data.campaignIntakeStep.id;
            console.log(response.data.data);
            dispatch(getCampaignSuccess(response.data.data));
            switch (intakeStep) {
            case 'LocationBudget':
                dispatch(locationFinished());
                dispatch(updateNavigation(navigationTabList.locations, intakeStepId));
                break;
            case 'CampaignSchedule':
                dispatch(updateNavigation(navigationTabList.campaignDates, intakeStepId));
                break;
            case 'Objectives':
                dispatch(updateNavigation(navigationTabList.objectives, intakeStepId));
                break;
            case 'MediaChannel':
                dispatch(updateNavigation(navigationTabList.mediaMix, intakeStepId));
                break;
            case 'Audience':
                dispatch(updateNavigation(navigationTabList.audiences, intakeStepId));
                break;
            case 'BusinessProfile':
                dispatch(updateNavigation(navigationTabList.businessProfile, intakeStepId));
                break;
            case 'Review':
                dispatch(updateNavigation(navigationTabList.review, intakeStepId));
                break;
            default:
                break;
            }
            console.log(response);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(campaignFail(errorMessage));
        }
    };
};
