import * as actionTypes from './actionTypes';
import { setErrorMessage } from '../../utils/utility_helpers';
import { getAdminCustomerDetailsService } from '../services/adminCustomerDetails';
import { getPackageSercvice, getCompanyPackageDicountsService } from '../services/package';
import { updateBusinessProfileService } from '../services/businessProfile';
import {
    getCompanyLocations,
    saveCompanyLocationsService,
    updateCompanyDetailsService,
    updateCompanyPackageDiscountsService
} from '../services/company';
import { moveToArchiveService } from '../services/campaign';

export const getAdminCustomerDetailsStart = () => {
    return {
        type: actionTypes.ADMIN_CUSTOMER_DETAILS_START
    };
};

export const getAdminCustomerDetailsFail = (errorMessage) => {
    return {
        type: actionTypes.ADMIN_CUSTOMER_DETAILS_FAIL,
        errorMessage
    };
};

export const getAdminCustomerDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_ADMIN_CUSTOMER_DETAILS_SUCCESS,
        data
    };
};

export const adminCustomerDetailsFinish = () => {
    return {
        type: actionTypes.ADMIN_CUSTOMER_DETAILS_SUCCESS
    };
};

export const updateCompanyProfileIsSaved = (data) => {
    return {
        type: actionTypes.UPDATE_COMPANY_PROFILE_IS_SAVED,
        data
    };
};

export const updateIsAccountSettingEditable = (data) => {
    return {
        type: actionTypes.UPDATE_IS_ACCOUNT_SETTINGS_EDITABLE,
        data
    };
};

export const saveLocationsSuccess = (data) => {
    return {
        type: actionTypes.SAVE_ADMIN_LOCATION_SUCCESS,
        data
    };
};

export const updateCompanyProfileSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_COMPANY_PROFILE_SUCCESS,
        data
    };
};

export const updateIsCompanyEditable = (data) => {
    return {
        type: actionTypes.UPDATE_IS_COMPANY_EDITABLE,
        data
    };
};

export const updateIsLocationEditable = (data) => {
    return {
        type: actionTypes.UPDATE_IS_LOCATION_EDITABLE,
        data
    };
};

export const updateCompanyProfileInitialState = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_PROFILE_INITIAL_STATE
    };
};

const moveToArchiveSuccess = (data) => {
    return {
        type: actionTypes.MOVE_TO_ARCHIVE_SUCCESS,
        data
    };
};

const updateIsMovedToarchiveStatus = (data) => {
    return {
        type: actionTypes.UPDATE_IS_MOVED_TO_ARCHIVE_STATUS,
        data
    };
};

const saveAccountSettingsSuccess = (data) => {
    return {
        type: actionTypes.SAVE_ACCOUNT_SETTINGS_SUCCESS,
        data
    };
};

export const getAdminCustomerDetails = (companyId) => {
    return async (dispatch) => {
        try {
            dispatch(getAdminCustomerDetailsStart());
            const companyDetails = await getAdminCustomerDetailsService(companyId);
            const packages = await getPackageSercvice();
            const companyPackageDicounts = await getCompanyPackageDicountsService(companyId);
            const locations = await getCompanyLocations(companyId);
            console.log(locations);
            const data = {
                companyDetails: companyDetails.data.data,
                packages: packages.data.data,
                companyPackageDicounts: companyPackageDicounts.data.data,
                locations: locations.data.data
            };
            // dispatch(updateIsMovedToarchiveStatus(true));
            dispatch(getAdminCustomerDetailsSuccess(data));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(getAdminCustomerDetailsFail(errorMessage));
        }
    };
};

export const updateCompanyProfile = (profileDetails) => {
    return async (dispatch) => {
        try {
            dispatch(getAdminCustomerDetailsStart());
            const response = await updateBusinessProfileService(
                profileDetails.companyObject
            );
            dispatch(updateCompanyProfileSuccess(profileDetails));
            dispatch(adminCustomerDetailsFinish());
            console.log(response);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(getAdminCustomerDetailsFail(errorMessage));
        }
    };
};

export const saveLocations = (locations, companyId) => {
    return async (dispatch) => {
        try {
            dispatch(getAdminCustomerDetailsStart());
            const response = await saveCompanyLocationsService(locations, companyId);
            dispatch(saveLocationsSuccess(response.data.data));
            console.log(response);
            dispatch(adminCustomerDetailsFinish());
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(getAdminCustomerDetailsFail(errorMessage));
        }
    };
};

export const moveToArchive = (campaignIds) => {
    return async (dispatch) => {
        try {
            dispatch(getAdminCustomerDetailsStart());
            dispatch(updateIsMovedToarchiveStatus(false));
            const response = await moveToArchiveService(campaignIds);
            console.log(response);
            dispatch(moveToArchiveSuccess(campaignIds));
            dispatch(updateIsMovedToarchiveStatus(true));
            dispatch(adminCustomerDetailsFinish());
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(getAdminCustomerDetailsFail(errorMessage));
        }
    };
};

export const saveAccountSettings = (data) => {
    return async (dispatch) => {
        try {
            dispatch(getAdminCustomerDetailsStart());
            const response = await updateCompanyDetailsService(data.companyObject, data.companyId);
            console.log(response);
            if (data.isPriceOveride) {
                const discountresponse = await
                updateCompanyPackageDiscountsService(data.packageDiscount, data.companyId);
                console.log(discountresponse);
            }
            dispatch(saveAccountSettingsSuccess(data));
            dispatch(adminCustomerDetailsFinish());
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(getAdminCustomerDetailsFail(errorMessage));
        }
    };
};
