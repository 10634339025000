import { API } from '../../helpers/apiHelpers';

const getCampaignMessageListService = (campaignId) => {
    if (campaignId) {
        const url = `/campaigns/${campaignId}/messages`;
        return API.get(url)
            .then((response) => {
                console.log(response);
                return response;
            }).catch((error) => {
                return Promise.reject(error);
            });
    }
    return null;
};

// eslint-disable-next-line consistent-return
const markAsReadOrUnreadService = (putData, campaignId) => {
    if (campaignId) {
        const url = `/campaign/${campaignId}/messages`;
        return API.put(url, putData)
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
};

export { getCampaignMessageListService, markAsReadOrUnreadService };
