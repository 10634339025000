import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    deliverableList: [{
        fileId: null,
        fileName: null,
        filePath: null,
        version: null,
        fileTypeDetails: null
    }],
    loading: false,
    errorMessage: null,
    getDeliverableLoading: false
};

const adminDeliverableListStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        errorMessage: null
    });
};

const adminDeliverableListFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: action.errorMessage,
        getDeliverableLoading: false
    });
};

const getAdminDeliverableListSuccess = (state, action) => {
    return updateObject(state, {
        deliverableList: action.data.deliverableList,
        loading: false,
        errorMessage: null,
        getDeliverableLoading: false
    });
};

const adminDeliverableDeleteStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        errorMessage: null
    });
};

const adminDeliverableDeleteFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: action.errorMessage
    });
};

const deleteAdminDeliverableSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: null
    });
};

const deliverableReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.ADMIN_GET_DELIVERABLE_LIST_START:
        return updateObject(state, { getDeliverableLoading: true });
    case actionTypes.ADMIN_DELIVERABLE_LIST_START:
        return adminDeliverableListStart(state, action);
    case actionTypes.GET_ADMIN_DELIVERABLE_LIST_SUCCESS:
        return getAdminDeliverableListSuccess(state, action);
    case actionTypes.ADMIN_DELIVERABLE_LIST_FAIL:
        return adminDeliverableListFail(state, action);
    case actionTypes.ADMIN_DELIVERABLE_DELETE_START:
        return adminDeliverableDeleteStart(state, action);
    case actionTypes.ADMIN_DELIVERABLE_DELETE_SUCCESS:
        return deleteAdminDeliverableSuccess(state, action);
    case actionTypes.ADMIN_DELIVERABLE_DELETE_FAIL:
        return adminDeliverableDeleteFail(state, action);

    default:
        return state;
    }
};

export default deliverableReducer;
