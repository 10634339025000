import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const intialState = {
    startDate: null,
    endDate: null,
    companyName: null,
    companyLegalName: null,
    id: null,
    errorMessage: null,
    loading: null,
    redirect: false,
    redirectToPackage: false,
    discountLoading: false
};

const marketStart = (state, action) => {
    return updateObject(state, {
        errorMessage: null,
        loading: true
    });
};
const marketSuccess = (state, action) => {
    return updateObject(state, {
        companyName: action.data.companyName,
        companyLegalName: action.data.companyLegalName,
        id: action.data.id,
        errorMessage: null,
        loading: false,
        redirect: true
    });
};

const marketFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.errorMessage,
        loading: false
    });
};

const deleteMIMPackageSuccess = (state, action) => {
    return updateObject(state, {
        redirectToPackage: true
    });
};


const reducer = (state = intialState, action) => {
    switch (action.type) {
    case actionTypes.MARKET_START: return marketStart(state, action);
    case actionTypes.MARKET_SUCCESS: return marketSuccess(state, action);
    case actionTypes.MARKET_FAIL: return marketFail(state, action);
    case actionTypes.CLEAR_ALL_PACKAGE_RELATED_STORES: return intialState;
    case actionTypes.DELETE_MARKETING_MODEL: return deleteMIMPackageSuccess(state, action);
    case actionTypes.MARKETING_MODEL_DISCOUNT_START: return updateObject(state, {
        discountLoading: true
    });
    case actionTypes.MARKETING_MODEL_DISCOUNT_END: return updateObject(state, {
        discountLoading: false
    });
    default:
        return state;
    }
};

export default reducer;
