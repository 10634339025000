import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import closeIcon from '../../assets/images/icon-close.svg';

class ConfirmAlert extends Component {
    handleClose = () => {
        const { close } = this.props;
        close();
    };

    handleSave = () => {
        const { save } = this.props;
        save();
    };

    render() {
        const {
            show,
            headerText,
            confirmText,
            saveButtonText,
            cancelButtonText,
            wrapperClass
        } = this.props;
        return (
            <Modal
                show={show}
                onHide={this.handleClose}
                // aria-labelledby="contained-modal-title-vcenter"
                centered
                className={`confirmation-modal ${wrapperClass}`}
            >
                <Modal.Header>
                    <h3 className="confirmation-modal-title">{headerText}</h3>
                    <button className="modal-btn-close" onClick={this.handleClose} type="button">
                        <img src={closeIcon} alt="reset-password-close" />
                    </button>
                </Modal.Header>
                <div className="confirmation-modal">
                    <Modal.Body>
                        <div className="confirmation-text mb-2">
                            <p>{confirmText}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="secondary-btn secondary-btn-blue modal-footer-btn mr-3"
                            onClick={this.handleClose}
                        >
                            {cancelButtonText}
                        </button>
                        <button
                            type="button"
                            className="primary-btn primary-btn-blue continue-popup-btn"
                            onClick={this.handleSave}
                        >
                            {saveButtonText}
                        </button>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }
}

export default ConfirmAlert;
