import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ConfettiAnimation from '../../../../common/Confetti';
import {
    clearAllPackageRelatedStore
} from '../../../../../store/actions/review';

class ReviewSuccessPopUp extends React.Component {
    constructor() {
        super();
        this.state = {
            show: true,
            pdfLink: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.pdfLink) {
            return {
                pdfLink: props.pdfLink
            };
        }
        return null;
    }

    componentDidMount() {
        const { clearAllPackages, isNewCampaignEnabled } = this.props;
        clearAllPackages();
        if (!isNewCampaignEnabled) {
            localStorage.setItem('numberOfIntakeCompletedCampaigns', 1);
        }
    }

    setModalShow = () => {
        this.setState({ show: false });
    }

    downLoadPdf = () => {
        const { pdfLink } = this.state;
        if (pdfLink) {
            window.open(pdfLink, '_blank');
        }
        return false;
    }

    render() {
        const {
            show
        } = this.state;
        const { isNewCampaignEnabled } = this.props;
        return (
            <>
                <ConfettiAnimation />
                <Modal
                    show={show}
                    centered
                    backdrop="static"
                    backdropClassName="review-success-popup-bg"
                    className="review-success-popup"
                >
                    <div className="review-success-popup-wrapper">
                        <Modal.Body>
                            {!isNewCampaignEnabled && <h4 className="success-popup-heading">Welcome to Media Agent!</h4>}
                            <p className="success-popup-description">We’ve sent a copy of your submission to your email. Your Media Agent Customer Success representative will be in touch shortly.</p>
                            <div className="text-center">
                                <a className="primary-btn primary-btn-blue download-btn" href="/customer/campaign-details">Continue to my Campaign</a>
                            </div>
                            <div className="text-center">
                                <button
                                    className="btn btn-link continue-campaign-link"
                                    type="button"
                                    onClick={this.downLoadPdf}
                                >
                                    Download Document
                                </button>
                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        pdfLink: state.review.pdfLink,
        isNewCampaignEnabled: state.customerCampaignDetails.isNewCampaignEnabled
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearAllPackages: () => {
            return dispatch(clearAllPackageRelatedStore());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSuccessPopUp);
