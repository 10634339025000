/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { slide as Menu } from 'react-burger-menu';

import classnames from 'classnames';

import Spinner from './Loader';
import Debounce from '../Utils/Debounce';
import AccountDetails from '../customer/accountSettings/AccountDetails';

import { isAuthenticated } from '../../utils/utility_helpers';
import {
    logout as logoutService
} from '../../store/actions/auth';

import Logo from '../../assets/images/Empower-new-logo.svg';
import HamBurger from '../../assets/images/hamburger-icon.svg';
import CloseIcon from '../../assets/images/ma-close-icon.svg';

class HeaderMobile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            prevScrollpos: window.pageYOffset,
            showHeader: true,
            shadow: true,
            errorMessage: null,
            loading: false,
            campaignId: null,
            show: false,
            menuOpen: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (
            props.errorMessage !== state.errorMessage
            || props.loading !== state.loading
            || props.redirect !== state.redirect
        ) {
            return {
                campaignId: props.campaignId,
                errorMessage: props.errorMessage,
                loading: props.loading,
                redirect: props.redirect
            };
        }

        return {
            campaignId: props.campaignId
        };
    }

    componentDidMount() {
        this.handleButtonClick();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('click', this.handleButtonClick);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', Debounce(this.handleScroll, 20));
        window.addEventListener('click', Debounce(this.handleButtonClick, 0));
    }

    handleButtonClick = () => {
        let tempShowHeader = true;
        const packageId = localStorage.getItem('PackageId');
        const pathName = window.location.pathname;

        if (localStorage.getItem('PackageId')) {
            if (packageId !== null && pathName === '/packages') {
                tempShowHeader = false;
            }
        }

        this.setState({ showHeader: tempShowHeader });
    }

    handleScroll = () => {
        const { prevScrollpos } = this.state;
        const currentScrollPos = window.pageYOffset;
        const shadow = prevScrollpos < 93;

        this.setState({
            prevScrollpos: currentScrollPos,
            shadow
        });
    };

    handleLogout = (event) => {
        const { onLogout } = this.props;
        event.preventDefault();

        onLogout();
    };

    checkUserLogged = () => {
        return isAuthenticated();
    };

    profileSettings = () => {
        this.setState({ show: true });
    }

    profileSettingsClose = () => {
        this.setState({
            show: false
        });
    }

    handleStateChange = (state) => {
        this.setState({ menuOpen: state.isOpen });
    };

    closeMenu = () => {
        this.setState({ menuOpen: false });
    };

    render() {
        const {
            shadow,
            loading,
            showHeader,
            campaignId,
            show,
            menuOpen
        } = this.state;
        console.log(campaignId, 'mobile');

        const { isNavVisibile } = this.props;
        const loggedIn = this.checkUserLogged();
        const packageId = localStorage.getItem('PackageId');
        const pathName = window.location.pathname;
        let tempShowHeader = true;

        if (localStorage.getItem('PackageId')) {
            tempShowHeader = showHeader;
            if (packageId !== null && pathName === '/packages') {
                tempShowHeader = false;
            }
        }

        return (
            <header
                className={classnames('header-wrapper marketing-header-wrapper', {
                    'header-shadow': !shadow
                })}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-6 header-left">
                            <div className="media-agent-logo-wrapper">
                                <a href="/">
                                    <img src={Logo} className="img-fluid" alt="Media Agent Header Logo" />
                                </a>
                            </div>
                        </div>
                        <div className="col-6 header-right">
                            {isNavVisibile !== 'hide' && (
                                <ul className="navigation-list">
                                    {/* {!loggedIn && (
                                        <li className="navigation-list-item">
                                            <a className="primary-btn primary-btn-green get-started"
                                             href="/#contact-us">
                                                CONTACT US
                                            </a>
                                        </li>
                                    )} */}
                                    <Menu
                                        right
                                        width="100%"
                                        className="em-mobilemenu"
                                        noOverlay
                                        isOpen={menuOpen}
                                        onStateChange={(state) => {
                                            return this.handleStateChange(state);
                                        }}
                                        customCrossIcon={<img src={CloseIcon} alt="Close Icon" />}
                                        customBurgerIcon={<img src={HamBurger} alt="HamBurger Icon" />}
                                    >
                                        {tempShowHeader && (
                                            <>

                                                <li onClick={() => { return this.closeMenu(); }} className="navigation-list-item">
                                                    <NavLink to="/what-to-expect">
                                                        What to expect
                                                    </NavLink>
                                                </li>

                                                <li onClick={() => { return this.closeMenu(); }} className="navigation-list-item">
                                                    <NavLink to="/packages" onClick={this.getPackages}>
                                                        packages and pricing
                                                    </NavLink>
                                                </li>

                                                <li onClick={() => { return this.closeMenu(); }} className="navigation-list-item">
                                                    <NavLink to="/how-it-works">how it works</NavLink>
                                                </li>

                                                <li onClick={() => { return this.closeMenu(); }} className="navigation-list-item">
                                                    <a
                                                        href="https://www.empowermm.com/category/articles/"
                                                        aria-label="Read more about articles1"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Articles
                                                    </a>
                                                </li>
                                            </>
                                        )}

                                        {loggedIn ? (
                                            <li>
                                                <Dropdown className="navigation-list-item dropdown-item">
                                                    <Dropdown.Toggle
                                                        className="welcome-user-link dropdown-toggle-btn"
                                                        id="dropdown-basic-user"
                                                    >
                                                        Hi
                                                        {' ' + localStorage.getItem('FirstName')}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu
                                                        alignRight
                                                    >
                                                        <Dropdown.Item
                                                            to="/logout"
                                                            className="navlink-logout"
                                                            onClick={this.handleLogout}
                                                        >
                                                            Logout
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            onClick={this.profileSettings}
                                                        >
                                                            Profile Settings
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                        ) : (
                                            <li onClick={() => { return this.closeMenu(); }} className="navigation-list-item">
                                                <NavLink
                                                    to="/login"
                                                    className="navlink-login"
                                                >
                                                    log in
                                                </NavLink>
                                            </li>
                                        )}
                                    </Menu>
                                </ul>
                            )}

                        </div>


                    </div>
                </div>

                {loading && <Spinner loaderClass="loader-fullscreen" /> }

                {/* {redirect && <Redirect to="/login" />} */}

                <AccountDetails
                    show={show}
                    close={this.profileSettingsClose}
                />
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token !== null,
        loading: state.auth.logoutLoading,
        errorMessage: state.auth.errorMessage,
        isLoggedIn: state.auth.isLoggedIn,
        redirect: state.auth.redirect,
        campaignId: state.campaign.campaignId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => {
            return dispatch(logoutService());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile);
