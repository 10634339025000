import * as actionTypes from './actionTypes';
import { getCampaignSercvice, updateCampaignService } from '../services/campaign';
import { setErrorMessage } from '../../utils/utility_helpers';
import { updateNavigation } from './navigation';
import { navigationTabList } from '../../components/Constants';
import { updateBusinessProfileService } from '../services/businessProfile';

export const businessProfileStart = () => {
    return {
        type: actionTypes.BUSINESS_PROFILE_START
    };
};

const businessProfileSuccess = (data) => {
    return {
        type: actionTypes.BUSINESS_PROFILE_SUCCESS,
        data
    };
};

const businessProfileFail = (errorMessage) => {
    return {
        type: actionTypes.BUSINESS_PROFILE_FAIL,
        errorMessage
    };
};

const updateBusinessProfileSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_BUSINESS_PROFILE_SUCCESS,
        data
    };
};

export const updateBusinessProfileIsSaved = (data) => {
    return {
        type: actionTypes.BUSINESS_PROFILE_IS_SAVED,
        data
    };
};

export const getBusinessProfile = (campaignId) => {
    return async (dispatch) => {
        try {
            dispatch(businessProfileStart());
            const response = await getCampaignSercvice(campaignId);
            dispatch(businessProfileSuccess(response.data.data));
            console.log(response);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(businessProfileFail(errorMessage));
            console.log(errorMessage);
        }
    };
};

export const updateBusinessProfile = (businessProfileObject) => {
    return async (dispatch) => {
        try {
            dispatch(businessProfileStart());
            const campaignId = localStorage.getItem('CampaignId');
            const response = await updateBusinessProfileService(
                businessProfileObject.businessProfile
            );
            const data = {};
            data.businessProfileComment = businessProfileObject.comment;
            data.intakeStepId = 7;
            const campaignresponse = await updateCampaignService(campaignId,
                data);
            dispatch(updateBusinessProfileSuccess(businessProfileObject));
            dispatch(updateNavigation(navigationTabList.review, 7));
            console.log(response);
            console.log(campaignresponse);
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(businessProfileFail(errorMessage));
        }
    };
};

export const discardUnSavedBusinessProfile = (navigate, type, intakeStepId) => {
    return (dispatch) => {
        if (type === 'delete') {
            dispatch(updateBusinessProfileIsSaved(true));
        }

        dispatch(updateNavigation(navigate, intakeStepId));
    };
};
