import { API } from '../../helpers/apiHelpers';

const getMediaChannelsService = () => {
    const url = '/channels';
    return API.get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getCampaignMediaChannelsService = (campaignId) => {
    const url = '/campaigns/' + campaignId + '/channel';
    return API.get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const saveMediaChannelsService = (campaignId, formData) => {
    const url = '/campaigns/' + campaignId + '/channel';
    return API.post(url, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export { getMediaChannelsService, getCampaignMediaChannelsService, saveMediaChannelsService };
