/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-tabs */
import _ from 'lodash';
import {
    ChannelTypes,
    navigationTabList,
	TaskType,
	PromotionClasses
} from '../components/Constants';
import tv from '../assets/images/tv.svg';
import video from '../assets/images/online-video.svg';
import radio from '../assets/images/radio.svg';
import audio from '../assets/images/online-audio.svg';
import newspaper from '../assets/images/newspaper.svg';
import magazine from '../assets/images/magazine.svg';
import outOfHome from '../assets/images/out-of-home.svg';
import digitalDisplay from '../assets/images/digital-display.svg';
import paidSocial from '../assets/images/paid-social.svg';
import paidSearch from '../assets/images/paid-search.svg';

// icons for task-actions
// import amazonActive from '../assets/images/icon-amazon-active.svg';
// import amazonCompleted from '../assets/images/icon-amazon-completed.svg';
// import amazonDisabled from '../assets/images/icon-amazon-disabled.svg';
// import amazonWarning from '../assets/images/icon-amazon-warning.svg';

import calendarActive from '../assets/images/icon-calendar-active.svg';
import calendarCompleted from '../assets/images/icon-calendar-completed.svg';
import calendarDisabled from '../assets/images/icon-calendar-disabled.svg';
import calendarWarning from '../assets/images/icon-calendar-warning.svg';

// import pdfActive from '../assets/images/icon-pdf-active.svg';
// import pdfCompleted from '../assets/images/icon-pdf-completed.svg';
// import pdfDisabled from '../assets/images/icon-pdf-disabled.svg';
// import pdfWarning from '../assets/images/icon-pdf-warning.svg';

// import socialActive from '../assets/images/icon-social-active.svg';
// import socialCompleted from '../assets/images/icon-social-completed.svg';
// import socialDisabled from '../assets/images/icon-social-disabled.svg';
// import socialWarning from '../assets/images/icon-social-warning.svg';

import speakerActive from '../assets/images/icon-speaker-active.svg';
import speakerCompleted from '../assets/images/icon-speaker-completed.svg';
import speakerDisabled from '../assets/images/icon-speaker-disabled.svg';
import speakerWarning from '../assets/images/icon-speaker-warning.svg';

import uploadActive from '../assets/images/icon-upload-active.svg';
import uploadCompleted from '../assets/images/icon-upload-completed.svg';
import uploadDisabled from '../assets/images/icon-upload-disabled.svg';
import uploadWarning from '../assets/images/icon-upload-warning.svg';

import userActive from '../assets/images/icon-user-active.svg';
import userCompleted from '../assets/images/icon-user-completed.svg';
import userDisabled from '../assets/images/icon-user-disabled.svg';
import userWarning from '../assets/images/icon-user-warning.svg';

import writeActive from '../assets/images/icon-write-active.svg';
import writeCompleted from '../assets/images/icon-write-completed.svg';
import writeDisabled from '../assets/images/icon-write-disabled.svg';
import writeWarning from '../assets/images/icon-write-warning.svg';

// import taskInformation from '../assets/images/task-information.svg';

import InfoActive from '../assets/images/icon-info-active.svg';
import InfoCompleted from '../assets/images/icon-info-completed.svg';
import InfoDisabled from '../assets/images/icon-info-disabled.svg';
import InfoWarning from '../assets/images/icon-info-warning.svg';

// import xlsActive from '../assets/images/icon-xls-active.svg';
// import xlsCompleted from '../assets/images/icon-xls-completed.svg';
// import xlsDisabled from '../assets/images/icon-xls-disabled.svg';
// import xlsWarning from '../assets/images/icon-xls-warning.svg';

// import Warning from '../assets/images/icon-warning.svg';

const moment = require('moment');

const isArrayEmpty = (data = []) => {
    if (!data) return true;
    if (data && data.length === 0) return true;
    return false;
};

const isObjectEmpty = (data = {}) => {
    if (!data) return true;
    if (data && Object.keys(data).length === 0) return true;
    return false;
};

const convertPropertyType = (value, whichType) => {
    switch (whichType) {
    case 'number':
        return Number(value);
    case 'string':
        return value ? value.toString() : value;
    default:
        return value;
    }
};

const removeObjectFromArray = (array, removeItem, property, typeOfProperty) => {
    if (Array.isArray(removeItem) && !isArrayEmpty(removeItem)) {
        let updatedArray = [...array];
        removeItem.map((item) => {
            updatedArray = _.filter(updatedArray, (data) => {
                return (
                    convertPropertyType(data[property], typeOfProperty)
                    !== convertPropertyType(item[property], typeOfProperty)
                );
            });
            return item;
        });
        return updatedArray;
    }

    if (!isObjectEmpty(removeItem) && typeof removeItem === 'object') {
        let updatedArray = [...array];
        Object.keys(removeItem).map((key) => {
            updatedArray = _.filter(updatedArray, (data) => {
                return (
                    convertPropertyType(data[property], typeOfProperty)
                    !== convertPropertyType(key, typeOfProperty)
                );
            });
            return key;
        });
        return updatedArray;
    }
    return array;
};

const searchDataByKeyword = (data, property, search = '', removeItem) => {
    if (data) {
        let currentData = data;
        console.log(property, currentData, search, removeItem, 'dfdgdg');
        if (removeItem) {
            currentData = removeObjectFromArray(currentData, removeItem, 'label');
        }
        return _.filter(currentData, (_item) => {
            let isfound = false;
            if (
                _item
                && _item[property]
                && _.includes(_item[property].toString().toLowerCase(), search.toLowerCase())
            ) {
                isfound = true;
            }
            return isfound;
        });
    }
    return data;
};

/**
 *
 * @param {*} data array
 * @param {*} field object field name
 * @param {*} order asc or desc
 */
const sortArrayByField = (data, field, order) => {
    return _.orderBy(data, [field], [order]);
};

const groupDataByField = (data = [], field) => {
    if (isArrayEmpty(data)) return {};
    return _.groupBy(data, field);
};

/*
statedata is taking first elemts
*/
const isObjectDeepCheck = (stateData = [], propsData = []) => {
    let isObjectsSame = true;
    let data1 = {};
    let data2 = {};
    if (Array.isArray(stateData) && Array.isArray(propsData)) {
        data1 = stateData && stateData.length ? stateData[0] : {};
        data2 = propsData && propsData.length ? propsData[0] : {};
    } else if (typeof stateData === 'object' && typeof propsData === 'object') {
        data1 = stateData || {};
        data2 = propsData || {};
    }
    if (isObjectEmpty(data1) || isObjectEmpty(data2)) {
        // don't change this to false
        return true;
    }
    Object.keys(data1).map((_data1key) => {
        if (!data2.hasOwnProperty(_data1key)) {
            isObjectsSame = false;
        } else if (data1[_data1key] !== data2[_data1key]) {
            isObjectsSame = false;
        }
    });
    return isObjectsSame;
};

const findSomeOfArrays = (arr1, arr2) => {
    return arr1.some((r) => {
        return arr2.includes(Number(r));
    });
};

const cloneObject = (obj) => {
    const clone = {};
    for (const i in obj) {
        if (typeof obj[i] === 'object' && obj[i] != null) {
            clone[i] = cloneObject(obj[i]);
        } else clone[i] = obj[i];
    }
    return clone;
};

const isObjectsAreEqual = (object1, object2) => {
    return typeof object1 === 'object' && Object.keys(object1).length > 0
        ? Object.keys(object1).length === Object.keys(object2).length
            && Object.keys(object1).every((p) => {
                return isObjectsAreEqual(object1[p], object2[p]);
            })
        : object1 === object2;
};

const setErrorMessage = (error) => {
    let errorMessage = 'Something went wrong. Try again';
    if (error.response && error.response.status !== 500) {
        const { data } = error.response;
        errorMessage = data && data.message ? data.message : errorMessage;
    }
    return errorMessage;
};

export const formatDate = (date) => {
    const tempDate = moment(date);
    console.log(tempDate.format('MM/DD/YY'));
    return tempDate.format('MM/DD/YY');
};

export const formatSize = (bytes, decimal) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export {
    isArrayEmpty,
    searchDataByKeyword,
    sortArrayByField,
    isObjectEmpty,
    groupDataByField,
    removeObjectFromArray,
    isObjectDeepCheck,
    findSomeOfArrays,
    cloneObject,
    isObjectsAreEqual,
    setErrorMessage
};

export const getChannelLogo = (channel) => {
    switch (channel.channelName.toLocaleLowerCase()) {
    case ChannelTypes.TV.toLocaleLowerCase():
        return tv;
    case ChannelTypes.OnlineAudio.toLocaleLowerCase():
        return audio;
    case ChannelTypes.OnlineVideo.toLocaleLowerCase():
        return video;
    case ChannelTypes.Radio.toLocaleLowerCase():
        return radio;
    case ChannelTypes.NewsPaper.toLocaleLowerCase():
        return newspaper;
    case ChannelTypes.Magzine.toLocaleLowerCase():
        return magazine;
    case ChannelTypes.OutOfHome.toLocaleLowerCase():
        return outOfHome;
    case ChannelTypes.DigitalDisplay.toLocaleLowerCase():
        return digitalDisplay;
    case ChannelTypes.PaidSocial.toLocaleLowerCase():
        return paidSocial;
    case ChannelTypes.PaidSearch.toLocaleLowerCase():
        return paidSearch;
    default:
        return digitalDisplay;
    }
};

export const getTabNavigation = (key) => {
    let intakeStepId = 1;
    switch (key) {
    case navigationTabList.locations:
        intakeStepId = 1;
        break;
    case navigationTabList.campaignDates:
        intakeStepId = 2;
        break;
    case navigationTabList.objectives:
        intakeStepId = 3;
        break;
    case navigationTabList.mediaMix:
        intakeStepId = 4;
        break;
    case navigationTabList.audiences:
        intakeStepId = 5;
        break;
    case navigationTabList.businessProfile:
        intakeStepId = 6;
        break;
    case navigationTabList.review:
        intakeStepId = 7;
        break;
    default:
        intakeStepId = 1;
        break;
    }
    return intakeStepId;
};

export const getBackNavigationKey = (key) => {
    let backKey = navigationTabList.locations;
    switch (key) {
    case navigationTabList.locations:
        backKey = navigationTabList.locations;
        break;
    case navigationTabList.campaignDates:
        backKey = navigationTabList.locations;
        break;
    case navigationTabList.objectives:
        backKey = navigationTabList.campaignDates;
        break;
    case navigationTabList.mediaMix:
        backKey = navigationTabList.objectives;
        break;
    case navigationTabList.audiences:
        backKey = navigationTabList.mediaMix;
        break;
    case navigationTabList.businessProfile:
        backKey = navigationTabList.audiences;
        break;
    case navigationTabList.review:
        backKey = navigationTabList.businessProfile;
        break;
    default:
        backKey = navigationTabList.locations;
        break;
    }
    return backKey;
};

export const isAuthenticated = () => {
    if (localStorage.getItem('x-token')) {
        return true;
    }

    return false;
};

export const numberOfIntakeCompleted = () => {
    if (isAuthenticated() && localStorage.getItem('numberOfIntakeCompletedCampaigns') > 0) {
        return true;
    }

    return false;
};

export const dateFormatter = (date, format) => {
    switch (format) {
    // ISO Format
    case '': {
        // Date must be in in ISO or RFC2822 format when performing any operations
        const dateFormat = moment(date);
        return moment(dateFormat, 'MM-DD-YYYY');
    }
    // Below formats are used for display purpose only. Don't use these for any operations
    case 'MM-DD-YYYY': {
        return moment(date).format('MM-DD-YYYY');
    }
    case 'MMM D': {
        const data = moment(date, 'MM-DD-YYYY');
        return moment(data).format('MMM D');
    }
    case 'D': {
        const data = moment(date, 'MM-DD-YYYY');
        return moment(data).format('D');
    }
    case 'MMMM Do': {
        const data = moment(date, 'MM-DD-YYYY');
        return moment(data).format('MMMM Do');
    }
    case 'MMMM YYYY': {
        return moment(date).format('MMMM YYYY');
    }
    case 'MM/DD/YYYY': {
        return moment(date).format('MM/DD/YYYY');
    }
    case 'utcToLocal':
        return moment.utc(date).local();
    case 'utcToLocalFormat':
        return moment.utc(date).local().format('MM-DD-YYYY');
    case 'utcToLocalOtherFormat':
        return moment.utc(date).local().format('MM/DD/YYYY');
    case 'localToUtcFormat':
        return moment(date).utc();
    case 'utcToLocalTimeFormat':
        return moment.utc(date).local().format('MM-DD-YYYY h:mm A');
    case 'utcToLocalTimeOtherFormat':
        return moment.utc(date).local().format('MM/DD/YYYY h:mm A');
    case 'utcTo MMMM Do': {
        const data = moment.utc(date).local();
        return moment(data).format('MMMM Do');
    }
    case 'hh:mm A':
        return moment(date).format('hh:mm A');
    default:
        return moment(date, 'MM-DD-YYYY');
    }
};

export const checkIsBetweenDates = (currentDate, startDate, endDate) => {
    // Converting to ISO format
    const fromDate = moment(startDate, 'MM-DD-YYYY');
    const toDate = moment(endDate, 'MM-DD-YYYY');
    return moment(currentDate, 'MM-DD-YYYY').isBetween(fromDate, toDate, null, '[]');
};

export const getDifferenceBetweenDates = (startDate, endDate) => {
    // Converting to ISO format
    const toDate = moment(endDate, 'MM-DD-YYYY');
    const fromDate = moment(startDate, 'MM-DD-YYYY');
    return toDate.diff(fromDate, 'days');
};

export const getDifferenceBetweenDatesInWeeks = (startDate, endDate) => {
    // Converting to ISO format
    const toDate = moment(endDate, 'MM-DD-YYYY');
    const fromDate = moment(startDate, 'MM-DD-YYYY');
    return toDate.diff(fromDate, 'weeks');
};

export const addDaysToDate = (date, number) => {
    // Converting to ISO format
    const data = moment(date, 'MM-DD-YYYY');
    return moment(data).add(number, 'days');
};

export const subtractDaysToDate = (date, number) => {
    // Converting to ISO format
    const data = moment(date, 'MM-DD-YYYY');
    return moment(data).subtract(number, 'days');
};

export const addWeeksToDate = (date, number) => {
    // Converting to ISO format
    const data = moment(date, 'MM-DD-YYYY');
    return moment(data).add(number, 'weeks');
};

export const isSunday = (date) => {
    // Converting to ISO format
    const data = moment(date, 'MM-DD-YYYY');
    if (data.day() === 0) {
        return true;
    }
    return false;
};

export const isMonday = (date) => {
    // Converting to ISO format
    const data = moment(date, 'MM-DD-YYYY');
    if (data.day() === 1) {
        return true;
    }
    return false;
};

export const getDateInString = (date) => {
    // Converting to ISO format
    const convertedDate = moment(date, 'MM-DD-YYYY');
    return moment(convertedDate).toDate();
};

export const getTodayDate = () => {
    // Today date in ISO format
    const today = moment().format('MM-DD-YYYY');
    return moment(today, 'MM-DD-YYYY');
};

export const isSameOrAfter = (date) => {
    // Converting to ISO format
    const today = moment();
    return moment(date, 'MM-DD-YYYY').isSameOrAfter(today, 'day');
};


export const isLoggedInIsAdmin = () => {
    const roles = localStorage.getItem('Roles') ? JSON.parse(localStorage.getItem('Roles')) : [];
    return roles.some((x) => { return x.roleName === 'Admin'; });
};

export const isLoggedInIsCustomer = () => {
    const roles = localStorage.getItem('Roles') ? JSON.parse(localStorage.getItem('Roles')) : [];
    return roles.some((x) => { return x.roleName === 'Customer'; });
};

export const getTaskLogo = (task) => {
    let logo = userActive;
    if (task && task.taskType) {
        switch (task.taskType.toLowerCase()) {
        case TaskType.DatePicker.toLowerCase(): {
            if (!task.isCompleted && task.isActive && !task.isPastDue) {
                logo = calendarActive;
            } else if (task.isCompleted && !task.isActive) {
                logo = calendarCompleted;
            } else if (task.isPastDue) {
                logo = calendarWarning;
            } else {
                logo = calendarDisabled;
            }
            break;
        }
        case TaskType.DocumentSigning.toLowerCase(): {
            if (!task.isCompleted && task.isActive && !task.isPastDue) {
                logo = writeActive;
            } else if (task.isCompleted && !task.isActive) {
                logo = writeCompleted;
            } else if (task.isPastDue) {
                logo = writeWarning;
            } else {
                logo = writeDisabled;
            }
            break;
        }
        case TaskType.DocumentUploaded.toLowerCase():
        case TaskType.UploadDocument.toLowerCase():
        case TaskType.submitForReview.toLowerCase(): {
            if (!task.isCompleted && task.isActive && !task.isPastDue) {
                logo = uploadActive;
            } else if (task.isCompleted && !task.isActive) {
                logo = uploadCompleted;
            } else if (task.isPastDue) {
                logo = uploadWarning;
            } else {
                logo = uploadDisabled;
            }
            break;
        }
        case TaskType.MarkAsComplete.toLowerCase():
        case TaskType.YesOrNo.toLowerCase(): {
            if (!task.isCompleted && task.isActive && !task.isPastDue) {
                logo = userActive;
            } else if (task.isCompleted && !task.isActive) {
                logo = userCompleted;
            } else if (task.isPastDue) {
                logo = userWarning;
            } else {
                logo = userDisabled;
            }
            break;
        }
        case TaskType.AutoCompleted.toLowerCase(): {
            if (!task.isCompleted && task.isActive && !task.isPastDue) {
                logo = InfoActive;
            } else if (task.isCompleted && !task.isActive) {
                logo = InfoCompleted;
            } else if (task.isPastDue) {
                logo = InfoWarning;
            } else {
                logo = InfoDisabled;
            }
            break;
        }
        case TaskType.ApproveRequestReject.toLowerCase():
        case TaskType.TrafficOrRequestRevisions.toLowerCase(): {
            const isDisabled = task.taskStatus && task.taskStatus === 'Disabled';
            if (!task.isCompleted && task.isActive && !task.isPastDue && !isDisabled) {
                logo = speakerActive;
            } else if (task.isCompleted && !task.isActive) {
                logo = speakerCompleted;
            } else if (task.isPastDue) {
                logo = speakerWarning;
            } else {
                logo = speakerDisabled;
            }
            break;
        }
        default:
            if (!task.isCompleted && task.isActive && !task.isPastDue) {
                logo = userActive;
            } else if (task.isCompleted && !task.isActive) {
                logo = userCompleted;
            } else if (task.isPastDue) {
                logo = userWarning;
            } else {
                logo = userDisabled;
            }
        }
    } else if (task && !task.isCompleted && task.isActive && !task.isPastDue) {
        logo = userActive;
    } else if (task && task.isCompleted && !task.isActive) {
        logo = userCompleted;
    } else if (task && task.isPastDue) {
        logo = userWarning;
    } else {
        logo = userDisabled;
    }
    return logo;
};

export const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            return resolve({
                fileName: file.name,
                base64: reader.result
            });
        };
        reader.onerror = (error) => {
            console.log(error);
            return reject(error);
        };
        console.log(reader);
    });
};

export const compareTwoObjects = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return true;
    for (let i = 0, len = arr1.length; i < len; i++) {
        if (arr1[i].value !== arr2[i].value) {
            return true;
        }
    }
    return false;
};

export const humanFileSize = (size) => {
	if (size < 1024) {
		return size + ' B';
	}
	const i = Math.floor(Math.log(size) / Math.log(1024));
	let num = (size / Math.pow(1024, i));
	const round = Math.round(num);
	num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
	return `${num} ${'KMGTPEZY'[i - 1]}B`;
};

export const uniqueArray = (arr) => {
	const a = [];
	for (let i = 0, l = arr.length; i < l; i++) {
		if (a.indexOf(arr[i]) === -1 && arr[i] !== '') {
			a.push(arr[i]);
		}
	}
	return a;
};

export const getPromotionColorClass = (promotionIndex) => {
	switch (promotionIndex) {
		case PromotionClasses.first:
			return 'first';
		case PromotionClasses.second:
			return 'second';
		case PromotionClasses.third:
			return 'third';
		case PromotionClasses.four:
			return 'four';
		case PromotionClasses.five:
			return 'five';
		case PromotionClasses.six:
			return 'six';
		case PromotionClasses.seven:
			return 'seven';
		case PromotionClasses.eigth:
			return 'eigth';
		case PromotionClasses.nine:
			return 'nine';
		case PromotionClasses.zero:
			return 'zero';
		default:
			return '';
	}
};

const buildChannelData = (loc, promotionId, locationTask) => {
    const { campaignChannels } = loc;
    const { selectedChannels } = locationTask;
    const tempChannelOptions = [];
    const tempChannelDetails = [];
    const tempSelectedChannels = [];
    let tempSelectedChannel = [];
    const uniqueIds = [];
    if (loc) {
        if (locationTask) {
            selectedChannels.map((item) => {
                tempChannelOptions.push({
                    value: item.locationGroupChannelId,
                    label: item.channelName
                });
            });
        }
        campaignChannels.map((channel) => {
            const tempChannels = [];
            if (channel.taskList) {
                channel.taskList.map((task) => {
                    console.log('task', channel.channelName, promotionId, task);
                    if (task.campaignPromotionId === promotionId && task.tasks.length > 0) {
                        tempChannels.push({
                            channelName: channel.channelName,
                            startDate: dateFormatter(task.promotionStartDate, 'MM-DD-YYYY'),
                            endDate: dateFormatter(task.promotionEndDate, 'MM-DD-YYYY'),
                            locationGroupChannelId: task.locationGroupChannelId,
                            channelPromotionId: task.channelPromotionId,
                            isChecked: false,
                            campaignLocationGroupId: loc.campaignLocationGroupId,
                            startDateListError: null,
                            endDateListError: null
                        });
                        tempSelectedChannels.push({
                            value: channel.locationGroupChannelId,
                            label: channel.channelName
                        });
                    }
                    if (task.campaignPromotionId === promotionId && channel.channelId === 10) {
                        tempChannels.push({
                            channelName: channel.channelName,
                            startDate: dateFormatter(task.promotionStartDate, 'MM-DD-YYYY'),
                            endDate: dateFormatter(task.promotionEndDate, 'MM-DD-YYYY'),
                            locationGroupChannelId: task.locationGroupChannelId,
                            channelPromotionId: task.channelPromotionId,
                            isChecked: false,
                            campaignLocationGroupId: loc.campaignLocationGroupId,
                            startDateListError: null,
                            endDateListError: null
                        });
                        tempSelectedChannels.push({
                            value: channel.locationGroupChannelId,
                            label: channel.channelName
                        });
                    }
                    return task;
                });
            }
            if (tempChannels.length > 0) {
                tempChannelDetails.push(tempChannels);
            }
            return channel;
        });
        tempSelectedChannel = tempSelectedChannels.filter((element) => {
            const isDuplicate = uniqueIds.includes(element.value);
            if (!isDuplicate) {
              uniqueIds.push(element.value);
              return true;
            }
            return false;
        });
    }
    return { tempChannelOptions, tempSelectedChannel, tempChannelDetails };
};

const buildLocationGroup = (loc) => {
    const { campaignLocationGroupId, campaignLocationGroupName } = loc;
    const tempSelectedLocationGroup = {};
    tempSelectedLocationGroup.label = campaignLocationGroupName;
    tempSelectedLocationGroup.value = campaignLocationGroupId;
    tempSelectedLocationGroup.isChecked = false;
    tempSelectedLocationGroup.checkOption = 'none';
    return tempSelectedLocationGroup;
};

export const getEditPromotionData = (campaignLocationGroups = [], promotionLocations = [],
    promotionId, locationGroups) => {
    let location = {};
    let locationTask = {};
    const tempPromotionsLocationsDetails = [];
        const promotionsLocations = {};
        const promotionsLocationsTask = {};
        campaignLocationGroups.forEach((loc) => {
            promotionsLocations[loc.campaignLocationGroupId] = loc;
        });
        locationGroups.forEach((loc) => {
            promotionsLocationsTask[loc.campaignLocationGroupId] = loc;
        });
        const locationOptionsTemp = [];
        if (locationGroups.length) {
            locationGroups.map((loc) => {
                const tempLoc = {
                    label: loc.locationGroupName,
                    value: loc.campaignLocationGroupId
                };
                locationOptionsTemp.push(tempLoc);
                return loc;
            });
		}
        campaignLocationGroups.forEach((loc) => {
            location = promotionsLocations[loc.campaignLocationGroupId];
            locationTask = promotionsLocationsTask[loc.campaignLocationGroupId];
            if (location && locationTask) {
                const channels = buildChannelData(location, promotionId, locationTask);
                if (channels.tempSelectedChannel.length === 0) {
                    return;
                }
                const tempPromotionsLocations = {};
                tempPromotionsLocations.locationOptions = [...locationOptionsTemp];
                tempPromotionsLocations.selectedLocationGroup = buildLocationGroup(location);
                tempPromotionsLocations.selectedChannels = channels.tempSelectedChannel;
                tempPromotionsLocations.channelDetails = channels.tempChannelDetails;
                tempPromotionsLocations.channelOptions = channels.tempChannelOptions;
                tempPromotionsLocationsDetails.push(tempPromotionsLocations);
            }
        });
        return tempPromotionsLocationsDetails;
};

export const getAddPromotionData = (tableLocationDetails) => {
    const promotionsLocationsDetails = [
        {
            locationOptions: [],
            selectedLocationGroup: {},
            channelOptions: [],
            selectedChannels: [],
            channelDetails: []
        }
    ];
    const locationOptionsTemp = [];
    const tempPromotionsLocationsDetails = [...promotionsLocationsDetails];
    if (tableLocationDetails.length) {
        tableLocationDetails.map((loc) => {
            const tempLoc = {
                label: loc.locationGroupName,
                value: loc.campaignLocationGroupId
            };
            locationOptionsTemp.push(tempLoc);
            return loc;
        });
    }
    tempPromotionsLocationsDetails[0].locationOptions = [...locationOptionsTemp];
    tempPromotionsLocationsDetails[0].selectedLocationGroup = {};
    tempPromotionsLocationsDetails[0].channelOptions = [];
    tempPromotionsLocationsDetails[0].selectedCahnnels = [];
    tempPromotionsLocationsDetails[0].channelDetails = [];
    return tempPromotionsLocationsDetails;
};

export const base64Regex = new RegExp('^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$');
