/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import Security from './Security';
import ChangePassword from './ChangePassword';
import ChangeUsername from './ChangeUsername';
import { updateCustomerCredentials, updateCustomerCredentialsReset } from '../../../store/actions/customerProfile';
import { validatePerson } from '../../../store/services/invitation';
import { AlertMessages, RegEx } from '../../Constants';
import Spinner from '../../common/Loader';

class SecurityIndex extends React.Component {
    constructor() {
        super();
        this.state = {
            isPasswordEditable: false,
            isUserNameEditable: false,
            currentUsername: '',
            newUsername: '',
            confirmUsername: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',

            currentUsernameError: '',
            newUsernameError: '',
            confirmUsernameError: '',
            currentPasswordError: '',
            newPasswordError: '',
            confirmPasswordError: '',
            selectedOption: '',
            isUserNameExits: false
        };

        this.validateUserNameCall = this.validateUserNameCall.bind(this);
    }

    changeEditable = (option) => {
        const { customerCredentialsReset } = this.props;
        if (option === 'password') {
            this.setState({ isPasswordEditable: true, selectedOption: 'password' }, () => {
                customerCredentialsReset();
            });
        } else {
            this.setState({ isUserNameEditable: true, selectedOption: 'username' }, () => {
                customerCredentialsReset();
            });
        }
    }

    onCancelClick = () => {
        const { customerCredentialsReset } = this.props;
        this.setState({ isPasswordEditable: false, isUserNameEditable: false }, () => {
            customerCredentialsReset();
        });
    }

    handleChange = (event, type, option) => {
        const {
            currentUsername,
            newUsername,
            confirmUsername,
            currentPassword,
            newPassword,
            confirmPassword,
            currentUsernameError,
            newUsernameError,
            confirmUsernameError,
            currentPasswordError,
            newPasswordError,
            confirmPasswordError

        } = this.state;
        console.log(
            currentUsername,
            newUsername,
            confirmUsername,
            currentPassword,
            newPassword,
            confirmPassword,

            currentUsernameError,
            newUsernameError,
            confirmUsernameError,
            currentPasswordError,
            newPasswordError,
            confirmPasswordError
        );

        const value = event.target.value.trim();
        console.log(value);

        switch (type) {
        case 'currentUsername':
            this.setState({ currentUsername: value, currentUsernameError: value ? '' : AlertMessages.Credential.CurrentUsername });
            break;
        case 'newUsername':
            if (!value) {
                this.setState({ newUsername: value, newUsernameError: value ? '' : AlertMessages.Credential.NewUsername });
            } else if (value.length > 0
                && value.length < 5) {
                this.setState({
                    newUsername: value,
                    newUsernameError: AlertMessages.Invitations.MinUserName
                });
            } else if (value.length > 0
                && value.length > 25) {
                this.setState({
                    newUsername: value,
                    newUsernameError: AlertMessages.Invitations.MaxUserName
                });
            } else {
                this.setState({
                    newUsername: value,
                    newUsernameError: value ? '' : AlertMessages.Credential.NewUsername
                });
            }

            if (confirmUsername && value) {
                if (confirmUsername !== value) {
                    this.setState({
                        confirmUsernameError: AlertMessages.Credential.DoesNotMatchUsername
                    });
                } else {
                    this.setState({ confirmUsernameError: '' });
                }
            }
            break;
        case 'confirmUsername':
            this.setState({ confirmUsername: value, confirmUsernameError: value ? '' : AlertMessages.Credential.ConfirmUsername });
            if (newUsername && value) {
                if (newUsername !== value) {
                    this.setState({
                        confirmUsernameError: AlertMessages.Credential.DoesNotMatchUsername
                    });
                }
            }
            break;

        case 'currentPassword':
            this.setState({ currentPassword: value, currentPasswordError: value ? '' : AlertMessages.Credential.CurrentPassword });
            break;
        case 'newPassword':
            this.setState({
                newPassword: value,
                newPasswordError: value
                    ? (!RegEx.passwordRegex.test(value) ? AlertMessages.Credential.ValidPassword
                        : '') : AlertMessages.Credential.NewPassword
            });
            if (confirmPassword && value) {
                if (confirmPassword !== value) {
                    this.setState({
                        confirmPasswordError: AlertMessages.Credential.DoesNotMatchPassword
                    });
                } else {
                    this.setState({ confirmPasswordError: '' });
                }
            }
            break;
        case 'confirmPassword':
            this.setState({ confirmPassword: value, confirmPasswordError: value ? '' : AlertMessages.Credential.ConfirmPassword });
            if (newPassword && value) {
                if (newPassword !== value) {
                    this.setState({
                        confirmPasswordError: AlertMessages.Credential.DoesNotMatchPassword
                    });
                } else {
                    this.setState({ confirmPasswordError: '' });
                }
            }
            break;

        default:
            break;
        }

        this.setState({ selectedOption: option });
    }

    onCancelClick = () => {
        const { customerCredentialsReset } = this.props;
        this.setState({
            isPasswordEditable: false,
            isUserNameEditable: false,
            selectedOption: '',
            currentUsername: '',
            newUsername: '',
            confirmUsername: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            currentUsernameError: '',
            newUsernameError: '',
            confirmUsernameError: '',
            currentPasswordError: '',
            newPasswordError: '',
            confirmPasswordError: '',
            isUserNameExits: false
        }, () => {
            customerCredentialsReset();
        });
    }

    validateUserName = () => {
        const {
            currentUsername,
            newUsername,
            confirmUsername,
            currentUsernameError,
            newUsernameError,
            confirmUsernameError
        } = this.state;
        console.log(
            currentUsername,
            newUsername,
            confirmUsername,
            currentUsernameError,
            newUsernameError,
            confirmUsernameError
        );

        let isValid = true;
        if (currentUsername === '') {
            isValid = false;
            this.setState({ currentUsernameError: AlertMessages.Credential.CurrentUsername });
        }
        if (newUsername === '') {
            isValid = false;
            this.setState({ newUsernameError: AlertMessages.Credential.NewUsername });
        }
        if (newUsername.length > 0
            && newUsername.length < 5) {
            isValid = false;
            this.setState({ newUsernameError: AlertMessages.Invitations.MinUserName });
        }
        if (newUsername.length > 0
            && newUsername.length > 25) {
            isValid = false;
            this.setState({ newUsernameError: AlertMessages.Invitations.MaxUserName });
        }
        if (confirmUsername === '') {
            isValid = false;
            this.setState({ confirmUsernameError: AlertMessages.Credential.ConfirmUsername });
        }
        if (confirmUsername && newUsername) {
            if (confirmUsername !== newUsername) {
                isValid = false;
                this.setState({
                    confirmUsernameError: AlertMessages.Credential.DoesNotMatchUsername
                });
            }
        }
        return isValid;
    }

    validatePassword = () => {
        const {
            currentPassword,
            newPassword,
            confirmPassword,
            currentPasswordError,
            newPasswordError,
            confirmPasswordError

        } = this.state;
        console.log(
            currentPassword,
            newPassword,
            confirmPassword,
            currentPasswordError,
            newPasswordError,
            confirmPasswordError
        );
        let isValid = true;
        if (currentPassword === '') {
            isValid = false;
            this.setState({ currentPasswordError: AlertMessages.Credential.CurrentPassword });
        }
        if (newPassword === '') {
            isValid = false;
            this.setState({ newPasswordError: AlertMessages.Credential.NewPassword });
        }
        if (newPassword) {
            if (!RegEx.passwordRegex.test(newPassword)) {
                isValid = false;
                this.setState({
                    newPasswordError: AlertMessages.Credential.ValidPassword
                });
            } else {
                this.setState({
                    newPasswordError: ''
                });
            }
        }
        if (confirmPassword === '') {
            isValid = false;
            this.setState({ confirmPasswordError: AlertMessages.Credential.ConfirmPassword });
        }
        if (newPassword && confirmPassword) {
            if (newPassword !== confirmPassword) {
                isValid = false;
                this.setState({
                    confirmPasswordError: AlertMessages.Credential.DoesNotMatchPassword
                });
            }
        }
        return isValid;
    }

    saveCredentials = () => {
        const { updateCredentials, customerProfile } = this.props;
        const {
            currentUsername,
            newUsername,
            confirmUsername,
            currentPassword,
            newPassword,
            confirmPassword,
            selectedOption,
            isUserNameExits
        } = this.state;

        const usernameData = {
            currentUserName: currentUsername,
            userName: newUsername,
            confirmUserName: confirmUsername
        };

        const passwordData = {
            currentPassword,
            password: newPassword,
            confirmPassword
        };
        let isValid = false;
        console.log(isUserNameExits);
        if (selectedOption === 'username') {
            isValid = this.validateUserName();
            if (isValid && !isUserNameExits) {
                updateCredentials(
                    usernameData,
                    customerProfile.personDetail.personId,
                    selectedOption
                );
            }
        }

        if (selectedOption === 'password') {
            isValid = this.validatePassword();
            if (isValid) {
                updateCredentials(
                    passwordData,
                    customerProfile.personDetail.personId,
                    selectedOption
                );
            }
        }

        const { credentialsLoading, credentialsErrorMessage } = this.props;
        console.log(this.props);
        if (credentialsLoading === false && isValid && !isUserNameExits) {
            if (credentialsErrorMessage === null) {
                this.setState({
                    isPasswordEditable: false,
                    isUserNameEditable: false,
                    selectedOption: '',
                    currentUsername: '',
                    newUsername: '',
                    confirmUsername: '',
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                    currentUsernameError: '',
                    newUsernameError: '',
                    confirmUsernameError: '',
                    currentPasswordError: '',
                    newPasswordError: '',
                    confirmPasswordError: ''
                });
            }
        }
    }

    async validateUserNameCall() {
        const {
            currentUsername,
            newUsername,
            confirmUsername,
            currentUsernameError,
            newUsernameError,
            confirmUsernameError
        } = this.state;
        const { unChangedCompanyDetails } = this.props;
        console.log(
            currentUsername,
            newUsername,
            confirmUsername,
            currentUsernameError,
            newUsernameError,
            confirmUsernameError
        );
        let userName = '';
        if (unChangedCompanyDetails) {
            userName = unChangedCompanyDetails.unChangedPersonDetail.userName;
        }

        if (userName !== newUsername) {
            if (newUsername.length > 0
            && newUsername.length < 5) {
                this.setState({ newUsernameError: AlertMessages.Invitations.MinUserName });
            } else if (newUsername.length > 0
                    && newUsername.length > 25) {
                this.setState({ newUsernameError: AlertMessages.Invitations.MaxUserName });
            } else {
                try {
                    const response = await validatePerson({
                        email: false,
                        userName: true,
                        searchText: newUsername
                    });
                    if (response.data.resultCount > 0) {
                        this.setState({
                            newUsernameError: AlertMessages.Invitations.ExistingErrorMessage + 'User Name',
                            isUserNameExits: true
                        });
                        console.log(response);
                    } else {
                        this.setState({ isUserNameExits: false });
                    }
                } catch (error) {
                    console.log(console.log(error));
                }
            }
        }
    }

    render() {
        const {
            isEditable,
            isPasswordEditable,
            isUserNameEditable,
            currentUsername,
            newUsername,
            confirmUsername,
            currentPassword,
            newPassword,
            confirmPassword,
            currentUsernameError,
            newUsernameError,
            confirmUsernameError,
            currentPasswordError,
            newPasswordError,
            confirmPasswordError,
            selectedOption

        } = this.state;
        const { customerProfile, credentialsLoading, credentialsErrorMessage } = this.props;
        console.log(selectedOption);
        console.log(this.state);
        return (
            <>
                { !isPasswordEditable && !isUserNameEditable
                    ? (
                        <Security
                            editable={this.changeEditable}
                            isEditable={isEditable}
                            customerProfile={customerProfile}
                        />
                    )
                    : null}

                { isPasswordEditable
                    ? (
                        <ChangePassword
                            handleChange={this.handleChange}
                            currentPassword={currentPassword}
                            newPassword={newPassword}
                            confirmPassword={confirmPassword}
                            currentPasswordError={currentPasswordError}
                            newPasswordError={newPasswordError}
                            confirmPasswordError={confirmPasswordError}
                        />
                    )
                    : null}

                { isUserNameEditable
                    ? (
                        <ChangeUsername
                            customerProfile={customerProfile}
                            handleChange={this.handleChange}
                            currentUsername={currentUsername}
                            newUsername={newUsername}
                            confirmUsername={confirmUsername}
                            currentUsernameError={currentUsernameError}
                            newUsernameError={newUsernameError}
                            confirmUsernameError={confirmUsernameError}
                            validateUserNameCall={this.validateUserNameCall}
                        />
                    )
                    : null}
                {credentialsErrorMessage && <p className="alert alert-danger">{credentialsErrorMessage}</p>}
                { isPasswordEditable || isUserNameEditable
                    ? (
                        <div className="cd-cancel-save-btn-wrapper">
                            <button
                                className="secondary-btn secondary-btn-blue"
                                type="submit"
                                onClick={() => {
                                    this.onCancelClick();
                                }}
                            >
                                CANCEL
                            </button>
                            <button
                                className="primary-btn primary-btn-blue"
                                type="submit"
                                onClick={this.saveCredentials}
                            >
                                SAVE
                            </button>
                        </div>
                    )
                    : null}
                {credentialsLoading ? <Spinner loaderClass="loader-fullscreen" /> : null}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        credentialsLoading: state.customerProfile.credentialsLoading,
        credentialsErrorMessage: state.customerProfile.credentialsErrorMessage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCredentials: (credentialsObject, personId, option) => {
            return dispatch(updateCustomerCredentials(credentialsObject, personId, option));
        },
        customerCredentialsReset: () => {
            return dispatch(updateCustomerCredentialsReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityIndex);
