import { CUSTOMER_TASK_LIST_START, CUSTOMER_TASK_LIST_FAIL, GET_CUSTOMER_TASK_LIST_SUCCESS } from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    customerTaskList: [],
    customerList: [],
    loading: false,
    errorMessage: null
};

const customerTaskListStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        errorMessage: null
    });
};

const customerTaskListFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: action.errorMessage
    });
};

const getCustomerTaskListSuccess = (state, action) => {
    return updateObject(state, {
        customerTaskList: action.data.tasks,
        customerList: action.data.customers,
        pastDueList: action.data.pastDueList,
        completedList: action.data.completedList,
        upcomingList: action.data.upcomingList,
        loading: false,
        errorMessage: null
    });
};

const customerTaskReducer = (state = initialState, action) => {
    switch (action.type) {
    case CUSTOMER_TASK_LIST_START:
        return customerTaskListStart(state, action);
    case GET_CUSTOMER_TASK_LIST_SUCCESS:
        return getCustomerTaskListSuccess(state, action);
    case CUSTOMER_TASK_LIST_FAIL:
        return customerTaskListFail(state, action);
    default:
        return state;
    }
};

export default customerTaskReducer;
