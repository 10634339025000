/* eslint-disable no-param-reassign */
/* eslint-disable indent */
/* eslint-disable no-tabs */
import {
    createStore,
    applyMiddleware,
    compose,
    combineReducers
} from 'redux';

import thunk from 'redux-thunk';
import logger from 'redux-logger';

import authReducer from './reducers/auth';
import campaignDatesReducer from './reducers/campaignDates';
import packageReducer from './reducers/packages';
import objectiveAndApproachReducer from './reducers/objectiveAndApproach';
import campaignReducer from './reducers/campaign';
import navigationReducer from './reducers/navigation';
import locationReducer from './reducers/location';
import companyReducer from './reducers/company';
import marketingModalReducer from './reducers/marketingModal';
import mediaChannelsReducer from './reducers/mediaChannels';
import audienceReduces from './reducers/audience';
import reviewReducer from './reducers/review';
import personReducer from './reducers/person';
import businessProfileReducer from './reducers/businessProfile';
import adminCustomerListReducer from './reducers/adminCustomerList';
import adminCampaignDetail from './reducers/adminCampaignDetails';
import adminCustomerDetailsReducer from './reducers/adminCustomerDetails';
import adminFlightingTableReducer from './reducers/adminFlightingTable';
import deliverableReducer from './reducers/adminDeliverables';
import promotionReducer from './reducers/adminCreativeUploads';
import customerCampaignDetailsReducer from './reducers/customerCampaignDetails';
import customerFlightingTableReducer from './reducers/customerFlightingTable';
import customerTaskReducer from './reducers/customerTaskList';
import requestFlightingChangeReducer from './reducers/requestFlightingChange';
import customerProfileDetails from './reducers/customerProfile';
import creativeLibraryReducer from './reducers/creativeLibrary';

const { ENV } = process.env;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
    /* your app’s top-level reducers */
    auth: authReducer,
    campaignDates: campaignDatesReducer,
    package: packageReducer,
    objectiveAndApproach: objectiveAndApproachReducer,
    campaign: campaignReducer,
    navigation: navigationReducer,
    location: locationReducer,
    company: companyReducer,
    mediaChannels: mediaChannelsReducer,
    audience: audienceReduces,
    review: reviewReducer,
    marketing: marketingModalReducer,
    person: personReducer,
    businessProfile: businessProfileReducer,
    adminCustomerList: adminCustomerListReducer,
    adminCampaignDetails: adminCampaignDetail,
    adminCustomerdetails: adminCustomerDetailsReducer,
    adminFlightingTable: adminFlightingTableReducer,
    deliverables: deliverableReducer,
    promotions: promotionReducer,
    customerCampaignDetails: customerCampaignDetailsReducer,
    customerFlightingTable: customerFlightingTableReducer,
    customerTaskList: customerTaskReducer,
    requestFlightingChange: requestFlightingChangeReducer,
	customerProfile: customerProfileDetails,
	creativeLibrary: creativeLibraryReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'AUTH_LOGOUT') {
        state = undefined;
    }
    return appReducer(state, action);
};

const middleWares = [thunk];

if (ENV.NODE_ENV !== 'production') {
    middleWares.push(logger);
}

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleWares))
);

export default store;
