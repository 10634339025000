import { updateObject } from '../../utils/reducer_utility';

import {
    UPDATE_NAVIGATION,
    CLEAR_ALL_PACKAGE_RELATED_STORES
} from '../actions/actionTypes';


const initialState = {
    loading: false,
    errorMessage: '',
    selectedKey: 'locations',
    intakeStepId: 1
};

const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_NAVIGATION: {
        let { intakeStepId } = state;
        if (intakeStepId < action.data.stepId) {
            intakeStepId = action.data.stepId;
        }
        return updateObject(state, {
            selectedKey: action.data.selectedKey,
            intakeStepId
        });
    }

    case CLEAR_ALL_PACKAGE_RELATED_STORES:
        return initialState;

    default:
        return state;
    }
};

export default navigationReducer;
