/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

class ChangePassword extends React.Component {
    render() {
        const {
            currentPassword,
            newPassword,
            confirmPassword,
            currentPasswordError,
            newPasswordError,
            confirmPasswordError,
            handleChange
        } = this.props;

        return (
            <div className="change-password">
                <h2 className="cd-sub-heading">
                    Change Password
                </h2>
                <div className="form-group">
                    <label htmlFor="currentPassword">Enter Current Password</label>
                    <input
                        className={
                            currentPasswordError === ''
                                ? 'form-control'
                                : 'form-control is-invalid'
                        }
                        id="currentPassword"
                        type="password"
                        value={currentPassword}
                        onChange={(event) => {
                            handleChange(event, 'currentPassword', 'password');
                        }}
                        placeholder="Enter Current Password"
                    />
                    <span className="invalid-feedback">
                        {currentPasswordError}
                    </span>
                </div>
                <div className="form-group">
                    <label htmlFor="newPassword">Enter New Password</label>
                    <input
                        className={
                            newPasswordError === ''
                                ? 'form-control'
                                : 'form-control is-invalid'
                        }
                        id="newPassword"
                        type="password"
                        value={newPassword}
                        onChange={(event) => {
                            handleChange(event, 'newPassword', 'password');
                        }}
                        placeholder="Enter New Password"
                    />
                    <span className="invalid-feedback">
                        {newPasswordError}
                    </span>
                </div>
                <div className="form-group">
                    <label htmlFor="reenterNewPassword">Re-Enter New Password</label>
                    <input
                        className={
                            confirmPasswordError === ''
                                ? 'form-control'
                                : 'form-control is-invalid'
                        }
                        id="reenterNewPassword"
                        type="password"
                        value={confirmPassword}
                        onChange={(event) => {
                            handleChange(event, 'confirmPassword', 'password');
                        }}
                        placeholder="Re-Enter New Password"
                    />
                    <span className="invalid-feedback">
                        {confirmPasswordError}
                    </span>
                </div>
            </div>
        );
    }
}

export default ChangePassword;
