import { API } from '../../helpers/apiHelpers';

const updateBusinessProfileService = (businessProfileObject) => {
    const { companyId } = businessProfileObject;
    const url = `company/${companyId}/businessprofile`;
    return API.post(url, businessProfileObject)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export { updateBusinessProfileService };
