/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { Component } from 'react';

// import { HashLink } from 'react-router-hash-link';
import SignInLeftSidePanel from '../../common/SignInLeftSidePanel';
import ResetPasswordModal from './ResetPasswordModal';
import Spinner from '../../common/Loader';

import {
    RegEx,
    AlertMessages,
    ForgotPasswordUserNameModalText
} from '../../Constants';
import {
    forgotUsernameService,
    forgotPasswordService
} from '../../../store/services/forgotUsernamePassword';
import { setErrorMessage } from '../../../utils/utility_helpers';

class ForgotUsernamePassword extends Component {
    constructor() {
        super();
        this.state = {
            isForgotUserName: false,
            isForgotPassword: true,
            userName: '',
            email: '',
            userNameErrorMessage: '',
            emailErrorMessage: '',
            showAlertModal: false,
            modalHeaderText: '',
            modalBodyText: '',
            loading: false,
            errorMessage: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onIsForgotPasswordChange = (event) => {
        this.setState({
            isForgotUserName: false,
            isForgotPassword: true,
            userName: '',
            email: '',
            errorMessage: ''
        });
    }

    onIsForgotUserNameChange = (event) => {
        this.setState({
            isForgotUserName: true,
            isForgotPassword: false,
            userName: '',
            email: '',
            errorMessage: ''
        });
    }

    onUserNameChange = (event) => {
        this.setState({
            userName: event.target.value,
            userNameErrorMessage: ''
        });
    }

    onEmailChange = (event) => {
        this.setState({
            email: event.target.value,
            emailErrorMessage: ''
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        if (this.validateForm()) {
            this.setState({ loading: true });
            const {
                email,
                userName,
                isForgotUserName,
                isForgotPassword
            } = this.state;

            try {
                if (isForgotUserName) {
                    await forgotUsernameService({ email });
                    this.setState({
                        modalHeaderText: ForgotPasswordUserNameModalText.HeaderText.ForgotUsername,
                        modalBodyText: ForgotPasswordUserNameModalText.BodyText.ForgotUsername
                    });
                }

                if (isForgotPassword) {
                    await forgotPasswordService({ userName });
                    this.setState({
                        modalHeaderText: ForgotPasswordUserNameModalText.HeaderText.ForgotPassword,
                        modalBodyText: ForgotPasswordUserNameModalText.BodyText.ForgotPassword
                    });
                }

                this.setState({
                    loading: false,
                    showAlertModal: true,
                    errorMessage: ''
                });
            } catch (error) {
                const errorMessage = setErrorMessage(error);
                this.setState({ loading: false, errorMessage });
            }
        }
    }

    modalClose = () => {
        this.setState({
            showAlertModal: false,
            modalHeaderText: '',
            modalBodyText: '',
            userName: '',
            email: '',
            errorMessage: ''
        });
    }

    validateForm = () => {
        const {
            isForgotPassword,
            userName,
            email,
            isForgotUserName
        } = this.state;

        let isValid = true;

        if (isForgotPassword) {
            if (userName.trim() === '') {
                this.setState({ userNameErrorMessage: AlertMessages.Common.RequiredUserName });
                isValid = false;
            } else {
                this.setState({ userNameErrorMessage: '' });
            }
        }

        if (isForgotUserName) {
            if (email.trim() === '') {
                this.setState({ emailErrorMessage: 'Please enter email address.' });
                isValid = false;
            } else if (email.trim() !== '' && !RegEx.EmailAddress.test(email)) {
                this.setState({ emailErrorMessage: AlertMessages.Common.ValidEmailAddress });
                isValid = false;
            } else {
                this.setState({ emailErrorMessage: '' });
            }
        }

        return isValid;
    }

    render() {
        const {
            isForgotUserName,
            isForgotPassword,
            userName,
            email,
            userNameErrorMessage,
            emailErrorMessage,
            showAlertModal,
            modalHeaderText,
            modalBodyText,
            loading,
            errorMessage
        } = this.state;

        return (
            <div className="sign-in-flow-main forgot-username-password-main sign-in-flow-new-theme">
                <div className="container sign-in-flow-wrapper">
                    <div className="row">
                        <h1 className="sign-in-intro-heading">
                            Forgot Username or Password
                        </h1>
                    </div>
                    <div className="row">
                        <SignInLeftSidePanel />
                        <div className="col sign-in-flow-right">
                            <form className="sign-in-form">
                                <div className="sign-in-wrapper">
                                    {/* <h2 className="sign-in-form-heading">Forgot Username or Password</h2> */}

                                    <div
                                        style={{ display: errorMessage !== '' }}
                                        className={errorMessage !== '' ? 'alert alert-danger' : ''}
                                    >
                                        <p>{errorMessage}</p>
                                    </div>

                                    <fieldset className="username-password-selector">
                                        <legend className="sr-only">Please choose any one option</legend>
                                        <div className="custom-control custom-radio mb-5">
                                            <input
                                                className="custom-control-input"
                                                id="sendUsername"
                                                name="forgotcredentials"
                                                type="radio"
                                                checked={isForgotUserName}
                                                onChange={(event) => {
                                                    this.onIsForgotUserNameChange(event);
                                                }}
                                            />

                                            <label className="custom-control-label" htmlFor="sendUsername">
                                                Please send me my username.
                                            </label>
                                        </div>

                                        <div className="custom-control custom-radio">
                                            <input
                                                className="custom-control-input"
                                                id="forgotPassword"
                                                name="forgotcredentials"
                                                type="radio"
                                                checked={isForgotPassword}
                                                onChange={(event) => {
                                                    this.onIsForgotPasswordChange(event);
                                                }}
                                            />

                                            <label className="custom-control-label" htmlFor="forgotPassword">
                                                I forgot my password.
                                            </label>
                                        </div>
                                    </fieldset>

                                    {isForgotPassword
                                    && (
                                        <div className="form-group">
                                            <label htmlFor="enterUsername">
                                                Enter your username to reset your password
                                            </label>

                                            <input
                                                id="enterUsername"
                                                className={`form-control ${
                                                    userNameErrorMessage === '' ? '' : 'is-invalid'
                                                }`}
                                                type="text"
                                                placeholder="Enter username"
                                                value={userName}
                                                onChange={(event) => {
                                                    this.onUserNameChange(event);
                                                }}
                                            />

                                            <span className="invalid-feedback">
                                                {userNameErrorMessage}
                                            </span>
                                        </div>
                                    )}

                                    { isForgotUserName
                                    && (
                                        <div className="form-group">
                                            <label htmlFor="enterEmail">
                                                Enter your email to retrieve your username
                                            </label>

                                            <input
                                                id="enterEmail"
                                                className={`form-control ${
                                                    emailErrorMessage === '' ? '' : 'is-invalid'
                                                }`}
                                                type="text"
                                                placeholder="Enter your email address"
                                                value={email}
                                                onChange={(event) => {
                                                    this.onEmailChange(event);
                                                }}
                                            />

                                            <span className="invalid-feedback">
                                                {emailErrorMessage}
                                            </span>
                                        </div>
                                    )}

                                    <button
                                        className="primary-btn-royal-blue sign-in-btn"
                                        type="submit"
                                        onClick={(event) => {
                                            this.onSubmit(event);
                                        }}
                                    >
                                        submit
                                    </button>

                                    <p className="more-info-text">
                                        Don’t have an account?
                                        {' '}
                                        <a href="https://mediaagent.wpengine.com/get-in-touch/">
                                            Request more information.
                                        </a>
                                    </p>
                                </div>
                            </form>

                            <ResetPasswordModal
                                show={showAlertModal}
                                headerText={modalHeaderText}
                                bodyText={modalBodyText}
                                modalClose={this.modalClose}
                                reSubmit={this.onSubmit}
                            />

                            {loading ? <Spinner loaderClass="loader-fullscreen" /> : null}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotUsernamePassword;
