import { API } from '../../helpers/apiHelpers';

const getPackageSercvice = () => {
    const url = '/packages';
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getCompanyPackageSercvice = (companyId) => {
    const url = '/packages/company?companyId=' + companyId;
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getCompanyPackageDicountsService = (companyId) => {
    const url = '/company/' + companyId + '/packagecompanydiscounts';
    return API.get(url)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export { getPackageSercvice, getCompanyPackageSercvice, getCompanyPackageDicountsService };
