import $ from 'jquery';
import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    if (isDesktop) {
        $('.App').addClass('App-dektop-view');
        return children;
    }
    $('.App').removeClass('App-dektop-view');

    return null;
};

const TabletOrMobile = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1199 });

    if (isTablet) {
        $('.App').addClass('App-mobile-view');
        return children;
    }

    $('.App').removeClass('App-mobile-view');

    return null;
};

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    if (isMobile) {
        $('.App').addClass('App-mobile-view');
        return children;
    }

    $('.App').removeClass('App-mobile-view');

    return null;
};

const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });

    if (isNotMobile) {
        $('.App').addClass('App-dektop-view');
        return children;
    }
    $('.App').removeClass('App-dektop-view');

    return null;
};

export {
    Desktop,
    TabletOrMobile,
    Mobile,
    Default
};
