import React from 'react';

const Spinner = (props) => {
    const { loaderClass, message } = props;

    return (
        <div className={loaderClass}>
            <div className="loader">Loading...</div>

            {message && <div className="loader-message"><p>{message}</p></div>}
        </div>
    );
};

export default Spinner;
