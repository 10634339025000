/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
import React from 'react';
// import Header from '../common/Header';
import AuthHeader from '../account/auth/AuthHeader';

const SignInLayout = (props) => {
    const { children } = props;
    return (
        <>
			<AuthHeader isNavVisibile="show" />
            {children}
        </>
    );
};

export default SignInLayout;
