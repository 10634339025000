/* eslint-disable array-callback-return */
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';

const initialState = {
    companyName: null,
    companyLegalName: null,
    companyId: null,
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    isLocationSpecificBudget: null,
    mediaBudgetTotal: null,
    intakeStepId: 0,
    locationBudgetComment: '',
    errorMessage: null,
    locations: [
        {
            location: {
                locationId: null,
                companyId: null,
                storeLocationName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                budget: null,
                noLocationsInMarket: null,
                isNational: false
            },
            errorMessages: {
                storeLocationNameError: '',
                address1Error: '',
                cityError: '',
                stateError: '',
                zipError: '',
                budgetError: ''
            }
        }
    ],
    marketLocations: [{
        location: {
            locationId: null,
            storeLocationName: '',
            budget: null,
            noLocationsInMarket: 1,
            isNational: false
        },
        errorMessages: {
            storeLocationNameError: '',
            numberOfLocationError: '',
            budgetError: ''
        }
    }],
    noSpecificLocations: [{
        location: {
            locationId: null,
            storeLocationName: 'NA',
            budget: null,
            noLocationsInMarket: 1,
            isNational: true
        },
        errorMessages: {
            storeLocationNameError: '',
            numberOfLocationError: '',
            budgetError: ''
        }
    }],
    redirect: false,
    redirectToHowItWorks: false,
    packageId: null,
    campaignId: null,
    loading: false,
    isSaved: false,
    isFormSubmitted: false,
    allLocations: []
};

const initialIndividualLocation = {
    location: {
        locationId: null,
        companyId: null,
        storeLocationName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        budget: null,
        noLocationsInMarket: null,
        isAddNewLocation: true,
        isNational: false
    },
    errorMessages: {
        storeLocationNameError: '',
        address1Error: '',
        cityError: '',
        stateError: '',
        zipError: '',
        budgetError: ''
    }
};

const initialGroupLocation = {
    location: {
        locationId: null,
        storeLocationName: '',
        budget: null,
        noLocationsInMarket: 1,
        isAddNewLocation: true,
        isNational: false
    },
    errorMessages: {
        storeLocationNameError: '',
        numberOfLocationError: '',
        budgetError: ''
    }
};

const initialNoSpecificLocation = {
    location: {
        locationId: null,
        storeLocationName: 'NA',
        budget: null,
        noLocationsInMarket: 1,
        isAddNewLocation: true,
        isNational: false
    },
    errorMessages: {
        storeLocationNameError: '',
        numberOfLocationError: '',
        budgetError: ''
    }
};

export const locationStart = (state, action) => {
    return updateObject(state, { errorMessage: null, loading: true });
};

export const updateIsSaved = (state, action) => {
    return updateObject(state, { isSaved: action.data });
};
export const locationFinished = (state, action) => {
    return updateObject(state, { loading: false });
};

const createNoSpecificLocationObject = (item, type) => {
    return {
        location: {
            locationId: item.id === undefined ? item.locationId : item.id,
            storeLocationName: item.storeLocationName,
            budget: type === 'GET' ? item.locationBudget.intakeMediaBudget : item.budget,
            noLocationsInMarket: item.noLocationsInMarket,
            isNational: item.isNational
        },
        errorMessages: {
            storeLocationNameError: '',
            numberOfLocationError: '',
            budgetError: ''
        }
    };
};

const createLocationObject = (item, type) => {
    return {
        location: {
            locationId: item.id === undefined ? item.locationId : item.id,
            companyId: item.companyId,
            storeLocationName: item.storeLocationName,
            address1: item.address1,
            address2: item.address2,
            city: item.city,
            state: item.state,
            zip: item.zip,
            budget: type === 'GET' ? item.locationBudget.intakeMediaBudget : item.budget,
            noLocationsInMarket: item.noLocationsInMarket,
            isAddNewLocation: false,
            isNational: item.isNational
        },
        errorMessages: {
            storeLocationNameError: '',
            address1Error: '',
            stateError: '',
            cityError: '',
            zipError: '',
            budgetError: ''
        }
    };
};

const createMarketLocationObject = (item, type) => {
    return {
        location: {
            locationId: item.id === undefined ? item.locationId : item.id,
            storeLocationName: item.storeLocationName,
            budget: type === 'GET' ? item.locationBudget.intakeMediaBudget : item.budget,
            noLocationsInMarket: item.noLocationsInMarket,
            isNational: item.isNational
        },
        errorMessages: {
            storeLocationNameError: '',
            numberOfLocationError: '',
            budgetError: ''
        }
    };
};

const locationSuccess = (state, action) => {
    const tempLocations = [];
    const tempMarketLocations = [];
    const tempNoSpecificLocations = [];
    let isIndividualLocation = false;
    let isNoSpecificLocation = false;
    let isGroupLocation = false;
    if (action.data.locations.locationDetails[0].address1 === null
        && !action.data.locations.locationDetails[0].isNational) {
        action.data.locations.locationDetails.map((item, index) => {
            tempMarketLocations.push(createMarketLocationObject(item, 'POST'));
            console.log(action.data.locations.locationDetails);
        });
        isGroupLocation = true;
    } else if (action.data.locations.locationDetails[0].isNational) {
        action.data.locations.locationDetails.map((item, index) => {
            tempNoSpecificLocations.push(createNoSpecificLocationObject(item, 'POST'));
            console.log(action.data.locations.locationDetails);
        });
        isNoSpecificLocation = true;
    } else {
        action.data.locations.locationDetails.map((item, index) => {
            tempLocations.push(createLocationObject(item, 'POST'));
        });
        isIndividualLocation = true;
    }
    return updateObject(state, {
        redirect: true,
        mediaBudgetTotal: action.data.locations.mediaBudget,
        redirectToHowItWorks: false,
        locations: (isIndividualLocation)
            ? tempLocations : [initialIndividualLocation],
        marketLocations: (isGroupLocation)
            ? tempMarketLocations : [initialGroupLocation],
        noSpecificLocations: (isNoSpecificLocation)
            ? tempNoSpecificLocations : [initialNoSpecificLocation],
        isLocationSpecificBudget: action.data.locations.isLocationSpecificBudget,
        locationBudgetComment: action.data.locationBudgetComment,
        // firstName: action.data.personData.firstName,
        // lastName: action.data.personData.lastName,
        // contactNumber: action.data.personData.contactNumber,
        loading: false,
        isSaved: true,
        errorMessage: null,
        isFormSubmitted: true
        // companyName: action.data.companyObject.companyName,
        // companyLegalName: action.data.companyObject.companyLegalName
    });
};

const locationFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.errorMessage,
        loading: false
    });
};

const deleteCampaignSuccess = (state, action) => {
    return updateObject(state, {
        errorMessage: null,
        redirectToHowItWorks: true,
        isFormSubmitted: false
    });
};

const updateCompanyDetails = (state, action) => {
    return updateObject(state, {
        companyId: action.data.id,
        companyName: action.data.companyName,
        companyLegalName: action.data.companyLegalName,
        loading: false
    });
};

const updateLocationAndMediaBudgetState = (state, action) => {
    return updateObject(state, {
        packageId: action.data.campaignPackage.id,
        mediaBudgetTotal: action.data.mediaBudgetTotal,
        redirectToHowItWorks: false,
        isLocationSpecificBudget: action.data.isLocationSpecificBudget,
        locationBudgetComment: action.data.locationBudgetComment,
        errorMessage: null
        // companyName: action.data.campaignCompany.companyName,
        // companyLegalName: action.data.campaignCompany.companyLegalName,
        // companyId: action.data.campaignCompany.id
    });
};

const setIndividualLocationDetails = (state, action) => {
    const tempLocations = [];
    const tempMarketLocations = [];
    const tempNoSpecificLocations = [];
    let isIndividualLocation = false;
    let isGroupLocation = false;
    if (action.data.campaignLocationList.length === 0) {
        tempLocations.push(initialIndividualLocation);
        tempMarketLocations.push(initialGroupLocation);
    } else if (action.data.campaignLocationList[0].address1 === null
        && !action.data.campaignLocationList[0].isNational) {
        action.data.campaignLocationList.map((item, index) => {
            tempMarketLocations.push(createMarketLocationObject(item, 'GET'));
        });
        isGroupLocation = true;
    } else if (action.data.campaignLocationList[0].isNational) {
        action.data.campaignLocationList.map((item, index) => {
            tempNoSpecificLocations.push(createNoSpecificLocationObject(item, 'GET'));
        });
    } else {
        action.data.campaignLocationList.map((item, index) => {
            tempLocations.push(createLocationObject(item, 'GET'));
        });
        isIndividualLocation = true;
    }

    return updateObject(state, {
        locations: isIndividualLocation ? tempLocations : [initialIndividualLocation],
        marketLocations: isGroupLocation ? tempMarketLocations : [initialGroupLocation],
        noSpecificLocations: tempNoSpecificLocations,
        loading: false
    });
};

const updateLocationPersonDetails = (state, action) => {
    return updateObject(state, {
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        contactNumber: action.data.contactNumber,
        email: action.data.email
    });
};

export const initialStateAfterDelete = {
    companyName: null,
    companyLegalName: null,
    companyId: null,
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    isLocationSpecificBudget: null,
    mediaBudgetTotal: null,
    intakeStepId: 0,
    locationBudgetComment: '',
    errorMessage: null,
    locations: [
        {
            location: {
                locationId: null,
                companyId: null,
                storeLocationName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                budget: null,
                numberOfLocations: null,
                isNational: false
            },
            errorMessages: {
                storeLocationNameError: '',
                address1Error: '',
                cityError: '',
                stateError: '',
                zipError: '',
                budgetError: ''
            }
        }
    ],
    marketLocations: [{
        location: {
            locationId: null,
            storeLocationName: '',
            budget: null,
            noLocationsInMarket: 1,
            isNational: false
        },
        errorMessages: {
            storeLocationNameError: '',
            numberOfLocationError: '',
            budgetError: ''
        }
    }],
    noSpecificLocations: [{
        location: {
            locationId: null,
            storeLocationName: 'NA',
            budget: null,
            noLocationsInMarket: 1,
            isNational: true
        },
        errorMessages: {
            storeLocationNameError: '',
            numberOfLocationError: '',
            budgetError: ''
        }
    }],
    redirect: false,
    redirectToHowItWorks: false,
    packageId: null,
    campaignId: null,
    loading: false,
    isSaved: false,
    isFormSubmitted: false
};

const setLocationInitialState = (state, action) => {
    return updateObject(state,
        {
            initialState: initialStateAfterDelete
        });
};

const updateCompanyAllLocations = (state, action) => {
    return updateObject(state, {
        allLocations: action.data.filter((x) => { return x.isNational === false; })
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.LOCATION_START:
        return locationStart(state, action);
    case actionTypes.LOCATION_SUCCESS:
        return locationSuccess(state, action);
    case actionTypes.LOCATION_FAIL:
        return locationFail(state, action);
    case actionTypes.IS_SAVED:
        return updateIsSaved(state, action);
    case actionTypes.DELETE_CAMPAIGN_SUCCESS:
        return deleteCampaignSuccess(state, action);
    case actionTypes.LOCATION_FINISHED:
        return locationFinished(state, action);
    case actionTypes.UPDATE_COMPANY_DETAILS:
        return updateCompanyDetails(state, action);
    case actionTypes.UPDATE_LOCATION_AND_MEDIA_BUDGET_STATE:
        return updateLocationAndMediaBudgetState(state, action);
    case actionTypes.SET_LOCATION_INITIAL_STATE:
        return setLocationInitialState(state, action);
    case actionTypes.SET_INDIVIDUAL_LOCATION_DETAILS:
        return setIndividualLocationDetails(state, action);
    case actionTypes.SET_LOCATION_PERSON_DETAILS:
        return updateLocationPersonDetails(state, action);
    case actionTypes.CLEAR_ALL_PACKAGE_RELATED_STORES:
        return initialState;
    case actionTypes.GET_CUSTOMER_COMPANY_LOCATION_SUCCESS:
        return updateCompanyAllLocations(state, action);
    default:
        return state;
    }
};

export default reducer;
