/* eslint-disable max-len */
/* eslint-disable no-script-url */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React from 'react';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';

import AccountDetails from '../customer/accountSettings/AccountDetails';
import Spinner from './Loader';
import Debounce from '../Utils/Debounce';

import {
    logout as logoutService
} from '../../store/actions/auth';


import {
    getCampaignDetails
} from '../../store/actions/customerCampaignDetails';

import {
    TaskType,
    PackageId
} from '../Constants';

import { isLoggedInIsAdmin, isAuthenticated } from '../../utils/utility_helpers';
import Logo from '../../assets/images/Empower-new-logo.svg';
import List from '../../assets/images/icon-list.svg';
import Error from '../../assets/images/icon-error.svg';
import PieChart from '../../assets/images/icon-pie-chart.svg';
import Messages from '../../assets/images/icon-messages.svg';
import DownArrowGrey from '../../assets/images/icon-down-arrow-dark-grey.svg';

class CustomerDashBoardHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            prevScrollpos: window.pageYOffset,
            shadow: true,
            show: false
        };
    }

    // Adds an event listener when the component is mount.
    componentDidMount() {
        if (!isLoggedInIsAdmin()) {
            document.body.classList.remove('admin');
            document.body.classList.add('customer');
        }
        if (isLoggedInIsAdmin()) {
            window.location.href = '/admin/list-customers';
        }
        window.addEventListener('scroll', this.handleScroll);
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener('scroll', Debounce(this.handleScroll, 20));
    }

    // Hide or show the menu.
    handleScroll = () => {
        const { prevScrollpos } = this.state;
        const currentScrollPos = window.pageYOffset;
        const shadow = prevScrollpos < 93;

        this.setState({
            prevScrollpos: currentScrollPos,
            shadow
        });
    };

    handleLogout = (event) => {
        const { onLogout } = this.props;
        event.preventDefault();

        onLogout();
    };

    handleActionItems = (item, type) => {
        const {
            getCampaigns,
            campaignList,
            companyId,
            campaignId
        } = this.props;

        const data = {};

        const selectedCampaign = campaignList && campaignList.find((list) => {
            return list.campaignId === item.campaignId;
        });

        let selectedAction = 'deliverable';
        const isSameCampaignSelected = campaignId === selectedCampaign.campaignId;
        const isMIMPackageSelected = selectedCampaign.packageId === Number(PackageId.MarketingImpactModel);

        data.isSameCampaignSelected = isSameCampaignSelected;
        data.isMIMPackageSelected = isMIMPackageSelected;

        if (type === 'task') {
            if (item && item.taskType
                && (item.taskType.toLowerCase() === TaskType.submitForReview.toLowerCase()
                || item.taskType.toLowerCase() === TaskType.TrafficOrRequestRevisions.toLowerCase())) {
                data.showCreativeUpload = true;
                data.channelTaskDetails = {};
                data.campaignId = item.campaignId;
                data.isChannelCreativeSelected = true;
                data.addOtherCreative = false;
                data.taskId = item.workFlowTaskId;

                selectedAction = 'creativeUpload';
            } else {
                data.selectedTask = item;
                data.showUpdateTaskDetailsPopup = true;
                data.campaignId = item.campaignId;

                selectedAction = 'taskUpdate';
            }
        } else if (type === 'deliverable') {
            if (item && !item.isRead) {
                data.campaignId = item.campaignId;
                data.companyId = companyId;
                data.file = item;

                selectedAction = 'deliverable';
            }
        } else if (type === 'message') {
            data.campaignId = item.campaignId;
            data.showMessageDetails = true;
            data.isRedirectToCustomerDashboard = true;
            data.showMessageListPopup = false;
            data.threadId = item.threadId || 0;
            data.messageId = [item.messageId] || [];
            data.isRead = item.isRead;
            data.companyId = companyId;

            selectedAction = 'message';
        }

        getCampaigns(selectedCampaign, selectedAction, data);
    }

    profileSettings = () => {
        this.setState({ show: true });
    }

    profileSettingsClose = () => {
        this.setState({
            show: false
        });
    }

    render() {
        const {
            shadow,
            show
        } = this.state;

        const {
            loading,
            activeTaskActionItems,
            deliverableActionItems,
            messageActionItems,
            pastDueTaskActionItems
        } = this.props;

		const query = window.location.pathname;
		let isCampaignActive = false;
		if (query && query.includes('campaign-details')) {
			isCampaignActive = true;
		}
		let isCreativeLibraryActive = false;
		if (query && query.includes('creative-library')) {
			isCreativeLibraryActive = true;
		}
        return (
            <header
                className={classnames('header-wrapper header-dashboard-wrapper', {
                    'header-shadow': !shadow
                })}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 header-left">
                            <div className="media-agent-logo-wrapper">
                                <a>
                                    <img src={Logo} className="img-fluid" alt="Media Agent Dashboard Logo" />
                                </a>
                            </div>
                        </div>

                        <div className="col-sm-9 header-right">
							<ul className="navigation-list">
								<li className="navigation-list-item">
									<a className={`${isCreativeLibraryActive ? 'active' : ''}`} href="/customer/creative-library">CREATIVE LIBRARY</a>
								</li>
								<li className="navigation-list-item">
									<a className={`${isCampaignActive ? 'active' : ''}`} href="/customer/campaign-details">CAMPAIGNS</a>
								</li>
								<li>
									<Dropdown className="navigation-list-item">
										<Dropdown.Toggle
											className="welcome-user-link dropdown-toggle-btn"
											id="dropdown-basic-user"
										>
											{`Welcome, ${localStorage.getItem('FirstName') ? localStorage.getItem('FirstName') : ''}`}
										</Dropdown.Toggle>

										<Dropdown.Menu
											alignRight
										>
											<Dropdown.Item
												onClick={this.handleLogout}
											>
												Log out
											</Dropdown.Item>
											<Dropdown.Item
												onClick={this.profileSettings}
											>
												Account Settings
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</li>
								{deliverableActionItems && deliverableActionItems.length ? (
									<li>
										<Dropdown className="navigation-list-item">
											<Dropdown.Toggle
												className="piechart-link dropdown-toggle-btn"
												id="dropdown-basic-piechart"
											>
												<img src={PieChart} width="35px" alt="icon-piechart" />

												<span className="badge badge-piechart">{deliverableActionItems.length}</span>

												<img
													className="down-arrow-icon"
													src={DownArrowGrey}
													alt="icon-down-arrow"
												/>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												{deliverableActionItems.map((list) => {
													return (
														<Dropdown.Item onClick={() => { this.handleActionItems(list, 'deliverable'); }}>
															{list.fileName}
														</Dropdown.Item>
													);
												})}
											</Dropdown.Menu>
										</Dropdown>
									</li>
								) : null}

								{pastDueTaskActionItems && pastDueTaskActionItems.length
									? (
										<li>
											<Dropdown className="navigation-list-item">
												<Dropdown.Toggle
													className="warning-link dropdown-toggle-btn"
													id="dropdown-basic-warning"
												>
													<img src={Error} width="29px" alt="icon-error" />

													<span className="badge badge-error">{pastDueTaskActionItems.length}</span>

													<img
														className="down-arrow-icon"
														src={DownArrowGrey}
														alt="icon-down-arrow"
													/>
												</Dropdown.Toggle>

												<Dropdown.Menu className="dropdown-menu-error">
													{pastDueTaskActionItems.map((list) => {
														return (
															<Dropdown.Item onClick={() => { this.handleActionItems(list, 'task'); }}>
																{list.taskName}
															</Dropdown.Item>
														);
													})}
												</Dropdown.Menu>
											</Dropdown>
										</li>
									) : null}

								{activeTaskActionItems && activeTaskActionItems.length
									? (
										<li>

											<Dropdown className="navigation-list-item">
												<Dropdown.Toggle
													className="messages-list dropdown-toggle-btn"
													id="dropdown-basic-list"
												>
													<img src={List} width="25px" alt="icon-list" />

													<span className="badge">{activeTaskActionItems.length}</span>

													<img
														className="down-arrow-icon"
														src={DownArrowGrey}
														alt="icon-down-arrow"
													/>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{activeTaskActionItems.map((list) => {
														return (
															<Dropdown.Item onClick={() => { this.handleActionItems(list, 'task'); }}>
																{list.taskName}
															</Dropdown.Item>
														);
													})}
												</Dropdown.Menu>
											</Dropdown>
										</li>
									) : null}

								{messageActionItems && messageActionItems.length
									? (
										<li>
											<Dropdown className="navigation-list-item">
												<Dropdown.Toggle
													className="messages-link dropdown-toggle-btn"
													id="dropdown-basic-messages"
												>
													<img src={Messages} alt="icon-messages" />

													<span className="badge">{messageActionItems.length}</span>

													<img
														className="down-arrow-icon"
														src={DownArrowGrey}
														alt="icon-down-arrow"
													/>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{messageActionItems.map((list) => {
														return (
															<Dropdown.Item onClick={() => { this.handleActionItems(list, 'message'); }}>
																{list.subject}
															</Dropdown.Item>
														);
													})}
												</Dropdown.Menu>
											</Dropdown>
										</li>
									) : null}
							</ul>
                        </div>
                    </div>
                </div>

                {loading && <Spinner loaderClass="loader-fullscreen" />}

                {!isAuthenticated() && <Redirect to="/login" />}

                <AccountDetails
                    show={show}
                    close={this.profileSettingsClose}
                />
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.logoutLoading,
        errorMessage: state.auth.errorMessage,
        redirect: state.auth.redirect,
        activeTaskActionItems: state.customerCampaignDetails.activeTaskActionItems,
        deliverableActionItems: state.customerCampaignDetails.deliverableActionItems,
        messageActionItems: state.customerCampaignDetails.messageActionItems,
        pastDueTaskActionItems: state.customerCampaignDetails.pastDueTaskActionItems,
        campaignId: state.customerCampaignDetails.campaignId,
        campaignList: state.customerCampaignDetails.campaignList,
        companyId: state.customerCampaignDetails.companyId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => {
            return dispatch(logoutService());
        },
        getCampaigns: (selectedCampaign, selectedAction, data) => {
            return dispatch(getCampaignDetails(selectedCampaign, selectedAction, data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDashBoardHeader);
