import React from 'react';

const PartialLayout = (props) => {
    const { children } = props;
    return (
        <div className="user-activation-main">
            <div className="container user-activation-wrapper">
                <div className="row">{children}</div>
            </div>
        </div>
    );
};

export default PartialLayout;
