import { API } from '../../helpers/apiHelpers';


const getMarketingPersonDetails = async () => {
    const campaignId = localStorage.getItem('CampaignId');
    const url = `/campaigns/${campaignId}?isDetailed=false`;
    try {
        return await API.get(url);
    } catch (error) {
        return Promise.reject(error);
    }
};


export default getMarketingPersonDetails;
