/* eslint-disable array-callback-return */
/* eslint-disable no-tabs */
/* eslint-disable indent */
import { updateObject } from '../../utils/reducer_utility';

import {
    // Campaign Details Section
    CUSTOMER_CAMPAIGN_START,
    CUSTOMER_CAMPAIGN_END,
    GET_CUSTOMER_CAMPAIGN_LIST,
    GET_CUSTOMER_CAMPAIGN_LIST_ERROR,
    CUSTOMER_CAMPAIGN_DATA,
    CUSTOMER_GET_CALL_TO_ACTION_ITEMS,
    CHANGE_IS_NEW_CAMPAIGN_FLAG,
    CUSTOMER_REWARD_NOTIFICATION,
    CUSTOMER_FETCH_CAMPAIGN_DETAILS_START,
    CUSTOMER_FETCH_CAMPAIGN_DETAILS_END,

    // Reward Points Section
    GET_CAMPAIGN_TOTAL_REWARD_POINTS_SUCCESS,

    // Message Section
    GET_CUSTOMER_CAMPAIGN_MESSAGE_SUCCESS,
    GET_CUSTOMER_CAMPAIGN_MESSAGE_FAIL,
    CUSTOMER_UPDATE_MESSAGE_FAIL,
    CUSTOMER_SHOW_COMPOSE_MESSAGE,
    CUSTOMER_MESSAGE_ERROR_HANDLER,
    CUSTOMER_SHOW_VIEW_MESSAGE,

    // Deliverable Section
    CUSTOMER_GET_DELIVERABLE_START,
    CUSTOMER_DELIVERABLE_LIST_ERROR,
    CUSTOMER_DELIVERABLES_LIST_SUCCESS,

    // Tasks Section
    CUSTOMER_GET_CAMPAIGN_TASKS_START,
    CUSTOMER_GET_CAMPAIGN_TASKS_SUCCESS,
    CUSTOMER_GET_CAMPAIGN_TASKS_FAIL,
    CUSTOMER_SHOW_UPDATE_TASK_DETAILS_POPUP,
    CUSTOMER_UPDATE_TASK_DETAILS_SUCCESS,
    CUSTOMER_UPDATE_TASK_DETAILS_ERROR,

    // Documents Section
    CUSTOMER_CAMPAIGN_DOCUMENT_SUCCESS,
    CUSTOMER_CAMPAIGN_DOCUMENT_FAIL
} from '../actions/actionTypes';

const initialState = {
    loading: false,
    loadingMessage: '',
    fetchLoading: false,

    // Campaign Details Section
    campaignList: [],
    campaignListErrorMessage: '',
    campaignId: 0,
    isArchivedCampaign: false,
    selectedCampaign: {},
    companyId: 0,

    // Reward Points Section
    totalRewardPoints: null,
    showRewardNotification: false,

    // Message Section
    messageList: [],
    messageListError: '',
    newMessagesList: [],
    showComposeMessage: false,
    showMessageListPopup: false,
    messageError: '',
    showMessageDetails: false,
    messageDetailsList: [],
    isRedirectToCustomerDashboard: false,

    // Deliverable Section
    deliverableLoading: false,
    deliverableErrorMessage: '',
    deliverableList: [],

    // Tasks Section
    taskListLoading: false,
    taskListErrorMessage: '',
    taskList: [],
    showUpdateTaskDetailsPopup: false,
    tasksErrorMessage: '',
    selectedTask: {},

    // Documents Section
    documentList: [],
    documentErrorMessage: '',

    // Action Items Section
    activeTaskActionItems: [],
    deliverableActionItems: [],
    messageActionItems: [],
    pastDueTaskActionItems: [],

    // New campaign
    isNewCampaignEnabled: false
};

const buildMessageList = (messageList) => {
    const list = [];
    messageList.map((item) => {
        const customer = (item.messages.filter((x) => { return x.fromPersonRole === 'Customer'; })).sort((a, b) => {
            return ((b.createdDateTime > a.createdDateTime) ? 1 : -1);
        });
        if (customer.length > 0) {
            list.push(customer[0]);
        }
        const admin = (item.messages.filter((x) => { return x.fromPersonRole !== 'Customer'; })).sort((a, b) => {
            return ((b.createdDateTime > a.createdDateTime) ? 1 : -1);
        });
        if (admin.length > 0) {
            list.push(admin[0]);
        }
    });
    return list.sort((a, b) => {
        return ((a.createdDateTime < b.createdDateTime) ? 1 : -1);
    });
};

const customerCampaignDetails = (state = initialState, action) => {
    switch (action.type) {
    case CUSTOMER_CAMPAIGN_START:
        return updateObject(state, {
            loading: true
        });

    case CUSTOMER_CAMPAIGN_END:
        return updateObject(state, {
            loading: false
        });
    case CUSTOMER_FETCH_CAMPAIGN_DETAILS_START: {
        return updateObject(state, {
            fetchLoading: true
        });
    }
    case CUSTOMER_FETCH_CAMPAIGN_DETAILS_END: {
        return updateObject(state, {
            fetchLoading: false
        });
    }

    // Campaign Details Section
		case GET_CUSTOMER_CAMPAIGN_LIST: {
		action.data.sort((a, b) => {
			return Date.parse(b.campaignCreatedDateTime) - Date.parse(a.campaignCreatedDateTime);
		});
        const firstCampaign = action.data && action.data ? action.data[0] : {};
        const campaignId = firstCampaign && firstCampaign.campaignId ? firstCampaign.campaignId : '';
        const packageId = firstCampaign && firstCampaign.packageId ? firstCampaign.packageId : 0;
        const companyId = firstCampaign && firstCampaign.companyId ? firstCampaign.companyId : 0;
        const showRewardNotification = firstCampaign
            && firstCampaign.isRewardNotification ? firstCampaign.isRewardNotification : false;
        const isArchivedCampaign = firstCampaign
        && firstCampaign.isComplete ? firstCampaign.isComplete : false;

        return updateObject(state, {
            campaignList: action.data,
            campaignListErrorMessage: '',
            campaignId,
            packageId,
            companyId,
            selectedCampaign: firstCampaign,
            showRewardNotification,
            isArchivedCampaign
        });
    }
    case GET_CUSTOMER_CAMPAIGN_LIST_ERROR: {
        return updateObject(state, {
            campaignListErrorMessage: action.data
        });
    }
    case CUSTOMER_CAMPAIGN_DATA: {
        return updateObject(state, {
            selectedCampaign: action.data,
            campaignId: action.data.campaignId ? action.data.campaignId : 0,
            packageId: action.data.packageId ? action.data.packageId : 0,
            isArchivedCampaign: action.data.isComplete ? action.data.isComplete : false,
            showRewardNotification: action.data.isRewardNotification
                ? action.data.isRewardNotification : false
        });
    }

    case CUSTOMER_GET_CALL_TO_ACTION_ITEMS: {
        const {
            activeTaskActionItems,
            deliverableActionItems,
            messageActionItems,
            pastDueTaskActionItems
        } = action.data;

        return updateObject(state, {
            activeTaskActionItems,
            deliverableActionItems,
            messageActionItems,
            pastDueTaskActionItems
        });
    }

    // Reward Points Section
    case GET_CAMPAIGN_TOTAL_REWARD_POINTS_SUCCESS: {
        return updateObject(state, {
            totalRewardPoints: action.data
        });
    }
    case CUSTOMER_REWARD_NOTIFICATION: {
        const campaignList = [...state.campaignList];
        const selectedCampaign = campaignList.find((item) => {
            return item.campaignId === action.data.campaignId;
        });
        if (selectedCampaign) {
            selectedCampaign.isRewardNotification = false;
        }

        return updateObject(state, {
            campaignList,
            showRewardNotification: action.data.showRewardNotification !== false
        });
    }

    // Message Section
    case GET_CUSTOMER_CAMPAIGN_MESSAGE_SUCCESS: {
        const list = buildMessageList(action.data);
        // const personId = localStorage.getItem('PersonId') ? localStorage.getItem('PersonId') : 0;
        let newMessagesList = list.filter((x) => {
            return x.isRead === false && x.fromPersonRole !== 'Customer';
        });
        newMessagesList = newMessagesList.sort((a, b) => {
            return ((a.createdDateTime < b.createdDateTime) ? 1 : -1);
        });
        return updateObject(state, {
            messageList: list,
            newMessagesList,
            messageListError: ''
        });
    }
    case GET_CUSTOMER_CAMPAIGN_MESSAGE_FAIL: {
        return updateObject(state, {
            loading: false,
            messageList: [],
            newMessagesList: [],
            messageListError: action.errorMessage
        });
    }

    case CUSTOMER_UPDATE_MESSAGE_FAIL: {
        return updateObject(state, {
            loading: false,
            messageListError: action.errorMessage
        });
    }

    case CUSTOMER_SHOW_COMPOSE_MESSAGE: {
        return updateObject(state, {
            showMessageListPopup: action.data.showMessageListPopup,
            showComposeMessage: action.data.showComposeMessage,
            showMessageDetails: action.data.showMessageDetails,
            messageError: ''
        });
    }
    case CUSTOMER_MESSAGE_ERROR_HANDLER: {
        return updateObject(state, {
            messageError: action.data
        });
    }
    case CUSTOMER_SHOW_VIEW_MESSAGE: {
        return updateObject(state, {
            showMessageListPopup: action.data.showMessageListPopup,
            showMessageDetails: action.data.showMessageDetails,
            messageError: '',
            isRedirectToCustomerDashboard: action.data.isRedirectToCustomerDashboard,
            messageDetailsList: action.data.messageDetailsList || []
        });
    }

    // Deliverable Section
    case CUSTOMER_GET_DELIVERABLE_START: {
        return updateObject(state, {
            deliverableLoading: true,
            deliverableList: []
        });
    }
    case CUSTOMER_DELIVERABLE_LIST_ERROR: {
        return updateObject(state, {
            deliverableLoading: false,
            deliverableErrorMessage: action.data
        });
    }
    case CUSTOMER_DELIVERABLES_LIST_SUCCESS: {
        return updateObject(state, {
            deliverableLoading: false,
            deliverableErrorMessage: '',
            deliverableList: action.data
        });
    }

    // Tasks Section
    case CUSTOMER_GET_CAMPAIGN_TASKS_START: {
        return updateObject(state, {
            taskListLoading: true,
            taskList: []
        });
    }
    case CUSTOMER_GET_CAMPAIGN_TASKS_FAIL: {
        return updateObject(state, {
            taskListLoading: false,
            taskListErrorMessage: action.data
        });
    }
    case CUSTOMER_GET_CAMPAIGN_TASKS_SUCCESS: {
        return updateObject(state, {
            taskListLoading: false,
            taskListErrorMessage: '',
            taskList: action.data
        });
    }
    case CUSTOMER_SHOW_UPDATE_TASK_DETAILS_POPUP: {
        return updateObject(state, {
            showUpdateTaskDetailsPopup: action.data.showUpdateTaskDetailsPopup,
            selectedTask: action.data.selectedTask,
            tasksErrorMessage: ''
        });
    }
    case CUSTOMER_UPDATE_TASK_DETAILS_SUCCESS: {
        return updateObject(state, {
            showUpdateTaskDetailsPopup: false,
            tasksErrorMessage: '',
            isArchivedCampaign: action.data
        });
    }
    case CUSTOMER_UPDATE_TASK_DETAILS_ERROR: {
        return updateObject(state, {
            tasksErrorMessage: action.errorMessage
        });
    }

    // Documents Section
    case CUSTOMER_CAMPAIGN_DOCUMENT_SUCCESS: {
        const tempDocuments = action.data;
        tempDocuments.sort((a, b) => {
            return ((a.modifiedDate < b.modifiedDate) ? 1 : -1);
        });
        return updateObject(state, {
            documentList: tempDocuments,
            documentErrorMessage: ''
        });
    }

    case CUSTOMER_CAMPAIGN_DOCUMENT_FAIL: {
        return updateObject(state, {
            documentList: [],
            documentErrorMessage: action.errorMessage
        });
    }

    // New campaign
    case CHANGE_IS_NEW_CAMPAIGN_FLAG: {
        return updateObject(state, {
            isNewCampaignEnabled: action.data
        });
    }

    default:
        return state;
    }
};

export default customerCampaignDetails;
