/* eslint-disable import/no-cycle */
/* eslint-disable no-tabs */
/* eslint-disable indent */
import * as actionTypes from './actionTypes';
import { locationFinished, updateCompanyDetails, locationFail } from './location';
import { updatePerson } from './person';
import { setErrorMessage } from '../../utils/utility_helpers';
import { getCompanyService, updateCompanyservice, getCompanyPromotions } from '../services/company';

const companyFail = (errorMessage) => {
    return {
        type: actionTypes.COMPANY_FAIL,
        errorMessage
    };
};

const companySuccess = (data) => {
    return {
        type: actionTypes.COMPANY_SUCCESS,
        data
    };
};

export const getCompany = () => {
    return async (dispatch) => {
        try {
            const response = await getCompanyService(localStorage.getItem('CompanyId'));
            dispatch(companySuccess(response.data.data));
            dispatch(updateCompanyDetails(response.data.data));
            dispatch(locationFinished());
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(companyFail(errorMessage));
        }
    };
};

export const updateCompany = (formData) => {
    return async (dispatch) => {
        try {
            await updateCompanyservice(formData.companyObject);
            dispatch(updatePerson(formData));
        } catch (error) {
            const errorMessage = setErrorMessage(error);
            dispatch(locationFail(errorMessage));
            dispatch(companyFail(errorMessage));
        }
    };
};

export const getCompanyPromotionsList = (companyId) => {
	return async (dispatch) => {
		// dispatch({ type: actionTypes.COMPANY_START });
		const response = await getCompanyPromotions(companyId);
		if (response.status === 200) {
			dispatch({
				type: actionTypes.GET_COMPANY_PROMOTIONS_SUCCESS,
				data: response.data
			});
		} else {
			const errorMessage = setErrorMessage(response);
			dispatch({ type: actionTypes.COMPANY_FAIL, data: errorMessage });
		}
	};
};
