import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion';
import CompanyProfileIndex from './CompanyProfileIndex';
import { getCustomerProfile, updateCustomerCredentialsReset, updateCompanyProfileReset } from '../../../store/actions/customerProfile';
import SecurityIndex from './SecurityIndex';
import closeIcon from '../../../assets/images/icon-close.svg';
import { isAuthenticated } from '../../../utils/utility_helpers';

class AccountDetails extends PureComponent {
    constructor() {
        super();
        this.state = {
            // show
        };
    }

    componentDidMount() {
        const { fetchCustomerProfile } = this.props;
        if (isAuthenticated()) {
            fetchCustomerProfile();
        }
    }


    handleClose = () => {
        const {
            close,
            customerCredentialsReset,
            companyProfileReset,
            fetchCustomerProfile
        } = this.props;
        close();
        companyProfileReset();
        customerCredentialsReset();
        fetchCustomerProfile();
    }

    render() {
        const {
            show,
            customerProfile,
            unChangedCompanyDetails
        } = this.props;

        let oldUserName = '';
        if (customerProfile) {
            oldUserName = customerProfile.personDetail.userName;
        }
        console.log(show, oldUserName);
        return (
            <Modal
                show={show}
                onHide={this.handleClose}
                // aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={false}
                className="confirmation-modal modal-popup-large modal-popup-shadow modal-account-setting"
            >
                <Modal.Header>

                    <h1 className="confirmation-modal-title">ACCOUNT SETTINGS</h1>

                    <button className="modal-btn-close" onClick={this.handleClose} type="button">
                        <img src={closeIcon} alt="reset-password-close" />
                    </button>
                </Modal.Header>
                <div className="confirmation-modal customer-account-details">
                    <Modal.Body>
                        <Accordion onChange={(event) => { console.log(event); }} allowMultipleExpanded="true" allowZeroExpanded="true" className="cd-accordion">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        COMPANY PROFILE
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <CompanyProfileIndex
                                        customerProfile={customerProfile}
                                        unChangedCompanyDetails={unChangedCompanyDetails}
                                        oldUserName={oldUserName}
                                    />
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        SECURITY
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <SecurityIndex
                                        customerProfile={customerProfile}
                                    />
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        customerProfile: state.customerProfile.customerProfileDetails,
        unChangedCompanyDetails: state.customerProfile.unChangedCompanyDetails,
        loading: state.customerProfile.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomerProfile: () => {
            return dispatch(getCustomerProfile());
        },
        customerCredentialsReset: () => {
            return dispatch(updateCustomerCredentialsReset());
        },
        companyProfileReset: () => {
            return updateCompanyProfileReset();
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
